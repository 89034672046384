import React, { useState, useEffect } from 'react';
import { ModalFooter, ModalBody, Modal, Col, Input, Row, Alert } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

import Loader from '../../Common/Loader';
import RoundedButton from '../../Buttons/RoundedButton/RoundedButton';

import { loadDiscount, createDiscount, updateDiscount, deleteDiscount } from '../../../helpers/api/discounts.api';

import '../Modals.scss';
import './EditDiscountModal.scss';

function formatBytes(bytes, decimals = 2) {
	if (bytes === 0) return '0 Bytes';
	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const EditDiscountModal = (props) => {
	const [discount, setDiscount] = useState({});

	const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	
	useEffect(() => {
		if (props.code) {
			setLoading(true);
			loadDiscount(props.code)
				.then((result) => {
					if (result.success) {
						setDiscount(result.discount)
					} else {
						setError(result.message);
						setDiscount({})
					}
                    setLoading(false);
				})
				.catch((error) => {
					setError(error.message);
					setLoading(false);
				});
		} else {
            setDiscount({});
            setError('');
            setLoading(false);
            setSuccess(false);
        }
	}, [props.code]);

	function handleOnFieldChange(name, value) {
		let s = { ...discount };
		s[name] = value;
		setDiscount(s);
	}

	async function handleOnSubmit() {
		setLoading(true);

		if (props.code) {
			// if è un edit dello sconto
			updateDiscount(props.code, discount)
				.then((result) => {
					setLoading(false);
					if (result.success) {
						setSuccess(result.message);
						setTimeout(() => {
							props.onSubmit && props.onSubmit();
						}, 2000);
					} else setError(result.message);
				})
				.catch((error) => {
					setLoading(false);
					setError(error.message);
				});
		} else {
			// else lo stiamo creando
			createDiscount(discount)
				.then((result) => {
					setLoading(false);
					if (result.success) {
						setSuccess(result.message);
						setTimeout(() => {
							props.onSubmit && props.onSubmit();
						}, 2000);
					} else setError(result.message);
				})
				.catch((error) => {
					setLoading(false);
					setError(error.message);
				});
		}
	}

	function handleOnClose() {
		if (props.onClose) props.onClose();
		setError(null);
		setSuccess(null);
	}

	function handleOnDeleteConfirm() {
		if (props.code) {
			setLoading(true);
			setDeleteConfirmModal(false);
			deleteDiscount(props.code)
				.then((result) => {
					setLoading(false);
					if (result.success) {
						setSuccess(result.message);
						setTimeout(() => {
							props.onSubmit && props.onSubmit();
						}, 2000);
					} else setError(result.message);
				})
				.catch((error) => {
					setLoading(false);
					setError(error.message);
				});
		}
	}


	return (
		<Modal
            className='edit-discount-modal'
			isOpen={props.isOpen}
			autoFocus={true}
			centered={true}
			backdrop={true}
			toggle={handleOnClose}>
			<ModalBody>
				{loading ? (
					<Loader className="p-5" />
				) : success ? (
					<Alert className="mb-4" color="success">
						{success}
					</Alert>
				) : <>
					<p className="error">{error}</p>
					<Row>
						<Col sm="12">
							<h6 className='mb-3'>{props.code ? 'Modifica buono sconto' : 'Crea buono sconto'}</h6>
							<p>
								Codice
							</p>
							<Input
								id="code"
								name="code"
								type="text"
                                className="form-control"
                                value={discount['code'] || ''}
                                onChange={(event) =>
                                    handleOnFieldChange('code', event.target.value)
                                }
							/>
						</Col>
					</Row>
                    <Row>
                        <Col sm="12">
                            <p>
                                Descrizione
                            </p>
							<Input
								id="description"
								name="description"
								type="text"
                                className="form-control"
                                value={discount['description'] || ''}
                                onChange={(event) =>
                                    handleOnFieldChange('description', event.target.value)
                                }
							/>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <p>
							Valore %
                            </p>
							<Input
								id="value"
								name="value"
								type="number"
                                className="form-control"
                                value={discount['value'] || ''}
                                onChange={(event) =>
                                    handleOnFieldChange('value', event.target.value)
                                }
							/>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <p>
                                Quantità
                            </p>
							<Input
								id="qt"
								name="qt"
								type="number"
                                className="form-control"
                                value={discount['qt'] || ''}
                                onChange={(event) =>
                                    handleOnFieldChange('qt', event.target.value)
                                }
							/>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <p>
							Scadenza
                            </p>
							<Input
								id="expiration"
								name="expiration"
								type="date"
                                className="form-control"
                                value={discount['expiration'] || ''}
                                onChange={(event) =>
                                    handleOnFieldChange('expiration', event.target.value)
                                }
							/>
                        </Col>
                    </Row>
				</>}
			</ModalBody>
			{!loading && !success && (
				<ModalFooter className='d-flex align-items-center'>
                    {props.code && (
                        <RoundedButton
                            className="waves-effect px-5 m-0 mt-2 mb-2 mr-2"
                            type="button"
                            color="secondary"
                            onClick={() => setDeleteConfirmModal(true)}>
                            Elimina
                        </RoundedButton>
                    )}
                    <RoundedButton
                        className="waves-effect px-5 m-0 mt-2 mb-2 mr-2 outline"
                        type="button"
                        color="secondary"
                        onClick={handleOnClose}>
                        Annulla
                    </RoundedButton>
					<RoundedButton
						className="waves-effect px-5 m-0 mt-2 mb-2 ml-2"
						type="button"
						color="primary"
						onClick={handleOnSubmit}>
						Salva
					</RoundedButton>
				</ModalFooter>
			)}
            {deleteConfirmModal ? (
                <SweetAlert
                    title={
                        'Vuoi davvero eliminare il servizio?'
                    }
                    showCancel
                    confirmBtnBsStyle="primary"
                    cancelBtnBsStyle="secondary"
                    onConfirm={handleOnDeleteConfirm}
                    onCancel={() => setDeleteConfirmModal(false)}
                />
            ) : null}
		</Modal>
	);
};

export default EditDiscountModal;
