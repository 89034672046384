import React, { useState, useEffect } from 'react';
import { PayPalButton } from 'react-paypal-button-v2';

import Loader from '../Common/Loader';

import * as checkoutAPI from '../../helpers/api/checkout.api';

export default function PaypalCheckoutForm(props) {
	const [loading, setLoading] = useState(false);

	const [clientId, setClientId] = useState('');

	const [amount, setAmount] = useState(0);

	useEffect(() => {
		if (props.amount > 0) {
			// Create PaymentIntent as soon as the page loads
			setLoading(true);
			checkoutAPI
				.prepareCheckout('paypal', props.amount, props.discount)
				.then((result) => {
					if (result.success) {
						setClientId(result.clientId);
						if (result.total)
							setAmount(Number(result.total) / 100);
					} else props.onError && props.onError(result.message);
					setLoading(false);
				})
				.catch((error) => {
					props.onError && props.onError(error.message);
					setLoading(false);
				});
		}
	}, [props.amount]);

	function handleOnSuccess(details, data) {
		setLoading(true);
		checkoutAPI
			.verifyCheckout(details.id, props.discount, 'paypal', props.track, props.title, props.owners)
			.then((result) => {
				if (result.success) {
					props.onSuccess && props.onSuccess();
				} else props.onError && props.onError(result.message);
				setLoading(false);
			})
			.catch((error) => {
				props.onError && props.onError(error.message);
				setLoading(false);
			});
	}
	
	return (
		<div className={props.className}>
			{loading ? <div className='d-flex flex-column align-items-center'>
				<Loader className="mb-2" />
				<p className='ml-0 text-center'>Ancora un attimo di pazienza.</p>
			</div> : clientId && amount > 0 ? (
				<PayPalButton
					amount={amount}
					currency="EUR"
					onError={(error) => {
						props.onError && props.onError(error);
					}}
					options={{
						disableFunding:
							'card,sofort,mybank,blik,eps,giropay,ideal,mercadopago,p24,sepa,venmo',
						locale: 'it_IT',
						clientId: clientId,
						currency: 'EUR'
					}}
					catchError={(error) => {
						props.onError && props.onError(error);
					}}
					onSuccess={handleOnSuccess}
				/>
			) : null}
		</div>
	);
}
