const { API_URL } = require('../../app.config');

export async function loadTracks(authorId, onlyPublic) {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	let url = API_URL + '/tracks?';
	if (authorId) url += '&aId=' + authorId;
	if (onlyPublic) url += '&pbl=' + 1;

	let response = await fetch(url, {
		method: 'GET',
		headers
	}).then((response) => response.json());

	return response;
}

export async function loadTopWeekly() {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	let response = await fetch(API_URL + '/tracks/top/week', {
		method: 'GET',
		headers
	}).then((response) => response.json());

	return response;
}

export async function loadTracksToVote() {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	let response = await fetch(API_URL + '/tracks/to-vote', {
		method: 'GET',
		headers
	}).then((response) => response.json());

	return response;
}

export async function loadTrack(trackId) {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	let response = await fetch(API_URL + '/tracks/' + trackId, {
		method: 'GET',
		headers
	}).then((response) => response.json());

	return response;
}

export async function loadTrackComments(trackId) {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	let response = await fetch(API_URL + '/tracks/' + trackId + '/comments', {
		method: 'GET',
		headers
	}).then((response) => response.json());

	return response;
}

export async function commentTrack(
	trackId, 
	text, 
	time
) {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	const body = JSON.stringify({
		text, time, 
	});

	let response = await fetch(API_URL + '/tracks/' + trackId + '/comments', {
		method: 'POST',
		headers,
		body
	}).then((response) => response.json());

	return response;
}

export async function loadTrackVotings(trackId, userId) {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	let url = API_URL + '/tracks/' + trackId + '/votings?';
	if (userId)
		url += 'voter=' + userId;

	let response = await fetch(url, {
		method: 'GET',
		headers
	}).then((response) => response.json());

	return response;
}

export async function voteTrack(
	trackId, 
	vote, comment
) {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	const body = JSON.stringify({
		vote, comment
	});

	let response = await fetch(API_URL + '/tracks/' + trackId + '/votings', {
		method: 'POST',
		headers,
		body
	}).then((response) => response.json());

	return response;
}

export async function updateTrackPublic(
	trackId, 
	publicStatus
) {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	const body = JSON.stringify({
		public: publicStatus ? 1 : 0
	});

	let response = await fetch(API_URL + '/tracks/' + trackId + '/public', {
		method: 'PUT',
		headers,
		body
	}).then((response) => response.json());

	return response;
}

export async function increaseStream(
	trackId
) {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	const body = JSON.stringify({ });

	let response = await fetch(API_URL + '/tracks/' + trackId + '/streams', {
		method: 'POST',
		headers,
		body
	}).then((response) => response.json());

	return response;
}

export async function renewTrack(
	trackId, days
) {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	const body = JSON.stringify({ days });

	let response = await fetch(API_URL + '/tracks/' + trackId + '/renew', {
		method: 'POST',
		headers,
		body
	}).then((response) => response.json());

	return response;
}

export async function deleteTrack(id) {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	let response = await fetch(API_URL + '/tracks/' + id, {
		method: 'DELETE',
		headers
	}).then((response) => response.json());

	return response;
}