import { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col, Alert } from 'reactstrap';
import SimpleBar from 'simplebar-react';

import { ReactComponent as BellIcon } from '../../../assets/images/notifications.svg';

import './NotificationsDropdown.scss';

function timeSince(date) {
	var seconds = Math.floor((new Date() - date) / 1000);

	var interval = seconds / 31536000;

	if (interval > 1) {
		return Math.floor(interval) + ' anni fa';
	}
	interval = seconds / 2592000;
	if (interval > 1) {
		return Math.floor(interval) + ' mesi fa';
	}
	interval = seconds / 86400;
	if (interval > 1) {
		return Math.floor(interval) + ' giorni fa';
	}
	interval = seconds / 3600;
	if (interval > 1) {
		return Math.floor(interval) + ' ore fa';
	}
	interval = seconds / 60;
	if (interval > 1) {
		return Math.floor(interval) + ' minuti fa';
	}
	return 'adesso'; //Math.floor(seconds) + ' seconds ago';
}

const NotificationsDropdown = (props) => {
	const [show, setShow] = useState(false);

	// async function handleOnNotificationClick(notificationId, notificationLink) {
	// 	//TODO move this to saga
	// 	let userId = JSON.parse(localStorage.getItem('authUser'))._id;
	// 	// await setReadNotification(userId, notificationId);
	// 	props.loadUserNotifications();
	// 	props.history.push(notificationLink);
	// }

	function handleOnShowAllClick() {
		if (props.history.location.pathname === '/notifications')
			props.history.go('/notifications');
	}

	return (
		<>
			<Dropdown
				isOpen={show}
				toggle={() => {
					//setShow(!show)
				}}
				className="dropdown d-inline-block"
				tag="li">
				<DropdownToggle
					className="btn noti-icon waves-effect"
					tag="a"
					href="/notifications"
					id="page-header-notifications-dropdown">
					<BellIcon />
					{props.nUnread > 0 && (
						<span className="badge badge-danger badge-pill ml-2">{props.nUnread < 10 ? props.nUnread : '+9'}</span>
					)}
				</DropdownToggle>

				<DropdownMenu className="dropdown-menu dropdown-menu-lg p-0" right>
					<div className="p-3">
						<Row className="align-items-center">
							<Col>
								<h6 className="m-0">Notifiche</h6>
							</Col>
							<div className="col-auto">
								<Link to="/notifications-list" className="small" onClick={handleOnShowAllClick}>
									Vedi tutte
								</Link>
							</div>
						</Row>
					</div>

					<div className="p-2 border-top">
						<Link
							className="btn btn-sm btn-link font-size-14 btn-block text-center"
							to="/notifications-list" onClick={handleOnShowAllClick}>
							Vedi tutte
						</Link>
					</div>
				</DropdownMenu>
			</Dropdown>
		</>
	);
};

export default withRouter(NotificationsDropdown);
