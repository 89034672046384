import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import moment from 'moment';
import { connect } from 'react-redux';
import ObjectID from 'bson-objectid';

import './ChatRow.scss';

const isToday = (someDate) => {
	const today = new Date()
	return someDate.getDate() == today.getDate() &&
	  someDate.getMonth() == today.getMonth() &&
	  someDate.getFullYear() == today.getFullYear()
}

const ChatRow = ({ chat, userId, onClick, active, unread }) => {
	
	let timestamp = new Date(chat.updatedAt).getTime();
	let updatedAt = isToday(new Date(timestamp)) ? moment(timestamp).format('hh:mm') : moment(timestamp).format('DD/MM/YYYY');

	const contact = chat.members ? chat.members.find(x => x._id !== userId) : null;

	const image = contact ? contact.image : null;
	const title = contact ? contact.name : '';
	const text = chat.messages[chat.messages.length-1].content;
	
	return <Row className={"chat-row " + (unread ? 'unread ' : '') + (active ? 'active ' : '')}>
			<Col className="h-100" sm="12">
				<Button color="link" className={"d-flex align-items-center justify-content-between h-100 "}
				onClick={onClick}>
					{image && image.url ? (
						<div
							style={{ backgroundImage: 'url(' + image.url + ')'}}
							className="avatar mr-4"
							alt="chat"
						/>
					) : null}
					<div className="d-flex flex-column align-items-start justify-content-center" style={{flex: 1}}>
						<p className="text-truncate font-size-14 d-inline mb-0">
							{title}
						</p>
						<small className="text-truncate font-size-14 d-inline mb-0">{text}</small>
					</div>
					<small className="mb-0">{updatedAt}</small>
				</Button>
			</Col>
		</Row>;
};

const mapStateToProps = state => {
	return {
		userId: state.Auth.user._id
	}
}

export default connect(mapStateToProps, null)(ChatRow);
