import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	Row,
	Col,
	Container,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Button,
	UncontrolledDropdown,
	UncontrolledTooltip
} from 'reactstrap';

import Page from '../../components/Layout/Page/Page';
import TrackWave from '../../components/Tracks/TrackWave/TrackWave';
import TopWeeklyDiscoveryCard from '../../components/Cards/TopWeeklyDiscoveryCard/TopWeeklyDiscoveryCard';
import PlayButton from '../../components/Buttons/PlayButton/PlayButton';
import Loader from '../../components/Common/Loader';
import RoundedButton from '../../components/Buttons/RoundedButton/RoundedButton';

import { ReactComponent as FacebookIcon } from '../../assets/images/fb.svg';
import { ReactComponent as InstagramIcon } from '../../assets/images/ig.svg';
import { ReactComponent as TwitterIcon } from '../../assets/images/tw.svg';
import { ReactComponent as WebsiteIcon } from '../../assets/images/web.svg';
import { ReactComponent as ExpandIcon } from '../../assets/images/expand.svg';
import { ReactComponent as RewardsIcon } from '../../assets/images/reward.svg';

import { loadTracks, loadTrackComments } from '../../helpers/api/tracks.api';
import { loadUserStats } from '../../helpers/api/users.api';

import './Dashboard.scss';

const Dashboard = (props) => {
	const [loading, setLoading] = useState(true);
	const [loadingStats, setLoadingStats] = useState(true);

	const [playing, setPlaying] = useState(false);

	const [tracks, setTracks] = useState(null);
	const [selectedTrack, setSelectedTrack] = useState(null);
	const [trackComments, setTrackComments] = useState([]);

	const [stats, setStats] = useState(null);

	useEffect(() => {
		setLoadingStats(true);
		loadUserStats()
			.then((result) => {
				if (result.success) setStats(result.stats);
				else {
					console.log(result.message);
					setStats(null);
				}
				setLoadingStats(false);
			})
			.catch((error) => {
				setStats(null);
				setLoadingStats(false);
				console.log(error);
			});
	}, []);

	useEffect(() => {
		if (props.user && props.user.type === 'artist') {
			setLoading(true);
			loadTracks(props.user._id)
				.then((result) => {
					if (result.success) {
						setTracks(result.tracks);
						setSelectedTrack(result.tracks[0]);
					} else {
						console.log(result.message);
						setTracks([]);
						setSelectedTrack(null);
					}
					setLoading(false);
				})
				.catch((error) => {
					setTracks([]);
					setSelectedTrack(null);
					setLoading(false);
					console.log(error);
				});
		}
	}, [props.user]);

	useEffect(() => {
		if (tracks && tracks[0]) setSelectedTrack(tracks[0]);
		else setSelectedTrack(null);
	}, [tracks]);

	useEffect(() => {
		if (selectedTrack) loadComments();
	}, [selectedTrack]);

	function loadComments() {
		loadTrackComments(selectedTrack._id)
			.then((result) => {
				if (result.success) {
					setTrackComments(result.comments);
				} else {
					console.log(result.message);
					setTrackComments([]);
				}
			})
			.catch((error) => {
				setTrackComments([]);
				console.log(error);
			});
	}

	const links = [];
	if (props.user) {
		if (props.user.websiteUrl)
			links.push(
				<Link className="social-link" target="_blank" to={props.user.websiteUrl}>
					<WebsiteIcon />
				</Link>
			);
		if (props.user.facebookUrl)
			links.push(
				<Link className="social-link" target="_blank" to={props.user.facebookUrl}>
					<FacebookIcon />
				</Link>
			);
		if (props.user.instagramUrl)
			links.push(
				<Link className="social-link" target="_blank" to={props.user.instagramUrl}>
					<InstagramIcon />
				</Link>
			);
		if (props.user.twitterUrl)
			links.push(
				<Link className="social-link" target="_blank" to={props.user.twitterUrl}>
					<TwitterIcon />
				</Link>
			);
	}

	return <Page className="dashboard"> {props.user && (
		<Container fluid>
			<Row className="pb-lg-5">
				<Col className="d-flex flex-column justify-content-between" md="8">
					{props.user.type === 'scout' &&
						(loadingStats ? (
							<Loader className="h-100 w-100" />
						) : stats ? (
							<>
								<h3 className="mb-0">Statistiche</h3>
								<Row className="h-100 pt-3">
									<Col xs="6" lg="6">
										<div className="stats h-100 mb-5">
											<span className="mt-5">
												{stats.tracksToVote}
											</span>
											<div>
												<span>
													Brani
													<br />
													da valutare
												</span>
											</div>
											<RoundedButton
												color="primary"
												onClick={() => {
													props.history.push('/tracks');
												}}>
												VAI AI BRANI
											</RoundedButton>
										</div>
									</Col>
									<Col xs="6" lg="6">
										<div className="stats mb-5">
											<span className="mt-5">
												{stats.savedTracks}
											</span>
											<div>
												<span>
													Brani
													<br />
													salvati
												</span>
											</div>
											<RoundedButton
												color="primary"
												onClick={() => {
													props.history.push('/playlists');
												}}>
												VEDI PLAYLIST
											</RoundedButton>
										</div>
									</Col>
									<Col xs="6" lg="6">
										<div className="stats mb-5">
											<span className="mt-5">
												{Number(stats.walletValue / 100).toFixed(2) + '€'}
											</span>
											<div>
												<span>accumulati</span>
											</div>
											<RoundedButton
												color="primary"
												onClick={() => {
													props.history.push('/wallet');
												}}>
												PAGAMENTI
											</RoundedButton>
										</div>
									</Col>
								</Row>
							</>
						) : null)}
					{props.user.type === 'artist' &&
						(loading ? (
							<Loader />
						) : (
							<>
								<Row className="mb-5">
									<Col lg="7">
										<div className="h-100 d-flex align-items-center justify-content-start">
											<div className="d-flex flex-column dropdown-container">
												<h6>
													<i>I tuoi brani</i>
												</h6>
												<UncontrolledDropdown>
													<DropdownToggle>
														{selectedTrack && <h4>{selectedTrack.title}</h4>}
														{tracks && tracks.length > 1 && <Button
															color="link"
															className="expand-button d-inline">
															<ExpandIcon />
														</Button>}
													</DropdownToggle>
													<DropdownMenu>
														{tracks && tracks
															.filter(
																(t) =>
																	t._id !==
																	selectedTrack._id
															)
															.map((t) => (
																<DropdownItem
																	key={t._id}
																	onClick={() =>
																		setSelectedTrack(t)
																	}>
																	{t.title}
																</DropdownItem>
															))}
													</DropdownMenu>
												</UncontrolledDropdown>
											</div>
											{selectedTrack && <PlayButton className="ml-4"
												playing={playing}
												onClick={() => setPlaying(!playing)}
											/>}
										</div>
									</Col>
									<Col className="d-none d-lg-block" lg="5">
										{selectedTrack && <div className="h-100 d-flex justify-content-end align-items-center">
											<p className="track-points">
												{selectedTrack.average || 0}
												{' punti'}
											</p>
											<p className="track-rewards">
												<RewardsIcon />
												{selectedTrack.rewards
													? selectedTrack.rewards.length
													: 0}
												{' Rewards'}
											</p>
										</div>}
									</Col>
								</Row>
								{tracks && tracks.length === 0 &&
									<Row>
										<Col className='text-center'>
											<p>Non hai caricato ancora nessun brano...
												<br />
												Cosa stai aspettando?
											</p>
											<RoundedButton
												className="px-5"
												color="primary"
												onClick={() => {
													props.history.push('/settings/upload-track');
												}}
											>
												Carica Brano
											</RoundedButton>
										</Col>
									</Row>}
								<Row>
									<Col>
										{selectedTrack && <div className="wave-wrapper">
											<TrackWave
												track={selectedTrack}
												playing={playing}
												comments={trackComments}
												onFinish={() => setPlaying(false)}
											/>
										</div>}
									</Col>
								</Row>
							</>
						))}
				</Col>
				<Col md="4" className="d-none d-lg-block">
					<TopWeeklyDiscoveryCard className="mb-4 mb-lg-0" />
				</Col>
			</Row>
			{props.user.type === 'artist'
				? stats && (
					<Row className="pt-lg-4 mb-5">
						<Col>
							<h3 className="mb-3 mb-lg-5">Statistiche</h3>
							{loadingStats ? (
								<Loader />
							) : (
								<Row className="py-3 py-lg-5">
									<Col
										className="h-100 d-flex align-items-center justify-content-center"
										xs="4">
										<div className="stats">
											<span>{stats.averageScore || 0}</span>
											<div className='d-flex align-items-center justify-content-center'>
												<span>Punti</span>
												<RoundedButton
													id="punti-info"
													type="button"
													onClick={(event) => event.stopPropagation()}
													className="d-inline p-0 text-center ml-2 mb-0"
													color="secondary outline"
												>
													i
												</RoundedButton>
												<UncontrolledTooltip placement="top" className="info-tooltip" target="punti-info">
													<div>
														Punteggio medio derivante dalle votazioni degli scouter sui tuoi brani.
													</div>
												</UncontrolledTooltip>
											</div>
										</div>
									</Col>
									<Col
										className="h-100 d-flex align-items-center justify-content-center"
										xs="4">
										<div className="stats">
											<span>{stats.feedbacksCount || 0}</span>
											<div className='d-flex align-items-center'>
												<span>Feedback</span>
												<RoundedButton
													id="feedback-info"
													type="button"
													onClick={(event) => event.stopPropagation()}
													className="d-inline p-0 text-center ml-2 mb-0"
													color="secondary outline"
												>
													i
												</RoundedButton>
												<UncontrolledTooltip placement="top" className="info-tooltip" target="feedback-info">
													<div>
														Un feedback è una sessione di valutazione ricevuta da un singolo scouter. Può essere un semplice voto o una review completa.
													</div>
												</UncontrolledTooltip>
											</div>
										</div>
									</Col>
									<Col
										className="h-100 d-flex align-items-center justify-content-center"
										xs="4">
										<div className="stats">
											<span>{stats.rewardsCount || 0}</span>
											<div className='d-flex align-items-center'>
												<span>Rewards</span>
												<RoundedButton
													id="rewards-info"
													type="button"
													onClick={(event) => event.stopPropagation()}
													className="d-inline p-0 text-center ml-2 mb-0"
													color="secondary outline"
												>
													i
												</RoundedButton>
												<UncontrolledTooltip placement="top" className="info-tooltip" target="rewards-info">
													<div>
														I rewards sono le ricompense che gli scouter ti hanno assegnato dopo la valutazione dei tuoi brani. Puoi riscattarle nell'apposita sezione.
													</div>
												</UncontrolledTooltip>
											</div>
										</div>
									</Col>
								</Row>
							)}
						</Col>
						{/* MESSAGGES CARD */}
						{/* <Col className="h-100" md="4">
                    <Card className="h-100">
                        <CardHeader></CardHeader>
                        <CardBody></CardBody>
                    </Card>
                </Col> */}
					</Row>
				)
				: null}

			<Row>
				<Col className="d-block d-lg-none">
					<TopWeeklyDiscoveryCard className="mb-4 mb-lg-0" />
				</Col>
			</Row>
		</Container>
	)}</Page>;
};

const mapStateToProps = (state) => {
	return {
		user: state.Auth.user
	};
};

export default connect(mapStateToProps, null)(Dashboard);
