import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import CapacitorStore from "redux-persist-capacitor-storage";
import createSagaMiddleware from 'redux-saga'
//import { logger } from 'redux-logger';

/* REDUCERS */
import rootReducer from './reducers';

/* SAGAS */
import rootSaga from './sagas';

const persistConfig = {
	key: 'root',
	storage: CapacitorStore,
};

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(sagaMiddleware))); //, logger));
let persistor = persistStore(store);

export { store, persistor, sagaMiddleware };

sagaMiddleware.run(rootSaga);