const { API_URL } = require('../../app.config');

export async function loadUser(userId) {
	const token = localStorage.getItem('token');

	const headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	const response = await fetch(API_URL + '/users/' + userId, {
		method: 'GET',
		headers,
	}).then((response) => response.json());

	return response;
}

export async function confirmUser(userId) {
	const token = localStorage.getItem('token');

	const headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	const response = await fetch(API_URL + '/users/confirm/' + userId, {
		method: 'GET',
		headers,
	}).then((response) => response.json());

	return response;
}

export async function loadArtists(page, sorting) {
	const token = localStorage.getItem('token');

	const headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	let url = API_URL + '/users?t=artist';

	if (page >= 0) url += '&pg=' + page;

	if (sorting) url += '&s=' + sorting;

	const response = await fetch(url, {
		method: 'GET',
		headers,
	}).then((response) => response.json());

	return response;
}

export async function loadScouts(page, sorting) {
	const token = localStorage.getItem('token');

	const headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	let url = API_URL + '/users?t=scout';

	if (page >= 0) url += '&pg=' + page;

	if (sorting) url += '&s=' + sorting;

	const response = await fetch(url, {
		method: 'GET',
		headers,
	}).then((response) => response.json());

	return response;
}

export async function loadUsers(page, sorting) {
	const token = localStorage.getItem('token');

	const headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	let url = API_URL + '/users?';

	if (page >= 0) url += '&pg=' + page;

	if (page) url += '&s=' + sorting;

	const response = await fetch(url, {
		method: 'GET',
		headers,
	}).then((response) => response.json());

	return response;
}

export async function loadUserStats() {
	const token = localStorage.getItem('token');

	const headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	const userId = JSON.parse(localStorage.getItem('authUser'))._id;
	const response = await fetch(API_URL + '/users/' + userId + '/stats', {
		method: 'GET',
		headers,
	}).then((response) => response.json());

	return response;
}

export async function loadUserWallet(userId) {
	const token = localStorage.getItem('token');

	const headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	let url = API_URL + '/users/' + userId + '/wallet';

	const response = await fetch(url, {
		method: 'GET',
		headers,
	}).then((response) => response.json());

	return response;
}

export async function searchUsers(
	artistType,
	nMembers,
	region,
	minConcerts,
	maxConcerts,
	minTracks,
	maxTracks,
	minYear,
	maxYear,
	releasesType,
	collecting,
	label,
	management,
	sorting,
	page
) {
	const token = localStorage.getItem('token');

	const headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	let url = API_URL + '/users/search?';

	if (artistType) url += '&artistType=' + artistType;
	if (nMembers > 0) url += '&nMembers=' + nMembers;

	if (region) url += '&region=' + region;

	if (minConcerts !== null && minConcerts >= 0) url += '&minConcerts=' + minConcerts;
	if (maxConcerts !== null && maxConcerts >= 0) url += '&maxConcerts=' + maxConcerts;

	if (minTracks !== null && minTracks >= 0) url += '&minTracks=' + minTracks;
	if (maxTracks !== null && maxTracks >= 0) url += '&maxTracks=' + maxTracks;

	if (minYear >= 0) url += '&minYear=' + minYear;
	if (maxYear >= 0) url += '&maxYear=' + maxYear;

	if (releasesType) url += '&releasesType=' + releasesType;

	if (collecting) url += '&collecting=' + collecting;

	if (label)
	url += '&label=' + label; 

	if (management)
	url += '&management=' + management;

	if (page >= 0) url += '&pg=' + page;

	if (page) url += '&s=' + sorting;

	const response = await fetch(url, {
		method: 'GET',
		headers,
	}).then((response) => response.json());

	return response;
}

export async function createScout(name, email, password, pics, letter, disclaimer) {
	let token = localStorage.getItem('token');

	const headers = new Headers();
	headers.append('Authorization', token);

	const data = new FormData();

	data.append('email', email);
	data.append('password', password);
	data.append('name', name);
	data.append('type', 'scout');
	data.append('letter', letter);
	data.append('disclaimer', disclaimer);

	if (pics)
		pics.forEach((pic, i) => {
			if (pic.url) data.append('pics-' + i, JSON.stringify(pic));
			else data.append('pics-' + i, pic);
		});

	const response = await fetch(API_URL + '/users/', {
		method: 'POST',
		headers,
		body: data,
	}).then((response) => response.json());

	return response;
}

export async function editUser({
	bio,
	genre,
	job,
	city,
	placeId, region,
	collecting,
	management,
	label,
	nMembers,
	artistType,
	releases,
	concerts,
	awards,
	socialLinks,
	pics,
	youtubeUrl,
	spotifyId,
	iban, ibanHolder
}) {
	const userId = JSON.parse(localStorage.getItem('authUser'))._id;
	const token = localStorage.getItem('token');

	const headers = new Headers();
	headers.append('Authorization', token);

	const data = new FormData();

	data.append('bio', bio || null);
	data.append('genre', genre || null);
	data.append('artistType', artistType || null);
	data.append('nMembers', nMembers || null);
	data.append('label', label || null);
	data.append('management', management || null);
	data.append('collecting', JSON.stringify(collecting));
	data.append('job', job || null);
	data.append('city', city || null);
	data.append('placeId', placeId || null);
	data.append('region', region || null);
	data.append('awards', JSON.stringify(awards));
	data.append('concerts', JSON.stringify(concerts));
	data.append('socialLinks', JSON.stringify(socialLinks));

	if (releases)
		releases.forEach((r, i) => {
			if (r.cover && !r.cover.url)
				data.append('covers-' + i, r.cover);
		});

	data.append('releases', JSON.stringify(releases));

	if (pics)
		pics.forEach((pic, i) => {
			if (pic.url) data.append('pics-' + i, JSON.stringify(pic));
			else data.append('pics-' + i, pic);
		});

	data.append('youtubeUrl', youtubeUrl || null);
	data.append('spotifyId', spotifyId || null);

	data.append('iban', iban || null);
	data.append('ibanHolder', ibanHolder || null);

	const response = await fetch(API_URL + '/users/' + userId, {
		method: 'PUT',
		headers,
		body: data,
	}).then((response) => response.json());

	return response;
}
export async function editUserImage(imageFile) {
	const userId = JSON.parse(localStorage.getItem('authUser'))._id;
	const token = localStorage.getItem('token');

	const headers = new Headers();
	headers.append('Authorization', token);

	const data = new FormData();
	data.append('image', imageFile);

	const response = await fetch(API_URL + '/users/' + userId + '/image', {
		method: 'PUT',
		headers,
		body: data,
	}).then((response) => response.json());

	return response;
}

export async function editUserSpotify(spotifyId) {
	const userId = JSON.parse(localStorage.getItem('authUser'))._id;
	const token = localStorage.getItem('token');

	const headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	const body = JSON.stringify({ spotifyId });

	const response = await fetch(API_URL + '/users/' + userId + '/spotify', {
		method: 'PUT',
		headers,
		body,
	}).then((response) => response.json());

	return response;
}

export async function deleteUserRelease(index) {
	const token = localStorage.getItem('token');
	const userId = JSON.parse(localStorage.getItem('authUser'))._id;

	const headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	const response = await fetch(API_URL + '/users/' + userId + '/releases/' + index, {
		method: 'DELETE',
		headers,
	}).then((response) => response.json());

	return response;
}

export async function deleteUser(userId) {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	let response = await fetch(API_URL + '/users/' + userId, {
		method: 'DELETE',
		headers
	}).then((response) => response.json());

	return response;
}