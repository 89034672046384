import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Row,
    Col,
    Container,
    Button,
    Collapse,
    Alert
} from 'reactstrap';
import RoundedButton from '../../../components/Buttons/RoundedButton/RoundedButton';
import Loader from '../../../components/Common/Loader';

import { loadArticles } from '../../../helpers/api/articles.api';

import './Blog.scss';

function strip(html) {
    let doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
}

const Blog = (props) => {
    const [page, setPage] = useState(0);
    const [error, setError] = useState(false);

    const [loading, setLoading] = useState(true);
    const [canLoadMore, setCanLoadMore] = useState(true);

    const [articles, setArticles] = useState([]);

    useEffect(() => {
        setArticles([]);
        setPage(0)
    }, [])

    useEffect(() => {
        if (page >= 0) {
            setLoading(true);
            loadArticles(page)
                .then((result) => {
                    if (result.success) {
                        setArticles(result.articles);
                        setCanLoadMore(result.canLoadMore)
                    } else {
                        setError(result.message);
                        setArticles([]);
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    setArticles([]);
                    setLoading(false);
                    setError(error.message);
                    console.log(error);
                });
        }
    }, [page]);

    return <Container fluid className="blog pb-5">
        <Row>
            <Col>
                <Container>
                    <Row className="mb-3">
                        <Col className="h-100 d-flex flex-column" md={{
                            size: 11, offset: 1
                        }}>
                            <h1>BLOG</h1>
                        </Col>
                    </Row>
                    {loading ?
                        <Loader />
                        :
                        error ?
                            <Alert className="mb-0" color="danger">
                                {error}
                            </Alert>
                            : <>
                                <Row className="pb-3">
                                    {articles.map((a, i) =>
                                        <Col sm="4">
                                            <Link
                                                to={"/blog/" + a._id}
                                                className='article d-flex flex-column align-items-center justify-content-between w-100 mb-4'>
                                                {a.media.length > 0 && <img src={a.media[0].url} />}
                                                <h4>{a.title}</h4>
                                                <p>{strip(a.content)}</p>
                                            </Link>
                                        </Col>
                                    )}
                                </Row><Row>
                                    <Col xs="12">
                                        <div className="text-center my-3">
                                            {!loading
                                                ?
                                                (canLoadMore ? <RoundedButton className="px-5" onClick={() => setPage(page + 1)} >
                                                    Carica più
                                                </RoundedButton> : null)
                                                :
                                                <div className='d-flex align-items-center justify-content-center'>
                                                    <Loader size="sm" />
                                                    <span>{'Caricando'}</span>
                                                </div>}
                                        </div>
                                    </Col>
                                </Row>
                            </>}
                </Container>
            </Col>
        </Row>
    </Container>
};

export default Blog;
