import { useEffect, useState } from 'react';

import './TrackCountdown.scss';

const useCountdown = (targetDate) => {
	const countDownDate = new Date(targetDate).getTime();

	const [countDown, setCountDown] = useState(countDownDate - new Date().getTime());

	useEffect(() => {
		const interval = setInterval(() => {
			setCountDown(countDownDate - new Date().getTime());
		}, 1000);

		return () => clearInterval(interval);
	}, [countDownDate]);

	return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
	// calculate time left
	const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
	const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
	const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

	return [days, hours, minutes, seconds];
};

const TrackCountdown = ({ targetDate, expiredCaption }) => {
	const [days, hours, minutes, seconds] = useCountdown(targetDate);

	return (
		<div className="track-countdown d-flex align-items-center">
			{days + hours + minutes + seconds <= 0 ? (
				<div className="expired-notice">
					<span>{expiredCaption || 'Scaduta'}</span>
				</div>
			) : (
				<>
					<div className={days <= 3 ? 'countdown danger' : 'countdown'}>
						<h4>{days}</h4>
						{/* <span>{'Giorni'}</span> */}
					</div>
					<h4>:</h4>
					<div className={false ? 'countdown danger' : 'countdown'}>
						<h4>{hours < 10 ? '0' + hours : hours}</h4>
						{/* <span>{'Ore'}</span> */}
					</div>
					<h4>:</h4>
					<div className={false ? 'countdown danger' : 'countdown'}>
						<h4>{minutes < 10 ? '0' + minutes : minutes}</h4>
						{/* <span>{'Minuti'}</span> */}
					</div>{' '}
					<h4>:</h4>
					<div className={false ? 'countdown danger' : 'countdown'}>
						<h4>{seconds < 10 ? '0' + seconds : seconds}</h4>
						{/* <span>{'Secondi'}</span> */}
					</div>
				</>
			)}
		</div>
	);
};

export default TrackCountdown;
