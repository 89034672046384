import { useEffect, useRef, useState } from 'react';
import { Alert, Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import Loader from '../../Common/Loader';
import RoundedButton from '../../Buttons/RoundedButton/RoundedButton';
import PaypalCheckoutForm from '../../Checkout/PaypalCheckoutForm';
import StripeCheckoutForm from '../../Checkout/StripeCheckoutForm';

import { ReactComponent as PlusIcon } from '../../../assets/images/plus.svg';

import { loadValidDiscount } from '../../../helpers/api/discounts.api';
import * as checkoutAPI from '../../../helpers/api/checkout.api';

import './UploadTrackForm.scss';

const UploadTrackForm = (props) => {
	let trackPicker = useRef(null);

	const [proceeded, setProceeded] = useState(false);
	const [ownersError, setOwnersError] = useState(false);

	const [loading, setLoading] = useState(false);
	const [loadingDiscount, setLoadingDiscount] = useState(false);

	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);

	const [serviceCost, setServiceCost] = useState(null);
	const [publicKey, setPublicKey] = useState(null);

	const [trackTitle, setTrackTitle] = useState('');
	const [trackFile, setTrackFile] = useState(null);
	const [trackOwners, setTrackOwners] = useState([]);

	const [consent, setConsent] = useState(false);


	const [discount, setDiscount] = useState('');
	const [discountValue, setDiscountValue] = useState(0);
	const [discountConfirmed, setDiscountConfirmed] = useState(false);

	const [method, setMethod] = useState('');

	useEffect(() => {
		loadPaymentInfo();
	}, []);

	function handleOnTrackUploadClick() {
		if (trackPicker.current && trackPicker.current.click) trackPicker.current.click();
	}

	function handleOnTrackFileChange({ target: { files } }) {
		if (files && files.length > 0) {
			let track = files[0];
			console.log('File caricato', track);
			if (track.size > 50000000)
				window.alert('File troppo grande. Dimensione massima: 50MB');
			else if (track.type === 'audio/mpeg' || track.type === 'audio/mpeg3') setTrackFile(track);
			else window.alert('Devi caricare un file audio.');
		}
	}

	function handleOnAddOwner() {
		let editedOwners = [...trackOwners];
		editedOwners.push({ firstname: '', lastname: '', birthplace: '', birthdate: new Date() });
		setTrackOwners(editedOwners);
		setOwnersError(false);
	}

	function handleOnOwnerFieldChange({ target: { name, value } }, index) {
		let editedOwners = [...trackOwners];
		editedOwners[index][name] = value;
		setTrackOwners(editedOwners);
		setOwnersError(false);
	}

	function handleOnProceedClick() {
		let ok = true;
		if (trackOwners && trackOwners.length > 0) {
			for (let to of trackOwners) {
				if (!to.firstname || !to.lastname || !to.birthdate || !to.birthplace) ok = false;
			}
		} else ok = false;
		if (ok) setProceeded(ok);
		else setOwnersError(true);
	}

	function loadPaymentInfo() {
		setLoading(true);
		checkoutAPI
			.loadPaymentSetup()
			.then((result) => {
				if (result.success) {
					setServiceCost(result.serviceCost);
					setPublicKey(result.publicKey);
				}
				else setError(result.message)
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				setError(error.message);
			});
	}

	function cancelDiscount() {
		setDiscount(undefined);
		setDiscountValue(0);
		setDiscountConfirmed(false);
	}

	function handleOnDiscountSubmitClick() {
		setLoadingDiscount(true);
		loadValidDiscount(discount)
			.then((result) => {
				setDiscount(result.discount.code);
				if (result.success && result.discount && !isNaN(result.discount.value)) {
					setDiscountValue(result.discount.value);
					setDiscountConfirmed(true);
				} else window.alert(result.message);
				setLoadingDiscount(false);
			})
			.catch((error) => {
				setDiscountValue(0);
				setDiscountConfirmed(false);
				setLoadingDiscount(false);
				window.alert(error.message);
			});
	}

	function handleOnSubmitFree() {
		setLoading(true);
		checkoutAPI
			.verifyCheckout(null, discount, 'free', trackFile, trackTitle, trackOwners)
			.then((result) => {
				if (result.success){
					setSuccess(true);
					props.onSuccess && props.onSuccess();
				} else window.alert(result.message);
				setLoading(false);
			})
			.catch((error) => {
				window.alert(error.message);
				setLoading(false);
			});
	}

	let amount = serviceCost > 0 ? serviceCost : 0;
	if (discount && discountConfirmed) {
		amount = amount * ((100 - Number(discountValue)) / 100);
	}

	return (
		<div className="upload-track-form" onSubmit={(event) => event.stopPropagation()}>
			{error ? (
				<div className="h-100 w-100 d-flex flex-column align-items-center justify-content-center pr-0 pr-lg-5">
					<h3 className="my-5">ATTENZIONE</h3>
					<p className="text-center px-5 ml-0">
						{error}
					</p>
				</div>
			) : success ? (
				<div className="h-100 w-100 d-flex flex-column align-items-center justify-content-center pr-0 pr-lg-5">
					<h3 className="my-5">Grazie!</h3>
					<p className="text-center px-5 ml-0">
					Attendi la valutazione dai nostri Talent scout, ci vorrà massimo una settimana.
					</p>
					<RoundedButton
						color="primary"
						onClick={() => {
							setSuccess(false);
							setTrackTitle('');
							setTrackFile(null);
							setTrackOwners([]);
							setMethod('');
							cancelDiscount();
							setProceeded(false);
						}}
					>
						Carica un altro brano
					</RoundedButton>
					<RoundedButton className="outline" onClick={() => props.history.replace('/tracks')}>
						Vai ai tuoi brani
					</RoundedButton>
				</div>
			) : (
				<Row>
					<Col md="5">
						<FormGroup className="mt-2" row>
							<Col>
								<Label for="title">Titolo del brano</Label>
								<Input
									type="text"
									name="title"
									disabled={proceeded}
									id="title"
									className="mb-0"
									onChange={({ target: { value } }) => setTrackTitle(value)}
								/>
							</Col>
						</FormGroup>
						<Row>
							<Col>
								{/* <UploadTrackIcon className="upload-track-icon" /> */}
								<p className="mb-2 ml-0">{trackFile ? trackFile.name : 'Nessun file caricato'}</p>
							</Col>
						</Row>

						<Row>
							<Col>
								<Input
									name="track-file"
									innerRef={trackPicker}
									type="file"
									style={{
										display: 'none',
									}}
									accept="audio/mpeg3" //,audio/wav,audio/x-wav"
									onChange={handleOnTrackFileChange}
								/>

								<RoundedButton
									className={'px-5 mb-2'}
									color={'primary'}
									type="button"
									disabled={proceeded}
									onClick={handleOnTrackUploadClick}
								>
									Carica brano
								</RoundedButton>
								<p className="ml-0 mb-0">È richiesto un file audio in formato .mp3</p>
							</Col>
						</Row>

						{trackFile && (
							<>
								<hr />
								<Row>
									<Col>
										<div className="d-flex align-items-center">
											<h6 className="my-1 d-inline">Proprietari del brano</h6>
											<Button
												className="add-owner d-inline mb-0"
												color="link"
												onClick={handleOnAddOwner}
												disabled={proceeded}
											>
												<PlusIcon className='add-owner-icon' width={'20px'} />
											</Button>
										</div>
										<p className={'ml-0 mb-0 ' + (ownersError ? 'error' : '')}>
											Indica tutti i proprietari responsabili del brano
										</p>
									</Col>
								</Row>
								{trackOwners.map((o, i) => (
									<Row className="pt-2">
										<Col xs="8" lg="6">
											<Label for="firstname">Nome</Label>
											<Input
												type="text"
												name="firstname"
												id="firstname"
												className='mb-1'
												disabled={proceeded}
												value={o.firstname}
												onChange={(event) => handleOnOwnerFieldChange(event, i)}
											/>
										</Col>
										<Col xs="8" lg="6">
											<Label for="lastname">Cognome</Label>
											<Input
												type="text"
												name="lastname"
												id="lastname"
												className='mb-1'
												disabled={proceeded}
												value={o.lastname}
												onChange={(event) => handleOnOwnerFieldChange(event, i)}
											/>
										</Col>
										<Col xs="8" lg="6">
											<Label for="birthdate">Data di nascita</Label>
											<Input
												type="date"
												name="birthdate"
												id="birthdate"
												disabled={proceeded}
												value={o.birthdate}
												onChange={(event) => handleOnOwnerFieldChange(event, i)}
											/>
										</Col>
										<Col xs="8" lg="6">
											<Label for="birthplace">Luogo di nascita</Label>
											<Input
												type="text"
												name="birthplace"
												id="birthplace"
												disabled={proceeded}
												value={o.birthplace}
												onChange={(event) => handleOnOwnerFieldChange(event, i)}
											/>
										</Col>
									</Row>
								))}
							</>
						)}
						<hr />
						<Row>
							<Col>
								<p className="mb-2 ml-4 text-left">
									<Input
										type="checkbox"
										checked={consent}
										disabled={proceeded}
										onChange={(event) => setConsent(!consent)}
									/>
									Acconsento alla{' '}
									<Link to="/consent" target={'_blank'} className="d-inline">
										{/* <i className="mdi mdi-lock mr-1"></i> */}
										liberatoria
									</Link>
									{' '}per l'utilizzo e la divulgazione del brano
									<sup>*</sup>
								</p>
								<div className="w-100 d-flex align-items-center justify-content-between mb-5">
									<h4 className="mt-0 mb-0">
										{'Totale: € '}
										{discountConfirmed && <>{' '}<span className='barred'>{Number(serviceCost).toFixed(2)}</span>{' '}</>}
										{Number(amount).toFixed(2)}
									</h4>
									{!proceeded && (
										<RoundedButton
											className={'px-5 mb-0 '}
											color={'primary'}
											type="button"
											disabled={!trackFile || !trackTitle || !trackOwners.length > 0 || !consent}
											onClick={handleOnProceedClick}
										>
											Procedi
										</RoundedButton>
									)}
								</div>
							</Col>
						</Row>
					</Col>

					<Col className="mt-2" md={{ size: 5, offset: 1 }}>
						{loadingDiscount || loading ? (
							<Loader />
						) : (
							<>
								{proceeded && (
									<Row>
										<Col md="12" className=" mb-4">
											{discount && discountConfirmed ? (
												<>
													<h5>CODICE SCONTO</h5>
													<h6>
														<strong>{discount}{' (-'}{Number(discountValue)}{'%)'}</strong>
													</h6>
												</>
											) : (
												<>
													<Label for="discount">Hai un codice Sconto?</Label>
													<Input
														type="text"
														name="discount"
														id="discount"
														onChange={({ target: { value } }) => setDiscount(value)}
														disabled={discountConfirmed}
													/>
													<RoundedButton
														className={'w-100 px-5'}
														color={'primary'}
														type="button"
														disabled={!discount || discountConfirmed}
														onClick={handleOnDiscountSubmitClick}
													>
														Inserisci Codice Sconto
													</RoundedButton>
												</>
											)}
										</Col>
									</Row>
								)}
								{proceeded && amount > 0 && !method && (
									<>
										<Row>
											<Col>
												<RoundedButton
													className={'px-5'}
													color={'primary'}
													onClick={() => setMethod('stripe')}
													type="button"
												>
													Paga con Carta
												</RoundedButton>
											</Col>
										</Row>
										<Row>
											<Col>
												<p className="pl-0 ml-0 mb-2 text-left">oppure</p>
												<PaypalCheckoutForm
													amount={amount}
													discount={discount}
													onError={(error) => window.alert(error)}
													onSuccess={() => {
														setSuccess(true);
														props.onSuccess && props.onSuccess();
													}}
													track={trackFile}
													title={trackTitle}
													owners={trackOwners}
												/>
											</Col>
										</Row>
									</>
								)}
								{proceeded && amount > 0 && method === 'stripe' && (
									<Row>
										<Col>
											<Elements stripe={loadStripe(publicKey)}>
												<StripeCheckoutForm
													className="d-flex flex-column justify-content-between"
													amount={amount}
													discount={discount}
													onError={(error) => window.alert(error)}
													onCancel={() => setMethod('')}
													onSuccess={() => {
														setSuccess(true);
														props.onSuccess && props.onSuccess();
													}}
													track={trackFile}
													title={trackTitle}
													owners={trackOwners}
												/>
											</Elements>
										</Col>
									</Row>
								)}
								{proceeded && amount === 0 &&
								<Row>
								<Col>
									{loading ? (
										<Loader className="mb-2" />
										) : (
										<>
											<RoundedButton
												id="submit"
												type="submit"
												color="primary"
												className="w-100 mb-3"
												onClick={handleOnSubmitFree} >
												Conferma
											</RoundedButton>
											<RoundedButton
												type="button"
												className="w-100 outline"
												onClick={() => {
													cancelDiscount();
													setProceeded(false);
												}}>
												Annulla
											</RoundedButton>
										</>
									)}
								</Col>
							</Row>}
							</>
						)}
					</Col>
				</Row>
			)}
		</div>
	);
};

export default withRouter(UploadTrackForm);
