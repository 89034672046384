import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button, Col, Form, Row, Input } from 'reactstrap';

import RoundedButton from '../Buttons/RoundedButton/RoundedButton';

import Loader from '../Common/Loader';

import * as checkoutAPI from '../../helpers/api/checkout.api';

const cardStyle = {
	iconStyle: 'solid',
	style: {
		base: {
			color: '#0b0b60',
			fontFamily: 'Montserrat, sans-serif',
			fontSmoothing: 'antialiased',
			fontSize: '16px',
			'::placeholder': {
				color: '#0b0b60'
			}
		},
		invalid: {
			fontFamily: 'Montserrat, sans-serif',
			color: '#fa755a',
			iconColor: '#fa755a'
		}
	}
};

export default function StripeCheckoutForm(props) {
	const stripe = useStripe();
	const elements = useElements();

	const [processing, setProcessing] = useState(false);
	const [disabled, setDisabled] = useState(true);
	const [clientSecret, setClientSecret] = useState('');

	const [loading, setLoading] = useState(false);

	const [cardName, setCardName] = useState('');

	useEffect(() => {
		if (props.amount > 0) {
			// Create PaymentIntent as soon as the page loads
			setLoading(true);
			checkoutAPI
				.prepareCheckout('stripe', props.amount, props.discount)
				.then((result) => {
					if (result.success) setClientSecret(result.clientSecret);
					else props.onError && props.onError(result.message);
					setLoading(false);
				})
				.catch((error) => {
					props.onError && props.onError(error.message);
					setLoading(false);
				});
		}
	}, [props.amount]);

	const handleOnChange = async (event) => {
		// Listen for changes in the CardElement
		// and display any errors as the customer types their card details
		setDisabled(event.empty);
		// props.onError && props.onError(event.error ? event.error.message : '');
	};

	const handleOnSubmit = async (event) => {
		event.preventDefault();
		setProcessing(true);

		let transactionId = null;
		if (clientSecret) {
			const payload = await stripe.confirmCardPayment(clientSecret, {
				payment_method: {
					card: elements.getElement(CardElement)
				}
			});

			if (payload.error) {
				props.onError && props.onError(`Payment failed ${payload.error.message}`);
				setProcessing(false);
				return;
			} else {
				// props.onError && props.onError(null);
				setProcessing(false);
				transactionId = payload.paymentIntent.id;
			}
		}

		setLoading(true);
		checkoutAPI
			.verifyCheckout(transactionId, props.discount, 'stripe', props.track, props.title, props.owners)
			.then((result) => {
				if (result.success) {
					props.onSuccess && props.onSuccess();
				} else props.onError && props.onError(result.message);
				setLoading(false);
				setProcessing(false);
			})
			.catch((error) => {
				props.onError && props.onError(error.message);
				setLoading(false);
				setProcessing(false);
			});
	};

	let checkoutDisabled = false;
	if (props.amount > 0) checkoutDisabled = loading || !cardName || processing || disabled || props.disabled;

	return (
		<div id="checkout-form" className={props.className}>
			{loading ? (
				 <div className='d-flex flex-column align-items-center'>
				 <Loader className="mb-2" />
				 <p className='ml-0 text-center'>Ancora un attimo di pazienza.</p>
			 </div> 
			) : (
				<>
					{clientSecret ? (
						<>
							<Row>
								<Col className="d-flex align-items-center justify-content-between" md="12">
									<h6 className="ml-0">DETTAGLI CARTA DI CREDITO</h6>
								</Col>
							</Row>
							<Row className="mb-2">
								<Col md="12">
									<p className="mb-2 ml-0">Nome sulla carta</p>
									<Input
										value={cardName}
										onChange={(event) => setCardName(event.target.value)}
									/>
								</Col>
							</Row>
							<Row className="mb-3">
								<Col md="12">
									<CardElement
										id="card-element"
										options={cardStyle}
										onChange={handleOnChange}
									/>
								</Col>
							</Row>
						</>
					) : null}

					{/* 
			<button disabled={checkoutDisabled} id="submit">
				<span id="button-text">
					{processing ? <div className="spinner" id="spinner"></div> : 'Pay'}
				</span>
			</button> */}

					<Row className="mb-0">
						<Col md={{ size: '8', offset: '2' }}>
							{processing ? (
								<Loader className="mb-2" />
								) : (
								<>
									<RoundedButton
										id="submit"
										type="submit"
										color="primary"
										className="w-100 mb-3"
										onClick={handleOnSubmit}
										disabled={checkoutDisabled}>
										Conferma
									</RoundedButton>
									<RoundedButton
										type="button"
										className="w-100 outline"
										onClick={() => props.onCancel && props.onCancel()}>
										Annulla
									</RoundedButton>
								</>
							)}
						</Col>
					</Row>
				</>
			)}
		</div>
	);
}
