const { API_URL } = require('../../app.config');

export async function loadChats() {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	let url = API_URL + '/chats';

	let response = await fetch(url, {
		method: 'GET',
		headers
	}).then((response) => response.json());

	return response;
}

export async function sendTextMessage(
	chatId,
	to,
	message) {
		let token = localStorage.getItem('token');

		let headers = new Headers();
		headers.append('Authorization', token);

		let url = API_URL + '/chats/';

		const data = new FormData();
		if (chatId) data.append('chatId', chatId);
		if (to) data.append('to', JSON.stringify(to));
		if (message) data.append('content', message);
		data.append('type', 'simple');

		// if (media) media.forEach((media, i) => data.append('media-' + i, media));

		let response = await fetch(url, {
			method: 'POST',
			headers,
			body: data
		}).then((response) => response.json());

		return response;
}

export async function readChat(chatId) {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	let url = API_URL + '/chats/' + chatId + '/read';

	let response = await fetch(url, {
		method: 'PUT',
		headers
	}).then((response) => response.json());

	return response;
}