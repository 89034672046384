import React from 'react';
import { Row, Col, Container } from 'reactstrap';

import Page from '../../components/Layout/Page/Page';
import Top10Slider from '../../components/Sliders/Top10Slider/Top10Slider';
import LatestSlider from '../../components/Sliders/LatestSlider/LatestSlider';

import 'swiper/swiper-bundle.min.css';

import './Discovery.scss';

const Discovery = (props) => {
	return <Page className="discovery">
			<Container className="h-100">
				<Row className="pb-5 pb-lg-3">
					<Col md="11">
						<h3 className="mb-4 mb-lg-5">
							<span>Top10</span>
							<span>HitDiscovery</span>
						</h3>
						<Top10Slider onSlideClick={u => props.history.push('/users/' + u._id)} />
					</Col>
				</Row>
				<Row className="pt-5 pt-lg-3 pb-5 pb-lg-0">
					<Col md="11">
						<h3>Ultimi Arrivi</h3>
						<LatestSlider onSlideClick={u => props.history.push('/users/' + u._id)} />
					</Col>
				</Row>
			</Container>
	</Page>;
};

export default Discovery;
