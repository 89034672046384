import React from 'react'
import { withRouter } from 'react-router';
import { Col, Row } from 'reactstrap';

import RoundedButton from '../../Buttons/RoundedButton/RoundedButton';

import './ArticleRow.scss';

function strip(html){
	let doc = new DOMParser().parseFromString(html, 'text/html');
	return doc.body.textContent || "";
}

const ArticleRow = props => (
	<li className="article-row py-3">
		<Row className='w-100'>
			<Col xs="12" sm="8" className="d-flex flex-column align-items-start justify-content-center">
				<h6 className="mb-0">{props.title}</h6>
				<p className='mb-3 mb-sm-0'>
					{strip(props.content)}
				</p>
			</Col>
			<Col xs="12" sm="4" className="d-flex align-items-center justify-content-center">
				<RoundedButton color="secondary" className="px-5 py-2" onClick={props.onEditClick}>Modifica</RoundedButton>
				<RoundedButton color="primary" className="px-5 py-2 ml-4" onClick={() => props.history.push('/blog/' + props.id)}>VISUALIZZA</RoundedButton>
			</Col>
		</Row>
	</li>);

export default withRouter(ArticleRow);