import React, { useEffect, useState } from 'react';
import {
    Row,
    Col,
    Container,
    Alert
} from 'reactstrap';
import { Link } from 'react-router-dom';

import Loader from '../../../components/Common/Loader';
import HomeHeader from '../../../components/Layout/PublicHeader/PublicHeader';
import HomeFooter from '../../../components/Layout/PublicFooter/PublicFooter';

import { loadScouts } from '../../../helpers/api/users.api';

import './Scouts.scss';

const Scouts = (props) => {
    const [loading, setLoading] = useState(false);
    const [scouts, setScouts] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        loadScouts()
            .then((result) => {
                if (result.success) setScouts(result.users);
                else setError(result.message);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                setError(error.message);
            });
    }, []);

    let scoutsCols = scouts ? scouts.map((s, i) => <Col className='d-flex align-items-center' xs="6" sm="3">
            <Link className="scout-link" to={"/scouts/" + s._id}>
                <span style={{
                    background: '#fff url("' + s.image?.url + '") center center / cover no-repeat'
                }} />
                <p>{s.name}</p>
            </Link>
        </Col>) : <Col></Col>;

    return <Container fluid className="scouts pb-5">
        <Row>
            <Col>
                <Container>
                    <Row className="mb-3">
                        <Col className="h-100 d-flex flex-column" md={{
                            size: 12
                        }}>
                            <h1>Scouter</h1>
                            <p>Scopri la squadra di professionisti della musica selezionata da HitReview</p>
                        </Col>
                    </Row>
                    <Row className="pb-3">
                        <Col md="12">
                            {loading ? (
                                <Loader className="pb-5 w-100 h-100 d-flex align-items-center justify-content-center" />
                            ) : error ? (
                                <Alert className="mb-0" color="danger">
                                    {error}
                                </Alert>
                            ) : (
                                <Row>
                                    {scoutsCols}
                                </Row>
                            )}
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
    </Container>
};

export default Scouts;
