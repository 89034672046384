import { useState } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import SweetAlert from 'react-bootstrap-sweetalert';

import RoundedButton from '../../Buttons/RoundedButton/RoundedButton';
import TrackCountdown from '../TrackCountdown/TrackCountdown';
import Loader from '../../Common/Loader';

import { ReactComponent as TrashIcon } from '../../../assets/images/trash.svg';
import { ReactComponent as RenewIcon } from '../../../assets/images/time-add.svg';
import { ReactComponent as RewardsIcon } from '../../../assets/images/reward.svg';

import { renewTrack, deleteTrack } from '../../../helpers/api/tracks.api';

import './TrackRow.scss';

const TrackRow = (props) => {
	const [actionLoading, setActionLoading] = useState(false);
	const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
	const [renewConfirmModal, setRenewConfirmModal] = useState(false);

	function handleOnRenewTrack(days) {
		setRenewConfirmModal(false);
		if (props.track){
		setActionLoading(true);
		renewTrack(props.track._id, days)
			.then((result) => {
				if (!result.success) console.log(result.message);
				setActionLoading(false);
				props.onRenewComplete && props.onRenewComplete();
			})
			.catch((error) => {
				console.log(error.message);
				setActionLoading(false);
			});
		}
	}

	function handleOnDeleteTrack() {
		setDeleteConfirmModal(false);
		if (props.track){
			setActionLoading(true);
		deleteTrack(props.track._id)
			.then((result) => {
				if (!result.success) console.log(result.message);
				setActionLoading(false);
				props.onDeleteComplete && props.onDeleteComplete();
			})
			.catch((error) => {
				console.log(error.message);
				setActionLoading(false);
			});
		}
	}

	const authorId = props.track && props.track.author ? props.track.author._id : undefined;
	const votingsComplete = props.track && props.track.votings && props.track.votings.length === props.maxVotings;
	
	return props.track ? (
		<>
		<li className={"track-row " + (props.active ? 'active' : '')} onClick={props.onClick}>
				<div>
					<div>
					{/* <PlayButton onClick={props.onPlayClick} /> */}
					<h6 className="mb-0 mt-0">
						{props.track.title}
						<br />
						<span>{props.track.author ? (props.track.author.name) : 'Utente eliminato'}</span>
					</h6>
					</div>
					<div className='votings_n_container'>
						<small>{'n. feedback'}</small>&nbsp;<h4 className={'votings_n ' + (votingsComplete ? 'complete' : '')}>{props.track.votings.length}</h4><small>{'/' +props.maxVotings}</small>
					</div>
				</div>
				<div>
					{props.user.type !== 'artist' && !votingsComplete ? (
							<TrackCountdown targetDate={new Date(props.track.expiresAt).getTime()} />
					) : null}
					{props.user._id === authorId || props.user.type !== 'scout' ? (
						<>
							<p className="track-points">
								{props.track.average || 0}
								<small>{' punti'}</small>
							</p>
							<p className="track-rewards">
								<RewardsIcon />
								{props.track.rewards ? props.track.rewards.length : 0}
								<small>{' rewards'}</small>
							</p>
						</>
					) : null}
					{authorId && props.user._id !== authorId ? (
						<RoundedButton
							color="primary"
							className="px-3 ml-4 d-none d-lg-block"
							onClick={() => props.history.push('/users/' + authorId)}
						>
							VISUALIZZA Profilo
						</RoundedButton>
					) : null}

					{actionLoading ? (
						<Loader className="ml-3" size="sm" />
					) : ((props.user && (props.user._id === authorId && props.track.votings.length === 0) || (props.user.type === 'admin'))) ? <>
					<Button
						className="renew-button ml-3 ml-lg-3 mr-0 mr-lg-0"
						color="link"
						onClick={(event) => { event.preventDefault(); event.stopPropagation(); setRenewConfirmModal(true); }}
					>
						<RenewIcon />
					</Button>
						<Button
							className="delete-button ml-0 ml-lg-0 mr-0 mr-lg-0"
							color="link"
							onClick={(event) => { event.preventDefault(); event.stopPropagation(); setDeleteConfirmModal(true); }}
						>
							<TrashIcon />
						</Button>
					</> : null}
				</div>
		</li>
		{deleteConfirmModal ? (
			<SweetAlert
				title={'Vuoi davvero eliminare il brano selezionato? Tutti i voti, commenti, reward e ogni cosa collegata al brano verranno eliminati.'}
				showCancel
				confirmBtnBsStyle="primary"
				confirmBtnText="Elimina"
				cancelBtnBsStyle="secondary"
				cancelBtnText="Annulla"
				onConfirm={handleOnDeleteTrack}
				onCancel={() => setDeleteConfirmModal(false)}
			/>
		) : null}
		{renewConfirmModal ? (
			<SweetAlert
				title={'Inserisci il numero di giorni da aggiungere alla scadenza, a partire da oggi.'}
				type='input'
				inputType='number'
				showCancel
				confirmBtnBsStyle="primary"
				confirmBtnText="Rinnova"
				cancelBtnBsStyle="secondary"
				cancelBtnText="Annulla"
				onConfirm={(response) => handleOnRenewTrack(response)}
				onCancel={() => setRenewConfirmModal(false)}
			/>
		) : null}
		</>
	) : null;
};

const mapStatetoProps = (state) => {
	return { user: state.Auth.user };
};

export default withRouter(connect(mapStatetoProps, {})(TrackRow));
