import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';

import { LOAD_UNREAD_NOTI, SET_READ } from './actionTypes';

import { loadNotificationsFailed, loadNotificationsSucessful } from '../actions';

import * as notiAPI from '../../helpers/api/notifications.api';

function* loadUserUnreadNotifications() {
    try {
		const response = yield call(notiAPI.loadUnreadNotifications);
		if (!response.success) yield put(loadNotificationsFailed(response.message));
		else {
            yield put(loadNotificationsSucessful({
                notifications: response.notifications || []
            }));
        }
    } catch (error) {
        yield put(loadNotificationsFailed(error.message));
    }
}

function* setReadNotification({ payload: { notiId } }) {
    try {
		yield call(notiAPI.setReadNotification, notiId);
    } catch (error) {
        console.log(error.message)
    }
}

export function* watchLoadUnreadNotifications() {
    yield takeEvery(LOAD_UNREAD_NOTI, loadUserUnreadNotifications);
}

export function* watchSetRead() {
    yield takeEvery(SET_READ, setReadNotification);
}

function* notiSaga() {
    yield all([fork(watchLoadUnreadNotifications), fork(watchSetRead)]);
}

export default notiSaga;