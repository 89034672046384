import React, { useState, useEffect } from 'react';
import {
	Button,
	ModalFooter,
	ModalBody,
	Modal,
	ModalHeader,
	FormGroup,
	Label,
	Col,
	Input,
	Row,
	Form,
	Alert
} from 'reactstrap';
import { connect } from 'react-redux';

import Loader from '../../Common/Loader';
import RoundedButton from '../../Buttons/RoundedButton/RoundedButton';

import { loadTrackVotings, voteTrack } from '../../../helpers/api/tracks.api';

import '../Modals.scss';
import './VoteTrackModal.scss';

const VoteTrackModal = (props) => {
	const [vote, setVote] = useState(0);
	const [comment, setComment] = useState('');

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);

	useEffect(() => {
		if (props.isOpen) {
			setVote(0);
			setError(null);
			setLoading(true);
			loadTrackVotings(props.track._id, props.user._id)
			.then((result) => {
				setLoading(false);
				if (result.success) {
					if (result.votings && result.votings.length > 0) {
						setVote(result.votings[0].vote);
						setComment(result.votings[0].comment)
					}
				} else
					setError(result.message);
			})
			.catch((error) => {
				setLoading(false);
				setError(error.message);
			});
		}
	}, [props.isOpen]);

	function handleOnSubmit() {
		setLoading(true);
		voteTrack(props.track._id, vote, comment)
			.then((result) => {
				setLoading(false);
				if (result.success) {
					setSuccess(result.message);
					setTimeout(() => {
						props.onSuccess && props.onSuccess();
						handleOnClose();
					}, 2000);
				} else {
					setError(result.message);
				}
			})
			.catch((error) => {
				setLoading(false);
				setError(error.message);
			});
	}

	function handleOnClose() {
		props.onClose && props.onClose();
		setError(null);
		setSuccess(null);
		setVote(0);
	}

	return (
		<Modal
			isOpen={props.isOpen}
			role="dialog"
			className="vote-track-modal"
			autoFocus={true}
			centered={true}
			tabIndex="-1"
			toggle={handleOnClose}>
			<ModalBody>
				{loading ? (
					<Loader className="p-5" />
				) : success ? (
					<Alert className="mb-4" color="success">
						{success}
					</Alert>
				) : <>
				{error &&
					<Alert className="mb-4" color="danger">
						{error}
					</Alert>} 
					<Row>
						<Col sm="12">
							<h6>Assegna voto a:</h6>
							<p><b>{props.track.title}</b>{' di ' + (props.track.author ? props.track.author.name : 'Utente eliminato')}</p>
							<div className="d-flex w-100 align-items-center justify-content-center">
								{[1, 2, 3, 4, 5].map(v => <Button key={v} className={"vote-button " + (vote >= v ? 'active' : '')} color="link" onClick={() => setVote(v)}>
									<b>{v}</b>
									<span className="circle"></span>
								</Button>)}
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<h6 className='mb-2'>Commento generale</h6>
							<Input
								id="comment"
								name="comment"
								rows={5}
								type="textarea"
								placeholder='Es.: Brano ben strutturato...'
								className="form-control"
								value={comment}
								onChange={(event) => setComment(event.target.value)}
							/>
						</Col>
					</Row>
					</>}
			</ModalBody>
			{!loading && !success &&
				<ModalFooter>
					<RoundedButton
						className="waves-effect px-5 mb-3"
						type="button"
						color="primary"
						onClick={handleOnSubmit}>
						CONFERMA
					</RoundedButton>
				</ModalFooter>}
		</Modal>
	);
};

const mapStatetoProps = (state) => {
	return { user: state.Auth.user };
};

export default connect(mapStatetoProps, null)(VoteTrackModal);
