import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Container, Table, Alert } from 'reactstrap';
import moment from 'moment';
import ObjectID from 'bson-objectid';

import Loader from '../../components/Common/Loader';
import Page from '../../components/Layout/Page/Page';
import RoundedButton from '../../components/Buttons/RoundedButton/RoundedButton';
import PayoutRequestModal from '../../components/Modals/Wallet/PayoutRequestModal';

import { ReactComponent as WalletIcon } from '../../assets/images/wallet.svg';

import { loadUserWallet } from '../../helpers/api/users.api';

import './Wallet.scss';

const Wallet = (props) => {
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [movements, setMovements] = useState([]);
	const [total, setTotal] = useState(0);

	const [payoutTreshold, setPayoutTreshold] = useState(0);
	const [requestingPayout, setRequestingPayout] = useState(false);

	const [profileId, setProfileId] = useState(null);

	useEffect(() => {
		let id;
		if (props.user.type === 'admin'){
			if (props.location.pathname) 
				id = props.location.pathname.split('/')[props.location.pathname.split('/').length - 1]; 
			
			if (id)
				setProfileId(id)
		} else if(props.user.type === 'scout'){
			id = props.user._id;
			setProfileId(props.user._id);
		} else return;
	}, [props.location.pathname]);

	useEffect(() => {
		if (profileId)
			loadWallet(profileId)
	}, [profileId]);

	function loadWallet() {
		setLoading(true);
		loadUserWallet(profileId)
			.then((result) => {
				if (result.success) {
					setMovements(result.movements);
					setPayoutTreshold(result.payoutTreshold);
					setTotal(result.total)
				} else setError(result.message);
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				console.log(error);
			});
	}

	function handleOnRequestPayout() {
		setRequestingPayout(true);
	}

    const watchingMyWallet = props.user && props.user._id === profileId;

	const pt = Number(payoutTreshold / 100);
	const tot =  Number(total / 100);

	return <Page className="wallet">
		<Container>
				<Row className="mb-5">
					<Col xs="12" lg="11">
						<WalletIcon className="title-icon" />
						{loading ? (
							<Loader className="pt-5" />
						) : error ? (
							<Alert className="mb-0" color="danger">
								{error}
							</Alert>
						) : (
							<>
								<h3>
									{watchingMyWallet ? 'Hai totalizzato ' : 'Totale: '}<span>{tot.toFixed(2)}</span>{' €'}
								</h3>
								<div className="wallet-table-container mb-5">
									<Table className="wallet-table" responsive borderless striped>
										<tbody>
											{movements.map((r) => {
												let text = watchingMyWallet && Number(r.value) > 0 ? 'Hai ricevuto ' : 'Hai inviato ';

												text += Number(r.value / 100).toFixed(2) + '€ ';
												if (r.cause === 'vote' && r.track) text += 'per aver votato "' + (r.track.title + '"');
												else if (r.cause === 'comment' && r.track) text += 'per la recensione su "' + (r.track.title + '"');
												else if (r.cause === 'listen' && r.track) text += 'per aver ascoltato "' + (r.track.title + '"');
												else if (r.cause === 'reward' && r.track) text += 'per la reward assegnata a "' + (r.track.title + '"');
												else if (r.cause === 'payout'){
													text += 'al tuo conto bancario con IBAN ' + r.iban;
													if (r.status === 'requested') 
														text += " (Richiesto)";
													else if (r.status === 'cancelled')
														text += " (Annullato)";
													else if (r.status === 'completed')
														text += ' (Completato)';
												}

												return <tr key={r._id}>
													<td>
														<p className="mb-0">
															{moment(ObjectID(r._id).getTimestamp()).format('DD/MM/YYYY')}
														</p>
													</td>
													<td>
														<p
															className="mb-0"
														>
															{text}
														</p>
													</td>
												</tr>
												}
											)}
										</tbody>
									</Table>
								</div>
								{watchingMyWallet && <>
								<RoundedButton color="primary" className="d-block px-5 mb-3 mx-auto" disabled={tot < pt} onClick={handleOnRequestPayout}>
										Richiedi
								</RoundedButton>
								<small className="d-block mx-auto text-center">
									(payout minimo: {pt.toFixed(2)}€)
								</small>
								<PayoutRequestModal isOpen={requestingPayout} onClose={() => setRequestingPayout(false)} max={tot} 
								onSuccess={() => {
									loadWallet()
								}} />
								</>}
							</>
						)}
					</Col>
				</Row>
			</Container>
		</Page>;
};

const mapStateToProps = state => {
	return {
		user: state.Auth.user
	}
}

export default connect(mapStateToProps, null)(Wallet);
