import React, { useState, useRef } from 'react';
import { Form, Input, Label } from 'reactstrap';
import RoundedButton from '../../Buttons/RoundedButton/RoundedButton';

import './MessageForm.scss';

const MessageForm = props => {
    const form = useRef();
    const [newMessage, setNewMessage] = useState('');

    function handleOnSubmit(e) {
        e.preventDefault();
        props.onSubmit && props.onSubmit(newMessage);
        setNewMessage('');
    }

    function handleOnEnterPress(e) {
        if (e.keyCode == 13 && e.shiftKey == false) {
            e.preventDefault();
            handleOnSubmit(e);
        }
    }
    
    return <Form ref={form} className="message-form" onSubmit={handleOnSubmit}>
        <Label for="text" className='pl-3'>
            {'Invia un messaggio'}{(props.to ? <>{' a '}<b>{props.to}</b></> : '')}
        </Label>
        <Input
            onKeyDown={handleOnEnterPress}
            type="textarea"
            name="text"
            id="text"
            value={
                newMessage
            }
            onChange={
                ({ target: { value } }) => setNewMessage(value)
            }
        />
        <RoundedButton
            className="px-5 mt-2 d-block ml-auto"
            color="primary"
            disabled={!newMessage}
            type="submit">
            INVIA
        </RoundedButton>
    </Form>;
};

export default MessageForm;
