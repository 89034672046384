import React, { useEffect, useState } from 'react';
import {
    Row,
    Col,
    Container,
} from 'reactstrap';

import HomeHeader from '../../../components/Layout/PublicHeader/PublicHeader';
import HomeFooter from '../../../components/Layout/PublicFooter/PublicFooter';
import UserDetail from '../../../components/Users/UserDetail/UserDetail';

import './Scouts.scss';

const ScoutProfile = (props) => {
    const [profileId, setProfileId] = useState(null);

    useEffect(() => {
        if (props.location.pathname) {
            const id =
                props.location.pathname.split('/')[props.location.pathname.split('/').length - 1];
            setProfileId(id);
        }
    }, [props.location.pathname]);

    return <Container fluid className="scouts py-5">
        <Row>
            <Col className='py-lg-5'>
                <UserDetail userId={profileId} />
            </Col>
        </Row>
    </Container>
};

export default ScoutProfile;
