import { Switch } from 'react-router-dom';
import { IonApp, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import Loader from './components/Common/Loader';
import ProtectedRoute from './components/Routes/ProtectedRoute';
import PublicRoute from './components/Routes/PublicRoute';
import { protectedRoutes, publicRoutes } from './pages/routes';

import WebSocketProvider from './helpers/socket/WebSocket';

import { store, persistor } from './store';

import 'bootstrap/dist/css/bootstrap.min.css';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './assets/scss/theme.scss';

setupIonicReact();

const App = () => (
	<Provider store={store}>
		<PersistGate loading={<Loader />} persistor={persistor}>
			<WebSocketProvider>
				<IonApp>
					<IonReactRouter>
						<Switch>
							{publicRoutes.map((route, i) => (
								<PublicRoute key={'public-' + i} {...route} component={route.component} />
							))}
							{protectedRoutes.map((route, i) => (
								<ProtectedRoute key={'protected-' + i} {...route} component={route.component} />
							))}
						</Switch>
					</IonReactRouter>
				</IonApp>
			</WebSocketProvider>
		</PersistGate>
	</Provider>
);

export default App;
