import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Container, Input, Label, Form, Button } from 'reactstrap';
import { IonItem, IonLabel, IonList, IonReorder, IonReorderGroup, ItemReorderEventDetail } from '@ionic/react';

import Page from '../../components/Layout/Page/Page';
import RoundedButton from '../../components/Buttons/RoundedButton/RoundedButton';
import TrackWave from '../../components/Tracks/TrackWave/TrackWave';
import PlaylistRow from '../../components/Playlists/PlaylistRow/PlaylistRow';
import PlaylistTrackItem from '../../components/Playlists/PlaylistTrackItem/PlaylistTrackItem';
import Loader from '../../components/Common/Loader';
import PlayButton from '../../components/Buttons/PlayButton/PlayButton';

import { ReactComponent as CheckedIcon } from '../../assets/images/checkbox-checked.svg';
import { ReactComponent as UncheckedIcon } from '../../assets/images/checkbox.svg';

import { loadUserPlaylists, createPlaylist, reorderTrackInPlaylist, updatePlaylistPublic } from '../../helpers/api/playlists.api';

import './Playlists.scss';

const Playlists = props => {
    const [loading, setLoading] = useState(true);

    const [creating, setCreating] = useState(false);
    const [creatingLoading, setCreatingLoading] = useState(false);
    const [creatingError, setCreatingError] = useState(null);

    const [playlists, setPlaylists] = useState([]);
    const [selectedPlaylist, setSelectedPlaylist] = useState(null);
    const [track, setTrack] = useState(null);

    const [playing, setPlaying] = useState(false);

    const [reorderDisabled, setReorderDisabled] = useState(false);

    useEffect(() => {
        load();
    }, []);

    useEffect(() => {
        if (playlists && playlists.length > 0)
            setSelectedPlaylist(playlists[0])
    }, [playlists]);

    useEffect(() => {
        if (selectedPlaylist && selectedPlaylist.tracks)
            setTrack(selectedPlaylist.tracks[0]);
    }, [selectedPlaylist]);

    function load() {
        setLoading(true);
        loadUserPlaylists()
            .then((result) => {
                if (result.success) {
                    setPlaylists(result.playlists);
                    setReorderDisabled(false);
                } else {
                    console.log(result.message);
                    setPlaylists([]);
                }
                setLoading(false);
            })
            .catch((error) => {
                setPlaylists([]);
                setLoading(false);
                console.log(error);
            });
    }

    function handleOnCreatePlaylist(event) {
        event.preventDefault();
        setCreatingError(null);
        setCreatingLoading(true);
        createPlaylist(event.target['title'].value)
            .then(result => {
                if (result.success) {
                    setCreating(false);
                    setCreatingError(null);
                    load();
                } else
                    setCreatingError(result.message);
                setCreatingLoading(false);
            })
            .catch((error) => {
                setCreatingError(error);
                setCreatingLoading(false);
            });
    }

	function handleOnPublicCheckboxClick() {
		let newStatus = !selectedPlaylist.public;
		setSelectedPlaylist({
			...selectedPlaylist,
			public: newStatus,
		});

		updatePlaylistPublic(selectedPlaylist._id, newStatus)
			.then((result) => {
				if (!result.success) {
					setSelectedPlaylist({
						...selectedPlaylist,
						public: !newStatus,
					});
				}
			})
			.catch((error) => {
				setSelectedPlaylist({
					...selectedPlaylist,
					public: !newStatus,
				});
				console.log(error);
			});
	}

    async function handleReorder(event) {
        // Finish the reorder and position the item in the DOM based on
        // where the gesture ended. This method can also be called directly
        // by the reorder group
        event.detail.complete();
        // The `from` and `to` properties contain the index of the item
        // when the drag started and ended, respectively
        console.log('Dragged from index', event.detail.from, 'to', event.detail.to);

        setReorderDisabled(true);
        await reorderTrackInPlaylist(selectedPlaylist._id, selectedPlaylist.tracks[event.detail.from]._id, event.detail.from, event.detail.to);
        setReorderDisabled(false);
    }

    const tracks = selectedPlaylist ? selectedPlaylist.tracks : [];

    return <Page className="playlists">
        <Container fluid className="h-100 d-flex flex-column">
            <Row className="h-100">
                <Col lg="9">
                    <Row>
                        <Col style={{borderRight: '1px solid white'}}>
                            <h4>Playlist</h4>
                            {selectedPlaylist ? <div className='d-flex align-items-center justify-content-between mb-3'>
                            <h5 className="playlist-title">{selectedPlaylist.title}</h5>
                            <div className="d-none d-lg-flex align-items-end justify-content-end h-100 mx-4">
                                    <Button
                                        className="public-checkbox"
                                        color="link"
                                        onClick={handleOnPublicCheckboxClick}
                                    >
                                        {selectedPlaylist.public ? <CheckedIcon /> : <UncheckedIcon />}
                                    </Button>
                                    <label className="ml-2 mb-0">Playlist visibile sul tuo profilo</label>
                                </div>
                            </div> : null}
                            {loading ?
                                <Loader className="mt-5" />
                                : <div className="playlist-content">
                                    <Row className="pb-lg-3">
                                        <Col className="d-flex flex-column justify-content-between">
                                            {track ?
                                                <>
                                                    <Row>
                                                        <Col xs="12" lg="8" className="d-flex align-items-center justify-content-start">
                                                                <h4 className='mr-5'>{track.title}</h4>
                                                            <PlayButton playing={playing} onClick={() => setPlaying(!playing)} />
                                                        </Col>
                                                        <Col lg="4" className="d-none d-lg-flex align-items-center justify-content-end">
                                                            <RoundedButton color="primary" className="px-3" onClick={() => props.history.push('/users/' + track.author._id)}>VISUALIZZA Profilo</RoundedButton>
                                                        </Col>
                                                    </Row>
                                                    <div className="wave-wrapper mt-5 pb-0">
                                                        <TrackWave track={track} playing={playing} onFinish={() => setPlaying(false)} />
                                                    </div>
                                                </> : null}
                                        </Col>
                                    </Row>

                                    <Row className="pt-lg-3">
                                        <Col className="">
                                        {tracks.length > 0 ? <IonList className="tracks-list mb-5 mb-lg-0 pt-3">
                                            <IonReorderGroup disabled={reorderDisabled} onIonItemReorder={handleReorder}>
                                                {tracks.map(t => <PlaylistTrackItem key={t._id}
                                                playlistId={selectedPlaylist._id} track={t}
                                                onClick={() => setTrack(t)} onRemoveComplete={load} />)}
                                                </IonReorderGroup>
                                            </IonList> : null}
                                        </Col>
                                    </Row>
                                </div>}
                        </Col>
                    </Row>

                </Col>
                <Col lg="3">
                    <h5>Elenco Playlist</h5>
                    <ul className="playlists-list">
                        {playlists.map((pl, i) => {
                            return <PlaylistRow key={pl._id} id={pl._id} onClick={() => setSelectedPlaylist(pl)} 
                            title={pl.title} onDeleteComplete={load} deletable={true} />
                        })}
                    </ul>
                    {!creating ? <RoundedButton className="mb-5" color="primary" onClick={() => {
                        setCreatingError(null); setCreating(true);
                    }}>
                        Crea Nuova
                    </RoundedButton>
                        : creatingLoading ?
                            <Loader />
                            : <Form className="mb-5" onSubmit={handleOnCreatePlaylist}>
                                <Label htmlFor="title">Titolo nuova playlist</Label>
                                <Input type="text" id="title" name="title" />
                                <RoundedButton color="primary" type="submit">
                                    Crea
                                </RoundedButton>
                                <p>{creatingError}</p>
                            </Form>}
                </Col>
            </Row>
        </Container>
    </Page>;
}

const mapStatetoProps = (state) => {
    return { user: state.Auth.user };
};

export default connect(mapStatetoProps, {})(Playlists);