import {
    LOAD_TRACKS_TO_VOTE,
    SET_TRACKS_TO_VOTE,
    PUSH_TO_TRACKS_TO_VOTE,
    PULL_FROM_TRACKS_TO_VOTE
} from './actionTypes';

export const loadTracksToVote = (tracks) => {
    return {
        type: LOAD_TRACKS_TO_VOTE
    }
}

export const setTracksToVote = (tracks) => {
    return {
        type: SET_TRACKS_TO_VOTE,
        payload: {
            tracks
        }
    }
}

export const pushToTracksToVote = (trackId) => {
    return {
        type: PUSH_TO_TRACKS_TO_VOTE,
        payload: {
            trackId
        }
    }
}

export const pullFromTracksToVote = (trackId) => {
    return {
        type: PULL_FROM_TRACKS_TO_VOTE,
        payload: {
            trackId
        }
    }
}
