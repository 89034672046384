import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';

import { LOAD_CHATS, OPEN_CHAT } from './actionTypes';

import { loadChatsFailed, loadChatsSucessful } from '../actions';

import * as chatsAPI from '../../helpers/api/chats.api';

export const getUser = (state) => state.Auth.user

function* loadUserChats() {
    try {
		const response = yield call(chatsAPI.loadChats);
		if (!response.success) yield put(loadChatsFailed(response.message));
		else {
            let user = yield select(getUser);
            const unreadChats = response.chats.filter(c => !c.readBy || !c.readBy.includes(user._id)).map(c => c._id);
            yield put(loadChatsSucessful({
                chats: response.chats,
                unreadChats
            }));
        }
    } catch (error) {
        yield put(loadChatsFailed(error.message));
    }
}

function* readChat({ payload: { chatId } }) {
    try {
		yield call(chatsAPI.readChat, chatId);
    } catch (error) {
        console.log(error.message)
    }
}

export function* watchLoadChats() {
    yield takeEvery(LOAD_CHATS, loadUserChats);
}

export function* watchOpenChat() {
    yield takeEvery(OPEN_CHAT, readChat);
}

function* chatSaga() {
    yield all([fork(watchLoadChats), fork(watchOpenChat)]);
}

export default chatSaga;