const { API_URL } = require('../../app.config');

export async function createReward(
	{ description, file }, 
	trackId
) {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);

	const data = new FormData();
	if (description) data.append('description', description);
	if (file) data.append('file', file);
	if (trackId) data.append('trackId', trackId);

	let response = await fetch(API_URL + '/rewards/', {
		method: 'POST',
		headers,
		body: data
	}).then((response) => response.json());

	return response;
}

export async function loadRewards() {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	let url = API_URL + '/rewards';

	let response = await fetch(url, {
		method: 'GET',
		headers
	}).then((response) => response.json());

	return response;
}
