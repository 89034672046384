import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import './ProfileMenu.scss';

const ProfileMenu = (props) => {
	// Declare a new state variable, which we'll call "menu"
	const [menu, setMenu] = useState(false);

	return (
		<React.Fragment>
			{/* <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block mr-4"> */}
			{/* <DropdownToggle */}
			{props.user && (
				<Button
					color="link"
					onClick={() => props.history.push('/users/' + props.user._id)}
					className="header-item waves-effect d-flex align-items-center"
					id="page-header-user-dropdown">
					<div
						className="avatar-xs rounded-circle header-profile-user mr-2"
						style={{
							backgroundImage: props.user.image
								? 'url(' + props.user.image.url + ')'
								: 'unset'
						}}
						alt="Header Avatar"
					/>
					<div className="d-none d-xl-flex flex-column align-items-center">
						<span className="d-block w-100 text-left">
							<b>{props.user.name}</b>
						</span>
						<span className="d-block w-100 text-left">
							{props.user.type === 'admin' ? 'Amministratore' : props.user.type === 'artist' ? 'Artista' : props.user.type === 'scout' ? 'Scouter' : ''}
						</span>
					</div>
				</Button>
			)}
			{/* </DropdownToggle> */}
			{/* <DropdownMenu right>
					<DropdownItem tag="a" href="/profile">
						Profilo
					</DropdownItem> */}
			{/* <DropdownItem tag="a" href="/crypto-wallet">
						<i className="bx bx-wallet font-size-16 align-middle mr-1"></i>
						{props.t('My Wallet')}
					</DropdownItem>
					<DropdownItem tag="a" href="#">
						<span className="badge badge-success float-right">11</span>
						<i className="mdi mdi-settings font-size-17 align-middle mr-1"></i>
						{props.t('Settings')}
					</DropdownItem>
					<DropdownItem tag="a" href="auth-lock-screen">
						<i className="bx bx-lock-open font-size-16 align-middle mr-1"></i>
						{props.t('Lock screen')}
					</DropdownItem> */}
			{/* <div className="dropdown-divider"></div>
					<Link to="/logout" className="dropdown-item">
						<span>Logout</span>
					</Link>
				</DropdownMenu> */}
			{/* </Dropdown>} */}
		</React.Fragment>
	);
};

const mapStatetoProps = (state) => {
	const { error, success, user } = state.Auth;
	return { error, success, user };
};

export default withRouter(connect(mapStatetoProps, {})(ProfileMenu));
