const { API_URL } = require('../../app.config');

export async function createDiscount({
	code,
	description,
	qt,
	value,
	expiration
}) {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	const body = JSON.stringify({
		code,
		description,
		qt,
		value,
		expiration
	});

	let response = await fetch(API_URL + '/discounts/', {
		method: 'POST',
		headers,
		body
	}).then((response) => response.json());

	return response;
}

export async function loadDiscounts() {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	let response = await fetch(API_URL + '/discounts', {
		method: 'GET',
		headers
	}).then((response) => response.json());

	return response;
}

export async function loadDiscount(code) {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	let response = await fetch(API_URL + '/discounts/' + code, {
		method: 'GET',
		headers
	}).then((response) => response.json());

	return response;
}

export async function loadValidDiscount(code) {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	let response = await fetch(API_URL + '/discounts/' + code + '/valid', {
		method: 'GET',
		headers
	}).then((response) => response.json());

	return response;
}

export async function updateDiscount(code, updatedFields) {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	const body = JSON.stringify(updatedFields);

	let response = await fetch(API_URL + '/discounts/' + code, {
		method: 'PUT',
		headers,
		body
	}).then((response) => response.json());
	return response;
}

export async function deleteDiscount(code) {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	let response = await fetch(API_URL + '/discounts/' + code, {
		method: 'DELETE',
		headers
	}).then((response) => response.json());

	return response;
}