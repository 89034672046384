import React from 'react'
import { withRouter } from 'react-router';
import { Col, Row } from 'reactstrap';

import RoundedButton from '../../Buttons/RoundedButton/RoundedButton';

import './PayoutRow.scss';

const PayoutRow = props => (
	<li className="payout-row py-3">
		<Row className='w-100'>
			<Col xs="12" sm="8" className="d-flex flex-column align-items-start justify-content-center">
				<h6 className="mb-0">{props.status === 'requested' ? 'Richiesto' : props.status === 'completed' ? 'Completato' : props.status === 'cancelled' ? 'Annullato' : 'undefined'}</h6>
				<p className='mb-3 mb-sm-0'>
					{'a '}{props.receiving}
				</p>
				<p className='mb-3 mb-sm-0'>
					{'IBAN: '}{props.iban}{' intestato a '}{props.holder}
				</p>
				<p className='mb-3 mb-sm-0'>
					{Number(props.value / -100).toFixed(2)} €
				</p>
			</Col>
			<Col xs="12" sm="4" className="d-flex align-items-center justify-content-center">
				{props.status === 'requested' &&
				<>
				<RoundedButton color="secondary" className="px-5 py-2" onClick={props.onCancelClick}>Annulla</RoundedButton>
				<RoundedButton color="primary" className="px-5 py-2 ml-4" onClick={props.onCompleteClick}>Completa</RoundedButton>
				</>
				}
			</Col>
		</Row>
	</li>);

export default withRouter(PayoutRow);