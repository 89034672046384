import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { LOAD_TRACKS_TO_VOTE } from './actionTypes';

import { setTracksToVote } from '../actions';

import * as tracksAPI from '../../helpers/api/tracks.api';

function* loadUserTracksToVote() {
    try {
		const response = yield call(tracksAPI.loadTracksToVote);
		if (response.success) {
            yield put(setTracksToVote(response.tracks));
        } else {
            console.log(response.message);
            yield put(setTracksToVote([]));
        }
    } catch (error) {
        console.log(error.message);
        yield put(setTracksToVote([]));
    }
}

export function* watchLoadTracksToVote() {
    yield takeEvery(LOAD_TRACKS_TO_VOTE, loadUserTracksToVote);
}

function* notiSaga() {
    yield all([fork(watchLoadTracksToVote)]);
}

export default notiSaga;