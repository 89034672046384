import { useEffect, useState } from 'react';
import { Col, Form, Button, FormGroup, Input, Label, Row, Alert } from 'reactstrap';

import Loader from '../../Common/Loader';
import RoundedButton from '../../Buttons/RoundedButton/RoundedButton';

import { searchUsers } from '../../../helpers/api/users.api';

import '../Forms.scss';
import './SearchForm.scss';

const ARTIST_TYPES = [
	{
		label: ' - ',
		value: '',
	},
	{
		label: 'Solista',
		value: 'solo',
	},
	{
		label: 'Band',
		value: 'band',
	},
	{
		label: 'Producer',
		value: 'producer',
	},
];

const REGIONS = [
	'Abruzzo',
	'Basilicata',
	'Calabria',
	'Campania',
	'Emilia-Romagna',
	'Friuli-Venezia Giulia',
	'Lazio',
	'Liguria',
	'Lombardia',
	'Marche',
	'Molise',
	'Piemonte',
	'Puglia',
	'Sardegna',
	'Sicilia',
	'Toscana',
	'Trentino-Alto Adige',
	'Umbria',
	"Valle d'Aosta",
	'Veneto',
];

const CONCERTS_OPTIONS = ['0', '1 - 10', '10+'];

const TRACKS_OPTIONS = ['0', '1 - 10', '10+'];

const COLLECTING_OPTIONS = [
	'SIAE',
	'LEA - Liberi Editori e Autori',
	'Federintermedia',
	'AFI - Associazione Fonografici Italiani',
	'Artisti 7607 Soc. Coop.',
	'Audiocoop',
	'Evolution srl',
	'Getsound srl',
	'Itsright srl',
	'Nuovo Imaie',
	'Rete Artisti Spettacolo per l’Innovazione',
	'SCF srl',
	'Soundreef Ltd',
	'Videorights srl',
];

const SearchForm = (props) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);

	const [values, setValues] = useState({});

	useEffect(() => {}, []);

	function handleOnValueChange({ target: { name, value } }) {
		let edited = { ...values };

		if (name === 'releasesType') {
			if (!edited.releasesType) edited.releasesType = [];
			if (edited.releasesType.includes(value))
				edited.releasesType = edited.releasesType.filter((x) => x !== value);
			else edited.releasesType.push(value);
		} else if (['facebook', 'twitter', 'website', 'instagram', 'tiktok', 'youtube'].includes(name))
			edited.socialLinks[name] = value;
		else edited[name] = value;
		setValues(edited);
	}

	function handleOnSubmit(event) {
		event.preventDefault();
		setLoading(true);
		searchUsers(
			values.artistType,
			values.nMembers,
			values.region,
			values.nConcerts === CONCERTS_OPTIONS[0]
				? 0
				: values.nConcerts === CONCERTS_OPTIONS[1]
				? 1
				: values.nConcerts === CONCERTS_OPTIONS[2]
				? 10
				: null,
			values.nConcerts === CONCERTS_OPTIONS[0] ? 0 : values.nConcerts === CONCERTS_OPTIONS[1] ? 10 : null,
			values.nTracks === TRACKS_OPTIONS[0]
				? 0
				: values.nTracks === TRACKS_OPTIONS[1]
				? 1
				: values.nTracks === TRACKS_OPTIONS[2]
				? 10
				: null,
			values.nTracks === TRACKS_OPTIONS[0] ? 0 : values.nTracks === TRACKS_OPTIONS[1] ? 10 : null,
			values.minYear,
			values.maxYear,
			values.releasesType?.length === 1 ? values.releasesType[0] : null,
			values.collecting,
			values.label,
			values.management,
			null,
			null
		)
			.then((result) => {
				if (result.success) props.onSuccess(result.users);
				else setError(result.message);
				setLoading(false);
			})
			.catch((error) => {
				setError(error.message);
				setLoading(false);
			});
	}

	return loading ? (
		<Loader />
	) : error ? (
		<Alert color="danger">{error}</Alert>
	) : (
		<Form className="search-form" onSubmit={handleOnSubmit}>
			<FormGroup row>
				<Col xs="10">
					<Label for="artistType">Tipologia</Label>
					<Input
						type="select"
						name="artistType"
						id="artistType"
						value={values.artistType}
						onChange={handleOnValueChange}
					>
						{ARTIST_TYPES.map((at) => (
							<option selected={values.artistType === at.value} value={at.value}>
								{at.label}
							</option>
						))}
					</Input>
					{values.artistType === 'band' && (
						<>
							<Label for="nMembers">N° componenti</Label>
							<Input
								type="number"
								name="nMembers"
								id="nMembers"
								value={values.nMembers}
								onChange={handleOnValueChange}
							/>
						</>
					)}
					<Label for="region">Regione</Label>
					<Input type="select" name="region" id="region" value={values.region} onChange={handleOnValueChange}>
						<option selected={!values.region} value="">
							-
						</option>
						{REGIONS.map((r) => (
							<option selected={values.region === r} value={r}>
								{r}
							</option>
						))}
					</Input>
					<Label for="nConcerts">
						Concerti <small>all'attivo</small>
					</Label>
					<Input
						type="select"
						name="nConcerts"
						id="nConcerts"
						value={values.nConcerts}
						onChange={handleOnValueChange}
					>
						<option selected={!values.nConcerts} value="">
							-
						</option>
						{CONCERTS_OPTIONS.map((c) => (
							<option selected={values.nConcerts === c} value={c}>
								{c}
							</option>
						))}
					</Input>
					<Label for="nTracks">Brani pubblicati</Label>
					<Input
						type="select"
						name="nTracks"
						id="nTracks"
						value={values.nTracks}
						onChange={handleOnValueChange}
					>
						<option selected={!values.nTracks} value="">
							-
						</option>
						{TRACKS_OPTIONS.map((t) => (
							<option selected={values.nTracks === t} value={t}>
								{t}
							</option>
						))}
					</Input>
					<Label for="minYear">Con pubblicazioni dal</Label>
					<div className="d-flex align-items-center justify-content-start">
						<Input
							type="number"
							name="minYear"
							id="minYear"
							className="mr-4"
							value={values.minYear}
							onChange={handleOnValueChange}
						/>
						<Input
							type="number"
							name="maxYear"
							id="maxYear"
							value={values.maxYear}
							onChange={handleOnValueChange}
						/>
					</div>
					<Label for="single">Tipo di pubblicazione già effettuate</Label>

					<div className="d-flex align-items-center justify-content-start">
						<p className="d-inline mr-2 mb-0 text-center">EP/SINGOLI</p>
						<Input
							className="d-inline-block m-0 mr-4"
							type="checkbox"
							name="single"
							id="single"
							checked={values.releasesType?.includes('single')}
							onChange={({ target: { name, value } }) =>
								handleOnValueChange({ target: { name: 'releasesType', value: 'single' } })
							}
						/>
						<p className="d-inline mr-2 mb-0 text-center">ALBUM</p>
						<Input
							className="d-inline-block m-0"
							type="checkbox"
							name="album"
							id="album"
							checked={values.releasesType?.includes('album')}
							onChange={({ target: { name, value } }) =>
								handleOnValueChange({ target: { name: 'releasesType', value: 'album' } })
							}
						/>
					</div>
					<Label for="collecting">Collecting</Label>
					<Input
						type="select"
						name="collecting"
						id="collecting"
						value={values.collecting}
						onChange={handleOnValueChange}
					>
						<option selected={!values.collecting} value="">
							-
						</option>
						{COLLECTING_OPTIONS.map((c) => (
							<option selected={values.collecting === c} value={c}>
								{c}
							</option>
						))}
					</Input>
					<Label for="label-y">Label</Label>

					<div className="d-flex align-items-center justify-content-start">
						<p className="d-inline mr-2 mb-0 text-center">Sì</p>
						<Input
							className="d-inline-block m-0 mr-4"
							type="checkbox"
							name="label-y"
							id="label-y"
							checked={values.label && values.label === 1}
							onChange={({ target: { name, value } }) => {
								if (values.label === 1) handleOnValueChange({ target: { name: 'label', value: 0 } });
								else handleOnValueChange({ target: { name: 'label', value: 1 } });
							}}
						/>
						<p className="d-inline mr-2 mb-0 text-center">NO</p>
						<Input
							className="d-inline-block m-0"
							type="checkbox"
							name="label-n"
							id="label-n"
							checked={values.label && values.label === -1}
							onChange={({ target: { name, value } }) => {
								if (values.label === -1) handleOnValueChange({ target: { name: 'label', value: 0 } });
								else handleOnValueChange({ target: { name: 'label', value: -1 } });
							}}
						/>
					</div>
					<Label for="mngmnt-y">Management</Label>

					<div className="d-flex align-items-center justify-content-start">
						<p className="d-inline mr-2 mb-0 text-center">Sì</p>
						<Input
							className="d-inline-block m-0 mr-4"
							type="checkbox"
							name="mngmnt-y"
							id="mngmnt-y"
							checked={values.management && values.management === 1}
							onChange={({ target: { name, value } }) => {
								if (values.management === 1)
									handleOnValueChange({ target: { name: 'management', value: 0 } });
								else handleOnValueChange({ target: { name: 'management', value: 1 } });
							}}
						/>
						<p className="d-inline mr-2 mb-0 text-center">NO</p>
						<Input
							className="d-inline-block m-0"
							type="checkbox"
							name="mngmnt-n"
							id="mngmnt-n"
							checked={values.management && values.management === -1}
							onChange={({ target: { name, value } }) => {
								if (values.management === -1)
									handleOnValueChange({ target: { name: 'management', value: 0 } });
								else handleOnValueChange({ target: { name: 'management', value: -1 } });
							}}
						/>
					</div>
				</Col>
			</FormGroup>
			<FormGroup row>
				<Col xs="12" lg="10">
					<RoundedButton className="d-block w-50 py-2 mt-4 mb-4 mx-auto" color="primary" type="submit">
						CERCA
					</RoundedButton>
				</Col>
			</FormGroup>
		</Form>
	);
};

export default SearchForm;
