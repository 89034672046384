import { useState } from 'react';
import { Alert, Label, FormGroup, Input, Col, Form, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import Loader from '../../Common/Loader';
import RoundedButton from '../../Buttons/RoundedButton/RoundedButton';

// actions
import { registerUser } from '../../../store/actions';
import { resendConfirmMail } from '../../../helpers/api/auth.api';

import '../Forms.scss';

const RegisterForm = (props) => {
	const [step, setStep] = useState(2);

	const [name, setName] = useState('');
	const [age, setAge] = useState('');
	const [city, setCity] = useState('');

	const [genre, setGenre] = useState('');
	const [albumsPublished, setAlbumsPublished] = useState('');
	const [tracksPublished, setTracksPublished] = useState('');
	const [formation, setFormation] = useState('');

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirm, setConfirm] = useState('');
	const [terms, setTerms] = useState(false);
	const [consent, setConsent] = useState(false);
	const [newsletter, setNewsletter] = useState(false);

	const [error, setError] = useState('');
	const [success, setSuccess] = useState(false);

	const [loadingMail, setLoadingMail] = useState(false);
	const [errorMail, setErrorMail] = useState('');
	const [successMail, setSuccessMail] = useState(false);

	function handleOnSubmit(event) {
		event.preventDefault();
		switch (step) {
			case 0: {
				if (!name || !age || !city) setError('Compila tutti i campi');
				else {
					setError('');
					setStep(1);
				}
				break;
			}
			case 1: {
				if (!genre || !albumsPublished || !tracksPublished || !formation) setError('Compila tutti i campi');
				else {
					setError('');
					setStep(2);
				}
				break;
			}
			case 2: {
				if (!email || !password || !confirm) setError('Compila tutti i campi');
				else {
					let values = {
						name,
						// age,
						// city,

						// genre,
						// tracksPublished,
						// albumsPublished,
						// formation,

						email,
						password,
						confirmPassword: confirm,

						terms,
						consent,
						newsletter,
					};
					props.registerUser(values, (error) => setError(error), () => setSuccess(true));
				}
				break;
			}
		}
	}

	function resendMail() {
		setErrorMail('');
		setLoadingMail(true);
		setSuccessMail(false);
		resendConfirmMail(email)
			.then(result => {
				if (result.success) {
					setErrorMail('');
					setLoadingMail(false);
					setSuccessMail(true);
				} else {
					setErrorMail(result.message);
					setLoadingMail(false);
					setSuccessMail(false);
				}
			})
			.catch((error) => {
				setLoadingMail(false);
				setSuccessMail(false);
				setErrorMail(error.message);
			});
	}

	return (
		<div className="p-2">
			{success ? (
				<div className='text-center'>
					<h5 className="text-center mb-5">
						Grazie per esserti registrato a HitReview.
						<br />
						<br />
						Riceverai una e-mail per confermare il tuo indirizzo (controlla anche nello spam!).<br />Clicca sul link al suo interno per accedere a HitReview!
					</h5>
					{/* <Alert color="success">Utente registrato correttamente.</Alert> */}
					{/* <Link to="/login" className="d-block text-center">
						Effettua il login
					</Link> */}
					<p className='text-center mb-0'>Non hai ricevuto l'e-mail?</p>
					{loadingMail ? <Loader size={'sm'} />
						: (!successMail && !errorMail) ? <Button color="link" onClick={resendMail} className='text-center py-0 mx-auto my-0'>
							<p className='d-inline'>Inviala nuovamente</p>
						</Button>
							: successMail ? <p className='d-inline'>Inviata correttamente.</p>
								: errorMail ?
									<p className='d-inline'>{errorMail}</p>
									: null
					}
				</div>
			) : props.loading ? (
				<Loader />
			) : (
				<>
					<Form className="form-horizontal" onSubmit={handleOnSubmit}>
						{step === 0 ? (
							<>
								<h5 className="text-center mb-5">Facci sapere qualcosa sul tuo progetto</h5>
								<FormGroup className="auth-form-group-custom mb-4">
									<Label className="d-block text-center" htmlFor="name">
										Nome Progetto
									</Label>
									<Input
										name="name"
										type="text"
										className="form-control"
										id="name"
										value={name}
										style={{ padding: '1.5em 1.5em' }}
										onChange={(event) => setName(event.target.value)}
										placeholder="Mariottide"
									/>
								</FormGroup>
								<FormGroup className="auth-form-group-custom mb-4" row>
									<Col sm={4}>
										<Label className="d-block text-center" htmlFor="age">
											Età
										</Label>
										<Input
											name="age"
											type="number"
											className="form-control text-center"
											id="age"
											value={age}
											style={{ padding: '1.5em 1.5em' }}
											onChange={(event) => setAge(event.target.value)}
											placeholder="69"
										/>
									</Col>
									<Col sm={8}>
										<Label className="d-block text-center" htmlFor="city">
											Luogo
										</Label>
										<Input
											name="city"
											type="text"
											className="form-control"
											id="city"
											value={city}
											style={{ padding: '1.5em 1.5em' }}
											onChange={(event) => setCity(event.target.value)}
											placeholder="Milano"
										/>
									</Col>
								</FormGroup>
							</>
						) : step === 1 ?
							<>
								<h5 className='text-center mb-5'>Facci sapere qualcosa sul tuo progetto</h5>
								<FormGroup className="auth-form-group-custom mb-4" row>
									<Col>
										<Label className="d-block text-center" htmlFor="genre">
											Genere
										</Label>
										<Input
											name="genre"
											type="text"
											className="form-control"
											id="genre"
											value={genre}
											style={{ padding: '1.5em 1.5em' }}
											onChange={event => setGenre(event.target.value)}
											placeholder="Christian Trap"
										/>
									</Col>
								</FormGroup>
								<FormGroup className="auth-form-group-custom mb-4" row>
									<Col sm={6}>
										<Label className="d-block text-center" htmlFor="tPublished">
											Inediti pubblicati
										</Label>
										<Input
											name="tPublished"
											type="number"
											className="form-control text-center"
											id="tPublished"
											value={tracksPublished}
											style={{ padding: '1.5em 1.5em' }}
											onChange={event => setTracksPublished(event.target.value)}
											placeholder="123"
										/>
									</Col>
									<Col sm={6}>
										<Label className="d-block text-center" htmlFor="aPublished">
											Album pubblicati
										</Label>
										<Input
											name="aPublished"
											type="number"
											className="form-control text-center"
											id="aPublished"
											value={albumsPublished}
											style={{ padding: '1.5em 1.5em' }}
											onChange={event => setAlbumsPublished(event.target.value)}
											placeholder="123"
										/>
									</Col>
								</FormGroup>

								<FormGroup className="auth-form-group-custom mb-4" row>
									<Col sm={12}>
										<Label className="d-block text-center" htmlFor="formation">
											Formazione
										</Label>
										<Input
											name="formation"
											type="text"
											className="form-control"
											id="formation"
											value={formation}
											style={{ padding: '1.5em 1.5em' }}
											onChange={event => setFormation(event.target.value)}
											placeholder="1 pizzaiolo, 2 fruttivendoli e tanta birra"
										/>
									</Col>
								</FormGroup>
							</>
							:
							<>
								{/* <h5 className='text-center mb-5'>Manca poco, gli ultimi dati</h5> */}

								<FormGroup className="auth-form-group-custom mb-2">
									<Label className="d-block text-center" htmlFor="name">
										Nome Progetto
									</Label>
									<Input
										name="name"
										type="text"
										className="form-control"
										id="name"
										value={name}
										style={{ padding: '1.5em 1.5em' }}
										onChange={event => setName(event.target.value)}
										placeholder="Mariottide"
									/>
								</FormGroup>

								<FormGroup className="auth-form-group-custom mb-2">
									{/* <i className="ri-user-2-line auti-custom-input-icon"></i> */}
									<Label className="d-block text-center" htmlFor="email">
										Mail
									</Label>
									<Input
										name="email"
										type="text"
										className="form-control"
										id="email"
										value={email}
										style={{ padding: '1.5em 1.5em' }}
										onChange={event => setEmail(event.target.value)}
										placeholder="mariorossi@hitreview.it"
									/>
								</FormGroup>

								<FormGroup className="auth-form-group-custom mb-2">
									{/* <i className="ri-lock-2-line auti-custom-input-icon"></i> */}
									<Label className="d-block text-center" htmlFor="userpassword">
										Password
									</Label>
									<Input
										name="password"
										type="password"
										className="form-control"
										id="userpassword"
										value={password}
										style={{ padding: '1.5em 1.5em' }}
										onChange={event => setPassword(event.target.value)}
										placeholder="••••••••••••••••"
									/>
								</FormGroup>

								<FormGroup className="auth-form-group-custom mb-2">
									{/* <i className="ri-lock-2-line auti-custom-input-icon"></i> */}
									<Label className="d-block text-center" htmlFor="confirm-password">
										Conferma Password
									</Label>
									<Input
										name="confirm-password"
										type="password"
										className="form-control"
										id="confirm-password"
										value={confirm}
										style={{ padding: '1.5em 1.5em' }}
										onChange={event => setConfirm(event.target.value)}
										placeholder="••••••••••••••••"
									/>
								</FormGroup>

								<p className="mt-4 px-3 text-center">
									<Input
										type="checkbox"
										checked={terms}
										onChange={(event) => setTerms(!terms)}
									/>
									Ho letto e accetto i{' '}
									<Link to="/terms" target={'_blank'} className="d-inline">
										{/* <i className="mdi mdi-lock mr-1"></i> */}
										termini di utilizzo
									</Link>
									{'e la '}
									<Link to="/privacy-policy" target={'_blank'} className="d-inline">
										{/* <i className="mdi mdi-lock mr-1"></i> */}
										privacy policy
									</Link>
									<sup>*</sup>
								</p>

								<p className="mt-4 px-3 text-center">
									<Input
										type="checkbox"
										checked={consent}
										onChange={(event) => setConsent(!consent)}
									/>
									{'Do il consenso alla '}
									<Link to="/consent" target={'_blank'} className="d-inline">
										{/* <i className="mdi mdi-lock mr-1"></i> */}
										liberatoria
									</Link>
									{' per il trattamento di immagini, foto, audio e video'}
									<sup>*</sup>
								</p>

								<p className="mt-4 px-3 text-center">
									<Input
										type="checkbox"
										checked={newsletter}
										onChange={(event) => setNewsletter(!newsletter)}
									/>
									Accetto di essere iscritto alla newsletter di HitReview per ricevere aggiornamenti
									sulle ultime novità
								</p>
							</>
						}
						{error ? <Alert color="danger">{error || ''}</Alert> : null}

						<div className="mt-4 d-flex align-items-center justify-content-between">
							{step === 0 ? (<>
								<RoundedButton color="secondary" className="py-2 mr-2 w-100" type="button" onClick={() => props.onCancel && props.onCancel()}>
									Annulla
								</RoundedButton>
								<RoundedButton color="primary" className="py-2 w-100 ml-2" type="submit">
									PROCEDI
								</RoundedButton>
							</>
							)
								: step === 1 ? (
									<>
										<RoundedButton color="secondary" className="py-2 w-100 mr-2" type="button" onClick={() => setStep(0)}>
											Indietro
										</RoundedButton>
										<RoundedButton color="primary" className="py-2 w-100 ml-2" type="submit">
											PROCEDI
										</RoundedButton>
									</>
								) : (
									<>
										<RoundedButton color="secondary" className="py-2 mr-2 w-100" type="button" onClick={() => props.onCancel && props.onCancel()}>
											Annulla
										</RoundedButton>
										{/* <RoundedButton color="secondary" className="py-2 w-100 mr-2" type="button" onClick={() => setStep(1)}>
								Indietro
							</RoundedButton> */}
										<RoundedButton color="primary" className="py-2 w-100 ml-2" type="submit">
											CREA ACCOUNT
										</RoundedButton>
									</>)}
						</div>
						<p className="mt-4 text-center">
							Hai già un account?{' '}
							<Button color="link" onClick={() => {
								props.onCancel && props.onCancel();
								props.history.push("/login")
							}} className="d-inline">
								Effettua il login
							</Button>
						</p>
					</Form>
				</>
			)}
		</div>
	);
};

const mapStatetoProps = (state) => {
	const { loading, user } = state.Auth;
	return { loading, user };
};

export default withRouter(connect(mapStatetoProps, { registerUser })(RegisterForm));
