import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Container, Input, ModalBody, Alert, Modal, ModalFooter, Button } from 'reactstrap';
import Dropzone from 'react-dropzone';

import Page from '../../components/Layout/Page/Page';
import Loader from '../../components/Common/Loader';
import UserRow from '../../components/Users/UserRow/UserRow';
import RoundedButton from '../../components/Buttons/RoundedButton/RoundedButton';

import { ReactComponent as RemoveIcon } from '../../assets/images/remove.svg';
import { ReactComponent as SignedIcon } from '../../assets/images/signed.svg';
import { ReactComponent as PlusIcon } from '../../assets/images/plus.svg';

import * as usersAPI from '../../helpers/api/users.api';

import '../Tracks/Tracks.scss';
import './Admin.scss';

const AdminScouts = props => {
    const [loading, setLoading] = useState(false);
    const [scouts, setScouts] = useState([]);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [letter, setLetter] = useState(null);
    const [disclaimer, setDisclaimer] = useState(null);

    const [loadingUser, setLoadingUser] = useState(null);

    const [creating, setCreating] = useState(false);
    const [loadingCreating, setLoadingCreating] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    useEffect(() => {
        loadScouts();
    }, []);

    async function loadScouts() {
        setLoading(true);
        usersAPI.loadScouts()
            .then((result) => {
                if (result.success) {
                    setScouts(result.users);
                } else {
                    setScouts([]);
                }
                setLoading(false);
            })
            .catch((error) => {
                setScouts([]);
                setLoading(false);
                console.log(error);
            });
    }

    async function handleOnSubmit() {
        setLoadingCreating(true);

        // else lo stiamo creando
        usersAPI.createScout(name, email, password, null, letter, disclaimer)
            .then((result) => {
                setLoadingCreating(false);
                if (result.success) {
                    setName('')
                    setEmail('')
                    setPassword('')
                    setLetter(null);
                    setDisclaimer(null);
                    setSuccess(result.message);
                    loadScouts();
                    setTimeout(() => {
                        handleOnClose();
                    }, 2000);
                } else setError(result.message);
            })
            .catch((error) => {
                setLoadingCreating(false);
                setError(error.message);
            });
    }

    function handleOnDisclaimerChange(files) {
        if (files && files.length > 0) {
            if (files[0].size > 1000000) window.alert('File troppo grande. Dimensioni massine: 1MB.');
            setDisclaimer(files[0]);
        }
    }

    function handleOnLetterChange(files) {
        if (files && files.length > 0) {
            if (files[0].size > 1000000) window.alert('File troppo grande. Dimensioni massine: 1MB.');

            setLetter(files[0]);
        }
    }

    function handleOnDeleteScout(userId) {
        setLoadingUser(userId);
        usersAPI.deleteUser(userId)
            .then((result) => {
                if (result.success)
                    loadScouts();
                else
                    setError(result.message);
                setLoadingUser(null);
            })
            .catch((error) => {
                loadScouts();
                setLoadingUser(null);
                setError(error.message);
            });
    }

    function handleOnClose() {
        setCreating(false);
        setError(null);
        setSuccess(null);
    }

    return <Page className="tracks">
        <Container fluid className="h-100 d-flex flex-column">
            <Row className='mb-2'>
                <Col className='d-flex align-items-center justify-content-end'>
                    <RoundedButton color="primary" onClick={() => setCreating(true)}>Crea Scout</RoundedButton>
                </Col>
            </Row>
            {error &&
                <Alert className="mb-2" color="danger">
                    {error}
                </Alert>}
            {loading ?
                <Loader />
                : scouts ? <>
                    <Row className="h-50 pt-3">
                        <Col className="h-100">
                            <ul className="users-list h-100">
                                {scouts.map(u => <UserRow key={u._id} user={u}
                                    loading={u._id === loadingUser} onDelete={handleOnDeleteScout} />)}
                            </ul>
                        </Col>
                    </Row>
                </> : null}
        </Container>
        <Modal
            className='edit-scout-modal'
            isOpen={creating}
            autoFocus={true}
            centered={true}
            backdrop={true}
            toggle={handleOnClose}>
            <ModalBody>
                {loadingCreating ? (
                    <Loader className="p-5" />
                ) : success ? (
                    <Alert className="mb-4" color="success">
                        {success}
                    </Alert>
                ) : <>
                    <p className="error">{error}</p>
                    <Row>
                        <Col sm="12">
                            <h6 className='mb-3'>{'Crea scout'}</h6>
                            <p>
                                Nome
                            </p>
                            <Input
                                id="name"
                                name="name"
                                type="text"
                                className="form-control"
                                value={name || ''}
                                onChange={(event) =>
                                    setName(event.target.value)
                                }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <p>
                                Indirizzo e-mail
                            </p>
                            <Input
                                id="email"
                                name="email"
                                type="email"
                                className="form-control"
                                value={email || ''}
                                onChange={(event) =>
                                    setEmail(event.target.value)
                                }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <p>
                                Password
                            </p>
                            <Input
                                id="password"
                                name="password"
                                type="password"
                                className="form-control"
                                value={password || ''}
                                onChange={(event) =>
                                    setPassword(event.target.value)
                                }
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="6" className='my-3'>
                            {disclaimer ? (
                                <div className='doc-preview py-3'>
                                    <Button
                                        color="link"
                                        className="remove-doc"
                                        onClick={() => setDisclaimer(null)}
                                    >
                                        <RemoveIcon />
                                    </Button>
                                    <SignedIcon className='doc-icon' />
                                    <small className="d-block text-center mt-2">
                                        {disclaimer.name}
                                    </small>
                                </div>
                            ) : <Dropzone
                                accept={['application/pdf']}
                                multiple={false}
                                maxFiles={1}
                                onDrop={(acceptedFiles) =>
                                    handleOnDisclaimerChange(acceptedFiles)
                                }
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div
                                        className="dropzone add-doc w-100 h-100 needsclick py-2"
                                        {...getRootProps()}
                                    >
                                        <input {...getInputProps()} />
                                        <div className="dz-message needsclick">
                                            <PlusIcon />
                                            <small className="d-block text-center mt-2">
                                                Liberatoria<br />firmata
                                            </small>
                                        </div>
                                    </div>
                                )}
                            </Dropzone>}
                        </Col>
                        <Col sm="6" className='my-3'>
                            {letter ? (
                                <div className='doc-preview py-3'>
                                    <Button
                                        color="link"
                                        className="remove-doc"
                                        onClick={() => setLetter(null)}
                                    >
                                        <RemoveIcon />
                                    </Button>
                                    <SignedIcon className='doc-icon' />
                                    <small className="d-block text-center mt-2">
                                        {letter.name}
                                    </small>
                                </div>
                            ) : <Dropzone
                                accept={['application/pdf']}
                                multiple={false}
                                maxFiles={1}
                                onDrop={(acceptedFiles) =>
                                    handleOnLetterChange(acceptedFiles)
                                }
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div
                                        className="dropzone add-doc w-100 h-100 needsclick py-2"
                                        {...getRootProps()}
                                    >
                                        <input {...getInputProps()} />
                                        <div className="dz-message needsclick">
                                            <PlusIcon />
                                            <small className="d-block text-center mt-2">
                                                Lettera incarico<br />firmata
                                            </small>
                                        </div>
                                    </div>
                                )}
                            </Dropzone>
                            }
                        </Col>
                    </Row>
                </>}
            </ModalBody>
            {!loadingCreating && !success && (
                <ModalFooter className='d-flex align-items-center'>
                    <RoundedButton
                        className="waves-effect px-5 m-0 mt-2 mb-2 mr-2 outline"
                        type="button"
                        color="secondary"
                        onClick={handleOnClose}>
                        Annulla
                    </RoundedButton>
                    <RoundedButton
                        className="waves-effect px-5 m-0 mt-2 mb-2 ml-2"
                        type="button"
                        color="primary"
                        onClick={handleOnSubmit}>
                        Crea
                    </RoundedButton>
                </ModalFooter>
            )}
        </Modal>
    </Page>;
}

const mapStatetoProps = (state) => {
    return { user: state.Auth.user };
};

export default connect(mapStatetoProps, {})(AdminScouts);