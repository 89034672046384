import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

//Account Redux states
import { REGISTER_USER } from '../actionTypes';
import { registerUserSuccessful, registerUserFailed } from '../actions';

import * as authAPI from '../../../helpers/api/auth.api';

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user, errorCallback, successCallback } }) {
    try {
		const response = yield call(authAPI.registerUser, user);
		if (!response.success) {
            yield put(registerUserFailed());
            yield call(errorCallback, response.message);
        } else {
            yield call(successCallback);
            yield put(registerUserSuccessful(response.user));
        }
    } catch (error) {
        yield put(registerUserFailed());
        yield call(errorCallback, error);
    }
}

export function* watchUserRegister() {
    yield takeEvery(REGISTER_USER, registerUser);
}

function* accountSaga() {
    yield all([fork(watchUserRegister)]);
}

export default accountSaga;