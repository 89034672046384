import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Container } from 'reactstrap';
import { isPlatform } from '@ionic/react';

import { ReactComponent as HitReviewLogo } from '../../../assets/images/HitReview_beta.svg';

import LoginForm from '../../../components/Forms/Auth/LoginForm';

import '../Auth.scss';

const Login = (props) => {
	useEffect(() => {
		document.body.classList.add('auth-body-bg');

		return () => {
			document.body.classList.remove('auth-body-bg');
		};
	}, []);

	const isMobile = isPlatform('mobile');

	return (
		<Container fluid className="auth">
			<Row>
				<Col lg={{ size: 4, offset: 4 }}>
					<div className="authentication-page-content py-4 mt-3 d-flex flex-column align-items-stretch justify-content-between min-vh-100 w-100">
						<h1 className="text-center mt-5 mb-0">ACCEDI</h1>
						
						<LoginForm />

						<Link to={isMobile ? '#' : "/"}>
						<div className="my-5 mx-auto w-25 text-center">
							<HitReviewLogo className="logo" />
						</div>
						</Link>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default Login;
