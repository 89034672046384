import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Alert } from 'reactstrap';

import Page from '../../components/Layout/Page/Page';
import Loader from '../../components/Common/Loader';
import DiscountRow from '../../components/Discounts/DiscountRow/DiscountRow';
import RoundedButton from '../../components/Buttons/RoundedButton/RoundedButton';
import EditDiscountModal from '../../components/Modals/Discounts/EditDiscountModal';

import { loadDiscounts } from '../../helpers/api/discounts.api';

import './Admin.scss';

const AdminDiscounts = props => {
    const [selectedCode, setSelectedCode] = useState(null);
    const [editing, setEditing] = useState(false);

    const [page, setPage] = useState(0);
    const [error, setError] = useState(false);

    const [loading, setLoading] = useState(true);

    const [discounts, setDiscounts] = useState([]);

    useEffect(() => {
        setDiscounts([]);
        setPage(0);
    }, [])

    useEffect(() => {
        if (page >= 0) {
            loadPagedDiscounts();
        }
    }, [page]);

    function loadPagedDiscounts() {
        setLoading(true);
        loadDiscounts(page)
            .then((result) => {
                setDiscounts([]);
                if (result.success)
                    setDiscounts(result.discounts);
                else
                    setError(result.message);
                setLoading(false);
            })
            .catch((error) => {
                setDiscounts([]);
                setLoading(false);
                setError(error.message);
                console.log(error);
            });
    }

    function handleOnDiscountSubmit() {
        setDiscounts([]);
        setPage(0);
        setSelectedCode(null)
        setEditing(false);
        loadPagedDiscounts();
    }

    return <Page className="discounts">
        <Container fluid className="h-100 d-flex flex-column">

            <Row>
                <Col className='text-right'>
                    <RoundedButton color="primary" className="d-inline-block"
                        onClick={() => {
                            setSelectedCode(null);
                            setEditing(true);
                        }}>Crea Buono Sconto</RoundedButton>
                </Col>
            </Row>
            {error ? (
                <Alert className="mb-4" color="danger">
                    {error}
                </Alert>
            ) : discounts ? <>
                <Row className="h-50 pt-3">
                    <Col className="h-100">
                        <ul className="discounts-list h-100">
                            {discounts.map(d => <DiscountRow key={d.code} code={d.code} total={d.total} qt={d.qt} value={d.value}
                            expiration={d.expiration}
                                onEditClick={() => {
                                    setSelectedCode(d.code);
                                    setEditing(true);
                                }} />)}
                        </ul>
                    </Col>
                </Row>
                {loading && <Row>
                    <Col xs="12">
                        <div className="text-center my-3">
                            <div className='d-flex align-items-center justify-content-center'>
                                <Loader size="sm" />
                                <span>{'Caricando'}</span>
                            </div>
                        </div>
                    </Col>
                </Row>}
            </> : null}
        </Container>
        <EditDiscountModal code={selectedCode} isOpen={editing} onClose={() => {
            setSelectedCode(null)
            setEditing(false);
        }} onSubmit={handleOnDiscountSubmit} />
    </Page>;
}

export default AdminDiscounts;