import {
	LOGIN_USER,
	LOGIN_USER_SUCCESS,
	LOGOUT_USER,
	LOGIN_USER_FAILED,
	LOGIN_USER_BY_TOKEN
} from '../actionTypes';

const initialState = {
	loading: false,
	user: null
};

const login = (state = initialState, action) => {
	switch (action.type) {
		case LOGIN_USER:
			state = {
				...state,
				loading: true
			};
			break;
		case LOGIN_USER_BY_TOKEN:
			state = {
				...state,
				loading: true
			};
			break;
		case LOGIN_USER_SUCCESS:
			state = {
				...state,
				loading: false,
				user: action.payload
			};
			break;
		case LOGIN_USER_FAILED:
			state = { ...state, loading: false };
			break;
		case LOGOUT_USER:
			state = { ...state, user: null };
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export default login;
