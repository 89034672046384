import { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { Row, Col } from 'reactstrap';

import { ReactComponent as Logo } from '../../../assets/images/HitReview_beta_ext.svg';
import { ReactComponent as Locate } from '../../../assets/images/locate.svg';
import { ReactComponent as IGIcon } from '../../../assets/images/ig.svg';
import { ReactComponent as TTIcon } from '../../../assets/images/tt.svg';
import { ReactComponent as LinkedinIcon } from '../../../assets/images/linkedin.svg';
import { ReactComponent as FBIcon } from '../../../assets/images/fb.svg';

import './PublicFooter.scss';

const PublicFooter = (props) => {
	const [isOpen, setIsOpen] = useState(false);

	function toggle() {
		setIsOpen(!isOpen);
	}

	return (
		<footer className="p-5">
			<Row>
				<Col xs="12" className="d-block d-lg-none mb-5">
					<Link to="/">Home</Link>
					<Link to="/#how">Come Funziona</Link>
					<Link to="/public-discovery">HitDiscovery</Link>
					<Link to="/scouts">Scouter</Link>
					<Link to="/faq">FAQ</Link>
					<Link to="/terms">Termini e condizioni</Link>
					<Link to="/privacy-policy">Privacy Policy</Link>
					<Link to="/consent">Liberatoria</Link>
				</Col>
				<Col xs="10" lg="3" className="d-flex justify-content-start align-items-start">
					<Locate width="20px" className="mr-3" />
					<p>
						HitReview Srls
						<br />
						Via di Portonaccio 23b - Roma
						<br />{' '}
						<a target="_blank" href="mailto:hitreview.italia@gmail.com">
							hitreview.italia@gmail.com
						</a>
					</p>
				</Col>
				<Col lg="7" className="d-none d-lg-block">
					<Row>
						<Col lg="3" className="d-flex flex-column">
							<a target="_blank" href="https://www.tiktok.com/@hitreview">
								<TTIcon /> HitReview
							</a>
							<a target="_blank" href="https://www.instagram.com/hit.review/">
								<IGIcon /> HitReview
							</a>
							<a target="_blank" href="https://www.facebook.com/profile.php?id=100076841324666">
								<FBIcon /> HitReview
							</a>
							<a target="_blank" href="https://www.linkedin.com/company/hit-review/about/">
								<LinkedinIcon /> HitReview
							</a>
						</Col>
						<Col lg="3" className="d-flex flex-column">
							<Link to="/">Home</Link>
							<Link to="/#how">Come Funziona</Link>
						</Col>
						<Col lg="3" className="d-flex flex-column">
							<Link to="/public-discovery">HitDiscovery</Link>
							<Link to="/scouts">Scouter</Link>
							<Link to="/faq">FAQ</Link>
						</Col>
						<Col lg="3" className="d-flex flex-column">
							<Link to="/terms">Termini e condizioni</Link>
							<Link to="/privacy-policy">Privacy Policy</Link>
							<Link to="/consent">Liberatoria</Link>
						</Col>
					</Row>
				</Col>
				<Col xs="6" className="d-block d-lg-none">
					<Row>
						<Col xs="3">
							<a className="d-inline" target="_blank" href="https://www.tiktok.com/@hitreview">
								<TTIcon />
							</a>
						</Col>
						<Col xs="3">
							<a className="d-inline" target="_blank" href="https://www.instagram.com/hit.review/">
								<IGIcon />
							</a>
						</Col>
						<Col xs="3">
							<a
								className="d-inline"
								target="_blank"
								href="https://www.facebook.com/profile.php?id=100076841324666"
							>
								<FBIcon />
							</a>
						</Col>
						<Col xs="3">
							<a
								className="d-inline"
								target="_blank"
								href="https://www.linkedin.com/company/hit-review/about/"
							>
								<LinkedinIcon />
							</a>
						</Col>
					</Row>
				</Col>
				<Col xs="6" lg="2">
					<div className="d-flex flex-column h-100 justify-content-end">
						<Logo height="30px" />
					</div>
				</Col>
			</Row>
		</footer>
	);
};

export default withRouter(PublicFooter);
