import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';

import PublicLayout from '../Layout/PublicLayout/PublicLayout';

const PublicRoute = ({ component: Component, noHeader, noFooter, title }) => {

	useEffect(() => {
		document.title = title || 'HitReview';
	}, [title]);

	return <Route
		render={(props) => <>
		<PublicLayout noHeader={noHeader} noFooter={noFooter}>
			<Component {...props} />
		</PublicLayout>
		</>}
	/>
};

export default PublicRoute;
