// WebSocket.js

import { createContext } from 'react'
import io from 'socket.io-client';
import { SOCKET_HOST } from '../../app.config';
import { useDispatch, useSelector } from 'react-redux';

import { messageReceived, sendMessage, newNotification, pushToTracksToVote, pullFromTracksToVote } from '../../store/actions';

const WebSocketContext = createContext(null)

export { WebSocketContext }

export default ({ children }) => {
    let socket;
    let ws;

    const user = useSelector(state => state.Auth.user);

    const dispatch = useDispatch();

    if (user && user._id) {
        socket = io(SOCKET_HOST);
        socket.emit("add-user", user._id);

        socket.on("msg-receive", ({chatId, message}) => {
			console.log('Socket: event msg-receive', chatId, message);
            dispatch(messageReceived(chatId, message));
        });

        socket.on("new-notification", ({notification}) => {
			console.log('Socket: event new-notification', notification);
            dispatch(newNotification(notification));
        });

        socket.on("new-track-to-vote", ({trackId}) => {
			console.log('Socket: event new-track-to-vote', trackId);
            dispatch(pushToTracksToVote(trackId));
        });
        socket.on("remove-track-to-vote", ({trackId}) => {
			console.log('Socket: event remove-track-to-vote', trackId);
            dispatch(pullFromTracksToVote(trackId));
        });

        ws = {
            socket: socket,
            sendMessage: ({chatId, to, message}) => {
                socket.emit("send-msg", {
                    chatId,
                    to,
                    message,
                    from: user._id
                });
                dispatch(sendMessage(chatId, message));
            }
        }
    }

    return (
        <WebSocketContext.Provider value={ws}>
            {children}
        </WebSocketContext.Provider>
    )
}