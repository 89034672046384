import {
    LOAD_UNREAD_NOTI,
    LOAD_NOTI,
    LOAD_NOTI_ERROR,
    LOAD_NOTI_SUCCESS,
    NEW_NOTIFICATION,
    SET_READ
} from './actionTypes';

export const newNotification = (notification) => {
    return {
        type: NEW_NOTIFICATION,
        payload: {
            notification
        }
    }
}

export const setRead = (notiId) => {
    return {
        type: SET_READ,
        payload: {
            notiId
        }
    }
}

export const loadUnreadNotifications = () => {
    return {
        type: LOAD_UNREAD_NOTI
    }
}

export const loadNotifications = () => {
    return {
        type: LOAD_NOTI
    }
}

export const loadNotificationsSucessful = (result) => {
    return {
        type: LOAD_NOTI_SUCCESS,
        payload: result
    }
}

export const loadNotificationsFailed = (result) => {
    return {
        type: LOAD_NOTI_ERROR,
        payload: result
    }
}