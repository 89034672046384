import React from 'react';
import {
	Row,
	Col,
	Container
} from 'reactstrap';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import RoundedButton from '../../../components/Buttons/RoundedButton/RoundedButton';
import Top10Slider from '../../../components/Sliders/Top10Slider/Top10Slider';

import { ReactComponent as HomeBG } from '../../../assets/images/home-bg.svg';
import { ReactComponent as HomeTracks } from '../../../assets/images/upload-track.svg';
import { ReactComponent as HomeRewards } from '../../../assets/images/home-rewards.svg';
import { ReactComponent as HomeRewards2 } from '../../../assets/images/home-rewards-2.svg';
import { ReactComponent as HomeNetwork } from '../../../assets/images/home-network.svg';
import { ReactComponent as HomeEar } from '../../../assets/images/home-ear.svg';
import { ReactComponent as HomeBass } from '../../../assets/images/home-bass.svg';
import { ReactComponent as HomeThumb } from '../../../assets/images/home-thumb.svg';
import { ReactComponent as HomeNota } from '../../../assets/images/home-nota.svg';

import partnerships from '../../../assets/images/blocco_loghi_fse.png';

import { showRegisterModal } from '../../../store/actions';

import './Home.scss';

const Home = (props) => {

	const MOBILE_AND_TABLET = useMediaQuery({
		query: "(max-device-width: 768px)",
	});

	return <Container fluid className="home">
		<Row>
			<Col>
				<Container>
					<HomeBG className="home-bg" />
					<Row className="pb-3">
						<Col className="h-100 d-flex flex-column" md={{
							size: 6
						}}>
							<h1>Fatti ascoltare <span>da professionisti</span></h1>
							<p>Entra in contatto con i migliori discografici d’Italia, fagli ascoltare la tua musica e ricevi un feedback.</p>
							<div className="cta-container">
								<RoundedButton className="w-50 mb-4 mb-lg-0 mr-lg-3" color="primary" onClick={props.showRegisterModal}>ISCRIVITI</RoundedButton>
								<RoundedButton tag={Link} to="/public-discovery" className="w-50 outline outline-white">Scopri gli artisti</RoundedButton>
							</div>
						</Col>
					</Row>

				</Container>
			</Col>
		</Row>
		<Row className="what">
			<Col>
				<Container className="d-flex flex-column justify-content-center">
					<Row>
						<Col>
							<h2 className="pb-3 text-center">La giusta attenzione per la tua musica e feedback garantiti.</h2>
						</Col>
					</Row>
					<Row className="pb-3">
						<Col md="4">
							<HomeTracks />
							<h5>Carica il tuo brano</h5>
							<p>Carica il tuo brano, aggiorna il
								tuo profilo e attendi
								i feedback dagli scouter
								entro 7 giorni.</p>
						</Col>
						<Col md="4">
							<HomeRewards />
							<h5>Ottieni Rewards</h5>
							<p>Ottieni sempre delle ricompense, a prescindere dal punteggio
								ottenuto. Potrai anche decidere di far apparire la tua musica nella sezione HitDiscovery.</p>
						</Col>
						<Col md="4">
							<HomeNetwork />
							<h5>Conosci altri artisti</h5>
							<p>Entra in contatto con altri musicisti. Fai network, scambiatevi opinioni e possibilità di crescita.</p>
						</Col>
					</Row>

				</Container>
			</Col>
		</Row>
		<Row id="how">
			<Col xs={{
				size: 11,
				offset: 1
			}} lg={{
				size: 10,
				offset: 1
			}}>
				<h2>Come funziona</h2>
				<Row>
					{/* <Col xs={{
						size: 1,
						offset: 0
					}} lg={{
						size: 2,
						offset: 1
					}}>
						<div className="road"></div>
						<div className="step" id="step-1"></div>
						<div className="step" id="step-2"></div>
						<div className="step" id="step-3"></div>
						<div className="step" id="step-4"></div>
						<div className="step" id="step-5"></div>
					</Col> */}
					<Col xs={{
						size: 11
					}} lg={{
						size: 11,
						offset: 2
					}}>
						<Row>
							<Col xs="8" lg="6" className='pl-5'>
								<h6>Iscriviti</h6>
								{<p>Registrati gratuitamente alla piattaforma di HitReview</p>}
						<div className="step" id="step-1"></div>
							</Col>
							<Col xs="4" lg="2" className="d-flex align-items-center mb-5 mb-lg-0 p-2 p-lg-5">
								<HomeNota />
							</Col>
						</Row>
						<Row>
							<Col xs="8" lg="6" className='pl-5'>
								<h6>Crea il tuo profilo</h6>
								{<p>Compila tutti i campi, collega i tuoi profili social e presentati nel modo migliore agli scouter.</p>}
						<div className="step" id="step-2"></div>
							</Col>
							<Col xs="4" lg="2" className="d-flex align-items-center mb-5 mb-lg-0 p-4 p-lg-5">
								<HomeThumb />
							</Col>
						</Row>
						<Row>
							<Col xs="8" lg="6" className='pl-5'>
								<h6>Sottoponi il brano</h6>
								{<p>Hai la tua hit e vuoi farti ascoltare? Carica il tuo brano sul tuo profilo ed ususfruisci del nostro servizio per farti ascoltare e valutare</p>}
						<div className="step" id="step-3"></div>
								</Col>
							<Col xs="4" lg="2" className="d-flex align-items-center mb-5 mb-lg-0 p-3 p-lg-5">
								<HomeEar />
							</Col>
						</Row>
						<Row>
							<Col xs="8" lg="6" className='pl-5'>
								<h6>Attendi il feedback</h6>
								{<p>Dal caricamento del tuo brano, riceverai i feedback dei 3 scouter in massimo una settimana! Attiva le notifiche per non perderti il momento esatto della tua valutazione.</p>}
						<div className="step" id="step-4"></div>
								</Col>
							<Col xs="4" lg="2" className="d-flex align-items-center mb-5 mb-lg-0 p-3 p-lg-5">
								<HomeBass />
							</Col>
						</Row>
						<Row>
							<Col xs="8" lg="6" className='pl-5'>
								<h6>Ottieni i tuoi rewards</h6>
								{<p>Al termine della valutazione riceverai delle rewards da parte di HitReview in base alla valutazione ottenuta ed ulteriori possbili rewards verranno distribuite dagli scouter agli artisti considerati più meritevoli.</p>}
						<div className="step" id="step-5"></div>
								</Col>
							<Col xs="4" lg="2" className="d-flex align-items-center p-3 p-lg-5">
								<HomeRewards2 />
							</Col>
						</Row>

					</Col>
				</Row>
			</Col>
		</Row>
		<Row id="discovery">
			<Col className='mb-5'>
				<Row className="mb-5">
					<Col lg="5" xl="6" className="d-none d-lg-flex align-items-center justify-content-end">
						<div className="line"></div>
					</Col>
					<Col md="6">
						<h2>HitDiscovery</h2>
					</Col>
				</Row>
				<Row>
					<Col md={{
						offset: 1,
						size: 10
					}}>
						<Top10Slider onSlideClick={u => props.history.push('/artists/' + u._id)} />
					</Col>
				</Row>
				<Row className="pb-5 pb-lg-5">
					<Col md="12">
						<h4>Ascolta gli artisti che hanno già partecipato</h4>

						<div className="mx-auto">
							<RoundedButton tag={Link} to="/public-discovery" className="w-50 mr-0 mr-lg-3 mb-3 mb-lg-0" color="primary">HitDiscovery</RoundedButton>
							<RoundedButton tag={Link} to="/faq" className="w-50 ml-0 mb-5 mb-lg-0 outline" >FAQ</RoundedButton>
						</div>
					</Col>
				</Row>
			</Col>
		</Row>
		<Row id="join" className='pb-0'>
			<Col md="12" className='mb-lg-5'>
				<h3>Unisciti a HitReview<br />e fai decollare la tua musica</h3>

				<div className="mx-auto">
					<RoundedButton className="w-50 mr-0 mr-lg-3 mb-3 mb-lg-0" color="primary" onClick={props.showRegisterModal}>ISCRIVITI</RoundedButton>
					<RoundedButton tag={Link} to="/public-discovery" className="w-50 ml-0 mb-5 mb-lg-0 outline">Scopri gli artisti</RoundedButton>
				</div>
			</Col>
			<Col>
			
		<Row id="partnerships" className='mt-5 pb-0'>
			<Col xs={{
				size: 12,
				offset: 0
			}} lg={{
				size: 8,
				offset: 2
			}} className=' p-5'>
				<img src={partnerships} alt="Loghi FSE" />
			</Col>
		</Row>
		</Col>
		</Row>
	</Container>
};

const mapDispatchToProps = {
	showRegisterModal
}

export default connect(null, mapDispatchToProps)(Home);
