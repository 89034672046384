import { useState } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { Link } from 'react-router-dom';

import Page from '../../components/Layout/Page/Page';
import SearchForm from '../../components/Forms/Search/SearchForm';
import RoundedButton from '../../components/Buttons/RoundedButton/RoundedButton';
import Top10Slider from '../../components/Sliders/Top10Slider/Top10Slider';
import LatestSlider from '../../components/Sliders/LatestSlider/LatestSlider';

import 'swiper/swiper-bundle.min.css';

import './Search.scss';

const Search = (props) => {
	const [results, setResults] = useState(null);

	function handleOnSuccess(results) {
		setResults(results);
	}

	let resultsCols =
		results &&
		results.map((u, i) => (
			<Col className="d-flex align-items-center justify-content-center" xs="6" sm="3">
				<Link className="result-link" to={'/users/' + u._id}>
					<span
						style={{
							background: '#fff url("' + u.image?.url + '") center center / cover no-repeat',
						}}
					/>
					<p>{u.name}</p>
				</Link>
			</Col>
		));

	return (
		<Page className="search">
			<Container className="h-100">
				{results ? (
					<Row className="mb-5 mb-lg-0">
						<Col>
							<RoundedButton
								className="d-block w-50 py-2 mt-4 mb-2 mx-auto"
								color="primary"
								type="button"
								onClick={() => setResults(null)}
							>
								RICERCA
							</RoundedButton>

							<h3 className="mb-4">
								<span></span>
								<span>Risultati della ricerca:</span>
								{' ' + results.length + ' artisti'}
							</h3>
							<Row className='mb-4'>
								{results.length > 0 ? resultsCols : <p>Nessun artista trovato.</p>}
							</Row>
						</Col>
					</Row>
				) : (
					<>
						<Row>
							<Col xs="9">
								<h3 className="mb-0">
									<span>Hit</span>
									<span>Research</span>
								</h3>
								<small className="d-block mb-4 mb-lg-5">Imposta i filtri di ricerca</small>
							</Col>
						</Row>
						<Row className="mb-5 mb-lg-2">
							<Col>
								<SearchForm onSuccess={handleOnSuccess} />
							</Col>
						</Row>
					</>
				)}
				<Row className="pb-5 pb-lg-3 mb-5">
					<Col md="11">
						<h3 className="mb-4 mb-lg-5">
							<span>Top10</span>
							<span>HitDiscovery</span>
						</h3>
						<Top10Slider onSlideClick={u => props.history.push('/users/' + u._id)} />
					</Col>
				</Row>
				<Row className="pt-5 pt-lg-3 pb-5 pb-lg-3 mb-5">
					<Col md="11">
						<h3>Ultimi Arrivi</h3>
						<LatestSlider onSlideClick={u => props.history.push('/users/' + u._id)} />
					</Col>
				</Row>
			</Container>
		</Page>
	);
};

export default Search;
