import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Row,
    Col,
    Container,
    Button,
    Collapse,
    Alert
} from 'reactstrap';
import RoundedButton from '../../../../components/Buttons/RoundedButton/RoundedButton';
import Loader from '../../../../components/Common/Loader';

import { loadArticle } from '../../../../helpers/api/articles.api';

import '../Blog.scss';

function strip(html) {
    let doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
}

const Article = (props) => {
    const [error, setError] = useState(false);

    const [loading, setLoading] = useState(true);

    const [articleId, setArticleId] = useState(null);
    const [article, setArticle] = useState(null);

	useEffect(() => {
		let id;
		if (props.location)
            id = props.location.pathname.substring(
				props.location.pathname.lastIndexOf('/') + 1
			);

		setArticleId(id);
	}, []);

    useEffect(() => {
        if (articleId){
            setLoading(true);
            loadArticle(articleId)
            .then((result) => {
                if (result.success) {
                    setArticle(result.article);
                } else {
                    setError(result.message);
                    setArticle(null);
                }
                setLoading(false);
            })
            .catch((error) => {
                setArticle(null);
                setLoading(false);
                setError(error.message);
                console.log(error);
            });
        }
    }, [articleId]);

    return <Container fluid className="blog pb-5">
        <Row>
            <Col>
                {loading ?
                    <Loader />
                    :
                    error ? (
                        <Alert className="mb-0" color="danger">
                            {error}
                        </Alert>
                    ) :
                        article ?
                            <Container>
                                {article.media.length > 0 &&
                                <Row className="my-5">
                                    <Col className="h-100 d-flex flex-column" md={{
                                        size: 11, offset: 1
                                    }}>
                                       <img src={article.media[0].url} />
                                    </Col>
                                </Row>}

                                <Row className="mb-5">
                                    <Col className="h-100 d-flex flex-column" md={{
                                        size: 11, offset: 1
                                    }}>
                                        <h2>{article.title}</h2>
                                    </Col>
                                </Row>
                                
                                <Row className="pb-3">
                                    <Col className="h-100 d-flex flex-column" md={{
                                        size: 11, offset: 1
                                    }}>
                                        <p dangerouslySetInnerHTML={{__html: article.content}} />
                                    </Col>
                                </Row>
                                       
                            </Container> : null}
            </Col>
        </Row>
    </Container>
};

export default Article;
