import React, { useEffect, useState } from 'react';
import {
    Row,
    Col,
    Container,
} from 'reactstrap';

import UserDetail from '../../../components/Users/UserDetail/UserDetail';

const ArtistProfile = (props) => {
    const [profileId, setProfileId] = useState(null);

    useEffect(() => {
        if (props.location.pathname) {
            const id =
                props.location.pathname.split('/')[props.location.pathname.split('/').length - 1];
            setProfileId(id);
        }
    }, [props.location.pathname]);

    return <Container fluid className="py-5">
        <Row>
            <Col className='py-lg-5'>
                <UserDetail userId={profileId} />
            </Col>
        </Row>
    </Container>
};

export default ArtistProfile;
