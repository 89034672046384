import {
	START_PLAY,
    PAUSE_PLAY,
    SET_TRACK
} from './actionTypes';

export const play = () => {
    return {
        type: START_PLAY
    }
}

export const pause = () => {
    return {
        type: PAUSE_PLAY
    }
}

export const select = (track) => {
    return {
        type: SET_TRACK,
        payload: { track }
    }
}