const API_URL_LOCAL = 'http://127.0.0.1:8080/api/v1';
// const API_URL = 'https://dev.api.hitreview.it/api/v1';
const API_URL = 'https://prod.api.hitreview.it/api/v1';

// const MEDIA_URL = 'http://dev.media.hitreview.it';
const MEDIA_URL = 'http://media.hitreview.it';

// const SOCKET_HOST = 'https://dev.api.hitreview.it';
const SOCKET_HOST = 'https://prod.api.hitreview.it';
const SOCKET_HOST_LOCAL = 'http://localhost:8080';

module.exports = {
	API_URL: process.env.REACT_APP_LOCAL ? API_URL_LOCAL : API_URL,
	MEDIA_URL,
	SOCKET_HOST: process.env.REACT_APP_LOCAL ? SOCKET_HOST_LOCAL : SOCKET_HOST
};
