import moment from 'moment';
import React from 'react'
import { Col, Row } from 'reactstrap';

import RoundedButton from '../../Buttons/RoundedButton/RoundedButton';

import './DiscountRow.scss';


const DiscountRow = props => {
	let expired = !moment(props.expiration).isAfter(moment());
	return (<li className="discount-row py-3">
		<Row className='w-100'>
			<Col xs="12" sm="10" className="d-flex flex-column align-items-start justify-content-center">
				<h6 className="mb-0">{props.code}{' '}<span>{props.value}%</span></h6>
				<p className='mb-3 mb-sm-0'>
					{'Totali: '}
					{props.total}
					{' Consumati: '}
					{props.total - props.qt}
					{' - '}
					{expired ? 'Scaduto' : 'Scade'}{' il '}
					{moment(props.expiration, 'YYYY-MM-DD').format('DD/MM/YYYY')}
				</p>
			</Col>
			<Col xs="12" sm="2" className="d-flex align-items-center justify-content-center">
				<RoundedButton color="primary" className="px-5 py-2 ml-4" onClick={props.onEditClick}>Modifica</RoundedButton>
			</Col>
		</Row>
	</li>);
}	

export default DiscountRow;