import React, { useEffect, useState } from 'react';
import { Button, Alert } from 'reactstrap';
import { useMediaQuery } from 'react-responsive';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Loader from '../../../components/Common/Loader';

import { default as blankUser } from '../../../assets/images/user.svg';
import { ReactComponent as Next } from '../../../assets/images/next.svg';
import { ReactComponent as Prev } from '../../../assets/images/prev.svg';

import { loadArtists } from '../../../helpers/api/users.api';

import 'swiper/swiper-bundle.min.css';

import '../Sliders.scss';

function isNearestSlide(slideIndex, activeIndex) {
    const indexBefore = activeIndex - 1;
    const indexAfter = activeIndex + 1;
    return slideIndex === indexBefore || slideIndex === indexAfter;
}

const Top10Slider = props => {
    const MOBILE_AND_TABLET = useMediaQuery({
        query: '(max-device-width: 768px)'
    });
    const [loadingTop10, setLoadingTop10] = useState(false);
    const [top10, setTop10] = useState([]);
    const [top10Error, setTop10Error] = useState(null);
    const [top10Swiper, setTop10Swiper] = useState(null);
    const [top10ActiveSlide, setTop10ActiveSlide] = useState(2);

    useEffect(() => {
        setLoadingTop10(true);
        loadArtists(0, 'rating')
            .then((result) => {
                if (result.success) {
                    result.users ? setTop10(result.users.concat(result.users.splice(0,result.users.length-2))) : setTop10([]);
                } else setTop10Error(result.message);
                setLoadingTop10(false);
            })
            .catch((error) => {
                setLoadingTop10(false);
                setTop10Error(error.message);
            });
    }, []);
    
    return loadingTop10 ? (
        <Loader className="pt-5" />
    ) : top10Error ? (
        <Alert className="mb-0" color="danger">
            {top10Error}
        </Alert>
    ) : top10 && Array.isArray(top10) ? (
        <>
            <Swiper
                className="top10-slider"
                modules={[Navigation, Pagination]}
                spaceBetween={0}
                slidesPerView={3}
                slidesOffsetBefore={0}
                slidesOffsetAfter={0}
                loop
                navigation={{
                    nextEl: '.top10-slider .swiper-button-next',
                    prevEl: '.top10-slider .swiper-button-prev'
                }}
                centeredSlides={true}
                initialSlide={top10ActiveSlide}
                pagination={{
                    el: '.top10-slider .swiper-pagination',
                    type: 'bullets'
                }}
                breakpoints={{
                    768: {
                        slidesPerView: 5,
                        slidesOffsetBefore: 0,
                        slidesOffsetAfter: 0,
                        spaceBetween: 50
                    }
                }}
                onSwiper={(swiper) => setTop10Swiper(swiper)}
                onSlideChange={({ realIndex }) =>
                    setTop10ActiveSlide(realIndex)
                }>
                {top10.map((t, i) => (
                    <SwiperSlide key={t._id} onClick={() => props.onSlideClick && props.onSlideClick(t)}>
                        <div className="profile-pic-container">
                            <div
                                className="profile-pic"
                                style={{
                                    backgroundColor: t.image ? '' : '#ffffff',
                                    backgroundImage: t.image ? 'url(' + t.image.url + ')' : 'url(' + blankUser + ')',
                                    backgroundPosition: t.image ? 'center' : 'bottom',
                                    backgroundSize: t.image ? 'cover' : '80%',
                                    backgroundRepeat: 'no-repeat',
                                    width:
                                        i === top10ActiveSlide && !MOBILE_AND_TABLET
                                            ? '120px'
                                            :
                                            i === top10ActiveSlide && MOBILE_AND_TABLET
                                                ? '100px'
                                                : isNearestSlide(
                                                    i,
                                                    top10ActiveSlide
                                                ) && !MOBILE_AND_TABLET
                                                    ? '80px'
                                                    :
                                                    isNearestSlide(
                                                        i,
                                                        top10ActiveSlide
                                                    ) && MOBILE_AND_TABLET
                                                        ?
                                                        '60px'
                                                        :
                                                        '40px',
                                    height:
                                        i === top10ActiveSlide && !MOBILE_AND_TABLET
                                            ? '120px'
                                            :
                                            i === top10ActiveSlide && MOBILE_AND_TABLET
                                                ? '100px'
                                                : isNearestSlide(
                                                    i,
                                                    top10ActiveSlide
                                                ) && !MOBILE_AND_TABLET
                                                    ? '80px'
                                                    :
                                                    isNearestSlide(
                                                        i,
                                                        top10ActiveSlide
                                                    ) && MOBILE_AND_TABLET
                                                        ?
                                                        '60px'
                                                        :
                                                        '40px'
                                }}>
                                {' '}
                            </div>
                        </div>
                        {/* TODO fix realIndex with index multiple of length */}
                        <div className={'name-container'}>
                            {isNearestSlide(i, top10ActiveSlide) ||
                                i === top10ActiveSlide ? (
                                <b>{t.name}</b>
                            ) : null}
                            {i === top10ActiveSlide && !MOBILE_AND_TABLET ? <span>{t.genre}</span> : null}
                        </div>
                    </SwiperSlide>
                ))}
                <Button
                    className="swiper-button-next"
                    color="link"
                    onClick={(event) =>
                        top10Swiper.slideToLoop(top10ActiveSlide + 1, 200, null)
                    }>
                    <Next width={MOBILE_AND_TABLET ? 20 : 40} height={MOBILE_AND_TABLET ? 20 : 40} />
                </Button>
                <Button
                    className="swiper-button-prev"
                    color="link"
                    onClick={(event) =>
                        top10Swiper.slideToLoop(top10ActiveSlide - 1, 200, null)
                    }>
                    <Prev width={MOBILE_AND_TABLET ? 20 : 40} height={MOBILE_AND_TABLET ? 20 : 40} />
                </Button>
            </Swiper>
            <div className="swiper-pagination mt-2 mt-lg-0">
                {top10.map((t, i) => (
                    <Button
                        key={'bullet_' + t._id}
                        onClick={(event) =>
                            top10Swiper.slideToLoop(i, 200, null)
                        }
                        className={
                            'swiper-pagination-bullet mx-1 ' +
                            (i === top10ActiveSlide
                                ? 'swiper-pagination-bullet-active'
                                : '')
                        } />
                ))}
            </div>
        </>
    ) : null;
}

export default Top10Slider;