import { useEffect, useState } from 'react';
import { IonContent, IonInfiniteScroll, IonList } from '@ionic/react';
import { Container, Row, Col, Card, CardHeader, CardBody, Alert } from 'reactstrap';

import Page from '../../components/Layout/Page/Page';
import Loader from '../../components/Common/Loader';
import NotificationRow from '../../components/Notifications/NotificationRow';

import { loadNotifications } from '../../helpers/api/notifications.api';

import './Notifications.scss';

const NotificationsList = (props) => {
	const [error, setError] = useState('');

	const [page, setPage] = useState(0);
	const [hasMore, setHasMore] = useState(true);
	const [notifications, setNotifications] = useState([]);

	useEffect(() => {
		load();
	}, []);

	function load() {
		setError(false);
		try {
			loadNotifications(page)
				.then((result) => {
					if (result.success) {
						if (result.notifications) setNotifications([...notifications, ...result.notifications]);
						setHasMore(result.hasMore);
						setPage(page + 1);
					} else setError(result.message);
				})
				.catch((error) => {
					setError(error.message);
				});
		} catch (error) {
			setError(error.message);
		}
	}

	const notificationsRows =
		notifications.length > 0 &&
		notifications.map((n) => <NotificationRow key={n._id.toString()} notification={n} />);

	return (
		<Page className="notifications h-100">
			<Container className="h-100">
				<Row className="h-100">
					<Col md="10" className="h-100 p-0 pb-lg-0">
						<Card className="h-100">
							<CardHeader>
								<h5>Notifiche</h5>
							</CardHeader>
							<CardBody>
								<Container fluid className="h-100">
									{error && (
										<Alert className="mb-0" color="danger">
											{error}
										</Alert>
									)}
									<IonContent forceOverscroll={false}>
										<IonList>{notificationsRows}</IonList>
										{hasMore ? (
											<IonInfiniteScroll
												onIonInfinite={(ev) => {
													load();
												}}
											>
												<div className="infinite-scroll-content p-4">
													<Loader />
												</div>
											</IonInfiniteScroll>
										) : !hasMore && notifications.length === 0 ? (
											<p>Nessuna notifica</p>
										) : null}
									</IonContent>
								</Container>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Page>
	);
};

export default NotificationsList;
