import { Link, withRouter } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import './Sidebar.scss';

import { ReactComponent as HomeIcon } from '../../../assets/images/home.svg';
import { ReactComponent as DiscoveryIcon } from '../../../assets/images/discovery.svg';
import { ReactComponent as MessageIcon } from '../../../assets/images/message.svg';
import { ReactComponent as RewardIcon } from '../../../assets/images/reward.svg';
import { ReactComponent as TracksIcon } from '../../../assets/images/wave.svg';
import { ReactComponent as ProfileIcon } from '../../../assets/images/profile.svg';
import { ReactComponent as WalletIcon } from '../../../assets/images/wallet.svg';
import { ReactComponent as PlaylistIcon } from '../../../assets/images/playlist.svg';
import { ReactComponent as LogoutIcon } from '../../../assets/images/logout.svg';
import { connect } from 'react-redux';

const Sidebar = (props) => {
	const [active, setActive] = useState('');

	useEffect(() => {
		setActive(props.location.pathname);
	}, [props.location.pathname]);

	return props.user ? (
		<div className="sidebar">
			<div className="sidebar-menu">
				<ul className="list-unstyled">
					{props.user.type === 'admin' ?
						<>
							<li className={active === '/admin/dashboard' || active === '/admin' ? 'active' : ''}>
								<Link to="/admin">
									<HomeIcon />
									<span>Dashboard</span>
								</Link>
							</li>
							<li className={active === '/admin/tracks' ? 'active' : ''}>
								<Link to="/admin/tracks">
									<TracksIcon />
									<span>Brani</span>
								</Link>
							</li>
							<li className={active === '/admin/artists' ? 'active' : ''}>
								<Link to={'/admin/artists'}>
									<ProfileIcon />
									<span>Artisti</span>
								</Link>
							</li>
							<li className={active === '/admin/scouts' ? 'active' : ''}>
								<Link to={'/admin/scouts'}>
									<ProfileIcon />
									<span>Scouter</span>
								</Link>
							</li>
							<li className={active === '/admin/articles' ? 'active' : ''}>
								<Link to={'/admin/articles'}>
									&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>Articoli</span>
								</Link>
							</li>
							<li className={active === '/admin/discounts' ? 'active' : ''}>
								<Link to="/admin/discounts">
									<WalletIcon />
									<span>Buoni Sconto</span>
								</Link>
							</li>
							<li className={active === '/admin/orders' ? 'active' : ''}>
								<Link to={'/admin/orders'}>
									&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>Ordini</span>
								</Link>
							</li>
							<li className={active === '/admin/payouts' ? 'active' : ''}>
								<Link to={'/admin/payouts'}>
									<WalletIcon />
									<span>Payout</span>
								</Link>
							</li>
						</>
						:
						<>

							<li className={active === '/dashboard' || active === '/' ? 'active' : ''}>
								<Link to="/dashboard">
									<HomeIcon />
									<span>Dashboard</span>
								</Link>
							</li>
							<li className={active === '/users/' + props.user._id ? 'active' : ''}>
								<Link to={'/users/' + props.user._id}>
									<ProfileIcon />
									<span>Il Mio Profilo</span>
								</Link>
							</li>
							<li className={active === '/tracks' ? 'active' : ''}>
								<Link to="/tracks">
									<TracksIcon style={{marginLeft: '-6px'}} />
									<span>{props.user.type === 'scout' ? 'Brani da valutare' : 'I Miei Brani'}</span>
									{props.hasUnvotedTracks && <span className='dot'></span>}
								</Link>
							</li>
							<li className={(active === '/chats' ? 'active' : '')}>
								<Link to="/chats">
									<MessageIcon />
									<span>Messaggi</span>
									{props.hasUnreadChats && <span className='dot'></span>}
								</Link>
							</li>
						</>}
					{props.user.type === 'artist' && <>
							<li className={active === '/discovery' ? 'active' : ''}>
								<Link to="/discovery">
									<DiscoveryIcon />
									<span>HitDiscovery</span>
								</Link>
							</li>
						<li className={active === '/rewards' ? 'active' : ''}>
							<Link to="/rewards">
								<RewardIcon />
								<span>Rewards</span>
							</Link>
						</li>
					</>}
					{props.user.type === 'scout' && <>
						<li className={active === '/search' ? 'active' : ''}>
							<Link to="/search">
								<DiscoveryIcon />
								<span>HitDiscovery</span>
							</Link>
						</li>
						<li className={active === '/playlists' ? 'active' : ''}>
							<Link to="/playlists">
								<PlaylistIcon />
								<span>Playlist</span>
							</Link>
						</li>
						<li className={active === '/wallet' ? 'active' : ''}>
							<Link to="/wallet">
								<WalletIcon />
								<span>Portafoglio</span>
							</Link>
						</li>
					</>}
				</ul>
			</div>
			<div className="sidebar-footer">
				<ul className="list-unstyled">
					<li>
						<Link to="/logout">
							<LogoutIcon />
							<span>Log Out</span>
						</Link>
					</li>
				</ul>
			</div>
		</div>
	) : null;
};

const mapStatetoProps = (state) => {
	const { error, success, user } = state.Auth;
	return {
		error,
		success,
		user,
		hasUnreadChats: state.Chat.unreadChats && state.Chat.unreadChats.length > 0,
		hasUnvotedTracks: state.Tracks.toVote && state.Tracks.toVote.length > 0
	};
};

export default withRouter(connect(mapStatetoProps, {})(Sidebar));
