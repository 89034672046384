const { API_URL } = require('../../app.config');

export async function loadAllPayouts(page) {
	const token = localStorage.getItem('token');

	const headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	let url = API_URL + '/movements?cause=payout&p=' + page;

	const response = await fetch(url, {
		method: 'GET',
		headers
	}).then((response) => response.json());

	return response;
}

export async function requestPayout(
	iban, holder, value
) {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	const body = JSON.stringify({
		iban,
		holder,
		value,
		cause: 'payout'
	});

	let url = API_URL + '/movements';

	let response = await fetch(url, {
		method: 'POST',
		headers,
		body
	}).then((response) => response.json());

	return response;
}

export async function completePayout(mId) {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	const body = JSON.stringify({
		status: 'completed',
	});

	let url = API_URL + '/movements/' + mId;

	let response = await fetch(url, {
		method: 'PUT',
		headers,
		body
	}).then((response) => response.json());

	return response;
}

export async function cancelPayout(mId) {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	const body = JSON.stringify({
		status: 'cancelled'
	});

	let url = API_URL + '/movements/' + mId;

	let response = await fetch(url, {
		method: 'PUT',
		headers,
		body
	}).then((response) => response.json());

	return response;
}