import React, { useEffect } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';

import RoundedButton from '../../components/Buttons/RoundedButton/RoundedButton'

import { ReactComponent as Claim } from '../../assets/images/claim.svg'
import { ReactComponent as HitReviewLogo } from '../../assets/images/HitReview_beta.svg'

import './Auth.scss'

const Auth = (props) => {

    useEffect(() => {
        document.body.classList.add('auth-body-bg');

        return () => {
            document.body.classList.remove('auth-body-bg');
        };
    }, []);

    return <Container fluid className="auth">
    <span className="ballon big pink"></span>
    <span className="ballon small pink"></span>
    <span className="ballon big yellow"></span>
        <Row>
            <Col lg={{ size: 4, offset: 4 }} style={{zIndex: 2}}>
                <div className="authentication-page-content py-4 pt-5 d-flex flex-column align-items-stretch justify-content-between min-vh-100 w-100">
                    <div className="my-5 mx-auto w-75 text-center">
                        <Claim className="claim" />
                    </div>

                    <div className="p-2">

                        <RoundedButton
                            color="secondary"
                            className="d-block w-75 mt-4 mb-1 mx-auto text-center"
                            type="button"
                            onClick={() => props.history.push('/login')}>
                            ACCEDI
                        </RoundedButton>
                        <RoundedButton
                            color="secondary"
                            className="d-block w-75 mt-4 mb-1 mx-auto text-center"
                            type="submit"
                            onClick={() => props.history.push('/register')}>
                            REGISTRATI
                        </RoundedButton>

                        <Link
                            to="/forgot-pwd"
                            className="d-block mt-4 text-center">
                            {/* <i className="mdi mdi-lock mr-1"></i> */}
                            Hai dimenticato la password?
                        </Link>
                    </div>

                    <div className="my-5 mx-auto w-25 text-center">
                        <HitReviewLogo className="logo" />
                    </div>
                </div>
            </Col>
        </Row>
    </Container>;
};

export default withRouter(Auth);
