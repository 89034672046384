import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
    TabPane,
    TabContent,
    NavItem,
    Nav,
    NavLink,
    Alert
} from 'reactstrap';

import UploadTrackForm from '../Forms/UploadTrackForm/UploadTrackForm';
import ArtistsContentsForm from '../Forms/Artists/ArtistsContentsForm';

const ArtistsSettingsTabs = (props) => {
    let { pathname } = useLocation();

    const [activeTab, setActiveTab] = useState('contents');

    useEffect(() => {
        let tab;
        if (pathname) {
            tab = pathname.split('/settings/')[1];
        }
        if (tab)
            tab = tab.replace('/', '');

        if (tab)
            setActiveTab(tab)
    }, [pathname]);

    return <>
        <Nav className="w-75" tabs>
            {/* <NavItem>
                    <NavLink
                        className={
                            activeTab === '1' ? 'active' : ''
                        }
                        onClick={() => {
                            setActiveTab('1');
                        }}>
                        Account
                    </NavLink>
                </NavItem> */}
            <NavItem>
                <NavLink
                    className={
                        activeTab === 'contents' ? 'active' : ''
                    }
                    tag={Link}
                    to="/settings/contents">
                    Contenuti
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={
                        activeTab === 'upload-track' ? 'active' : ''
                    }
                    tag={Link}
                    to="/settings/upload-track">
                    {'Carica brano'}
                </NavLink>
            </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
            {/* <TabPane tabId="1">
													<Form>
														<FormGroup row>
															<Col md="5">
																<Label for="email">
																	Indirizzo email
																</Label>
																<Input
																	type="email"
																	name="email"
																	id="email"
																	value={user.email}
													onChange={handleOnFieldChange)
													}
																/>
															</Col>
														</FormGroup>
														<Row>
															<Col md="5">
																<RoundedButton
																	className="mb-5"
																	color="primary"
																	type="submit">
																	Aggiorna Email
																</RoundedButton>
															</Col>
														</Row>
													</Form> 
													<Form>
														<FormGroup row>
															<Col md="5">
																<Label for="old-password">
																	Vecchia Password
																</Label>
																<Input
																	type="password"
																	name="password"
																	id="old-password"
																/>
															</Col>
														</FormGroup>
														<FormGroup row>
															<Col md="5">
																<Label for="new-password">
																	Nuova Password
																</Label>
																<Input
																	type="password"
																	name="password"
																	id="new-password"
																/>
															</Col>
														</FormGroup>
														<FormGroup row>
															<Col md="5">
																<Label for="confirm-password">
																	Conferma Password
																</Label>
																<Input
																	type="password"
																	name="password"
																	id="confirm-password"
																/>
															</Col>
														</FormGroup>
														<Row>
															<Col md="5">
																<RoundedButton
																	className="mb-5"
																	color="primary"
																	type="submit">
																	Cambia password
																</RoundedButton>
															</Col>
														</Row>
													</Form>
												</TabPane> */}
            <TabPane tabId="contents">
                <ArtistsContentsForm />
            </TabPane>
            <TabPane tabId="upload-track">
                <UploadTrackForm onSuccess={() => { }} />
            </TabPane>
        </TabContent>
    </>;
}

export default ArtistsSettingsTabs;
