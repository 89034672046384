import React, { useEffect, useState } from 'react';
import {
    TabContent, TabPane
} from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';

import ScoutsContentsForm from '../Forms/Scouts/ScoutsContentsForm';
import ScoutsPaymentsForm from '../Forms/Scouts/ScoutsPaymentsForm';

const ScoutsMobileSettingsMenu = (props) => {
    let { pathname } = useLocation();

    const [activeTab, setActiveTab] = useState('');

    useEffect(() => {
        let tab;
        if (pathname) {
            tab = pathname.split('/settings')[1];
        }
        if (tab)
            tab = tab.replace('/', '');

        setActiveTab(tab)
    }, [pathname]);

    return <TabContent activeTab={activeTab} className='h-100'>
        <TabPane tabId={''} className='h-100'>
            <div className='h-100 d-flex flex-column justify-content-between'>
                <div>
                    <ul>
                        {/* <li>
                            <Link to="/settings/account">Account</Link>
                        </li> */}
                        <li>
                            <Link to="/settings/contents">Contenuti</Link>
                        </li>
                        <li>
                            <Link to="/settings/payments">Pagamenti</Link>
                        </li>
                        <li>
                            <Link to="/wallet">Portafoglio</Link>
                        </li>
                        <li>
                    <Link to="/help">Aiuto</Link>
                </li>
                    </ul>
                </div>
                <div>
                    <ul className="list-unstyled">
                        <li>
                            <Link to="/logout">
                                Esci
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </TabPane>
        <TabPane tabId={'contents'}>
            <ScoutsContentsForm />
        </TabPane>
        <TabPane tabId={'payments'}>
            <ScoutsPaymentsForm />
        </TabPane>
    </TabContent>;
}

export default ScoutsMobileSettingsMenu;
