import React from 'react';
import { ModalBody, Modal } from 'reactstrap';

import LoginForm from '../../Forms/Auth/LoginForm';

import '../Modals.scss';
import './AuthModal.scss';

const LoginModal = (props) => {

	function handleOnClose() {
		if (props.onClose) props.onClose();
	}

	return (
		<Modal
			className="auth-modal"
			isOpen={props.isOpen}
			autoFocus={true}
			centered={true}
			backdrop={true}
			toggle={handleOnClose}>
			<ModalBody>
				<LoginForm onCancel={handleOnClose} />
			</ModalBody>
		</Modal>
	);
};

export default LoginModal;
