import { Capacitor } from '@capacitor/core';
import { connect } from 'react-redux';

import PublicHeader from '../PublicHeader/PublicHeader';
import PublicFooter from '../PublicFooter/PublicFooter';
import LoginModal from '../../Modals/Auth/LoginModal';
import RegisterModal from '../../Modals/Auth/RegisterModal';

import { closeModal } from '../../../store/actions';

import './PublicLayout.scss';
import { IonContent } from '@ionic/react';

const platform = Capacitor.getPlatform();

const PublicLayout = (props) => {
	return (
		<IonContent>
			<div className="public-layout">
				{platform === 'web' ? props.noHeader ? null : <PublicHeader /> : null}
				<div className='public-content'>
				{props.children}
				</div>
				{platform === 'web' ? props.noFooter ? null : <PublicFooter /> : null}
				<LoginModal isOpen={props.modal === 'login'} onClose={props.closeModal} />
				<RegisterModal isOpen={props.modal === 'register'} onClose={props.closeModal} />
			</div>
		</IonContent>
	);
};

const mapStateToProps = (state) => {
	return {
		...state.Layout,
	};
};

const mapDispatchToProps = {
	closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicLayout);
