import { useState } from 'react'
import { withRouter } from 'react-router';
import { Button } from 'reactstrap';
import { IonItem, IonReorder } from '@ionic/react';

import RoundedButton from '../../Buttons/RoundedButton/RoundedButton';
import Loader from '../../Common/Loader';

import { ReactComponent as TrashIcon } from '../../../assets/images/trash.svg';

import { removeTrackFromPlaylist } from '../../../helpers/api/playlists.api';

import './PlaylistTrackItem.scss';

const PlaylistTrackItem = (props) => {

    const [loading, setLoading] = useState(false);

    async function handleOnRemoveTrack() {
        setLoading(true)
        removeTrackFromPlaylist(props.playlistId, props.track._id)
            .then(result => {
                if (result.success) 
                    props.onRemoveComplete && props.onRemoveComplete();
                else console.log(result.message);
                setLoading(false)
            })
            .catch(error => {
                console.log(error);
                setLoading(false)
            });
    }

    return props.track ?
        <IonItem lines="none" detail={false} className="ion-no-padding playlist-track-item" onClick={props.onClick}>
            <div className="title-container">
                <h6 className="mr-5 mb-0">{props.track.title}<br /><span>{props.track.author.name}</span></h6>
                {/* <PlayButton onClick={props.onPlayClick} /> */}
            </div>
            <div className="buttons-container">
                {loading ?
                    <Loader className="mr-2"/>
                    :
                    props.deletable ?
                    <Button className="delete-button ml-3 ml-lg-0 mr-0 mr-lg-3" color="link" onClick={handleOnRemoveTrack}>
                        <TrashIcon />
                    </Button> : null
                }
                <RoundedButton color="primary" className="d-none d-lg-block px-3" onClick={() => props.history.push('/users/' + props.track.author._id)}>VISUALIZZA Profilo</RoundedButton>
            </div>
            <IonReorder slot="end"></IonReorder>
        </IonItem>
        : null;
}

export default withRouter(PlaylistTrackItem);