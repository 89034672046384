import { withRouter } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import ObjectID from 'bson-objectid';

import { setRead } from '../../store/actions';

import './NotificationRow.scss';

const isToday = (someDate) => {
	const today = new Date();
	return (
		someDate.getDate() == today.getDate() &&
		someDate.getMonth() == today.getMonth() &&
		someDate.getFullYear() == today.getFullYear()
	);
};

const NotificationRow = (props) => {

	function handleOnClick() {
		props.setRead && props.setRead(props.notification._id);
		// props.notification.read = true;
		if (props.notification.link)
			props.history.push(props.notification.link);
	}

	const notification = props.notification;
	let timestamp = ObjectID(notification._id).getTimestamp();
	let createdAt = isToday(new Date(timestamp))
		? moment(timestamp).format('HH:mm')
		: moment(timestamp).format('DD/MM/YYYY');

	return (
		<Row className="notification-row">
			<Col className="h-100" sm="12">
				<Button color="link" onClick={handleOnClick}
				className="d-flex align-items-center justify-content-between h-100">
					<div className="d-flex align-items-center">
						{!notification.read && <span className='dot'></span>}
						{notification.icon && notification.icon.url ? (
							<img src={notification.icon.url} className="rounded-circle avatar-sm mr-4" alt="notification" />
						) : null}
						<div>
						<p className="text-truncate font-size-14 d-block mb-0"><b>{notification.title}</b></p>
						<p className="font-size-14 d-block mb-0">{notification.message}</p>
						</div>
					</div>
					<small className="mb-0">{createdAt}</small>
				</Button>
			</Col>
		</Row>
	);
};

export default withRouter(connect(null, {setRead})(NotificationRow));
