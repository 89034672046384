import { REHYDRATE } from 'redux-persist/lib/constants';
import {
	START_PLAY,
	PAUSE_PLAY,
    SET_TRACK
} from './actionTypes';

const initialState = {
    track: null, playing: false
}

const player = (state = initialState, action) => {
    switch (action.type) {
        case REHYDRATE:
			if (action.payload && action.payload.player)
				state = {
					...state,
					...action.payload.player,
					playing: false
				};
			else state = {
					...state,
				};
            break;
        case START_PLAY:
            state = {
                ...state,
                playing: true
            }
            break;
        case PAUSE_PLAY:
            state = {
                ...state,
                playing: false
            }
            break;
        case SET_TRACK:
            state = {
                ...state,
                track: action.payload.track
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default player;