import React, { useState, useEffect } from 'react';
import { withRouter, Link, useLocation } from 'react-router-dom';
import {
    TabPane,
    TabContent,
    NavItem,
    Nav,
    NavLink
} from 'reactstrap';

import ScoutsContentsForm from '../Forms/Scouts/ScoutsContentsForm';
import ScoutsPaymentsForm from '../Forms/Scouts/ScoutsPaymentsForm';

const ScoutsSettingsTabs = (props) => {
    let { pathname } = useLocation();

    const [activeTab, setActiveTab] = useState('contents');

    useEffect(() => {
        let tab;
        if (pathname) {
            tab = pathname.split('/settings/')[1];
        }
        if (tab)
            tab = tab.replace('/', '');

        if (tab)
            setActiveTab(tab)
    }, [pathname]);

    return <>
        <Nav className="w-75" tabs>
            {/* <NavItem>
                <NavLink
                    className={
                        activeTab === '1' ? 'active' : ''
                    }
                    onClick={() => {
                        setActiveTab('1');
                    }}>
                    Account
                </NavLink>
            </NavItem> */}
            <NavItem>
                <NavLink
                    className={
                        activeTab === 'contents' ? 'active' : ''
                    }
                    tag={Link}
                    to="/settings/contents" >
                    Contenuti
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={
                        activeTab === 'payments' ? 'active' : ''
                    }
                    tag={Link}
                    to="/settings/payments">
                    {'Pagamenti'}
                </NavLink>
            </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
            {/* <TabPane tabId="1">
													<Form>
														<FormGroup row>
															<Col md="5">
																<Label for="email">
																	Indirizzo email
																</Label>
																<Input
																	type="email"
																	name="email"
																	id="email"
																	value={user.email}
													onChange={handleOnFieldChange)
													}
																/>
															</Col>
														</FormGroup>
														<Row>
															<Col md="5">
																<RoundedButton
																	className="mb-5"
																	color="primary"
																	type="submit">
																	Aggiorna Email
																</RoundedButton>
															</Col>
														</Row>
													</Form> 
													<Form>
														<FormGroup row>
															<Col md="5">
																<Label for="old-password">
																	Vecchia Password
																</Label>
																<Input
																	type="password"
																	name="password"
																	id="old-password"
																/>
															</Col>
														</FormGroup>
														<FormGroup row>
															<Col md="5">
																<Label for="new-password">
																	Nuova Password
																</Label>
																<Input
																	type="password"
																	name="password"
																	id="new-password"
																/>
															</Col>
														</FormGroup>
														<FormGroup row>
															<Col md="5">
																<Label for="confirm-password">
																	Conferma Password
																</Label>
																<Input
																	type="password"
																	name="password"
																	id="confirm-password"
																/>
															</Col>
														</FormGroup>
														<Row>
															<Col md="5">
																<RoundedButton
																	className="mb-5"
																	color="primary"
																	type="submit">
																	Cambia password
																</RoundedButton>
															</Col>
														</Row>
													</Form>
												</TabPane> */}
            <TabPane tabId="contents">
                <ScoutsContentsForm />
            </TabPane>
            <TabPane tabId="payments">
                <ScoutsPaymentsForm />
            </TabPane>
        </TabContent>
    </>;
}

export default withRouter(ScoutsSettingsTabs);
