import { __awaiter, __extends, __generator } from "tslib";
import { WebPlugin } from './index';
var ClipboardPluginWeb = /** @class */ (function (_super) {
    __extends(ClipboardPluginWeb, _super);
    function ClipboardPluginWeb() {
        return _super.call(this, {
            name: 'Clipboard',
            platforms: ['web']
        }) || this;
    }
    ClipboardPluginWeb.prototype.write = function (options) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!navigator.clipboard) {
                            return [2 /*return*/, Promise.reject('Clipboard API not available in this browser')];
                        }
                        if (!(options.string !== undefined || options.url)) return [3 /*break*/, 2];
                        return [4 /*yield*/, navigator.clipboard.writeText(options.string !== undefined ? options.string : options.url)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        if (options.image) {
                            return [2 /*return*/, Promise.reject('Setting images not supported on the web')];
                        }
                        _a.label = 3;
                    case 3: return [2 /*return*/, Promise.resolve()];
                }
            });
        });
    };
    ClipboardPluginWeb.prototype.read = function (_options) {
        return __awaiter(this, void 0, void 0, function () {
            var text, data, _i, _a, item;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!navigator.clipboard) {
                            return [2 /*return*/, Promise.reject('Clipboard API not available in this browser')];
                        }
                        if (!(_options.type === 'string' || _options.type === 'url')) return [3 /*break*/, 2];
                        return [4 /*yield*/, navigator.clipboard.readText()];
                    case 1:
                        text = _b.sent();
                        return [2 /*return*/, Promise.resolve({ value: text })];
                    case 2: return [4 /*yield*/, navigator.clipboard.read()];
                    case 3:
                        data = _b.sent();
                        for (_i = 0, _a = data.items; _i < _a.length; _i++) {
                            item = _a[_i];
                            if (item.type === 'text/plain') {
                                return [2 /*return*/, Promise.resolve({ value: item.getAs('text/plain') })];
                            }
                        }
                        _b.label = 4;
                    case 4: return [2 /*return*/, Promise.reject('Unable to get data from clipboard')];
                }
            });
        });
    };
    return ClipboardPluginWeb;
}(WebPlugin));
export { ClipboardPluginWeb };
var Clipboard = new ClipboardPluginWeb();
export { Clipboard };
