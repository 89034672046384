const { API_URL } = require('../../app.config');

export async function loadPaymentSetup() {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');
	
	let response = await fetch(API_URL + '/checkout/setup', {
		method: 'GET',
		headers
	}).then((response) => response.json());

	return response;
}

export async function prepareCheckout(method, amount, discount) {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');
	
	let response = await fetch(API_URL + '/checkout/prepare', {
		method: 'POST',
		headers,
		body: JSON.stringify({ method, amount, discount })
	}).then((response) => response.json());

	return response;
}

export async function verifyCheckout(transactionId, discountCode, method, track, title, owners) {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);

	const data = new FormData();
	
	data.append('transactionId', transactionId);
	data.append('discount', discountCode);
	data.append('method', method);

	data.append('title', title);
	data.append('track', track);
	data.append('owners', JSON.stringify(owners));
	
	let response = await fetch(API_URL + '/checkout/verify', {
		method: 'POST',
		headers,
		body: data
	}).then((response) => response.json());

	return response;
}