import React, { useState, useEffect } from 'react';
import { Card, ModalFooter, ModalBody, Modal, Col, Input, Row, Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import SweetAlert from 'react-bootstrap-sweetalert';

// Import Editor
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import * as draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import Loader from '../../Common/Loader';
import RoundedButton from '../../Buttons/RoundedButton/RoundedButton';

import {
	loadArticle,
	createArticle,
	updateArticle,
	deleteArticle,
	deleteArticleMedia,
} from '../../../helpers/api/articles.api';

import '../Modals.scss';
import './EditArticleModal.scss';

function formatBytes(bytes, decimals = 2) {
	if (bytes === 0) return '0 Bytes';
	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const EditArticleModal = (props) => {
	const [article, setArticle] = useState({});

	const [editorState, setEditorState] = useState(EditorState.createEmpty());

	const [selectedFiles, setSelectedFiles] = useState([]);

	const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);

	useEffect(() => {
		if (props.articleId) {
			setLoading(true);
			loadArticle(props.articleId)
				.then((result) => {
					if (result.success) {
						if (result.article['content'])
							setEditorState(
								EditorState.createWithContent(
									ContentState.createFromBlockArray(convertFromHTML(result.article['content']))
								)
							);

						setArticle(result.article);
					} else {
						setArticle({});
						setError(result.message);
					}

					setLoading(false);
				})
				.catch((error) => {
					setError(error.message);
					setLoading(false);
				});
		} else {
			setArticle({});
			setSelectedFiles([]);
			setError('');
			setEditorState(EditorState.createEmpty());
			setLoading(false);
			setSuccess(false);
		}
	}, [props.articleId]);

	function handleOnFieldChange(name, value) {
		let s = { ...article };
		s[name] = value;
		setArticle(s);
	}

	function handleOnDeleteFileClick(url) {
		let media = article.media;
		let toDelete = media && media.find((m) => m.url === url);
		if (toDelete) {
			let newMedia = media.filter((m) => m.url !== url);
			toDelete.delete = true;
			newMedia.push(toDelete);
			let s = { ...article, media: newMedia };
			setArticle(s);
		}
	}

	function handleOnAcceptedFiles(files) {
		files.slice(0, 9).map((file) =>
			Object.assign(file, {
				preview: URL.createObjectURL(file),
				formattedSize: formatBytes(file.size),
			})
		);

		setSelectedFiles(files);
	}

	async function handleOnSubmit() {
		setLoading(true);

		if (props.articleId) {
			// if è un edit del servizio
			let mediaToDelete = article.media.filter((m) => m.delete);

			if (mediaToDelete && mediaToDelete.length > 0) {
				let mediaUrls = mediaToDelete.map((m) => m.url);
				if (mediaUrls && mediaUrls.length > 0) {
					let result = await deleteArticleMedia(article._id, mediaUrls);
					if (!result.success) {
						setLoading(false);
						setError(result.message);
						return;
					}
				}
			}

			article.media = selectedFiles;

			updateArticle(article._id, article)
				.then((result) => {
					setLoading(false);
					if (result.success) {
						setSuccess(result.message);
						setTimeout(() => {
							props.onSubmit && props.onSubmit();
						}, 2000);
					} else setError(result.message);
				})
				.catch((error) => {
					setLoading(false);
					setError(error.message);
				});
		} else {
			// else lo stiamo creando
			article.media = selectedFiles;

			createArticle(article)
				.then((result) => {
					setLoading(false);
					if (result.success) {
						setSuccess(result.message);
						setTimeout(() => {
							props.onSubmit && props.onSubmit();
						}, 2000);
					} else setError(result.message);
				})
				.catch((error) => {
					setLoading(false);
					setError(error.message);
				});
		}
	}

	function handleOnClose() {
		if (props.onClose) props.onClose();
		setError(null);
		setSuccess(null);
	}

	function handleOnDeleteConfirm() {
		if (props.articleId) {
			setLoading(true);
			setDeleteConfirmModal(false);
			deleteArticle(props.articleId)
				.then((result) => {
					setLoading(false);
					if (result.success) {
						setSuccess(result.message);
						setTimeout(() => {
							props.onSubmit && props.onSubmit();
						}, 2000);
					} else setError(result.message);
				})
				.catch((error) => {
					setLoading(false);
					setError(error.message);
				});
		}
	}

	return (
		<Modal
			className="edit-article-modal"
			isOpen={props.isOpen}
			autoFocus={true}
			centered={true}
			backdrop={true}
			toggle={handleOnClose}
		>
			<ModalBody>
				{loading ? (
					<Loader className="p-5" />
				) : success ? (
					<Alert className="mb-4" color="success">
						{success}
					</Alert>
				) : (
					<>
						<p className="error">{error}</p>
						<Row>
							<Col sm="12">
								<h6 className="mb-3">{props.articleId ? 'Modifica articolo' : 'Crea articolo'}</h6>
								<p>Titolo</p>
								<Input
									id="title"
									name="title"
									type="text"
									className="form-control"
									value={article['title'] || ''}
									onChange={(event) => handleOnFieldChange('title', event.target.value)}
								/>
							</Col>
						</Row>
						<Row>
							<Col sm="12">
								<p>Corpo</p>
								<Editor
									toolbarClassName="toolbarClassName"
									wrapperClassName="wrapperClassName"
									editorClassName="editorClassName"
									placeholder="Scrivi l'articolo..."
									editorState={editorState}
									onEditorStateChange={(es) => setEditorState(es)}
									onContentStateChange={(contentState) =>
										handleOnFieldChange('content', draftToHtml(contentState))
									}
								/>
							</Col>
						</Row>
						<Row>
							<Col sm="12">
								<Dropzone
									accept={['image/*']}
									multiple={true}
									maxFiles={1}
									onDrop={(acceptedFiles) => handleOnAcceptedFiles(acceptedFiles)}
								>
									{({ getRootProps, getInputProps }) => (
										<div className="dropzone">
											<div className="dz-message needsclick" {...getRootProps()}>
												<input {...getInputProps()} />
												<div className="dz-message needsclick">
													<div className="mb-3">
														<i className="display-4 text-muted bx bxs-cloud-upload"></i>
													</div>
													<p>Clicca o trascina qui i file</p>
												</div>
											</div>
										</div>
									)}
								</Dropzone>
								{selectedFiles && (
									<div className="dropzone-previews my-3" id="file-previews">
										{selectedFiles.map((f, i) => {
											return (
												<Card
													className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
													key={i + '-file'}
												>
													<div className="p-2">
														<Row className="align-items-center">
															<Col className="col-auto">
																{
																	<img
																		data-dz-thumbnail=""
																		height="80"
																		className="avatar-sm rounded bg-light"
																		alt={f.name}
																		src={f.preview || f.url}
																	/>
																}
															</Col>
															<Col>
																<Link to="#" className="text-muted font-weight-bold">
																	{f.name}
																</Link>
																<small className="mb-0">{f.formattedSize}</small>
															</Col>
														</Row>
													</div>
												</Card>
											);
										})}
									</div>
								)}
								{article.media && (
									<>
										<hr />
										<div className="dropzone-previews my-3" id="file-previews">
											{article.media.map((m, i) => {
												if (!m.delete)
													return (
														<Card
															className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
															key={i + '-file'}
														>
															<div className="p-2">
																<Row className="align-items-center">
																	<Col className="col-auto">
																		{
																			<img
																				data-dz-thumbnail=""
																				height="80"
																				className="avatar-sm rounded bg-light"
																				alt={m.url}
																				src={m.url}
																			/>
																		}
																	</Col>
																	<Col
																		className="flex-row justify-content-between"
																		style={{
																			display: 'flex',
																		}}
																	>
																		<div
																			style={{
																				display: 'inline',
																				flex: '1 0 auto',
																			}}
																		>
																			<small
																				className="mb-0"
																				style={{
																					display: 'flex',
																				}}
																			>
																				{m.url &&
																					m.url.split('/')[
																						m.url.split('/').length - 1
																					]}
																			</small>
																		</div>
																		<Link
																			to="#"
																			className="action-icon text-danger mb-0 mr-2"
																			style={{
																				display: 'flex',
																				flex: '0 0 auto',
																			}}
																			onClick={(event) => {
																				event.preventDefault();
																				handleOnDeleteFileClick(m.url);
																			}}
																		>
																			<i className="mdi mdi-trash-can font-size-18"></i>
																		</Link>
																	</Col>
																</Row>
															</div>
														</Card>
													);
												return null;
											})}
										</div>
									</>
								)}
							</Col>
						</Row>
					</>
				)}
			</ModalBody>
			{!loading && !success && (
				<ModalFooter className="d-flex align-items-center">
					{props.articleId && (
						<RoundedButton
							className="waves-effect px-5 m-0 mb-2 mr-2"
							type="button"
							color="secondary"
							onClick={() => setDeleteConfirmModal(true)}
						>
							Elimina
						</RoundedButton>
					)}
					<RoundedButton
						className="waves-effect px-5 m-0 mb-2 mr-2 outline"
						type="button"
						color="secondary"
						onClick={handleOnClose}
					>
						Annulla
					</RoundedButton>
					<RoundedButton
						className="waves-effect px-5 m-0 mb-2 ml-2"
						type="button"
						color="primary"
						onClick={handleOnSubmit}
					>
						Salva
					</RoundedButton>
				</ModalFooter>
			)}
			{deleteConfirmModal ? (
				<SweetAlert
					title={'Vuoi davvero eliminare l\'articolo?'}
					showCancel
					confirmBtnBsStyle="primary"
					confirmBtnText="Elimina"
					cancelBtnBsStyle="secondary"
					cancelBtnText="Annulla"
					onConfirm={handleOnDeleteConfirm}
					onCancel={() => setDeleteConfirmModal(false)}
				/>
			) : null}
		</Modal>
	);
};

export default EditArticleModal;
