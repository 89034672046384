import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Alert } from 'reactstrap';

import Page from '../../components/Layout/Page/Page';
import Loader from '../../components/Common/Loader';
import RoundedButton from '../../components/Buttons/RoundedButton/RoundedButton';
import PayoutRow from '../../components/Payouts/ArticleRow/PayoutRow';

import { completePayout, cancelPayout, loadAllPayouts } from '../../helpers/api/movements.api';

import './Admin.scss';

const AdminPayouts = props => {
    const [selectedPayoutId, setSelectedPayoutId] = useState(null);
    const [editing, setEditing] = useState(false);

    const [page, setPage] = useState(0);
    const [error, setError] = useState(false);

    const [loading, setLoading] = useState(true);
    const [canLoadMore, setCanLoadMore] = useState(true);

    const [payouts, setPayouts] = useState([]);

    useEffect(() => {
        setPayouts([]);
        setPage(0);
    }, [])

    useEffect(() => {
        if (page >= 0) {
            loadPayouts();
        }
    }, [page]);

    function loadPayouts(){
        setLoading(true);
        loadAllPayouts(page)
            .then((result) => {
                if (result.success) {
                    setPayouts(result.movements);
                    setCanLoadMore(result.hasMore)
                } else {
                    setError(result.message);
                    setPayouts([]);
                }
                setLoading(false);
            })
            .catch((error) => {
                setPayouts([]);
                setLoading(false);
                setError(error.message);
                console.log(error);
            });
    }

    async function complete(pId){
        setLoading(true);
        await completePayout(pId)
        loadPayouts();
    }

    async function cancel(pId){
        setLoading(true);
        await cancelPayout(pId)
        loadPayouts();
    }
    
    return <Page className="payouts">
        <Container fluid className="h-100 d-flex flex-column">
            {error ? (
					<Alert className="mb-4" color="danger">
						{error}
					</Alert>
				) : payouts ? <>
                <Row className="h-50 pt-3">
                    <Col className="h-100">
                        <ul className="payouts-list h-100">
                            {payouts.map(p => <PayoutRow key={p._id} id={p._id} {...p}
                                onCancelClick={() => {
                                    setSelectedPayoutId();
                                    cancel(p._id)
                                }} 
                                onCompleteClick={() => {
                                    setSelectedPayoutId(p._id);
                                    complete(p._id)
                                }}
                            />)}
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        <div className="text-center my-3">
                            {!loading
                                ?
                                (canLoadMore ? <RoundedButton className="px-5" onClick={() => setPage(page + 1)} >
                                    Carica più
                                </RoundedButton> : null)
                                :
                                <div className='d-flex align-items-center justify-content-center'>
                                    <Loader size="sm" />
                                    <span>{'Caricando'}</span>
                                </div>}
                        </div>
                    </Col>
                </Row>
            </> : null}
        </Container>
    </Page>;
}

export default AdminPayouts;