import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Alert } from 'reactstrap';

import Page from '../../components/Layout/Page/Page';
import Loader from '../../components/Common/Loader';
import OrderRow from '../../components/Orders/OrderRow/OrderRow';
import RoundedButton from '../../components/Buttons/RoundedButton/RoundedButton';

import { loadOrders } from '../../helpers/api/orders.api';

import './Admin.scss';

const AdminOrders = props => {

    const [page, setPage] = useState(0);
    const [error, setError] = useState(false);

    const [loading, setLoading] = useState(true);
    const [canLoadMore, setCanLoadMore] = useState(true);

    const [orders, setOrders] = useState([]);

    useEffect(() => {
        setOrders([]);
        setPage(0);
    }, [])

    useEffect(() => {
        if (page >= 0) {
            loadPagedOrders();
        }
    }, [page]);

    function loadPagedOrders() {
        setLoading(true);
        loadOrders(page)
            .then((result) => {
                if (result.success) {
                    setOrders(result.orders);
                    setCanLoadMore(result.canLoadMore)
                } else {
                    setError(result.message);
                    setOrders([]);
                }
                setLoading(false);
            })
            .catch((error) => {
                setOrders([]);
                setLoading(false);
                setError(error.message);
                console.log(error);
            });
    }

    function handleOnOrderSubmit() {
        setOrders([]);
        setPage(0);
        loadPagedOrders();
    }


    return <Page className="orders">
        <Container fluid className="h-100 d-flex flex-column">
            {error ? (
                <Alert className="mb-4" color="danger">
                    {error}
                </Alert>
            ) : orders ? <>
                <Row className="h-50 pt-3">
                    <Col className="h-100">
                        <ul className="orders-list h-100">
                            {orders.map(o => <OrderRow
                                key={o._id}
                                id={o.id}
                                transaction={o.transaction}
                                user={o.user}
                                track={o.track}
                                _id={o._id}
                            />)}
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        <div className="text-center my-3">
                            {!loading
                                ?
                                (canLoadMore ? <RoundedButton className="px-5" onClick={() => setPage(page + 1)} >
                                    Carica più
                                </RoundedButton> : null)
                                :
                                <div className='d-flex align-items-center justify-content-center'>
                                    <Loader size="sm" />
                                    <span>{'Caricando'}</span>
                                </div>}
                        </div>
                    </Col>
                </Row>
            </> : null}
        </Container>
    </Page>;
}

export default AdminOrders;