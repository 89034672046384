import { Spinner } from 'reactstrap';

import './Loader.scss';

const Loader = (props) => {
	return (
		<div className={"loader d-flex align-items-center justify-content-center " + props.className}>
			<Spinner className="mr-2" color="primary" size={props.size}/>
		</div>
	);
};
export default Loader;
