import React, { useEffect } from 'react';
import {
	Row,
	Col,
	Container
} from 'reactstrap';
import { useMediaQuery } from 'react-responsive';

import SettingsCard from '../../components/Settings/SettingsCard';
import SettingsMenu from '../../components/Settings/SettingsMenu';
import Page from '../../components/Layout/Page/Page';

import './Settings.scss';

const Settings = (props) => {

    const MOBILE_AND_TABLET = useMediaQuery({
        query: "(max-device-width: 768px)",
    });

	return <Page className="settings">
		<Container className="h-100">
			<Row className="h-100">
				<Col md="10" className="h-100">
					{MOBILE_AND_TABLET ? 
					<SettingsMenu /> : <SettingsCard />}
				</Col>
			</Row>
		</Container>
	</Page>;
};

export default Settings;
