const { API_URL } = require('../../app.config');

export async function createArticle({
	title,
	content,
	media,
}) {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);

	const data = new FormData();
	if (title) data.append('title', title);
	if (content) data.append('content', content);
	
	if (media) media.forEach((media, i) => data.append('media-' + i, media));

	let response = await fetch(API_URL + '/articles/', {
		method: 'POST',
		headers,
		body: data
	}).then((response) => response.json());

	return response;
}

export async function loadArticles(page) {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	let response = await fetch(API_URL + '/articles?p=' + page, {
		method: 'GET',
		headers
	}).then((response) => response.json());

	return response;
}

export async function loadArticle(id) {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	let response = await fetch(API_URL + '/articles/' + id, {
		method: 'GET',
		headers
	}).then((response) => response.json());

	return response;
}

export async function updateArticle(
	articleId,
	{ title, content, media}
) {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);

	const data = new FormData();
	if (title) data.append('title', title);
	if (content) data.append('content', content);

	if (media) media.forEach((media, i) => data.append('media-' + i, media));

	let response = await fetch(API_URL + '/articles/' + articleId, {
		method: 'PUT',
		headers,
		body: data
	}).then((response) => response.json());

	return response;
}

export async function deleteArticle(articleId) {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	let response = await fetch(API_URL + '/articles/' + articleId, {
		method: 'DELETE',
		headers
	}).then((response) => response.json());

	return response;
}

export async function deleteArticleMedia(articleId, media) {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	const body = JSON.stringify({ media });

	let response = await fetch(API_URL + '/articles/' + articleId + '/media', {
		method: 'DELETE',
		headers,
		body
	}).then((response) => response.json());

	return response;
}