import { REHYDRATE } from 'redux-persist/lib/constants';
import {
    LOAD_CHATS,
    LOAD_CHATS_SUCCESS,
    LOAD_CHATS_ERROR,
    MESSAGE_RECEIVED,
    SEND_MESSAGE,
    OPEN_CHAT
} from './actionTypes';

const initialState = {
    loading: false,
    error: null,
    selectedChat: null, // chat id
    chats: [], // array of chat obj
    unreadChats: [] // array of chat ids
}

const chat = (state = initialState, action) => {
    switch (action.type) {
		case REHYDRATE: {
			if (action.payload)
				state = {
					...state,
					...action.payload.Chat,
                    selectedChat: null
				};
			else
				state = {
					...state,
                    selectedChat: null
				};
			break;
            }
        case OPEN_CHAT: {
            state = {
                ...state,
                selectedChat: action.payload.chatId,
                unreadChats: [...state.unreadChats.filter(x => x !== action.payload.chatId)] 
            }
            break;
        }
        case LOAD_CHATS: {
            state = {
                ...initialState,
                loading: true
            }
            break;
        }
        case LOAD_CHATS_SUCCESS: {
            state = {
                ...state,
                loading: false,
                chats: action.payload.chats,
                selectedChat: null,
                unreadChats: action.payload.unreadChats || []
            }
            break;
        }
        case LOAD_CHATS_ERROR: {
            state = {
                ...state,
                loading: false,
                error: action.payload
            }
            break;
        }
        case MESSAGE_RECEIVED: {
            let newUnread = [...state.unreadChats],
                newChats = [...state.chats],
                chatId = action.payload && action.payload.chatId,
                message = action.payload && action.payload.message;
            if (action.payload && action.payload.chatId) { 
                if(!state.unreadChats.includes(chatId) && chatId !== state.selectedChat)
                    newUnread.push(chatId);
                let newMsgChat = {...state.chats.find(c => c._id === chatId)};
                let filtered = [...state.chats.filter(c => c._id !== chatId)];
                if (!newMsgChat || !newMsgChat.messages) {
                    newMsgChat = {
                        _id: chatId,
                        messages: [ ],
                    };
                }
                newMsgChat.messages.push(message);
                newMsgChat.updatedAt = new Date().toISOString();
                newChats = [...filtered, newMsgChat];
                newChats.sort((a,b) => {
                    return new Date(b.updatedAt) - new Date(a.updatedAt);
                });
            }
            state = {
                ...state,
                chats: newChats,
                unreadChats: newUnread
            }
            break;
        }
        case SEND_MESSAGE: {
            let newChats = [...state.chats],
                chatId = action.payload && action.payload.chatId,
                message = action.payload && action.payload.message;
            if (action.payload && action.payload.chatId) { 
                let newMsgChat = {...state.chats.find(c => c._id === chatId)};
                let filtered = [...state.chats.filter(c => c._id !== chatId)];
                newMsgChat.messages.push(message);
                newChats = [...filtered, newMsgChat];
            }
            newChats.sort((a,b) => {
                return new Date(a.updatedAt) - new Date(b.updatedAt);
            });
            state = {
                ...state,
                chats: newChats
            }
            break;
        }
        default: {
            state = { ...state };
            break;
        }
    }
    return state;
}

export default chat;