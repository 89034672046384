import React, { useState, useEffect } from 'react';
import { ModalFooter, ModalBody, Modal, Col, Input, Row, Alert } from 'reactstrap';
import moment from 'moment';

import Loader from '../../Common/Loader';
import RoundedButton from '../../Buttons/RoundedButton/RoundedButton';

import { requestPayout } from '../../../helpers/api/movements.api';

import '../Modals.scss';

const PayoutRequestModal = (props) => {
	const [iban, setIban] = useState('');
	const [holder, setHolder] = useState('');
	const [value, setValue] = useState(0);

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);

	function handleOnSubmit() {
		setLoading(true);
		requestPayout(iban, holder, value)
			.then((result) => {
				setLoading(false);
				setIban('');
				setHolder('');
				if (result.success) {
					setError(null);
					setSuccess(result.message);
					setTimeout(() => {
						props.onSuccess && props.onSuccess();
						handleOnClose();
					}, 2000);
				} else {
					setError(result.message);
				}
			})
			.catch((error) => {
				setLoading(false);
				setError(error.message);
			});
	}

	function handleOnClose() {
		if (props.onClose) props.onClose();
		setError(null);
		setSuccess(null);
		setIban('');
		setHolder('');
	}

	return (
		<Modal
			isOpen={props.isOpen}
			autoFocus={true}
			centered={true}
			backdrop={true}
			toggle={handleOnClose}>
			<ModalBody>
				{loading ? (
					<Loader className="p-5" />
				) : success ? (
					<Alert className="mb-4" color="success">
						{success}
					</Alert>
				) : (
					<Row>
						<Col sm="12">
							<h6 className='mb-3'>Effettuare bonifico a:</h6>
							<p>
								IBAN
							</p>
							<Input
								id="iban"
								name="iban"
								type="text"
								className="form-control mb-3"
								onChange={(event) => setIban(event.target.value)}
								value={iban}
							/>
							<p>
								Intestato a
							</p>
							<Input
								id="holder"
								name="holder"
								type="text"
								className="form-control mb-3"
								onChange={(event) => setHolder(event.target.value)}
								value={holder}
							/>
							<p>
								Del valore di
							</p>
							<Input
								id="holder"
								name="holder"
								type="number"
								className="form-control mb-3"
								onChange={(event) => setValue(event.target.value)}
								max={props.max}
								value={value}
							/>
							<p className="error">{error}</p>
						</Col>
					</Row>
				)}
			</ModalBody>
			{!loading && !success && (
				<ModalFooter>
					<RoundedButton
						className="waves-effect px-5 m-0 mb-2"
						type="button"
						color="primary"
						onClick={handleOnSubmit}>
						Richiedi
					</RoundedButton>
				</ModalFooter>
			)}
		</Modal>
	);
};

export default PayoutRequestModal;
