import { REHYDRATE } from 'redux-persist/lib/constants';
import {
	LOGIN_USER,
	LOGIN_USER_SUCCESS,
	LOGIN_USER_FAILED,
	LOGIN_USER_BY_TOKEN,
	LOGIN_USER_BY_APPLE_ID,
	LOGIN_USER_BY_FACEBOOK,

	LOGOUT_USER,

	REGISTER_USER,
	REGISTER_USER_SUCCESSFUL,
	REGISTER_USER_FAILED,

	UPDATE_USER_DATA
} from './actionTypes';

import loginReducer from './login/reducer';
import registerReducer from './register/reducer';
import userReducer from './user/reducer';

const initialState = {
	loading: false
};

const authReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case REHYDRATE:
			if (payload && payload.user)
				state = {
					...state,
					...payload.user,
					loading: false
				};
			else
				state = {
					...state,
					loading: false
				};
			break;
		case LOGIN_USER:
			state = {
				...loginReducer(state, { type, payload })
			};
			break;
		case LOGIN_USER_SUCCESS:
			state = {
				...loginReducer(state, { type, payload })
			};
			break;
		case LOGIN_USER_FAILED:
			state = {
				...loginReducer(state, { type, payload })
			};
			break;
		case LOGIN_USER_BY_TOKEN:
			state = {
				...loginReducer(state, { type, payload })
			};
			break;
		case LOGIN_USER_BY_APPLE_ID:
			state = {
				...loginReducer(state, { type, payload })
			};
			break;
		case LOGIN_USER_BY_FACEBOOK:
			state = {
				...loginReducer(state, { type, payload })
			};
			break;
		case LOGOUT_USER:
			state = {
				...loginReducer(state, { type, payload })
			};
			break;

		case REGISTER_USER:
			state = {
				...state,
				...registerReducer(state, { type, payload }),
			}
			break;
		case REGISTER_USER_SUCCESSFUL:
			state = {
				...state,
				...registerReducer(state, { type, payload }),
			}
			break;
		case REGISTER_USER_FAILED:
			state = {
				...state,
				...registerReducer(state, { type, payload }),
			}
			break;

		case UPDATE_USER_DATA:
			state = {
				...state,
				...userReducer(state, { type, payload })
			};
			break;

		default:
			state = { ...state };
			break;
	}
	return state;
};

export default authReducer;
