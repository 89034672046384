import React, { useState } from 'react';
import {
	ModalFooter,
	ModalBody,
	Modal,
	Label,
	Col,
	Input,
	Row,
	Alert,
	Card,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Dropzone from 'react-dropzone';

import Loader from '../../Common/Loader';
import RoundedButton from '../../Buttons/RoundedButton/RoundedButton';

import { createReward } from '../../../helpers/api/rewards.api';

// import { ReactComponent as ExpandIcon } from '../../assets/images/expand.svg';
// import { ReactComponent as PlusIcon } from '../../assets/images/plus.svg';

import '../Modals.scss';
import './RewardTrackModal.scss';

function formatBytes(bytes, decimals = 2) {
	if (bytes === 0) return '0 Bytes';
	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}


const RewardTrackModal = (props) => {
	// const [rewards, setRewards] = useState([]);
	// const [selectedRewards, setSelectedRewards] = useState(['']);

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);

	const [description, setDescription] = useState('');
	const [selectedFile, setSelectedFile] = useState(null);

	// function handleOnAddReward() {
	// 	const nsr = [...selectedRewards];
	// 	nsr.push('');
	// 	setSelectedRewards(nsr);
	// }

	// function handleOnSelectReward(reward, index) {
	// 	const nsr = [...selectedRewards];
	// 	nsr[index] = reward;
	// 	setSelectedRewards(nsr);
	// }

	function handleOnAcceptedFiles(files) {
		console.log(files)
		let f = files[0];
		Object.assign(f, {
			preview: URL.createObjectURL(f),
			formattedSize: formatBytes(f.size)
		});
		setSelectedFile(f);
	}

	function handleOnSubmit(event) {
		event.preventDefault();
		setError(null);
		setLoading(true);
		createReward({ description, file: selectedFile }, props.track._id)
			.then((result) => {
				setLoading(false);
				setDescription('')
				setSelectedFile(null)
				if (result.success) {
					setSuccess(result.message);
					setTimeout(() => {
						props.onSuccess && props.onSuccess();
						handleOnClose();
					}, 2000);
				} else {
					setError(result.message);
				}
			})
			.catch((error) => {
				setLoading(false);
				setError(error.message);
			});
	}

	function handleOnClose() {
		props.onClose && props.onClose();
		setError(null);
		setSuccess(null);
		setDescription('')
		setSelectedFile(null)
		// setSelectedRewards(['']);
	}

	return (
		<Modal
			isOpen={props.isOpen}
			role="dialog"
			className="reward-track-modal"
			autoFocus={true}
			centered={true}
			tabIndex="-1"
			toggle={handleOnClose}>
			<ModalBody>
				{success ? (
					<Alert className="mb-4" color="success">
						{success}
					</Alert>
				) : (
					<Row>
						<Col sm="12">
							<h6>Assegna reward per:</h6>
							<p className="mb-3"><b>{props.track.title}</b>{' di ' + (props.track.author ? props.track.author.name : 'Utente eliminato')}</p>

							{loading ? <Loader className="mb-5" />
								: <>
									<Label htmlFor="description">Descrizione reward</Label>
									<Input type="text" id="description" name="description" onChange={(event) => setDescription(event.target.value)} />
									<p className="error mt-2">{error}</p>


									{!selectedFile && (
									<Dropzone
										multiple={false}
										maxFiles={1}
										onDrop={(acceptedFiles) => handleOnAcceptedFiles(acceptedFiles)}>
										{({ getRootProps, getInputProps }) => (
											<div className="dropzone mb-3">
												<div className="dz-message needsclick" {...getRootProps()}>
													<input {...getInputProps()} />
													<div className="dz-message needsclick">
														<div className="mb-3">
															<i className="display-4 text-muted bx bxs-cloud-upload"></i>
														</div>
														<p>Clicca o trascina qui il file della reward</p>
													</div>
												</div>
											</div>
										)}
									</Dropzone>)}
									{selectedFile && (
										<div className="dropzone-previews my-3" id="file-previews">
											{<Card
												className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
											>
												<div className="p-2">
													<Row className="align-items-center">
														<Col className="col-auto">
															{
																<img
																	data-dz-thumbnail=""
																	height="80"
																	className="avatar-sm rounded bg-light"
																	alt={selectedFile.name}
																	src={selectedFile.preview || selectedFile.url}
																/>
															}
														</Col>
														<Col>
															<Link
																to="#"
																className="text-muted font-weight-bold">
																{selectedFile.name}
															</Link>
															{' '}
															<small className="mb-0">
																{selectedFile.formattedSize}
															</small>
														</Col>
													</Row>
												</div>
											</Card>
											}

										</div>
									)}
								</>}
							{/* {selectedRewards.map((sr, i) => <UncontrolledDropdown>
								<DropdownToggle className={'w-100 mb-2 ' + (sr ? 'choosen' : '')}>
									<p>{sr ? sr : 'Scegli una reward...'}</p>
									<span
										color="link"
										className="expand-button d-inline">
										<ExpandIcon />
									</span>
								</DropdownToggle>
								<DropdownMenu>
									<DropdownItem
										key={'0'}
										onClick={() => handleOnSelectReward(null, i)}>
										{' - - - - - '}
									</DropdownItem>
									{rewards
										.map((r, i) => (
											<DropdownItem
												key={r + i}
												onClick={() => handleOnSelectReward(r, i)}>
												{r}
											</DropdownItem>
										))}
								</DropdownMenu>
							</UncontrolledDropdown>)}
							<div className="w-100 d-flex align-items-center justify-content-center mb-5">
								<Button className="add-button" color="link" onClick={handleOnAddReward}><PlusIcon /></Button>
							</div> */}
						</Col>
					</Row>)}
			</ModalBody>
			{!loading && !success &&
				<ModalFooter>
					<RoundedButton
						className="waves-effect px-5 mb-3"
						type="button"
						color="primary"
						onClick={handleOnSubmit}>
						CONFERMA
					</RoundedButton>
				</ModalFooter>}
		</Modal>
	);
};

export default RewardTrackModal;
