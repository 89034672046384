import { combineReducers } from "redux";
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// Authentication
import authReducer from "./auth/reducer";
import playerReducer from "./player/reducer";
import layoutReducer from "./layout/reducer";
import chatReducer from "./chat/reducer";
import notiReducer from "./notifications/reducer";
import tracksReducer from "./tracks/reducer";

const authPersistConfig = {
  key: 'auth',
  storage: storage
}

const playerPersistConfig = {
  key: 'player',
  storage: storage
}

const layoutPersistConfig = {
  key: 'layout',
  storage: storage
}

const chatPersistConfig = {
  key: 'chat',
  storage: storage
}

const notiPersistConfig = {
  key: 'notifications',
  storage: storage
}

const tracksPersistConfig = {
  key: 'tracks',
  storage: storage
}


const rootReducer = combineReducers({
	Auth: persistReducer(authPersistConfig, authReducer),
  
	Player: persistReducer(playerPersistConfig, playerReducer),
  
	Layout: persistReducer(layoutPersistConfig, layoutReducer),
  
	Chat: persistReducer(chatPersistConfig, chatReducer),

  Notifications: persistReducer(notiPersistConfig, notiReducer),
  
	Tracks: persistReducer(tracksPersistConfig, tracksReducer),
});

export default rootReducer;
