import { useState, useEffect } from 'react';
import { Alert, Label, Form, FormGroup, Input, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Loader from '../../../components/Common/Loader';
import RoundedButton from '../../../components/Buttons/RoundedButton/RoundedButton';

// actions
import { loginUser, loginUserByToken } from '../../../store/actions';

import { ReactComponent as OpenEyeIcon } from '../../../assets/images/show-eye.svg';
import { ReactComponent as ClosedEyeIcon } from '../../../assets/images/hide-eye.svg';

import '../Forms.scss';

const LoginForm = (props) => {
	const [pwdVisible, setPwdVisible] = useState(false);

	const [error, setError] = useState('');

	useEffect(() => {
		const urlParams = new URLSearchParams(props.location && props.location.search);

		const userId = urlParams.get('userId');
		const token = urlParams.get('token');
		if (userId && token)
			props.loginUserByToken(userId, token, props.history, (error) => {
				console.log('e', error);
				setError(error);
			});
	}, [props.location]);

	function handleOnSubmit(event) {
		event.preventDefault();
		let values = {
			email: event.target.email.value,
			password: event.target.password.value,
		};
		props.loginUser(values, props.history, (error) => {
			console.log('e', error);
			setError(error);
		});
	}

	return (
		<div className="p-2">
			{props.loading ? (
				<Loader />
			) : (
				<>
					{error ? (
						<Alert className="text-center" color="danger">
							{error}
						</Alert>
					) : null}
					<Form className="form-horizontal" onSubmit={handleOnSubmit}>
						<FormGroup className="auth-form-group-custom mb-4">
							{/* <i className="ri-user-2-line auti-custom-input-icon"></i> */}
							<Label className="d-block text-center" htmlFor="email">
								Mail
							</Label>
							<Input
								name="email"
								type="text"
								className="form-control"
								style={{ padding: '1.5em 1.5em' }}
								id="email"
								placeholder="mariorossi@hitreview.it"
							/>
						</FormGroup>

						<FormGroup className="auth-form-group-custom mb-4">
							{/* <i className="ri-lock-2-line auti-custom-input-icon"></i> */}
							<Label className="d-block text-center" htmlFor="userpassword">
								Password
							</Label>
							<Input
								name="password"
								type={pwdVisible ? "text" : "password"}
								className="form-control"
								id="userpassword"
								style={{ padding: '1.5em 1.5em' }}
								placeholder="••••••••••••••••"
							/>
							<Button type="button" className='show-pwd' color='link' onClick={() => setPwdVisible(!pwdVisible)}>
								{pwdVisible ? <ClosedEyeIcon /> : <OpenEyeIcon />}
							</Button>
						</FormGroup>

						<div className="mt-4 mb-5 text-center">
							<RoundedButton color="primary" className="w-50" type="submit">
								ACCEDI
							</RoundedButton>
						</div>

						<p className="mt-4 text-center">
							Hai dimenticato la password?
							<br />
							<Button color="link" onClick={() => {
								props.onCancel && props.onCancel();
								props.history.push("/forgot-pwd")
							 }} className="d-inline">
								{/* <i className="mdi mdi-lock mr-1"></i> */}
								Richiedi il ripristino
							</Button>
						</p>

						<p className="mt-4 text-center">
							Non hai un account?{' '}
							<Button color="link" onClick={() => {
								props.onCancel && props.onCancel();
								props.history.push("/register")
							 }} className="d-inline">
								{/* <i className="mdi mdi-lock mr-1"></i> */}
								Registrati
							</Button>
						</p>
					</Form>
				</>
			)}
		</div>
	);
};

const mapStatetoProps = (state) => {
	const { loading, user } = state.Auth;
	return { loading, user };
};

export default withRouter(connect(mapStatetoProps, { loginUser, loginUserByToken })(LoginForm));
