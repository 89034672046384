import { REHYDRATE } from 'redux-persist/lib/constants';
import {
    SHOW_LOGIN_MODAL,

    SHOW_REGISTER_MODAL,

    SHOW_MESSAGE_MODAL,

    CLOSE_MODAL
} from './actionTypes';

const initialState = {
    modal: null,
    messageUser: null
}

const player = (state = initialState, action) => {
    switch (action.type) {
        case REHYDRATE: {
            state = {
                ...initialState
            };
            break;
        }
        case SHOW_LOGIN_MODAL:
            state = {
                ...state,
                modal: 'login'
            }
            break;
        case SHOW_REGISTER_MODAL:
            state = {
                ...state,
                modal: 'register'
            }
            break;
        case SHOW_MESSAGE_MODAL:
            state = {
                ...state,
                modal: 'message',
                messageUser: action.payload.messageUser
            }
            break;
        case CLOSE_MODAL:
            state = {
                ...initialState
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default player;