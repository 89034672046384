import { useState } from 'react';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { NavLink as RRNavLink, withRouter } from 'react-router-dom';
import {
    Nav,
    NavItem,
    NavLink,
    Navbar,
    NavbarBrand,
    Collapse,
    NavbarToggler,
	Button
} from 'reactstrap';
import { genericHashLink } from 'react-router-hash-link';

import RoundedButton from '../../Buttons/RoundedButton/RoundedButton';

import logo from '../../../assets/images/HitReview_beta_ext.svg';

import { showLoginModal, showRegisterModal } from '../../../store/actions';

import './PublicHeader.scss';

const PublicHeader = props => {

    const MOBILE_AND_TABLET = useMediaQuery({
        query: "(max-device-width: 768px)",
    });

    const [isOpen, setIsOpen] = useState(false);

    function toggle() {
        setIsOpen(!isOpen);
    }

    const HLink = genericHashLink(RRNavLink);

    return <>
        <header id="page-topbar">
            <Navbar expand="lg" className="pt-3 py-lg-0 h-100">
                <NavbarBrand
                    tag={RRNavLink}
                    to="/"
                    className="mb-3 mb-lg-0 logo logo-dark">
                    <span className="logo-sm">
                        <img src={logo} alt="" height="22" />
                    </span>
                    <span className="logo-lg">
                        <img src={logo} alt="" height="40" />
                    </span>
                </NavbarBrand>

                <NavbarToggler className="navbar-dark mb-3" onClick={toggle}>
                    </NavbarToggler>

                <Collapse isOpen={isOpen} navbar className="px-3 w-100">
                    <Nav navbar className="d-flex justify-content-between w-100">
                        <div className="d-flex flex-column flex-lg-row align-items-stretch flex-wrap w-100 py-3">
                            <NavItem className="my-auto">
                                <NavLink
                                    tag={RRNavLink}
                                    className="py-2 px-4"
                                    isActive={(match, location) => {
                                        if (location.pathname === '/' && !location.hash) {
                                            return true;
                                        }
                                        return false
                                    }}
                                    to="/"
                                    href="/">
                                    Home
                                </NavLink>
                            </NavItem>
                            <NavItem className="my-auto">
                                <NavLink
                                    tag={HLink}
                                    className="py-2 pl-4 pr-0 py-lg-2 pl-lg-4 pr-lg-4"
                                    exact
                                    to="/#how"
                                    isActive={(match, location) => {
                                        if (location.pathname === '/' && location.hash === '#how') {
                                            return true;
                                        }
                                        return false
                                    }}
                                    smooth
                                    href="/#how">
                                    Come funziona
                                </NavLink>
                            </NavItem>
                            <NavItem className="my-auto">
                                <NavLink
                                    tag={RRNavLink}
                                    className="py-2 pl-4 pr-0 py-lg-2 pl-lg-4 pr-lg-4 "
                                    to="/public-discovery"
                                    href="/public-discovery">
                                    HitDiscovery
                                </NavLink>
                            </NavItem>
                            <NavItem className="my-auto">
                                <NavLink
                                    tag={RRNavLink}
                                    className="py-2 pl-4 pr-0 py-lg-2 pl-lg-4 pr-lg-4"
                                    to="/scouts"
                                    href="/scouts">
                                    Scouter
                                </NavLink>
                            </NavItem>
                            <NavItem className="my-auto">
                                <NavLink
                                    tag={RRNavLink}
                                    className="py-2 pl-4 pr-0 py-lg-2 pl-lg-4 pr-lg-4"
                                    to="/blog"
                                    href="/blog">
                                    Blog
                                </NavLink>
                            </NavItem>
                            <NavItem className="my-auto">
                                <NavLink
                                    tag={RRNavLink}
                                    className="py-2 pl-4 pr-0 py-lg-2 pl-lg-4 pr-lg-4"
                                    to="/faq"
                                    href="/faq">
                                    FAQ
                                </NavLink>
                            </NavItem>
                            {MOBILE_AND_TABLET && 
                            <NavItem className="my-auto">
                                <Button color="link" onClick={props.showLoginModal}
                                    className="w-100 py-2 pr-0 py-lg-2 pl-0 mb-3">
                                    ACCEDI
                            </Button>
                            </NavItem>}
                            {MOBILE_AND_TABLET && 
                            <NavItem className="my-auto">
                            <RoundedButton className="w-100 px-5 py-2 pr-0 py-lg-2 pl-0" style={{fontSize: '1em'}} color="primary" onClick={props.showRegisterModal}>
                                ISCRIVITI
                            </RoundedButton>
                            </NavItem>}
                        </div>
                        {!MOBILE_AND_TABLET && <div className="d-flex align-items-center justify-content-end">
                            <Button color="link" onClick={props.showLoginModal}
                                    className="py-2 pl-4 pr-0 py-lg-2 pl-lg-4 pr-lg-4">
                                    ACCEDI
                            </Button>
                            <RoundedButton className="ml-3 mr-0 px-4" color="primary" onClick={props.showRegisterModal}>
                                ISCRIVITI
                            </RoundedButton>
                        </div>}
                    </Nav>
                </Collapse>
            </Navbar>
        </header>
    </>;
}

const mapDispatchToProps = {
    showLoginModal, showRegisterModal
}

export default connect(null,  mapDispatchToProps)(withRouter(PublicHeader));