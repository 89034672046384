export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';

export const LOGIN_USER_BY_TOKEN = 'LOGIN_USER_BY_TOKEN';
export const LOGIN_USER_BY_APPLE_ID = 'LOGIN_USER_BY_APPLE_ID';
export const LOGIN_USER_BY_FACEBOOK = 'LOGIN_USER_BY_FACEBOOK';

export const LOGOUT_USER = 'LOGOUT_USER';

export const REGISTER_USER = 'register_user';
export const REGISTER_USER_SUCCESSFUL = 'register_user_successfull';
export const REGISTER_USER_FAILED = 'register_user_failed';

export const UPDATE_USER_DATA = "update_user_data";