import { Row, Col, Container } from 'reactstrap';

import './Policies.scss'

const Consent = () => (
	<Container className="policies my-5">
		<Row className="pb-3">
			<Col
				className="h-100 d-flex flex-column"
				md={{
					size: 11,
					offset: 1,
				}}
			>
				<h2 className="mb-5">
					Liberatoria e consenso alla pubblicazione e trasmissione di immagini, foto, audio e video
				</h2>

				<p>
					Con riferimento alle immagini (foto e video) e ai brani (audio) caricate presso la piattaforma on
					line Hitreview.it, icon la presente: 
				</p>
				<p>
					<b>DICHIARO</b> che le opere caricate sulla piattaforma Hitreview.it
					sono originali e ne sono l’unico proprietario, 
				</p>
				<p>
					<b>SOLLEVO</b> la Hitreview srls con sede legale in Roma
					00177, alla Via Prenestina 445, e mi assumo la completa responsabilità di eventuali rivendicazioni
					sui diritti dell’opera da parte di terzi.
				</p>
				<p>
					<b>AUTORIZZO</b> a titolo gratuito, senza limiti di tempo, anche ai sensi degli artt. 10 e 320 c.c.
					nonché della Legge n. 633 del 22.04.1941 (Legge sul diritto d’autore), la società Hitreview srls,
					con sede legale in Roma 00177, alla Via Prenestina 445, all’utilizzo, alla trasmissione, alla
					pubblicazione e/o diffusione in qualsiasi forma del materiale audio, video o fotografico caricato
					sulla sopracitata piattaforma.
				</p>
				<p>
					<b>AUTORIZZO</b> altresì la conservazione del predetto materiale negli archivi informatici della
					Società Hitreview srls e prendo atto che le finalità di tali pubblicazioni/diffusioni sono di
					carattere informativo, divulgativo e promozionale. 
				</p>
				<p>La presente liberatoria/autorizzazione potrà
					essere revocata in ogni tempo con comunicazione scritta da inviare a mezzo posta raccomandata alla
					sede legale della Società Hitreview srls. 
				</p>
				<p>Questa autorizzazione esclude la cessione a terzi ma in
					considerazione del fatto che il materiale, una volta reso pubblico per il tramite dei predetti
					canali, può essere oggetto di acquisizione e ripubblicazione da parte di terzi, anche senza
					consenso, <b>DICHIARO</b> di assumermi ogni responsabilità per qualsiasi pretesa, spesa, danno derivante
					dall’utilizzo e/o dalla diffusione della propria immagine e/o voce, delle proprie opere d’arte, del
					proprio nome o dalla violazione di una qualsiasi delle dichiarazioni e garanzie qui rese. In ragione
					di ciò,
				</p>
				<p>
					<b>SOLLEVO</b> la Hitreview srls, con sede legale in Roma 00177, alla Via Prenestina 445, da ogni
					effetto pregiudizievole che possa derivare da un uso abusivo, scorretto o comunque non autorizzato
					da parte di terzi del predetto materiale audio-video-foto
                    </p>
                <p>
					<b>CONFERMO</b> di non avere nulla a pretendere
					in ragione di quanto sopra indicato e di rinunciare irrevocabilmente ad ogni diritto, azione e/o
					pretesa derivante da quanto sopra autorizzato.
				</p>
				<p>
					<b>ATTESTO</b> che le dichiarazioni rilasciate, inclusi dati, informazioni, narrazioni,
					descrizioni, ricostruzioni, opinioni riguardanti fatti, circostanze, luoghi, eventi e persone da me
					riferiti, descritti e/o esposti nel corso delle riprese audio-video e nelle mie opere, sono vere e
					non contengono alcun elemento avente finalità o natura pubblicitaria diretta, indiretta o sublimale
					o che possa ledere diritti personali o patrimoniali di terzi - che sono nella disponibilità del/la
					sottoscritto/a gli oggetti raffigurati, consegnati ed in qualunque modo utilizzati, nelle fotografie
					o nel corso delle riprese e/o registrazioni audio e che non ledono diritti personali ovverosia
					patrimoniali di alcuno. 
                </p>
				<p>
					<b>In ultimo DICHIARO</b> di accettare incondizionatamente il giudizio che le mie
					opere riceveranno sulla piattaforma Hitreview.it, essendo tale giudizio parte fondamentale del
					servizio contrattualizzato e rinuncio sin da ora a qualunque pretesa, di qualunque forma e a
					qualunque titolo, nei confronti di Hitreview srls e nei confronti dei singoli Scouter per i
					giudizi ricevuti sulle opere che ho caricato sulla piattaforma Hitreview.it
				</p>
			</Col>
		</Row>
	</Container>
);

export default Consent;
