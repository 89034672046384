import { useEffect, useState } from 'react';
import { Alert, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import moment from 'moment';

import Loader from '../../Common/Loader.jsx';

import { loadTopWeekly } from '../../../helpers/api/tracks.api.js';

import './TopWeeklyDiscoveryCard.scss';
import { Link } from 'react-router-dom';

const TopWeeklyDiscoveryCard = (props) => {

	const [loadingTopWeekly, setLoadingTopWeekly] = useState(true);
	const [topWeekly, setTopWeekly] = useState([]);
	const [topWeeklyError, setTopWeeklyError] = useState(null);

	useEffect(() => {
		setLoadingTopWeekly(true);
		loadTopWeekly()
			.then((result) => {
				if (result.success) setTopWeekly(result.tracks);
				else {
					setTopWeeklyError(result.message);
					setTopWeekly([]);
				}
				setLoadingTopWeekly(false);
			})
			.catch((error) => {
				setTopWeekly([]);
				setLoadingTopWeekly(false);
				setTopWeeklyError(error.message);
			});
	}, []);

	return (
		<Card {...props} className={'top-weekly-discovery-card ' + props.className}>
			<CardHeader>
				<CardTitle tag="h5">{'Top HitDiscovery Week ' + moment().format('w')}</CardTitle>
			</CardHeader>
			<CardBody >
				{loadingTopWeekly ? <Loader className="w-100 h-100" /> :
					topWeeklyError ?
						<Alert className="mb-0" color="danger">
							{topWeeklyError}
						</Alert>
						:
						<div className="top-weekly-rows-container w-100">
							{topWeekly.map((t, i) => <Link to={"/tracks/" + t._id} key={t.title + t.author + i} className="top-weekly-row">
								<div className="image-container"
									style={{ backgroundImage: t.author && t.author.image && t.author.image.url ? 'url(' + t.author.image.url + ')' : '' }} >
								</div>
								<div className="title-container">
									<p>{t.title}</p>
									<p>{t.author ? t.author.name : ''}</p>
								</div>
								<div className="score-container">
									<span className="dot"></span>
									<span className="score">{Number(t.averageVote).toFixed(1)}</span>
								</div>
							</Link>)}
						</div>}
			</CardBody>
		</Card>
	);
};
export default TopWeeklyDiscoveryCard;
