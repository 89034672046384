import {
	UPDATE_USER_DATA
} from '../actionTypes';

const initialState = {
	user: null
};

const user = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_USER_DATA:
			state = {
				...state,
				user: action.payload
			};
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export default user;
