import React from 'react';

import { Container, Row, Col, Card, CardHeader, CardBody, Alert } from 'reactstrap';

import Page from '../../components/Layout/Page/Page';

import './Help.scss'

const Help = (props) => {

	return <Page className="help">
			<Container>
				<Row>
					<Col md="10" className="pb-4 pb-lg-0">
						<Card>
							<CardBody className='d-flex align-items-center'>
									<Container fluid>
                                    <Row>
                                        <Col lg={{
                                            offset: 3, size: 6
                                        }}>
                                           
								<h3 className='text-center'>
									Hai bisogno di aiuto?
								</h3>
                                <p className='text-center'>Prova a consultare le <a href="https://hitreview.it/faq" target="_blank"><b>FAQ</b></a><br/>
                                oppure invia una e-mail a <a href="mailto:help@hitreview.it"><b>help@hitreview.it</b></a></p>
                                        </Col>
                                    </Row>
                                    </Container>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
	</Page>;
};

export default Help;
