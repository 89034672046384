import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Alert } from 'reactstrap';

import Page from '../../components/Layout/Page/Page';
import Loader from '../../components/Common/Loader';
import ArticleRow from '../../components/Articles/ArticleRow/ArticleRow';
import RoundedButton from '../../components/Buttons/RoundedButton/RoundedButton';
import EditArticleModal from '../../components/Modals/Articles/EditArticleModal';

import { loadArticles } from '../../helpers/api/articles.api';

import './Admin.scss';

const AdminArticles = props => {
    const [selectedArticleId, setSelectedArticleId] = useState(null);
    const [editing, setEditing] = useState(false);

    const [page, setPage] = useState(0);
    const [error, setError] = useState(false);

    const [loading, setLoading] = useState(true);
    const [canLoadMore, setCanLoadMore] = useState(true);

    const [articles, setArticles] = useState([]);

    useEffect(() => {
        setArticles([]);
        setPage(0);
    }, [])

    useEffect(() => {
        if (page >= 0) {
            loadPagedArticles();
        }
    }, [page]);

    function loadPagedArticles(){
        setLoading(true);
        loadArticles(page)
            .then((result) => {
                if (result.success) {
                    setArticles(result.articles);
                    setCanLoadMore(result.canLoadMore)
                } else {
                    setError(result.message);
                    setArticles([]);
                }
                setLoading(false);
            })
            .catch((error) => {
                setArticles([]);
                setLoading(false);
                setError(error.message);
                console.log(error);
            });
    }

    function handleOnArticleSubmit() {
        setArticles([]);
        setPage(0);
        setSelectedArticleId(null)
        setEditing(false);
        loadPagedArticles();
    }

    return <Page className="articles">
        <Container fluid className="h-100 d-flex flex-column">

            <Row>
                <Col className='text-right'>
                    <RoundedButton color="primary" className="d-inline-block"
                        onClick={() => {
                            setSelectedArticleId(null);
                            setEditing(true);
                        }}>Crea Articolo</RoundedButton>
                </Col>
            </Row>
            {error ? (
					<Alert className="mb-4" color="danger">
						{error}
					</Alert>
				) : articles ? <>
                <Row className="h-50 pt-3">
                    <Col className="h-100">
                        <ul className="articles-list h-100">
                            {articles.map(a => <ArticleRow key={a._id} id={a._id} title={a.title} content={a.content}
                                onEditClick={() => {
                                    setSelectedArticleId(a._id);
                                    setEditing(true);
                                }} />)}
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        <div className="text-center my-3">
                            {!loading
                                ?
                                (canLoadMore ? <RoundedButton className="px-5" onClick={() => setPage(page + 1)} >
                                    Carica più
                                </RoundedButton> : null)
                                :
                                <div className='d-flex align-items-center justify-content-center'>
                                    <Loader size="sm" />
                                    <span>{'Caricando'}</span>
                                </div>}
                        </div>
                    </Col>
                </Row>
            </> : null}
        </Container>
        <EditArticleModal articleId={selectedArticleId} isOpen={editing} onClose={() => {
            setSelectedArticleId(null)
            setEditing(false);
        }} onSubmit={handleOnArticleSubmit} />
    </Page>;
}

export default AdminArticles;