import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import moment from 'moment';
import { connect } from 'react-redux';

import "moment/locale/it";

import { ReactComponent as TrashIcon } from '../../../assets/images/trash.svg';

import './ChatMessage.scss';

const isToday = (someDate) => {
    const today = new Date()
    return someDate.getDate() == today.getDate() &&
        someDate.getMonth() == today.getMonth() &&
        someDate.getFullYear() == today.getFullYear()
}

const ChatMessage = ({ message }) => {
    let createdAt = isToday(new Date(message.createdAt)) ? moment(message.createdAt).format('hh:mm') : moment(message.createdAt).format('dddd DD MMMM YYYY');

    const contact = message.author;

    const image = contact.image;
    const title = contact.name;
    const text = message.content;

    async function handleOnDeleteMessage() {
        // setLoading(true)
        // deleteMessage(props.playlistId, props.track._id)
        //     .then(result => {
        //         if (result.success) 
        //             props.onRemoveComplete && props.onRemoveComplete();
        //         else console.log(result.message);
        //         setLoading(false)
        //     })
        //     .catch(error => {
        //         console.log(error);
        //         setLoading(false)
        //     });
    }

    return <Row className={"chat-message"}>
        <Col className="d-flex align-items-start justify-content-between h-100">{image && image.url ? (
            <div
                style={{ backgroundImage: 'url(' + image.url + ')' }}
                className="avatar mr-4"
                alt="chat"
            />
        ) : null}
            <div className="d-flex flex-column w-75">
                <p className="text-truncate font-size-14 d-inline mb-0">
                    {title}
                </p>
                <p className="text text-truncate font-size-14 d-inline my-2">{text}</p>
                <small className="mb-0">{createdAt}</small>
            </div>
            {/* <Button className="delete-button ml-3 ml-lg-0 mr-0 mr-lg-3" color="link" onClick={handleOnDeleteMessage}>
                <TrashIcon />
            </Button> */}
        </Col>
    </Row>;
};

const mapStateToProps = state => {
    return {
        userId: state.Auth.user._id
    }
}

export default connect(mapStateToProps, null)(ChatMessage);
