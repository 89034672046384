import { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Col, Form, FormGroup, Input, Label, Row, Alert } from 'reactstrap';
import { useMediaQuery } from 'react-responsive';
import Compressor from 'compressorjs';
import Autocomplete from 'react-google-autocomplete';

import Loader from '../../Common/Loader';
import RoundedButton from '../../Buttons/RoundedButton/RoundedButton';

import { updateUserData } from '../../../store/actions';
import { loadUser, editUser, editUserImage } from '../../../helpers/api/users.api';

import '../Forms.scss';

const ScoutsContentsForm = (props) => {

    const MOBILE_AND_TABLET = useMediaQuery({
        query: "(max-device-width: 768px)",
    });

    let imagePicker = useRef(null);

    const [user, setUser] = useState(null);

    const [loading, setLoading] = useState(false);
    const [loadingImage, setLoadingImage] = useState(false);

    const [error, setError] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('authUser')) {
            setLoading(true);
            const userId = JSON.parse(localStorage.getItem('authUser'))._id;
            loadUser(userId)
                .then((result) => {
                    if (result.success) setUser(result.user);
                    else console.log(result.message);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                });
        }
    }, []);

    function handleOnImageClick() {
        if (imagePicker.current && imagePicker.current.click) imagePicker.current.click();
    }

    function handleOnImageSubmit({ target: { files } }) {
        if (files && files.length > 0) {
            let image = files[0];
            if (image.size > 5000000)
                window.alert('File troppo grande. Dimensione massima: 5MB');
            else if (!image.type.startsWith('image')) window.alert('Devi caricare una immagine.');
            else {
                new Compressor(image, {
                    quality: 0.6,

                    // The compression process is asynchronous,
                    // which means you have to access the `result` in the `success` hook function.
                    success(compressed) {
                        setLoadingImage(true);
                        editUserImage(compressed)
                            .then((result) => {
                                if (result.success) setUser(result.updated);
                                else setError(result.message);
                                setLoadingImage(false);
                            })
                            .catch((error) => {
                                setError(error.message);
                                setLoadingImage(false);
                            });
                    },
                    error(error) {
                        window.alert(error.message);
                    },
                });
            }
        }
    }

    function handleOnFieldChange({ target: { name, value } }) {
        let editedUser = { ...user };
        
        if (['facebook', 'twitter', 'website', 'instagram'].includes(name)) {
            if (!editedUser.socialLinks)
                editedUser.socialLinks = {};
            editedUser.socialLinks[name] = value;
        } else editedUser[name] = value;
        setUser(editedUser);
    }

    function handleOnPlaceSelected(place) {
        let editedUser = { ...user };
        if (place) {
            editedUser['placeId'] = place.place_id;
            editedUser['city'] = place.formatted_address;
            editedUser['region'] = place.address_components.find((x) =>
                x.types.includes('administrative_area_level_1')
            )?.short_name;
        } else {
            editedUser['placeId'] = null;
            editedUser['city'] = user.city;
            editedUser['region'] = null;
        }
        setUser(editedUser);
    }

    function handleOnDataUpdateSubmit(event) {
        event.preventDefault();
        setLoading(true);
        editUser(user)
            .then((result) => {
                if (result.success) {
                    props.updateUserData && props.updateUserData(result.updated);
                    props.history.push('/users/' + user._id);
                } else setError(result.message);
                setLoading(false);
            })
            .catch((error) => {
                setError(error.message);
                setLoading(false);
            });
    }

    return loading ? (
        <Loader />
    ) : error ? (<Alert color="danger">{error}</Alert>) : user ? <Form onSubmit={handleOnDataUpdateSubmit}>
        <FormGroup row>
            <Col md="4">
                <h6>Foto Profilo</h6>
                {loadingImage ? (
                    <Loader />
                ) : user.image ? (
                    <div
                        style={{
                            backgroundImage:
                                'url(' +
                                user.image.url +
                                ')'
                        }}
                        className="avatar-xs rounded-circle profile-avatar"></div>
                ) : null}

                <RoundedButton
                    className="px-5 mt-4"
                    color="primary"
                    onClick={handleOnImageClick}>
                    Carica
                </RoundedButton>
                <Input
                    innerRef={imagePicker}
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleOnImageSubmit}
                />

                <h6>Informazioni</h6>

                <Label for="job">
                    Occupazione
                </Label>
                <Input
                    type="text"
                    name="job"
                    id="job"
                    value={user.job}
                    onChange={
                        handleOnFieldChange
                    }
                />

                <Label for="city">Città</Label>
                <Autocomplete
                    apiKey={'AIzaSyALRFTVJXyYyjpf623vo80ZgdX0O2KBeS0'}
                    onChange={(event) => {
                        handleOnPlaceSelected(null);
                        console.log('changed')
                    }}
                    onPlaceSelected={handleOnPlaceSelected}
                    defaultValue={user.city}
                    options={{
                        types: ['(cities)'],
                        componentRestrictions: { country: 'it' },
                    }}
                    style={{
                        width: '100%',
                        padding: '.5em 1em',
                        fontStyle: 'italic',
                        fontFamily: 'Poppins',
                        fontSize: '.75em',
                        background: 'transparent',
                        borderRadius: '20px',
                        marginBottom: '0.5em',
                        border: '1px solid rgba(35, 35, 35, .45)',
                    }}
                />

                <Label for="nMembers">Biografia</Label>
                <Input
                    type="textarea"
                    name="bio"
                    id="bio"
                    rows={6}
                    value={user.bio}
                    onChange={handleOnFieldChange}
                />

                {!MOBILE_AND_TABLET && <RoundedButton
                    className="px-5 mt-4"
                    color="primary"
                    type="submit">
                    Salva
                </RoundedButton>}
            </Col>
            <Col
                md={{
                    size: 6,
                    offset: 2
                }}>
                <Row>
                    <Col md="8">
                        <h6>Link Social</h6>
                        <Label for="website">
                            Sito Web
                        </Label>
                        <Input
                            type="text"
                            name="website"
                            id="website"
                            value={
                                user.socialLinks
                                    ?.website
                            }
                            onChange={
                                handleOnFieldChange
                            }
                        />
                        <Label for="facebook">
                            Facebook
                        </Label>
                        <Input
                            type="text"
                            name="facebook"
                            id="facebook"
                            value={
                                user.socialLinks
                                    ?.facebook
                            }
                            onChange={
                                handleOnFieldChange
                            }
                        />
                        <Label for="instagram">
                            Instagram
                        </Label>
                        <Input
                            type="text"
                            name="instagram"
                            id="instagram"
                            value={
                                user.socialLinks
                                    ?.instagram
                            }
                            onChange={
                                handleOnFieldChange
                            }
                        />
                        <Label for="twitter">
                            Twitter
                        </Label>
                        <Input
                            type="text"
                            name="twitter"
                            id="twitter"
                            value={
                                user.socialLinks
                                    ?.twitter
                            }
                            onChange={
                                handleOnFieldChange
                            }
                        />

                        {MOBILE_AND_TABLET && <RoundedButton
                            className="px-5 mt-4"
                            color="primary"
                            type="submit">
                            Salva
                        </RoundedButton>}
                    </Col>
                </Row>
            </Col>
        </FormGroup>
    </Form> : null;
};

export default withRouter(connect(null, { updateUserData })(ScoutsContentsForm));