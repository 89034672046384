import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Card, CardHeader, CardBody, Alert } from 'reactstrap';

import Page from '../../components/Layout/Page/Page';
import Loader from '../../components/Common/Loader';
import ChatRow from '../../components/Chats/ChatRow/ChatRow';
import ChatContainer from '../../components/Chats/ChatContainer/ChatContainer';

import { openChat, loadChats } from '../../store/actions';

const Chats = (props) => {
	useEffect(() => {
		props.loadChats();
	}, []);

	function handleOnChatSelect(chatId) {
		props.openChat(chatId);
	} 
	
	const chatsRows = props.chats ?
		props.chats.map((c) => <ChatRow key={c._id.toString()} chat={c} onClick={() => handleOnChatSelect(c._id)}
		active={props.selectedChatId === c._id} unread={props.unreadChats ? props.unreadChats.includes(c._id) : false} />) : [];

	const selectedChat = props.chats ? props.chats.find(c => c._id === props.selectedChatId) : null;

	return <Page className="chats h-100">
		<Container className="h-100">
			<Row className="h-100">
				<Col md="11" className="h-100 pb-4 pb-lg-0">
					<Card className="h-100">
						<CardHeader>
							<h5>Messaggi</h5>
						</CardHeader>
						<CardBody>
							<Row className='h-100'>
								<Col className='h-100' lg="5">
									{props.loading ? (
										<Loader className="mt-5" />
									) : props.error ? (
										<Alert className="mb-0" color="danger">
											{props.error}
										</Alert>
									) : (
										<Container fluid>{chatsRows}</Container>
									)}
								</Col>
								<Col lg="7">
									{selectedChat ?
										<ChatContainer chat={selectedChat} />
										: null}
								</Col>
							</Row>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	</Page>;
};

const mapStateToProps = state => {
	return {
		userId: state.Auth.user._id,
		loading: state.Chat.loading, 
		error: state.Chat.error,
		chats: state.Chat.chats,
		selectedChatId: state.Chat.selectedChat,
		unreadChats: state.Chat.unreadChats
	}
}

const mapDispatchToProps = {
	loadChats,
	openChat
}

export default connect(mapStateToProps, mapDispatchToProps)(Chats);
