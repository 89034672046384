const { API_URL } = require('../../app.config');

export async function createPlaylist(title) {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	const body = JSON.stringify({
		title
	});

	let response = await fetch(API_URL + '/playlists/', {
		method: 'POST',
		headers,
		body
	}).then((response) => response.json());

	return response;
}

export async function addTrackToPlaylist(playlistId, trackId) {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	let response = await fetch(API_URL + '/playlists/' + playlistId + '/tracks/' + trackId, {
		method: 'PUT',
		headers
	}).then((response) => response.json());

	return response;
}

export async function reorderTrackInPlaylist(playlistId, trackId, from, to) {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	let response = await fetch(API_URL + '/playlists/' + playlistId + '/tracks/' + trackId + '/position', {
		method: 'PUT',
		headers,
		body: JSON.stringify({
			from, to
		})
	}).then((response) => response.json());

	return response;
}

export async function loadUserPlaylists(ownerId) {
	const token = localStorage.getItem('token');

	const headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	let url = API_URL + '/playlists?';
	
	if (ownerId) url += 'owner=' + ownerId;

	const response = await fetch(url, {
		method: 'GET',
		headers
	}).then((response) => response.json());

	return response;
}

export async function removeTrackFromPlaylist(playlistId, trackId) {
	const token = localStorage.getItem('token');

	const headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	const response = await fetch(API_URL + '/playlists/' + playlistId + '/tracks/' + trackId, {
		method: 'DELETE',
		headers
	}).then((response) => response.json());

	return response;
}

export async function updatePlaylistPublic(playlistId, publicStatus) {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	const body = JSON.stringify({
		public: publicStatus ? 1 : 0
	});

	let response = await fetch(API_URL + '/playlists/' + playlistId + '/public', {
		method: 'PUT',
		headers,
		body
	}).then((response) => response.json());

	return response;
}

export async function deletePlaylist(id) {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	let response = await fetch(API_URL + '/playlists/' + id, {
		method: 'DELETE',
		headers
	}).then((response) => response.json());

	return response;
}
