import React, { useRef, useState, useEffect, useContext } from 'react';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';
import ChatMessage from '../ChatMessage/ChatMessage';
import MessageForm from '../../Forms/Messages/MessageForm';

import { WebSocketContext } from '../../../helpers/socket/WebSocket';

import '../../Forms/Forms.scss';
import './ChatContainer.scss';

const ChatContainer = ({ chat, user }) => {

    const messagesEndRef = useRef();
    const ws = useContext(WebSocketContext);

    useEffect(() => {
        if (messagesEndRef.current)
            messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
    }, [chat]);

    function handleOnSendMessage(message) {
        console.log('sending', chat.members.filter(x => x._id !== user._id).map(x => x._id))
        ws.sendMessage({
            chatId: chat._id, 
            to: chat.members.filter(x => x._id !== user._id).map(x => x._id),
            message: {
                content: message,
                author: user,
                createdAt: new Date().toISOString(),
                type: 'text'
            }
        })
    };

    const receiver = chat.members.find(x => x._id !== user._id);
    const title = receiver ? receiver.name : '';
    return <div className='chat-container h-100 w-100 px-5 pb-3'>
        <div className='messages-wrapper'>
            <Container className='messages-container'>
                {chat && chat.messages.map(m => <ChatMessage key={new Date(m.createdAt).getTime()} message={m} />)}
                <div ref={messagesEndRef} />
            </Container>
        </div>
        <MessageForm title={title} onSubmit={handleOnSendMessage} />
    </div>;
};

const mapStateToProps = state => {
    return {
        user: state.Auth.user
    }
}

export default connect(mapStateToProps, null)(ChatContainer);
