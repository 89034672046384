import React, { useState } from 'react';
import {
    Row,
    Col,
    Container,
    Button,
    Collapse
} from 'reactstrap';

import { ReactComponent as HomeBG } from '../../../assets/images/home-bg.svg';
import { ReactComponent as Expand } from '../../../assets/images/expand.svg';

import './FAQ.scss';

const faqs = [
    {
        question: 'COME FUNZIONA?',
        answer: <>Iscriviti e carica il tuo brano;<br/>Ricevi un valutazione da una giuria composta da 5 esperti;<br/>Ottieni la valutazione ed il certificato di qualità del tuo brano ed i nostri rewards.</>
    },
    {
        question: 'CHI SONO GLI SCOUTER?',
        answer: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt magni, voluptas debitis similique porro a molestias consequuntur earum odio officiis natus, amet hic, iste sed dignissimos esse fuga! Minus, alias.'
    },
    {
        question: 'QUANTO COSTA?',
        answer: 'Il costo del servizio a singolo brano è di 50 euro e comprende: creazione del profilo, caricamento del brano e la valutazione da parte di tre esperti dell\'industria musicale.'
    },
    {
        question: 'POSSO CANCELLARE IL MIO PROFILO?',
        answer: 'Si, è sempre possibile la cancellazione del profilo gratuitamente. '
    },
    {
        question: 'SI RICEVONO SEMPRE REWARDS?',
        answer: 'Si, le rewards si ottengono sempre al termine della valutazione di tutti e tre gli scouter selezionati per il tuo brano. Le rewards venogno assegnate dalla piattaforma HitReview in base alla valutazione ottenuta e spontaneamente dagli scouter agli artisti ritenuti più meritevoli. Con una valutazione superiore ai 4 punti si ottiene il risarcimento completo del costo del caricamento del brano. '
    },
]

const FAQ = (props) => {

    const [open, setOpen] = useState(0);

    const faqRows = faqs.map((f, i) => <Row className={'faq-row ' + (open === i ? 'active' : '')}>
        <Col>
            <div className='w-100'>
                <div className='d-flex align-items-center justify-content-between w-100 mb-4'>
                    <h4> {f.question}</h4>
                    <Button color="link" className='expand-button' onClick={() => {
                        setOpen(i)
                    }} >
                        <Expand width="20px" />
                    </Button></div>
                <Collapse isOpen={open === i}>
                    <p>
                        {f.answer}
                    </p>
                </Collapse>
            </div>
        </Col>
    </Row>);

    return <Container fluid className="faq pb-5">
        <Row>
            <Col>
                <Container>
                    <Row className="mb-3">
                        <Col className="h-100 d-flex flex-column" md={{
                            size: 11, offset: 1
                        }}>
                            <h1>FAQ</h1>
                        </Col>
                    </Row>
                    <Row className="pb-3">
                        <Col className="h-100" md={{
                            size: 6, offset: 1
                        }}>
                            {faqRows}
                        </Col>
                        <HomeBG className="home-bg" />
                    </Row>
                </Container>
            </Col>
        </Row>
    </Container>
};

export default FAQ;
