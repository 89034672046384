import { all } from 'redux-saga/effects';

//public
import LoginSaga from './auth/login/saga';
import RegisterSaga from './auth/register/saga';
import ChatSaga from './chat/saga';
import NotiSaga from './notifications/saga';
import TracksSaga from './tracks/saga';

export default function* rootSaga() {
    yield all([
        RegisterSaga(),
        LoginSaga(),
        ChatSaga(),
        NotiSaga(),
        TracksSaga(),
    ])
}