import React from 'react';
import { connect } from 'react-redux';
import { Card, CardHeader, CardBody } from 'reactstrap';

import ArtistsSettingsTabs from './ArtistsSettingsTabs';
import ScoutsSettingsTabs from './ScoutsSettingsTabs';

const SettingsCard = (props) => {
	return (
		<Card className="h-100">
			<CardHeader>
				<h3>Impostazioni</h3>
			</CardHeader>
			<CardBody style={{ overflow: 'hidden', maxHeight: '70vh' }}>
				{props.user.type === 'artist' ? (
					<ArtistsSettingsTabs />
				) : props.user.type === 'scout' ? (
					<ScoutsSettingsTabs />
				) : null}
			</CardBody>
		</Card>
	);
};

const mapStatetoProps = (state) => {
	return { user: state.Auth.user };
};

export default connect(mapStatetoProps, null)(SettingsCard);
