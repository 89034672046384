import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Container, Input, Label, FormGroup, Alert, Form } from 'reactstrap';
import { isPlatform } from '@ionic/react';

import RoundedButton from '../../../components/Buttons/RoundedButton/RoundedButton';
import Loader from '../../../components/Common/Loader';

import { ReactComponent as HitReviewLogo } from '../../../assets/images/HitReview_beta.svg';

import { resetPassword } from '../../../helpers/api/auth.api';

import '../Auth.scss';

const ResetPwd = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);

    function handleOnSubmit(event) {
        event.preventDefault();
		setError(false)
		setSuccess(false)
		setLoading(true)
		let password = event.target.password.value;
		let confirm = event.target['confirm-password'].value;
        if (!password || !confirm) {
            setLoading(false);
            setError('Compilare entrambi i campi.');
        } else if (password !== confirm) {
            setLoading(false);
            setError('Le password non combaciano.');
        } else {
            const urlParams = new URLSearchParams(props.location && props.location.search);

            let userId = urlParams.get('userId');
            let token = urlParams.get('token');

            resetPassword({ userId, password, token })
                .then((result) => {
                    setError(result.message);
                    setLoading(false);
                    if (result.success) {
                        setSuccess(true);
                    } else {
                        setError(result.message)
                    }
                })
                .catch((error) => {
                    setError(error.message);
                    setLoading(false);
                });
        }
    }

    useEffect(() => {
        document.body.classList.add('auth-body-bg');

        return () => {
            document.body.classList.remove('auth-body-bg');
        };
    }, []);

    const isMobile = isPlatform('mobile');

    return (
        <Container fluid className="auth">
            <Row>
                <Col lg={{ size: 4, offset: 4 }}>
                    <div className="authentication-page-content py-4 mt-3 d-flex flex-column align-items-stretch justify-content-between min-vh-100 w-100">
                        <h1 className="text-center mt-5 mb-0">RECUPERO</h1>

                        <div className="p-2">

                            {success ? (
                                <div className='text-center'>
                                    <h5 className="text-center mb-5">
                                        La tua password è stata cambiata<br />correttamente.
                                    </h5>

                                    <Link to="/login" className="d-block text-center">
                                        Torna alla login
                                    </Link>
                                </div>
                            ) : loading ? (
                                <Loader />
                            ) : (
                                <>
                                    {error ? (
                                        <Alert className="text-center" color="danger">
                                            {error}
                                        </Alert>
                                    ) : null}
                                    <Form className="form-horizontal" onSubmit={handleOnSubmit}>
                                        <FormGroup className="auth-form-group-custom mb-4">
                                            <Label className="d-block text-center" htmlFor="password">
                                                Nuova Password
                                            </Label>
                                            <Input
                                                name="password"
                                                type="password"
                                                className="form-control"
                                                style={{ padding: '1.5em 1.5em' }}
                                                id="password"
                                            />
                                        </FormGroup>

                                        <FormGroup className="auth-form-group-custom mb-4">
                                            <Label className="d-block text-center" htmlFor="confirm-password">
                                                Conferma Password
                                            </Label>
                                            <Input
                                                name="confirm-password"
                                                type="password"
                                                className="form-control"
                                                style={{ padding: '1.5em 1.5em' }}
                                                id="confirm-password"
                                            />
                                        </FormGroup>

                                        <div className="mt-4 mb-5 text-center">
                                            <RoundedButton color="primary" className="w-50" type="submit">
                                                PROCEDI
                                            </RoundedButton>
                                        </div>

                                        <p className="mt-4 text-center">
                                            Ricordi la password?{' '}
                                            <Link to="/login" className="d-inline">
                                                {/* <i className="mdi mdi-lock mr-1"></i> */}
                                                Accedi ora
                                            </Link>
                                        </p>
                                    </Form>
                                </>
                            )}
                        </div>

                        <Link to={isMobile ? '#' : "/"}>
                            <div className="my-5 mx-auto w-25 text-center">
                                <HitReviewLogo className="logo" />
                            </div>
                        </Link>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default ResetPwd;
