const { API_URL } = require('../../app.config');

export async function loadNotifications(page) {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	let url = API_URL + '/notifications';
	if (page >= 0) url += '?p=' + parseInt(page);

	let response = await fetch(url, {
		method: 'GET',
		headers
	}).then((response) => response.json());

	return response;
}

export async function loadUnreadNotifications() {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	let response = await fetch(API_URL + '/notifications?r=0', {
		method: 'GET',
		headers
	}).then((response) => response.json());

	return response;
}

export async function setReadNotification(notificationId) {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	return await fetch(
		API_URL + '/notifications/' + notificationId + '/read',
		{
			method: 'PUT',
			headers
		}
	).then((response) => response.json());
}