import {
    OPEN_CHAT,
    LOAD_CHATS,
    LOAD_CHATS_SUCCESS,
    LOAD_CHATS_ERROR,
    MESSAGE_RECEIVED,
    SEND_MESSAGE
} from './actionTypes';

export const messageReceived = (chatId, message) => {
    return {
        type: MESSAGE_RECEIVED,
        payload: {
            chatId, message
        }
    }
}

export const sendMessage = (chatId, message) => {
    return {
        type: SEND_MESSAGE,
        payload: {
            chatId, message
        }
    }
}

export const openChat = (chatId) => {
    return {
        type: OPEN_CHAT,
        payload: {
            chatId
        }
    }
}

export const loadChats = () => {
    return {
        type: LOAD_CHATS
    }
}

export const loadChatsSucessful = (result) => {
    return {
        type: LOAD_CHATS_SUCCESS,
        payload: result
    }
}

export const loadChatsFailed = (result) => {
    return {
        type: LOAD_CHATS_ERROR,
        payload: result
    }
}