const { API_URL } = require('../../app.config');

export async function loadOrders(page) {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	let response = await fetch(API_URL + '/orders?p=' + page, {
		method: 'GET',
		headers
	}).then((response) => response.json());

	return response;
}

