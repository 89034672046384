import { useState, useEffect } from 'react';
import {
	Row,
	Col,
	Container,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Button,
	UncontrolledDropdown,
} from 'reactstrap';
import { useMediaQuery } from 'react-responsive';
import { withRouter } from 'react-router-dom';
import YouTube from 'react-youtube';
import { connect } from 'react-redux';
import Zoom from 'react-medium-image-zoom';
import { IonList } from '@ionic/react';

import RoundedButton from '../../Buttons/RoundedButton/RoundedButton';
import Loader from '../../Common/Loader';
import TrackWave from '../../Tracks/TrackWave/TrackWave';
import PlayButton from '../../Buttons/PlayButton/PlayButton';
import PlaylistTrackItem from '../../Playlists/PlaylistTrackItem/PlaylistTrackItem';
import PlaylistRow from '../../Playlists/PlaylistRow/PlaylistRow';

import { loadUser } from '../../../helpers/api/users.api';
import { loadTracks, loadTrackComments } from '../../../helpers/api/tracks.api';
import { loadUserPlaylists } from '../../../helpers/api/playlists.api';

import { showMessageModal } from '../../../store/actions';

import { ReactComponent as FacebookIcon } from '../../../assets/images/fb.svg';
import { ReactComponent as InstagramIcon } from '../../../assets/images/ig.svg';
import { ReactComponent as TiktokIcon } from '../../../assets/images/tt.svg';
import { ReactComponent as YoutubeIcon } from '../../../assets/images/yt.svg';
import { ReactComponent as WebsiteIcon } from '../../../assets/images/web.svg';
import { ReactComponent as ExpandIcon } from '../../../assets/images/expand.svg';
import { ReactComponent as RewardsIcon } from '../../../assets/images/reward.svg';

import './UserDetail.scss';

import 'react-medium-image-zoom/dist/styles.css';

function youtubeParser(url) {
	if (url) {
		var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
		var match = url.match(regExp);
		return match && match[7].length == 11 ? match[7] : false;
	} else return null;
}

const UserDetail = (props) => {
	const MOBILE_AND_TABLET = useMediaQuery({
		query: '(max-device-width: 768px)',
	});

	const [loading, setLoading] = useState(true);

	const [user, setUser] = useState(null);

	const [tracks, setTracks] = useState([]);
	const [track, setTrack] = useState(null);
	const [trackComments, setTrackComments] = useState([]);

    const [playlists, setPlaylists] = useState([]);
    const [selectedPlaylist, setSelectedPlaylist] = useState(null);
    const [plTrack, setPLTrack] = useState(null);

	const [playing, setPlaying] = useState(false);

	useEffect(() => {
		if (props.userId) {
			setLoading(true);
			loadUser(props.userId)
				.then((result) => {
					if (result.success) {
						setUser(result.user);
					} else console.log(result.message);
					setLoading(false);
				})
				.catch((error) => {
					setLoading(false);
					console.log(error);
				});

			loadTracks(props.userId, true)
				.then((result) => {
					if (result.success) {
						let tracks = [...result.tracks, ...result.expiredTracks];
						setTracks(tracks);
						if (tracks && tracks.length > 0) setTrack(tracks[0]);
					} else console.log(result.message);
					setLoading(false);
				})
				.catch((error) => {
					setLoading(false);
					console.log(error);
				});

			loadUserPlaylists(props.userId)
			.then((result) => {
				if (result.success) {
					setPlaylists(result.playlists);
				} else {
					setPlaylists([]);
					console.log(result.message);
				}
			})
			.catch((error) => {
                setPlaylists([]);
				console.log(error);
			});
		}
	}, [props.userId]);

	useEffect(() => {
		if (track) loadComments();
	}, [track]);

    useEffect(() => {
        if (playlists && playlists.length > 0)
            setSelectedPlaylist(playlists[0])
    }, [playlists]);

    useEffect(() => {
        if (selectedPlaylist && selectedPlaylist.tracks)
            setPLTrack(selectedPlaylist.tracks[0]);
    }, [selectedPlaylist]);

	function loadComments() {
		loadTrackComments(track._id)
			.then((result) => {
				if (result.success) {
					setTrackComments(result.comments);
				} else {
					console.log(result.message);
					setTrackComments([]);
				}
			})
			.catch((error) => {
				setTrackComments([]);
				console.log(error);
			});
	}

	let links = [],
		pics = [];
	if (user) {
		if (user.socialLinks) {
			if (user.socialLinks.instagram)
				links.push(
					<a key="instagram" className="social-link" href={user.socialLinks.instagram} target="_blank">
						<InstagramIcon />
					</a>
				);
			if (user.socialLinks.facebook)
				links.push(
					<a key="facebook" className="social-link" href={user.socialLinks.facebook} target="_blank">
						<FacebookIcon />
					</a>
				);
			if (user.socialLinks.tiktok)
				links.push(
					<a key="tiktok" className="social-link" href={user.socialLinks.tiktok} target="_blank">
						<TiktokIcon />
					</a>
				);
			if (user.socialLinks.youtube)
				links.push(
					<a key="youtube" className="social-link" href={user.socialLinks.youtube} target="_blank">
						<YoutubeIcon />
					</a>
				);
			if (user.socialLinks.website)
				links.push(
					<a key="website" className="social-link" href={user.socialLinks.website} target="_blank">
						<WebsiteIcon />
					</a>
				);
		}
		if (user.pics)
			pics = user.pics.map((p, i) => (
				<Zoom style={{ display: 'inline-block' }} key={'img+' + i}>
					<img className="artist-pic mb-3" src={p.url} alt="" />
				</Zoom>
			));
	}

    const plTracks = selectedPlaylist ? selectedPlaylist.tracks : [];

	return loading ? (
		<Loader />
	) : user ? (
		<Container className="user-detail">
			<Row>
				<Col lg={user.type === 'artist' ? "6" : "5"}>
					<Row className="mb-2 mb-lg-5">
						<Col xs="5" lg="4">
							<div
								className="user-pic"
								style={{
									backgroundImage: user.image ? 'url(' + user.image.url + ')' : '',
								}}
								alt="profile"
							/>
						</Col>
						<Col
							xs={{
								size: 7,
								offset: 0,
							}}
							lg={{
								size: 7,
								offset: 1,
							}}
						>
							<h3>{user.name}</h3>
							<h6>
								<i>
									{user.type === 'scout'
										? user.job || ''
										: user.artistType
										? user.artistType.charAt(0).toUpperCase() + user.artistType.slice(1)
										: ''}
								</i>
							</h6>
							<h6>{user.city}</h6>
							<div className="d-block mt-3">{links}</div>
							{MOBILE_AND_TABLET && (
								<RoundedButton
									className="ml-0 mt-4 w-75"
									color="primary"
									onClick={() => {
										props.history.push('/settings');
									}}
								>
									Modifica
								</RoundedButton>
							)}
						</Col>
					</Row>

					{!MOBILE_AND_TABLET && (
						<Row>
							<Col>
								{!props.user || props.user.type === 'admin' ? null : props.user._id !== user._id ? (
									<RoundedButton
										className="px-4"
										color="primary"
										onClick={() => props.showMessageModal && props.showMessageModal(user)}
									>
										Invia Messaggio
									</RoundedButton>
								) : (
									<>
										{tracks && tracks.length > 0 ? (
											<RoundedButton
												className="px-5 mr-3"
												color="primary"
												onClick={() => {
													props.history.push('/settings/upload-track');
												}}
											>
												Carica Brano
											</RoundedButton>
										) : null}
										<RoundedButton
											className="px-5"
											color="secondary"
											onClick={() => {
												props.history.push('/settings');
											}}
										>
											Modifica
										</RoundedButton>
									</>
								)}
							</Col>
						</Row>
					)}

					{user.type === 'scout' || (user.type === 'artist' && MOBILE_AND_TABLET) ? (
						<Row className="mt-lg-5">
							<Col>
								<h3>Bio</h3>
								<p className="user-bio mb-4">{user.bio}</p>
							</Col>
						</Row>
					) : null}

					{user.type === 'artist' ? (
						<Row className="mt-4 mt-lg-5">
							<Col>
								<div className="w-100 d-flex justify-content-between">
									<div className="d-flex align-items-center">
										<div className="d-flex flex-column">
											<h6>
												<i>Brani</i>
											</h6>

											{track && (
												<UncontrolledDropdown>
													<DropdownToggle>
														<h6>{track.title}</h6>
														{tracks && tracks.length > 1 ? (
															<Button color="link" className="expand-button d-inline">
																<ExpandIcon />
															</Button>
														) : null}
													</DropdownToggle>
													<DropdownMenu>
														{tracks ? tracks
															.filter((t) => t._id !== track._id)
															.map((t) => (
																<DropdownItem key={t._id} onClick={() => setTrack(t)}>
																	{t.title}
																</DropdownItem>
															)) : null}
													</DropdownMenu>
												</UncontrolledDropdown>
											)}
										</div>

										{track && (
											<PlayButton
												className="ml-4 mr-4"
												playing={playing}
												onClick={() => setPlaying(!playing)}
											/>
										)}
									</div>

									{track && (
										<div className="d-none d-lg-flex align-items-center justify-content-end">
											<p className="track-points">
												{track.average || 0}
												{' punti'}
											</p>
											<p className="track-rewards">
												<RewardsIcon />
												{track.rewards ? track.rewards.length : 0}
												{' Rewards'}
											</p>
										</div>
									)}
								</div>

								{props.user && props.user._id !== user._id && tracks && tracks.length === 0 ? (
									<Row>
										<Col className="text-left">
											<p>{user.name + ' non ha caricato ancora nessun brano...'}</p>
										</Col>
									</Row>
								) : tracks && tracks.length === 0 ? (
									<Row>
										<Col className="text-left">
											{props.user ? <><p>
												Non hai caricato nessun brano o non ne hai reso pubblico nessuno...
												<br />
												Cosa stai aspettando?
											</p>
											<RoundedButton
												className="px-5 mb-5"
												color="primary"
												onClick={() => {
													props.history.push('/settings/upload-track');
												}}
											>
												Carica Brano
											</RoundedButton>
											</> : <p>
												Questo artista non ha ancora nessun brano pubblico.
											</p>}
										</Col>
									</Row>
								) : null}
								{track && (
									<div className="wave-wrapper mt-3">
										<TrackWave
											track={track}
											comments={trackComments}
											playing={playing}
											onFinish={() => setPlaying(false)}
										/>
									</div>
								)}
								{/* {props.user.type === 'scout' &&
                                    <div className="d-flex align-items-center justify-content-between mb-4">
                                        <RoundedButton onClick={() => setMakingAction('comment')} className="px-5">COMMENTA</RoundedButton>
                                        <RoundedButton onClick={() => setMakingAction('vote')} className="px-5">ASSEGNA VOTO</RoundedButton>
                                        <RoundedButton onClick={() => setMakingAction('save')} className="px-5">SALVA</RoundedButton>
                                        <RoundedButton onClick={() => setMakingAction('reward')} className="px-5">REWARD</RoundedButton>
                                    </div>}
                                <CommentTrackModal isOpen={makingAction === 'comment'} track={track} time={time} onClose={() => setMakingAction('')} onSuccess={() => loadComments()} />
                                <VoteTrackModal isOpen={makingAction === 'vote'} track={track} onClose={() => setMakingAction('')} />
                                <SaveTrackModal isOpen={makingAction === 'save'} track={track} onClose={() => setMakingAction('')} />
                                <RewardTrackModal isOpen={makingAction === 'reward'} track={track} onClose={() => setMakingAction('')} /> */}
							</Col>
						</Row>
					) : null}
				</Col>

				<Col lg={user.type === 'artist' ? { offset: 1, size: 5 } : { size: 7 }}>
				{user.type === 'artist' ? <>
						<Row className="mb-1 mb-lg-2">
							<Col>
								<h3>Foto</h3>
								<div className="mt-4">{pics}</div>
							</Col>
						</Row>
						<Row className="d-none d-lg-flex mb-4 mb-lg-5">
							<Col>
								<h3>Bio</h3>
								<p className="user-bio mb-0">{user.bio}</p>
							</Col>
						</Row>
						<Row>
							<Col>
								<h3>Media</h3>
								{!user.youtubeUrl ? (
									<p>Nessun link ad un video YouTube inserito</p>
								) : !youtubeParser(user.youtubeUrl) ? (
									<p>Il link al video inserito non è valido</p>
								) : (
									<div className="mt-4 mb-4 mb-lg-5">
										<YouTube
											videoId={youtubeParser(user.youtubeUrl)}
											opts={{
												width: '100%',
												height: '250',
												playerVars: {
													autoplay: 0,
												},
											}}
										/>
									</div>
								)}
							</Col>
						</Row>
						{MOBILE_AND_TABLET && (
							<Row>
								<Col>
									<h3>Brani pubblicati</h3>
									<p className="big mb-4 mb-lg-5">
										<b>
											{user.releases
												? user.releases.reduce((acc, r) => {
														return acc + Number(r.nTracks);
												  }, 0)
												: 0}
										</b>
									</p>
									{user.artistType === 'band' ? (
										<>
											<h3>Componenti</h3>
											<p className="big mb-4 mb-lg-5">
												<b>{user.nMembers || 1}</b>
											</p>
										</>
									) : null}
									<div className="releases">
										<h3>Pubblicazioni</h3>
										{!user.releases || user.releases?.length === 0 ? (
											<p>{'Nessuna release pubblicata.'}</p>
										) : (
											user.releases.map((r, i) => (
												<div className="release-row pb-4 mb-4 mb-lg-5" key={'release-' + i}>
													<Row>
														<Col xs="4">
															<h6>
																<i>Tipologia</i>
															</h6>
															<p className="mb-2">
																<b>
																	{r.type?.charAt(0).toUpperCase() + r.type.slice(1)}
																</b>
															</p>
														</Col>
														<Col xs="4">
															<h6>
																<i>Titolo</i>
															</h6>
															<p className="mb-2">
																<b>{r.title}</b>
															</p>
														</Col>
														<Col xs="3">
															<h6>
																<i>Cover</i>
															</h6>
															{r.cover && (
																<img
																	className="cover d-block mb-2"
																	src={r.cover.url}
																	alt=""
																/>
															)}
														</Col>
														<Col xs="4">
															<h6>
																<i>Anno</i>
															</h6>
															<p className="mb-2">
																<b>{r.year}</b>
															</p>
														</Col>
														<Col xs="4">
															<h6>
																<i>N° Tracce</i>
															</h6>
															<p className="mb-2">
																<b>{r.nTracks}</b>
															</p>
														</Col>
													</Row>
												</div>
											))
										)}
									</div>
									<div className="concerts">
										<h3>Storico concerti</h3>
										{!user.concerts || user.concerts.length === 0 ? (
											<p>{'Nessuna informazione inserita.'}</p>
										) : (
											user.concerts?.map((c, i) => (
												<div className="concert-row pb-4 mb-4 mb-lg-5" key={'concert-' + i}>
													<Row>
														<Col xs="6">
															<h6>
																<i>Evento</i>
															</h6>
															<p className="mb-2">
																<b>{c.name}</b>
															</p>
														</Col>
														<Col xs="6">
															<h6>
																<i>Luogo</i>
															</h6>
															<p className="mb-2">
																<b>{c.location}</b>
															</p>
														</Col>
													</Row>
													<Row>
														<Col xs="6">
															<h6>
																<i>Data</i>
															</h6>
															<p className="mb-2">
																<b>{c.date}</b>
															</p>
														</Col>
														<Col xs="6">
															<h6>
																<i>Informazioni</i>
															</h6>
															<p className="mb-2">{c.info}</p>
														</Col>
													</Row>
												</div>
											))
										)}
									</div>
									<div className="awards">
										<h3>Riconoscimenti</h3>
										{!user.awards || user.awards.length === 0 ? (
											<p>{'Nessuna informazione inserita.'}</p>
										) : (
											user.awards?.map((a, i) => (
												<div className="award-row pb-4 mb-4 mb-lg-5" key={'award-' + i}>
													<Row>
														<Col xs="6">
															<h6>
																<i>Nome</i>
															</h6>
															<p className="mb-2">
																<b>{a.name}</b>
															</p>
														</Col>
														<Col xs="6">
															<h6>
																<i>Informazioni</i>
															</h6>
															<p className="mb-2">{a.info}</p>
														</Col>
													</Row>
												</div>
											))
										)}
									</div>

									<h3>Label</h3>
									<p className="mb-4 mb-lg-5">{user.label || 'Nessuna'}</p>

									<h3>Management</h3>
									<p className="mb-4 mb-lg-5">{user.management || 'Nessuno'}</p>

									<h3>Collecting</h3>
									<p className="mb-4 mb-lg-0">{user.collecting && user.collecting.length > 0
									? user.collecting.join(', ')
									: 'Nessuna'}</p>
								</Col>
							</Row>
						)}
					</>
				: user.type === 'scout' ? <>
				<Row className="h-100 playlists">
					<Col lg={{size: 8, offset: 1}}>
                    <Row>
                        <Col>
                            <h4>Playlist</h4>
							{playlists.length === 0 ?
							<>
								<p>
									Nessuna playlist creata
								</p>
								{props.user && props.user._id === user._id && <RoundedButton
									className="px-5 mb-5"
									color="primary"
									onClick={() => {
										props.history.push('/playlists');
									}}
								>
									Crea Playlist
								</RoundedButton>}
								</>
							: null}
                            {selectedPlaylist ? <div className='d-flex align-items-center justify-content-between mb-3'>
                            <h5 className="playlist-title">{selectedPlaylist.title}</h5>
                            </div> : null}
                            {loading ?
                                <Loader className="mt-5" />
                                : <div className="playlist-content">
                                    <Row className="pb-lg-3">
                                        <Col className="d-flex flex-column justify-content-between">
                                            {plTrack ?
                                                <>
                                                    <Row>
                                                        <Col xs="12" lg="8" className="d-flex align-items-center justify-content-start">
                                                                <h4 className='mr-5'>{plTrack.title}</h4>
                                                            <PlayButton playing={playing} onClick={() => setPlaying(!playing)} />
                                                        </Col>
                                                        <Col lg="4" className="d-none d-lg-flex align-items-center justify-content-end">
                                                            <RoundedButton color="primary" className="px-3" onClick={() => props.history.push('/users/' + plTrack.author._id)}>VISUALIZZA Profilo</RoundedButton>
                                                        </Col>
                                                    </Row>
                                                    <div className="wave-wrapper mt-5 pb-0">
                                                        <TrackWave track={plTrack} playing={playing}
												onFinish={() => setPlaying(false)} />
                                                    </div>
                                                </> : null}
                                        </Col>
                                    </Row>

                                    <Row className="pt-lg-3">
                                        <Col className="">
											{plTracks && plTracks.length > 0 ? <IonList className="tracks-list mb-5 mb-lg-0 pt-3">
                                                {plTracks.map(t => <PlaylistTrackItem key={t._id}
                                                playlistId={selectedPlaylist._id} track={t}
                                                onClick={() => setPLTrack(t)} deletable={false} />)} 
                                            </IonList> : null}
                                        </Col>
                                    </Row>
                                </div>}
                        </Col>
                    </Row>

                </Col>
				{playlists && playlists.length > 0 ?
                <Col lg="3"  style={{borderLeft: '1px solid white'}}>
                    <h5>Elenco Playlist</h5>
                    <ul className="playlists-list">
                        {playlists.map((pl, i) => {
                            return <PlaylistRow key={pl._id} id={pl._id} onClick={() => setSelectedPlaylist(pl)} 
                            title={pl.title} />
                        })}
                    </ul>
                </Col> : null}
            </Row>
				</> : null}
				</Col>
			</Row>

			{user.type === 'artist' && !MOBILE_AND_TABLET && (
				<>
					<Row>
						<Col lg="6">
							<div className="releases">
								<h3>Pubblicazioni</h3>
								{!user.releases || user.releases?.length === 0 ? (
									<p>{'Nessuna release pubblicata.'}</p>
								) : (
									user.releases?.map((r, i) => (
										<div className="release-row pb-3 mb-4" key={'release-' + i}>
											<Row>
												<Col xs="4">
													<h6>
														<i>Tipologia</i>
													</h6>
													<p className="mb-2">
														<b>{r.type?.charAt(0).toUpperCase() + r.type?.slice(1)}</b>
													</p>
													<h6>
														<i>Anno</i>
													</h6>
													<p className="mb-2">
														<b>{r.year}</b>
													</p>
												</Col>
												<Col xs="4">
													<h6>
														<i>Titolo</i>
													</h6>
													<p className="mb-2">
														<b>{r.title}</b>
													</p>
													<h6>
														<i>N° Tracce</i>
													</h6>
													<p className="mb-2">
														<b>{r.nTracks}</b>
													</p>
												</Col>
												<Col xs="3">
													<h6>
														<i>Cover</i>
													</h6>
													{r.cover && (
														<img className="cover d-block mb-2" src={r.cover.url} alt="" />
													)}
												</Col>
											</Row>
										</div>
									))
								)}
							</div>
							<div className="concerts">
								<h3>Storico concerti</h3>
										{!user.concerts ? (
											<p>{'Nessuna informazione inserita.'}</p>
										) : user.concerts.length === 0 ? (
											<p>{'Nessuna informazione inserita.'}</p>
										) : (
									user.concerts.map((c, i) => (
										<div className="concert-row mb-5" key={'concert-' + i}>
											<Row>
												<Col xs="6">
													<h6>
														<i>Evento</i>
													</h6>
													<p className="mb-2">
														<b>{c.name}</b>
													</p>
												</Col>
												<Col xs="6">
													<h6>
														<i>Luogo</i>
													</h6>
													<p className="mb-2">
														<b>{c.location}</b>
													</p>
												</Col>
											</Row>
											<Row>
												<Col xs="6">
													<h6>
														<i>Data</i>
													</h6>
													<p className="mb-2">
														<b>{c.date}</b>
													</p>
												</Col>
												<Col xs="6">
													<h6>
														<i>Informazioni</i>
													</h6>
													<p className="mb-2">{c.info}</p>
												</Col>
											</Row>
										</div>
									))
								)}
							</div>
							<div className="awards">
								<h3>Riconoscimenti</h3>
										{!user.awards ? (
											<p>{'Nessuna informazione inserita.'}</p>
										) : user.awards.length === 0 ? (
											<p>{'Nessuna informazione inserita.'}</p>
										) : (
									user.awards?.map((a, i) => (
										<div className="award-row mb-4" key={'award-' + i}>
											<Row>
												<Col xs="6">
													<h6>
														<i>Nome</i>
													</h6>
													<p className="mb-2">
														<b>{a.name}</b>
													</p>
												</Col>
												<Col xs="6">
													<h6>
														<i>Informazioni</i>
													</h6>
													<p className="mb-2">{a.info}</p>
												</Col>
											</Row>
										</div>
									))
								)}
							</div>
						</Col>
						<Col lg={{ offset: 1, size: 5 }}>
							<h3>Brani pubblicati</h3>
							<p className="big mb-4">
								<b>
									{user.releases
										? user.releases.reduce((acc, r) => {
												return acc + Number(r.nTracks);
										  }, 0)
										: 0}
								</b>
							</p>
							{user.artistType === 'band' ? (
								<>
									<h3>Componenti</h3>
									<p className="big">
										<b>{user.nMembers || 1}</b>
									</p>
								</>
							) : null}
						</Col>
					</Row>
					<Row>
						<Col>
							<h3>Label</h3>
							<p className="mb-4">{user.label || 'Nessuna'}</p>
						</Col>
						<Col>
							<h3>Management</h3>
							<p className="mb-4">{user.management || 'Nessuno'}</p>
						</Col>
						<Col>
							<h3>Collecting</h3>
							<p className="mb-4">
								{user.collecting && user.collecting.length > 0
									? user.collecting.join(', ')
									: 'Nessuna.'}
							</p>
						</Col>
					</Row>
				</>
			)}
		</Container>
	) : null;
};

const mapStatetoProps = (state) => {
	const { error, success, user } = state.Auth;
	return { error, success, user };
};
export default withRouter(
	connect(mapStatetoProps, {
		showMessageModal,
	})(UserDetail)
);
