import { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { ReactComponent as HomeIcon } from '../../../assets/images/home.svg';
import { ReactComponent as DiscoveryIcon } from '../../../assets/images/discovery.svg';
import { ReactComponent as RewardIcon } from '../../../assets/images/reward.svg';
import { ReactComponent as TracksIcon } from '../../../assets/images/wave.svg';
import { ReactComponent as ProfileIcon } from '../../../assets/images/profile.svg';
import { ReactComponent as PlaylistIcon } from '../../../assets/images/playlist.svg';

import './ProtectedFooter.scss';

const ProtectedFooter = (props) => {
	const [active, setActive] = useState('');

	useEffect(() => {
		setActive(props.location.pathname);
	}, [props.location.pathname]);

	return props.user ? (
		<div className="footer">
			<ul className="list-unstyled footer-menu">
				{props.user.type === 'admin' ? (
					<>
						<li className={active === '/admin/dashboard' || active === '/admin' ? 'active' : ''}>
							<Link to="/admin">
								<HomeIcon />
								<span>Dashboard</span>
							</Link>
						</li>
						<li className={active === '/admin/tracks' ? 'active' : ''}>
							<Link to="/admin/tracks">
								<TracksIcon />
								<span>Brani</span>
							</Link>
						</li>
						<li className={active === '/admin/artists' ? 'active' : ''}>
							<Link to={'/admin/artists'}>
								<ProfileIcon />
								<span>Artisti</span>
							</Link>
						</li>
						<li className={active === '/admin/scouts' ? 'active' : ''}>
							<Link to={'/admin/scouts'}>
								<ProfileIcon />
								<span>Scouter</span>
							</Link>
						</li>
					</>
				) : (
					<>
						<li className={active === '/dashboard' || active === '/' ? 'active' : ''}>
							<Link to="/dashboard">
								<HomeIcon />
								<span>Dashboard</span>
							</Link>
						</li>
						<li className={active === '/users/' + props.user._id ? 'active' : ''}>
							<Link to={'/users/' + props.user._id}>
								<ProfileIcon />
								<span>Il Mio Profilo</span>
							</Link>
						</li>
						<li className={active === '/tracks' ? 'active' : ''}>
							<Link to="/tracks">
								<TracksIcon />
								<span>{props.user.type === 'scout' ? 'Brani da valutare' : 'I Miei Brani'}</span>
							</Link>
						</li>
					</>
				)}
				{props.user.type === 'artist' && (
					<>
						<li className={active === '/rewards' ? 'active' : ''}>
							<Link to="/rewards">
								<RewardIcon />
								<span>Rewards</span>
							</Link>
						</li>
						<li className={active === '/discovery' ? 'active' : ''}>
							<Link to="/discovery">
								<DiscoveryIcon />
								<span>HitDiscovery</span>
							</Link>
						</li>
					</>
				)}
				{props.user.type === 'scout' && (
					<>
						<li className={active === '/playlists' ? 'active' : ''}>
							<Link to="/playlists">
								<PlaylistIcon />
								<span>Playlist</span>
							</Link>
						</li>
						<li className={active === '/search' ? 'active' : ''}>
							<Link to="/search">
								<DiscoveryIcon />
								<span>HitResearch</span>
							</Link>
						</li>
					</>
				)}
			</ul>
		</div>
	) : null;
};

const mapStatetoProps = (state) => {
	const { error, success, user } = state.Auth;
	return { error, success, user };
};

export default withRouter(connect(mapStatetoProps, {})(ProtectedFooter));
