import React, { useState } from 'react';
import {
	Row,
	Col,
	Container
} from 'reactstrap';

import Top10Slider from '../../../components/Sliders/Top10Slider/Top10Slider';
import LatestSlider from '../../../components/Sliders/LatestSlider/LatestSlider';

import '../Home/Home.scss';

const Discovery = (props) => {

	return <Container fluid className="home pb-5">
			<Row>
				<Col>
					<Container id="discovery">
						<Row className="mb-5 h-auto">
							<Col md={{
								offset: 1,
								size: 10
							}}>
								<h2 className='d-inline mb-5'>Top10</h2><h4 className='d-inline text-left'>HitDiscovery</h4>
								<Top10Slider onSlideClick={u => props.history.push('/artists/' + u._id)} />
								<div className='line w-100'></div>
							</Col>
						</Row>
						<Row className="mb-5">
							<Col md={{
								offset: 1,
								size: 10
							}}>
								<h4 className='text-left'>Ultimi arrivi</h4>
								<LatestSlider onSlideClick={u => props.history.push('/artists/' + u._id)} />
								<div className='line w-100'></div>
							</Col>
						</Row>

						<Row className="mb-5">
							<Col md={{
								offset: 1,
								size: 10
							}}>
								<h4 className='text-left'>Ultimi arrivi</h4>
								<LatestSlider onSlideClick={u => props.history.push('/artists/' + u._id)} />
								<div className='line w-100'></div>
							</Col>
						</Row>

					</Container>
				</Col>
			</Row>
		</Container>
};

export default Discovery;
