import { useState } from 'react';
import { Button } from 'reactstrap';
import { withRouter } from 'react-router';
import SweetAlert from 'react-bootstrap-sweetalert';

import Loader from '../../Common/Loader';
import RoundedButton from '../../Buttons/RoundedButton/RoundedButton';

import { ReactComponent as TrashIcon } from '../../../assets/images/trash.svg';
import { ReactComponent as RewardsIcon } from '../../../assets/images/reward.svg';

import './UserRow.scss';

const UserRow = props => {

	const [deleteConfirm, setDeleteConfirm] = useState(false);

	return props.loading ? 
	<Loader className="my-4" size="sm" />
	: props.user ? <>
		<li className="user-row">
			<div className="w-50 d-flex align-items-center">
				<h6 className="mr-5 mt-0 mb-0">{props.user.name}
					<br />
					<span>
					<a className='pb-1 pt-2 d-inline-block' href={"mailto:" + props.user.email}>
						<b>{props.user.email}</b>
					</a></span>
					<br/>
					<span>
						{props.user.type === 'admin' ? 'Amministratore' : props.user.type === 'artist' ? 'Artista' : props.user.type === 'scout' ? 'Scouter' : ''}
					</span>
				</h6>
			</div>
			<div className="w-50 d-flex justify-content-end">
				{props.user.type !== 'scout' ?
					<>
						<p className="track-points d-flex align-items-center">
							{props.points || 0}
							{' punti'}
						</p>
						<p className="track-rewards d-flex align-items-center">
							<RewardsIcon />
							{props.rewards ? props.rewards.length : 0}
							{' Rewards'}
						</p>
					</> :
					<RoundedButton color="primary" className="px-3 ml-4 " onClick={() => props.history.push('/wallet/' + props.user._id)}>Portafoglio</RoundedButton>}

				<RoundedButton color="primary" className="px-3 ml-4 " onClick={() => props.history.push('/users/' + props.user._id)}>VISUALIZZA Profilo</RoundedButton>

				<Button className="delete-button ml-3 mr-0" color="link" onClick={() => setDeleteConfirm(true)}>
					<TrashIcon />
				</Button>
			</div>
		</li>
			{deleteConfirm ? (
				<SweetAlert
					title={'Vuoi davvero eliminare '
					+ (props.user.type === 'artist' ? 'l\'artista ' : props.user.type === 'scout' ? 'lo scouter ' : '')
					+ props.user.name
					+ '? Tutti i suoi brani e i riferimenti a lui verranno eliminati da HitReview.'}
					showCancel
					confirmBtnBsStyle="primary"
					confirmBtnText="Elimina"
					cancelBtnBsStyle="secondary"
					cancelBtnText="Annulla"
					onConfirm={() => {
						setDeleteConfirm(false);
						props.onDelete && props.onDelete(props.user._id);
					}}
					onCancel={() => setDeleteConfirm(false)}
				/>
			) : null}
	</>
		: null;
}

export default withRouter(UserRow);