import { connect } from 'react-redux';
import {useMediaQuery} from 'react-responsive';
import Hotjar from '@hotjar/browser';

import ProtectedHeader from '../ProtectedHeader/ProtectedHeader';
import Sidebar from '../Sidebar/Sidebar';
import ProtectedFooter from '../ProtectedFooter/ProtectedFooter';
import MessageModal from '../../Modals/Message/MessageModal';

import './ProtectedLayout.scss';

import { closeModal } from '../../../store/actions';

const siteId = 3691570;
const hotjarVersion = 6;

const Layout = props => {
    Hotjar.init(siteId, hotjarVersion);

    const MOBILE_AND_TABLET = useMediaQuery({
        query: "(max-device-width: 768px)",
    });

    const DESKTOP = useMediaQuery({
        query: "(min-device-width: 769px)",
    });
    
    return <div className="protected-layout"> {/* has-player">*/}
        <ProtectedHeader showBack={props.showBack}/>
        {DESKTOP &&
            <Sidebar />}

        <div className="content">
            {props.children}
        </div>

        {/* <GlobalPlayer /> */}

        {MOBILE_AND_TABLET &&
            <ProtectedFooter />}

		<MessageModal isOpen={props.modal === 'message'} onClose={props.closeModal}/>
    </div>;
}

const mapStateToProps = state => {
    return {
        ...state.Layout
    }
}

const mapDispatchToProps = {
    closeModal
}

export default connect(mapStateToProps,  mapDispatchToProps)(Layout);