import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button } from 'reactstrap';

import Loader from '../../Common/Loader';

import { ReactComponent as TrashIcon } from '../../../assets/images/trash.svg';

import { deletePlaylist } from '../../../helpers/api/playlists.api';

const PlaylistRow = (props) => {
	const [deletingLoading, setDeletingLoading] = useState(false);

	function handleOnDeletePlaylist(event) {
		event.preventDefault();
		setDeletingLoading(true);
		deletePlaylist(props.id)
			.then((result) => {
				if (!result.success) console.log(result.message);
				setDeletingLoading(false);
				props.onDeleteComplete && props.onDeleteComplete();
			})
			.catch((error) => {
				console.log(error.message);
				setDeletingLoading(false);
			});
	}

	return (
		<li>
			<Button color="link" onClick={props.onClick}>
				{props.title}
			</Button>
			{deletingLoading ? (
				<Loader className="mr-2" size={'sm'} />
			) : props.deletable ? (
				<Button
					className="delete-button ml-3 ml-lg-0 mr-0 mr-lg-3"
					color="link"
					onClick={handleOnDeletePlaylist}
				>
					<TrashIcon />
				</Button>
			) : null}
		</li>
	);
};

const mapStatetoProps = (state) => {
	return { user: state.Auth.user };
};

export default withRouter(connect(mapStatetoProps, {})(PlaylistRow));
