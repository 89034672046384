import React from 'react';
import { Button } from "reactstrap";

import './PlayButton.scss';

import { ReactComponent as PauseIcon } from '../../../assets/images/pause.svg';
import { ReactComponent as PlayIcon } from '../../../assets/images/play2.svg';

const PlayButton = props => {
    return <Button color="link" className={"play-button d-inline " + props.className} onClick={props.onClick}>
        {props.playing ? <PauseIcon /> : <PlayIcon />}
    </Button>
}

export default PlayButton;