
import { useState, useEffect } from 'react'
import { Alert } from 'reactstrap';
import ReactApexChart from 'react-apexcharts';
import ObjectID from 'bson-objectid';
import moment from 'moment';

import { loadTracks } from '../../helpers/api/tracks.api';
import Loader from '../Common/Loader';

const TracksCharts = props => {

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [tracks, setTracks] = useState([]);
    const [series, setSeries] = useState([]);

    useEffect(() => {
        setLoading(true)
        loadTracks()
            .then((result) => {
                if (result.success)
                    setTracks(result.tracks)
                else setError(result.message)
                setLoading(false)
            })
            .catch(error => {
                setLoading(false);
                setError(error.message)
            });
    }, []);

useEffect(() => {
    let grouped = [];
    tracks.forEach((t) => {
        const date = moment(ObjectID(t._id).getTimestamp()).format('MM/DD/YYYY');
        let i = grouped.findIndex(a => a.x === date);
        if (i >= 0) {
            grouped[i].y += 1;
        } else {
            grouped.push({x :date, y : 1});
        }
    })

    setSeries([{
        name: 'Tracce',
        type: 'column',
        data: grouped
    }, {
        name: 'Andamento Tracce',
        type: 'area',
        data: grouped
    }]);
}, [tracks]);

const options = {
    chart: {
        stacked: false,
        toolbar: {
            show: false
        }
    },
    stroke: {
        width: [0, 2],
        curve: 'smooth'
    },
    plotOptions: {
        bar: {
            columnWidth: '10%',
            endingShape: 'rounded' 
        }
    },
    colors: ['#ff3883', '#f1f1f1'],
    fill: {
        opacity: [0.85, 0.25],
        gradient: {
            inverseColors: false,
            shade: 'light',
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100]
        }
    },
    markers: {
        size: 2
    },
    legend: {
        offsetY: 11,
    },
    labels: {
        format: 'dd/MM/YYYY',
    },
    xaxis: {
        type: 'datetime'
    },
    yaxis: {
        title: {
            text: 'Tracce caricate',
        },
    },
    tooltip: {
        shared: true,
        intersect: false,
        y: {
            formatter: function (val) {
                return val + " tracce caricate"
            }
        },
        theme: 'dark'
    },
    grid: {
        borderColor: '#f1f1f1'
    },
};


    return (loading ?
        <Loader />
        : error ?
            <Alert>{error}</Alert> :
            <ReactApexChart options={options} series={series} type="bar" height={350} />
    );
}

export default TracksCharts