import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Container, Alert } from 'reactstrap';

import Page from '../../components/Layout/Page/Page';
import Loader from '../../components/Common/Loader';
import UserRow from '../../components/Users/UserRow/UserRow';

import { loadArtists, deleteUser } from '../../helpers/api/users.api';

import '../Tracks/Tracks.scss';

const AdminArtists = props => {
    const [loading, setLoading] = useState(true);
    const [artists, setArtists] = useState([]);

    const [loadingUser, setLoadingUser] = useState(null);

    const [error, setError] = useState(false);

    useEffect(() => {
        load();
    }, []);

    function load() {
        setLoading(true);
        loadArtists()
            .then((result) => {
                if (result.success) {
                    setArtists(result.users);
                } else {
                    setArtists([]);
                    setError(result.message);
                }
                setLoading(false);
            })
            .catch((error) => {
                setArtists([]);
                setLoading(false);
                setError(error.message);
                console.log(error);
            });
    }

    function handleOnDeleteArtist(userId) {
        setLoadingUser(userId);
        deleteUser(userId)
            .then((result) => {
                if (result.success)
                    load();
                else
                    setError(result.message);
                setLoadingUser(null);
            })
            .catch((error) => {
                load();
                setLoadingUser(null);
                setError(error.message);
            });
    }

    return <Page className="tracks">
        <Container fluid className="h-100 d-flex flex-column">
            {error &&
				<Alert className="mb-2" color="danger">
						{error}
					</Alert>}
            {loading ?
                <Loader />
                : artists ? <Row className="h-50 pt-3">
                    <Col className="h-100">
                        <ul className="users-list h-100">
                            {artists.map(u => <UserRow key={u._id} user={u}
                                loading={u._id === loadingUser} onDelete={handleOnDeleteArtist} />)}
                        </ul>
                    </Col>
                </Row> : null}
        </Container>
    </Page>;
}

const mapStatetoProps = (state) => {
    return { user: state.Auth.user };
};

export default connect(mapStatetoProps, {})(AdminArtists);