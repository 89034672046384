import {
	SHOW_LOGIN_MODAL,

    SHOW_REGISTER_MODAL,
    
    SHOW_MESSAGE_MODAL,

	CLOSE_MODAL
} from './actionTypes';

export const showLoginModal = () => {
    return {
        type: SHOW_LOGIN_MODAL
    }
}

export const showRegisterModal = () => {
    return {
        type: SHOW_REGISTER_MODAL
    }
}

export const showMessageModal = (user) => {
    return {
        type: SHOW_MESSAGE_MODAL,
        payload: {
            messageUser: user
        }
    }
}

export const closeModal = () => {
    return {
        type: CLOSE_MODAL
    }
}