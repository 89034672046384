import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Row,
    Col,
    Container,
} from 'reactstrap';

import Page from '../../components/Layout/Page/Page';
import TopWeeklyDiscoveryCard from '../../components/Cards/TopWeeklyDiscoveryCard/TopWeeklyDiscoveryCard';
import Loader from '../../components/Common/Loader';
import RoundedButton from '../../components/Buttons/RoundedButton/RoundedButton';

import { loadUserStats } from '../../helpers/api/users.api';

import '../Dashboard/Dashboard.scss';
import TracksCharts from '../../components/Charts/TracksChart';

const Dashboard = (props) => {
    const [loadingStats, setLoadingStats] = useState(true);

    const [stats, setStats] = useState(null);
	
    useEffect(() => {
        setLoadingStats(true);
        loadUserStats()
            .then((result) => {
                if (result.success) setStats(result.stats);
                else {
                    console.log(result.message);
                    setStats(null);
                }
                setLoadingStats(false);
            })
            .catch((error) => {
                setStats(null);
                setLoadingStats(false);
                console.log(error);
            });
    }, []);

    return <Page className="dashboard">{props.user &&
        <Container fluid className="h-100">
            <Row className="h-50 pb-3">
                <Col className="h-100 d-flex flex-column justify-content-between" md="8">
                   <TracksCharts ></TracksCharts>

                </Col>
                <Col className="h-100" md="4">
                    <TopWeeklyDiscoveryCard />
                </Col>
            </Row>
            <Row className='pt-3'>
                <Col className="h-100 d-flex flex-column justify-content-between" md="12">
                {props.user.type === 'admin' && (
                        loadingStats ?
                            <Loader className="h-100 w-100" />
                            : stats ?
                                <>
                                    <h3 className="mb-0">Statistiche</h3>
                                    <Row className="h-100 pt-3">
                                        <Col className="h-100" sm="4">
                                            <div className="stats h-100 mb-5">
                                                <span className="mt-5">{stats.tracks || 0}</span>
                                                <span>
                                                    Brani
                                                </span>
                                                <RoundedButton
                                                    color="primary"
                                                    onClick={() => {
                                                        props.history.push('/admin/tracks');
                                                    }}>
                                                    VAI AI BRANI
                                                </RoundedButton>
                                            </div>
                                        </Col>
                                        <Col sm="4">
                                            <div className="stats mb-5">
                                                <span className="mt-5">{stats.artists || 0}</span>
                                                <span>
                                                    Artisti
                                                </span>
                                                <RoundedButton
                                                    color="primary"
                                                    onClick={() => {
                                                        props.history.push('/admin/artists');
                                                    }}>
                                                    VEDI ARTISTI
                                                </RoundedButton>
                                            </div>
                                        </Col>
                                        <Col sm="4">
                                            <div className="stats mb-5">
                                                <span className="mt-5">{stats.scouts || 0}</span>
                                                <span>Scouter</span>
                                                <RoundedButton
                                                    color="primary"
                                                    onClick={() => {
                                                        props.history.push('/admin/scouts');
                                                    }}>
                                                    VEDI SCOUTER
                                                </RoundedButton>
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                                : null)}
                </Col>
            </Row>
        </Container>
    }</Page>;
};

const mapStateToProps = (state) => {
    return {
        user: state.Auth.user
    };
};

export default connect(mapStateToProps, null)(Dashboard);
