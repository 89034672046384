import { Capacitor } from '@capacitor/core';

import Auth from './Auth/Auth';
import Login from './Auth/Login/Login';
import Register from './Auth/Register/Register';
import Logout from './Auth/Logout';

import Home from './Public/Home/Home';
import PublicDiscovery from './Public/Discovery/Discovery';
import Scouts from './Public/Scouts/Scouts';
import ScoutProfile from './Public/Scouts/ScoutProfile';
import ArtistProfile from './Public/Artists/ArtistProfile';
import Blog from './Public/Blog/Blog';
import Article from './Public/Blog/Article/Article';
import FAQ from './Public/FAQ/FAQ';
import Consent from './Public/Policies/Consent';
import Terms from './Public/Policies/Terms';
import PrivacyPolicy from './Public/Policies/PrivacyPolicy';

// Dashboard
import Dashboard from './Dashboard/Dashboard';
import Help from './Help/Help';
import Chats from './Chats/Chats';
import Search from './Search/Search';
import Discovery from './Discovery/Discovery';
import Rewards from './Rewards/Rewards';
import Settings from './Settings/Settings';
import Tracks from './Tracks/Tracks';
import Playlists from './Playlists/Playlists';
import Profile from './Profile/Profile';
import Notifications from './Notifications/Notifications';
import Wallet from './Wallet/Wallet';

// Admin
import AdminDashboard from './Admin/AdminDashboard';
import AdminDiscounts from './Admin/AdminDiscounts';
import AdminArticles from './Admin/AdminArticles';
import AdminOrders from './Admin/AdminOrders';
import AdminArtists from './Admin/AdminArtists';
import AdminScouts from './Admin/AdminScouts';
import AdminPayouts from './Admin/AdminPayouts';
import ForgetPwd from './Auth/ForgetPwd/ForgetPwd';
import ResetPwd from './Auth/ResetPwd/ResetPwd';

const platform = Capacitor.getPlatform();

const publicRoutes = [
	{
		path: '/',
		exact: true,
		component: platform === 'web' ? Home : Auth
	},
	{
		path: '/public-discovery',
		exact: true,
		component: PublicDiscovery
	},
	{
		path: '/artists/:id',
		exact: true,
		component: ArtistProfile
	},
	{
		path: '/scouts',
		exact: true,
		component: Scouts
	},
	{
		path: '/scouts/:id',
		exact: true,
		component: ScoutProfile
	},
	{
		path: '/blog/:id',
		exact: true,
		component: Article
	},
	{
		path: '/blog',
		exact: true,
		component: Blog
	},
	{
		path: '/faq',
		exact: true,
		component: FAQ
	},
	{
		path: '/auth',
		exact: true,
		component: Auth, 
		noHeader: true, 
		noFooter: true
	},
	{
		path: '/forgot-pwd',
		exact: true,
		component: ForgetPwd, 
		noHeader: true, 
		noFooter: true
	},
	{
		path: '/reset-pwd',
		exact: true,
		component: ResetPwd, 
		noHeader: true, 
		noFooter: true
	},
	{
		path: '/login',
		exact: true,
		component: Login, 
		noHeader: true, 
		noFooter: true
	},
	{
		path: '/register',
		exact: true,
		component: Register, 
		noHeader: true, 
		noFooter: true
	},
	{
		path: '/logout',
		exact: true,
		component: Logout, 
		noHeader: true, 
		noFooter: true
	},


	{
		path: '/consent',
		exact: true,
		component: Consent,
	},
	{
		path: '/terms',
		exact: true,
		component: Terms,
	},
	{
		path: '/privacy-policy',
		exact: true,
		component: PrivacyPolicy,
	},
];

const protectedRoutes = [
	{
		path: '/users/:id',
		exact: true,
		component: Profile,
		title: 'Profilo'
	},

	{
		path: '/dashboard',
		exact: true,
		component:
			Dashboard,
			title: 'Dashboard'
	},

	{
		path: '/discovery',
		exact: true,
		component:
			Discovery,
			title: 'HitDiscovery'
	},

	{
		path: '/search',
		exact: true,
		component:
			Search,
			title: 'HitResearch'
	},

	{
		path: '/rewards',
		exact: true,
		component:
			Rewards,
			title: 'Rewards'
	},

	{
		path: '/tracks/:id',
		exact: true,
		component: Tracks,
		title: 'I tuoi brani'
	},
	{
		path: '/tracks',
		exact: true,
		component: Tracks,
		title: 'I tuoi brani'
	},

	{
		path: '/playlists',
		exact: true,
		component: Playlists,
		title: 'Playlist'
	},

	{
		path: '/chats',
		exact: true,
		component: Chats,
		title: 'Messaggi'
	},

	{
		path: '/notifications',
		exact: true,
		component: Notifications,
		title: 'Notifiche'
	},

	{
		path: '/settings',
		exact: true,
		component: Settings,
		title: 'Impostazioni',
		showBack: true
	},
	{
		path: '/settings/account',
		exact: true,
		component: Settings,
		title: 'Account',
		showBack: true
	},
	{
		path: '/settings/contents',
		exact: true,
		component: Settings,
		title: 'Contenuti',
		showBack: true
	},
	{
		path: '/settings/payments',
		exact: true,
		component: Settings,
		title: 'Pagamenti',
		showBack: true
	},
	{
		path: '/settings/upload-track',
		exact: true,
		component: Settings,
		title: 'Carica Brano',
		showBack: true
	},

	{
		path: '/wallet',
		exact: true,
		component: Wallet,
		title: 'Portafoglio'
	},

	{
		path: '/wallet/:id',
		exact: true,
		component: Wallet,
		title: 'Portafoglio'
	},

	{
		path: '/help',
		exact: true,
		component: Help,
		title: 'Aiuto'
	},

	//admin router

	{
		path: '/admin/articles',
		exact: true,
		component: AdminArticles
	},
	{
		path: '/admin/discounts',
		exact: true,
		component: AdminDiscounts
	},
	{
		path: '/admin/orders',
		exact: true,
		component: AdminOrders
	},
	{
		path: '/admin/tracks',
		exact: true,
		component: Tracks
	},
	{
		path: '/admin/payouts',
		exact: true,
		component: AdminPayouts
	},
	{
		path: '/admin/scouts',
		exact: true,
		component: AdminScouts
	},
	{
		path: '/admin/artists',
		exact: true,
		component: AdminArtists
	},
	{
		path: '/admin/',
		exact: true,
		component: AdminDashboard
	},
];

export {
	protectedRoutes, 
	publicRoutes 
};
