import React, { useState, useEffect } from 'react';
import {
	Button,
	ModalFooter,
	ModalBody,
	Modal,
	Col,
	Row,
	Alert,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Form,
	Label,
	Input
} from 'reactstrap';

import Loader from '../../Common/Loader';
import RoundedButton from '../../Buttons/RoundedButton/RoundedButton';

import { ReactComponent as ExpandIcon } from '../../../assets/images/expand.svg';

import { loadUserPlaylists, createPlaylist, addTrackToPlaylist } from '../../../helpers/api/playlists.api';

import '../Modals.scss';
import './SaveTrackModal.scss';

const SaveTrackModal = (props) => {
	const [playlists, setPlaylists] = useState([]);

	const [creating, setCreating] = useState(false);
	const [creatingLoading, setCreatingLoading] = useState(false);
	const [creatingError, setCreatingError] = useState(null);

	const [selectedPlaylist, setSelectedPlaylist] = useState(null);

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);

	useEffect(() => {
		if (props.isOpen)
			load();
	}, [props.isOpen]);

	function load() {
		setLoading(true);
		loadUserPlaylists()
			.then((result) => {
				setLoading(false);
				if (result.success)
					setPlaylists(result.playlists);
				else
					setError(result.message);

			})
			.catch((error) => {
				setLoading(false);
				setError(error.message);
			});
	}

	function handleOnCreatePlaylist(event) {
		event.preventDefault();
		setCreatingError(null);
		setCreatingLoading(true);
		createPlaylist(event.target['title'].value)
			.then(result => {
				if (result.success) {
					setCreating(false);
					setCreatingError(null);
					load();
				} else
					setCreatingError(result.message);
				setCreatingLoading(false);
			})
			.catch((error) => {
				setCreatingError(error);
				setCreatingLoading(false);
			});
	}

	function handleOnSubmit() {
		setLoading(true);
		addTrackToPlaylist(selectedPlaylist._id, props.track._id)
			.then((result) => {
				setLoading(false);
				if (result.success) {
					setSuccess(result.message);
					setTimeout(() => {
						props.onSuccess && props.onSuccess();
						handleOnClose();
					}, 2000);
				} else {
					setError(result.message);
				}
			})
			.catch((error) => {
				setLoading(false);
				setError(error.message);
			});
	}

	function handleOnClose() {
		props.onClose && props.onClose();
		setError(null);
		setSuccess(null);
	}

	return (
		<Modal
			isOpen={props.isOpen}
			role="dialog"
			autoFocus={true}
			className="save-track-modal"
			centered={true}
			tabIndex="-1"
			toggle={handleOnClose}>
			<ModalBody>
				{loading ? (
					<Loader className="p-5" />
				) : error ? (
					<Alert className="mb-4" color="danger">
						{error}
					</Alert>
				) : success ? (
					<Alert className="mb-4" color="success">
						{success}
					</Alert>
				) : (
					<Row>
						<Col sm="10">

							<h6>Aggiungi a una playlist:</h6>
							<p className="mb-3"><b>{props.track.title}</b>{' di ' + (props.track.author ? props.track.author.name : 'Utente eliminato')}</p>

							<UncontrolledDropdown>
								<DropdownToggle className={'w-100 mb-4 ' + (selectedPlaylist ? 'choosen' : '')}>
									<p>{selectedPlaylist ? selectedPlaylist.title : 'Scegli una playlist...'}</p>
									<span
										color="link"
										className="expand-button d-inline">
										<ExpandIcon />
									</span>
								</DropdownToggle>
								<DropdownMenu>
									<DropdownItem
										key={'0'}
										onClick={() => setSelectedPlaylist(null)}>
										{' - - - - - '}
									</DropdownItem>
									{playlists
										.map((p) => (
											<DropdownItem
												key={p._id}
												onClick={() => setSelectedPlaylist(p)}>
												{p.title}
											</DropdownItem>
										))}
								</DropdownMenu>
							</UncontrolledDropdown>


							{!creating ? <RoundedButton className="mb-5 outline" onClick={() => {

								setCreatingError(null); setCreating(true);
							}}>
								Crea Nuova Playlist
							</RoundedButton>
								: creatingLoading ?
									<Loader />
									: <Form onSubmit={handleOnCreatePlaylist}>
										<Label htmlFor="title">Titolo nuova playlist</Label>
										<Input type="text" id="title" name="title" />
										<RoundedButton color="primary" type="submit">
											Crea
										</RoundedButton>
										<p className="error">{creatingError}</p>
									</Form>}
						</Col>
					</Row>)}
			</ModalBody>
			{!loading && !error && !success &&
				<ModalFooter>
					<RoundedButton
						className="waves-effect px-5 mb-3"
						type="button"
						color="primary"
						onClick={handleOnSubmit}>
						Salva
					</RoundedButton>
				</ModalFooter>}
		</Modal>
	);
};

export default SaveTrackModal;
