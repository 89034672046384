import { REHYDRATE } from 'redux-persist/lib/constants';
import {
    LOAD_TRACKS_TO_VOTE,
    SET_TRACKS_TO_VOTE,
    PUSH_TO_TRACKS_TO_VOTE,
    PULL_FROM_TRACKS_TO_VOTE
} from './actionTypes';

const initialState = {
    toVote: []
}

const tracks = (state = initialState, action) => {
    switch (action.type) {
        case REHYDRATE: {
            if (action.payload)
                state = {
                    ...state,
                    toVote: action.payload.toVote || []
                };
            else
                state = {
                    ...state
                };
            break;
        }
        case LOAD_TRACKS_TO_VOTE: {
            state = { ...state }
            break;
        }
        case SET_TRACKS_TO_VOTE: {
            let newState = { ...state }
            newState.toVote = action.payload.tracks;
            state = { ...newState }
            break;
        }
        case PULL_FROM_TRACKS_TO_VOTE: {
            let newState = { ...state }
            newState.toVote = newState.toVote.filter(tv => tv !== action.payload.trackId);
            state = { ...newState }
            break;
        }
        case PUSH_TO_TRACKS_TO_VOTE: {
            let newState = { ...state }
            newState.toVote = newState.toVote.filter(tv => tv === action.payload.trackId);
            newState.toVote.push(action.payload.trackId);
            state = { ...newState }
            break;
        }
        default: {
            state = { ...state };
            break;
        }
    }
    return state;
}

export default tracks;