import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { ModalBody, Modal, Alert } from 'reactstrap';

import Loader from '../../Common/Loader';
import MessageForm from '../../Forms/Messages/MessageForm';

import { sendTextMessage } from '../../../helpers/api/chats.api';

import '../Modals.scss';

const MessageModal = (props) => {

    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);

    const to = useSelector(state => state.Layout.messageUser);

    async function handleOnSendMessage(message) {
        setSuccess('');
        setError('');
        setLoading(true);
        try {
            sendTextMessage(null, [to._id], message)
            .then(result => {
                setLoading(false);
                if (result.success) {
                    setSuccess(result.message);
                } else {
                    setError(result.message);
                }
                setTimeout(() => {
                    handleOnClose();
                }, 2000);
            })
        } catch (error)  {
            setLoading(false);
            setSuccess('');
            setError(error.message);
        
            setTimeout(() => {
                handleOnClose();
            }, 2000);
        }
    };

    function handleOnClose() {
        setSuccess('');
        setError('');
        setLoading(false);
        if (props.onClose) props.onClose();
    }

    return (
        <Modal
            className="message-modal"
            isOpen={props.isOpen}
            autoFocus={true}
            centered={true}
            backdrop={true}
            toggle={handleOnClose}>
            <ModalBody className='pb-4'>
                {loading ?
                    <Loader />
                    : error ? (
                        <Alert className="mb-0" color="danger">
                            {error}
                        </Alert>
                    ) : success ? (
                        <Alert className="mb-0" color="success">
                            {success}
                        </Alert>
                    ) :
                        <MessageForm to={to && to.name} onSubmit={handleOnSendMessage} />}
            </ModalBody>
        </Modal>
    );
};

export default MessageModal;
