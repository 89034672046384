import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Table, Alert, Button } from 'reactstrap';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';
import ObjectID from 'bson-objectid';

import Loader from '../../components/Common/Loader';
import Page from '../../components/Layout/Page/Page';
import RoundedButton from '../../components/Buttons/RoundedButton/RoundedButton';

import './Rewards.scss';

import { ReactComponent as RewardIcon } from '../../assets/images/reward.svg';
import { ReactComponent as DownloadIcon } from '../../assets/images/download.svg';

import { loadRewards } from '../../helpers/api/rewards.api';

const Rewards = (props) => {

    const MOBILE_AND_TABLET = useMediaQuery({
        query: "(max-device-width: 768px)",
    });

	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [rewards, setRewards] = useState([]);

	useEffect(() => {
		setLoading(true);
		loadRewards()
			.then((result) => {
				if (result.success) setRewards(result.rewards);
				else setError(result.message);
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				console.log(error);
			});
	}, []);

	function handleOnDownload(url) {
		let name = url.split('/').pop();
		var link = document.createElement("a");
		link.download = name;
		link.href = url;
		link.target = "_blank";
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	return <Page className="rewards">
			<Container >
				<Row className="mb-lg-5">
					<Col lg="11">
						<RewardIcon className="title-icon" />
						{loading ? (
							<Loader className="pt-5" />
						) : error ? (
							<Alert className="mb-0" color="danger">
								{error}
							</Alert>
						) : (
							<>
								<h3>
									Hai ricevuto <span>{rewards.length}</span> Rewards
								</h3>
								<div className="rewards-table-container">
									<Table className="rewards-table" responsive borderless striped>
										<tbody>
											{rewards.map((r) => (
												<tr key={r._id}>
													<td>
														<p className="mb-0">
															{MOBILE_AND_TABLET ? 
															moment(ObjectID(r._id).getTimestamp()).format('DD MMM YYYY')
															: moment(ObjectID(r._id).getTimestamp()).format('DD/MM/YYYY')}
														</p>
													</td>
													<td>
														<p
															className="mb-0"
														>
															<b>{'"' + r.description + '"'}</b>
															{' per il tuo brano '}
															<b>{'"' + r.track.title + '"'}</b>
														</p>
													</td>
													<td>
														{r.attachment ? <>
															{MOBILE_AND_TABLET ? <Button
																color='link'
																className={'download-button p-0'}
																onClick={() => handleOnDownload(r.attachment.url)}>
																<DownloadIcon />
															</Button> : <RoundedButton
																color="primary"
																className={'download-button'}
																onClick={() => handleOnDownload(r.attachment.url)}>
																SCARICA
															</RoundedButton>}
														</> : null}
													</td>
												</tr>
											))}
										</tbody>
									</Table>
								</div>
							</>
						)}
					</Col>
				</Row>
			</Container>
	</Page>;
};

export default Rewards;
