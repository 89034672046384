import React, { useEffect, useState } from 'react';
import { Button, Alert } from 'reactstrap';
import { useMediaQuery } from 'react-responsive';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Loader from '../../Common/Loader';

import { default as blankUser } from '../../../assets/images/user.svg';
import { ReactComponent as Next } from '../../../assets/images/next.svg';
import { ReactComponent as Prev } from '../../../assets/images/prev.svg';

import { loadArtists } from '../../../helpers/api/users.api';

import 'swiper/swiper-bundle.min.css';

import '../Sliders.scss';

function isNearestSlide(slideIndex, activeIndex) {
    const indexBefore = activeIndex - 1;
    const indexAfter = activeIndex + 1;
    return slideIndex === indexBefore || slideIndex === indexAfter;
}

const LatestSlider = props => {
	const MOBILE_AND_TABLET = useMediaQuery({
		query: '(max-device-width: 768px)'
	});
    const [loadingLatest, setLoadingLatest] = useState(false);
    const [latest, setLatest] = useState([]);
    const [latestError, setLatestError] = useState(null);
    const [latestSwiper, setLatestSwiper] = useState(null);
    const [latestActiveSlide, setLatestActiveSlide] = useState(2);

    useEffect(() => {
        setLoadingLatest(true);
        loadArtists(0, 'latest')
            .then((result) => {
                if (result.success) setLatest(result.users);
                else setLatestError(result.message);
                setLoadingLatest(false);
            })
            .catch((error) => {
                setLoadingLatest(false);
                setLatestError(error.message);
            });
    }, []);

    return loadingLatest ? (
        <Loader className="pt-5" />
    ) : latestError ? (
        <Alert className="mb-0" color="danger">
            {latestError}
        </Alert>
    ) : latest && Array.isArray(latest) ? (
        <>
            <Swiper
                className="latest-slider"
                modules={[Navigation, Pagination]}
                spaceBetween={0}
                slidesPerView={3}
				slidesOffsetBefore={0}
				slidesOffsetAfter={0}
                loop
				breakpoints={{
					768: {
						slidesPerView: 5,
						slidesOffsetBefore: 0,
						slidesOffsetAfter: 0,
						spaceBetween: 50
					}
				}}
                navigation={{
                    nextEl: '.latest-slider .swiper-button-next',
                    prevEl: '.latest-slider .swiper-button-prev'
                }}
                centeredSlides={true}
                initialSlide={latestActiveSlide}
                pagination={{
                    el: '.latest-slider .swiper-pagination',
                    type: 'bullets'
                }}
                onSwiper={(swiper) => setLatestSwiper(swiper)}
                onSlideChange={({ realIndex }) =>
                    setLatestActiveSlide(realIndex)
                }>
                {latest.map((t, i) => (
                    <SwiperSlide key={t._id} onClick={() => props.onSlideClick && props.onSlideClick(t)}>
                        <div className="profile-pic-container">
                            <div
                                className="profile-pic"
                                style={{
                                    backgroundColor: t.image ? '' : '#ffffff',
                                    backgroundImage: t.image ? 'url(' + t.image.url + ')' : 'url(' + blankUser + ')',
                                    backgroundPosition: t.image ? 'center' : 'bottom',
                                    backgroundSize: t.image ? 'cover' : '80%',
                                    backgroundRepeat: 'no-repeat',
                                    width:
									i === latestActiveSlide && !MOBILE_AND_TABLET
										? '120px'
										:
										i === latestActiveSlide && MOBILE_AND_TABLET
											? '100px'
                                            : isNearestSlide(
                                                i,
                                                latestActiveSlide
                                            ) && !MOBILE_AND_TABLET
                                                ? '80px'
                                                :
												isNearestSlide(
													i,
													latestActiveSlide
												) && MOBILE_AND_TABLET
												?
												'60px'
                                                : '40px',
                                    height:
                                        i === latestActiveSlide && !MOBILE_AND_TABLET
                                            ? '120px'
											:
											i === latestActiveSlide && MOBILE_AND_TABLET
												? '100px'
                                            : isNearestSlide(
                                                i,
                                                latestActiveSlide
                                            ) && !MOBILE_AND_TABLET
                                                ? '80px'
                                                :
												isNearestSlide(
													i,
													latestActiveSlide
												) && MOBILE_AND_TABLET
												?
												'60px'
												:
												'40px'
                                }}>
                                {' '}
                            </div>
                        </div>
                        {/* TODO fix realIndex with index multiple of length */}
                        <div className={'name-container'}>
                            {isNearestSlide(i, latestActiveSlide) ||
                                i === latestActiveSlide ? (
                                <b>{t.name}</b>
                            ) : null}
                            {i === latestActiveSlide && !MOBILE_AND_TABLET ? <span>{t.genre}</span> : null}
                        </div>
                    </SwiperSlide>
                ))}
                <Button
                    className="swiper-button-next"
                    color="link"
                    onClick={(event) =>
                        latestSwiper.slideToLoop(
                            latestActiveSlide + 1,
                            200,
                            null
                        )
                    }>
                    <Next width={MOBILE_AND_TABLET ? 20 : 40} height={MOBILE_AND_TABLET ? 20 : 40} />
                </Button>
                <Button
                    className="swiper-button-prev"
                    color="link"
                    onClick={(event) =>
                        latestSwiper.slideToLoop(
                            latestActiveSlide - 1,
                            200,
                            null
                        )
                    }>
                    <Prev width={MOBILE_AND_TABLET ? 20 : 40} height={MOBILE_AND_TABLET ? 20 : 40} />
                </Button>
            </Swiper>
            <div className="swiper-pagination mt-2 mt-lg-0">
                {latest.map((t, i) => (
                    <Button
                        key={'bullet_' + t._id}
                        onClick={(event) =>
                            latestSwiper.slideToLoop(i, 200, null)
                        }
                        className={
                            'swiper-pagination-bullet mx-1 ' +
                            (i === latestActiveSlide
                                ? 'swiper-pagination-bullet-active'
                                : '')
                        }/>
                ))}
            </div>
        </>
    ) : latest;
}

export default LatestSlider;