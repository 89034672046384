import { REHYDRATE } from 'redux-persist/lib/constants';
import {
    LOAD_UNREAD_NOTI,
    LOAD_NOTI,
    LOAD_NOTI_ERROR,
    LOAD_NOTI_SUCCESS,
    NEW_NOTIFICATION,
    SET_READ
} from './actionTypes';

const initialState = {
    loading: false,
    error: null,
    // selectedChat: null, // chat id
    list: [], // array of chat obj
    // unreadChats: [] // array of chat ids
}

const noti = (state = initialState, action) => {
    switch (action.type) {
		case REHYDRATE: {
			if (action.payload)
				state = {
					...state,
					...action.payload.Notifications
				};
			else
				state = {
					...state
				};
			break;
            }
        case SET_READ: {
            let newList = [...state.list];
            newList.map(n => { if (n._id === action.payload.notiId) n.read = true; return n;});
            state = {
                ...state,
                list: newList
            }
            break;
        }
        case LOAD_UNREAD_NOTI: {
            state = {
                ...initialState
            }
            break;
        }
        case LOAD_NOTI: {
            state = {
                ...initialState,
                loading: true
            }
            break;
        }
        case LOAD_NOTI_SUCCESS: {
            state = {
                ...state,
                loading: false,
                list: action.payload.notifications,
            }
            break;
        }
        case LOAD_NOTI_ERROR: {
            state = {
                ...state,
                loading: false,
                error: action.payload
            }
            break;
        }
        case NEW_NOTIFICATION: {
            let notifications = [...state.list],
            newNoti = action.payload && action.payload.notification;
            notifications.push(newNoti);
                                
            state = {
                ...state,
                list: notifications
            }
            break;
        }
        
        default: {
            state = { ...state };
            break;
        }
    }
    return state;
}

export default noti;