import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Container, Button } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

import Page from '../../components/Layout/Page/Page';
import RoundedButton from '../../components/Buttons/RoundedButton/RoundedButton';
import TrackWave from '../../components/Tracks/TrackWave/TrackWave';
import TrackRow from '../../components/Tracks/TrackRow/TrackRow';
import Loader from '../../components/Common/Loader';
import CommentTrackModal from '../../components/Modals/Tracks/CommentTrackModal';
import VoteTrackModal from '../../components/Modals/Tracks/VoteTrackModal';
import SaveTrackModal from '../../components/Modals/Tracks/SaveTrackModal';
import RewardTrackModal from '../../components/Modals/Tracks/RewardTrackModal';
import PlayButton from '../../components/Buttons/PlayButton/PlayButton';
import TrackCountdown from '../../components/Tracks/TrackCountdown/TrackCountdown';

import { ReactComponent as RewardsIcon } from '../../assets/images/reward.svg';
import { ReactComponent as CheckedIcon } from '../../assets/images/checkbox-checked.svg';
import { ReactComponent as UncheckedIcon } from '../../assets/images/checkbox.svg';

import { loadTracks, loadTrackComments, loadTrackVotings, loadTrack, updateTrackPublic, deleteTrack } from '../../helpers/api/tracks.api';

import { ReactComponent as TrashIcon } from '../../assets/images/trash.svg';

import { play, pause, select, setTracksToVote } from '../../store/actions';

import './Tracks.scss';

const MAX_VOTINGS = 3;

const Tracks = (props) => {
	const [loading, setLoading] = useState(true);
	const [loadingTrack, setLoadingTrack] = useState(true);

	const [tracks, setTracks] = useState([]);
	const [expiredTracks, setExpiredTracks] = useState([]);

	const [trackId, setTrackId] = useState(null);
	const [track, setTrack] = useState(null);

	const [loadingComments, setLoadingComments] = useState(true);
	const [trackComments, setTrackComments] = useState([]);

	const [loadingVotings, setLoadingVotings] = useState(true);
	const [trackVotings, setTrackVotings] = useState([]);

	const [time, setTime] = useState(null);

	const [votingEnabled, setVotingEnabled] = useState(false);

	const [makingAction, setMakingAction] = useState('');

	const [deletingLoading, setDeletingLoading] = useState(false);
	const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);

	useEffect(() => {
		if (props.location.pathname) {
			const id = props.location.pathname.split('/')[props.location.pathname.split('/').length - 1];
			if (id && id !== 'tracks') setTrackId(id);
		}
	}, [props.location.pathname]);

	useEffect(() => {
		if (!trackId) return;
		setLoading(true);
		loadTrack(trackId)
			.then((result) => {
				console.log(track);
				if (result.success) {
					setTrack(result.track);
				} else {
					setTrack(null);
				}
			})
			.catch((error) => {
				setTrack(null);
				console.log(error);
			});
	}, [trackId]);

	useEffect(() => {
		props.setTracksToVote([]);
	}, []);

	useEffect(() => {
		load();
	}, [props.user]);

	useEffect(() => {
		if (tracks && !trackId) setTrack(tracks[0]);
	}, [tracks]);

	useEffect(() => {
		setVotingEnabled(false);
		if (track) {
			loadComments();
			loadVotings();
		}
	}, [track]);

	useEffect(() => {
		setTrack(props.player.track);
	}, [props.player.track]);

	function load() {
		setLoading(true);
		if (props.user) {
			if (props.user.type === 'artist') loadArtistTracks();
			else loadAllTracks();
		}
	}

	function loadArtistTracks() {
		setLoading(true);
		loadTracks(props.user._id)
			.then((result) => {
				if (result.success) {
					setTracks(result.tracks);
				} else {
					console.log(result.message);
					setTracks([]);
				}
				setLoading(false);
			})
			.catch((error) => {
				setTracks([]);
				setLoading(false);
				console.log(error);
			});
	}

	async function loadAllTracks() {
		try {
			setLoading(true);
			let result = await loadTracks();
			if (result.success) {
				setTracks(result.tracks);
				setExpiredTracks(result.expiredTracks);
			} else {
				console.log(result.message);
				setTracks([]);
				setExpiredTracks([]);
			}
			setLoading(false);
		} catch (error) {
			setTracks([]);
			setExpiredTracks([]);
			setLoading(false);
			console.log(error);
		}
	}

	function loadComments() {
		if (track._id) {
			setLoadingComments(true);
			loadTrackComments(track._id)
				.then((result) => {
					if (result.success) {
						setTrackComments(result.comments);
					} else {
						console.log(result.message);
						setTrackComments([]);
					}
					setLoadingComments(false);
				})
				.catch((error) => {
					setTrackComments([]);
					console.log(error);
					setLoadingComments(false);
				});
		}
	}

	function loadVotings() {
		if (track._id) {
			setLoadingVotings(true);
			loadTrackVotings(track._id)
				.then((result) => {
					if (result.success) {
						setTrackVotings(result.votings);
					} else {
						console.log(result.message);
						setTrackVotings([]);
					}
					setLoadingVotings(false);
				})
				.catch((error) => {
					setTrackVotings([]);
					console.log(error);
					setLoadingVotings(false);
				});
		}
	}

	function handleOnPublicCheckboxClick() {
		let newStatus = !track.public;
		setTrack({
			...track,
			public: newStatus,
		});

		updateTrackPublic(track._id, newStatus)
			.then((result) => {
				if (!result.success) {
					setTrack({
						...track,
						public: !newStatus,
					});
				}
			})
			.catch((error) => {
				setTrack({
					...track,
					public: !newStatus,
				});
				console.log(error);
			});
	}

	function handleOnDeleteTrack() {
		setDeleteConfirmModal(false);
		if (track) {
			setDeletingLoading(true);
			deleteTrack(track._id)
				.then((result) => {
					if (!result.success) console.log(result.message);
					setDeletingLoading(false);
					load();
				})
				.catch((error) => {
					console.log(error.message);
					setDeletingLoading(false);
				});
		}
	}

	const alreadyVoted = track && track.votings ? track.votings.map(x => x.voter).includes(props.user._id.toString()) : false;
	const alreadyMaxVotings = track && track.votings ? track.votings.length >= MAX_VOTINGS : false;
	const authorId = track && track.author ? track.author._id : undefined;

	const minutes = Math.floor(time / 60000);
	const seconds = ((time % 60000) / 1000).toFixed(0);

	return (
		<Page className="tracks">
			<Container fluid className="h-100 d-flex flex-column">
				<Row className="pb-0">
					<Col className="h-100 d-flex flex-column justify-content-between">
						{loading ? (
							<Loader />
						) : track ? (
							<>
								<Row>
									<Col sm="8" className="d-flex align-items-center">
										<div className="d-flex flex-column mr-5">
											<h6>
												<i>{props.user._id === authorId ? 'I tuoi brani' : 'Brani'}</i>
											</h6>
											<h4 className="mb-0">{track.title}</h4>
											<h6>{track.author ? track.author.name : 'Utente eliminato'}</h6>
										</div>
										{/* <PlayButton playing={playing} onClick={() => setPlaying(!playing)} /> */}
										{loadingTrack ? null : <PlayButton
											playing={props.player.playing}
											onClick={() => (!props.player.playing ? props.play() : props.pause())}
										/>}
										{props.user._id === authorId ? (
											<div className="d-none d-lg-flex align-items-center justify-content-end h-100 w-50 ml-4">
												<label className="mr-2 mb-0">Brano visibile sul tuo profilo</label>
												<Button
													className="public-checkbox"
													color="link"
													onClick={handleOnPublicCheckboxClick}
												>
													{track.public ? <CheckedIcon /> : <UncheckedIcon />}
												</Button>
											</div>
										) : null}
									</Col>
									<Col sm="4" className="d-none d-lg-flex align-items-center justify-content-end">
										{props.user._id === authorId ? (
											<div className="d-flex align-items-center justify-content-end">
												<p className="track-points">
													{track.average || 0}
													{' punti'}
												</p>
												<p className="track-rewards">
													<RewardsIcon />
													{track.rewards ? track.rewards.length : 0}
													{' Rewards'}
												</p>
												{deletingLoading ? (
													<Loader className="ml-2" size="sm" />
												) : props.user._id === authorId ||
												  (props.user && props.user.type === 'admin') ? (
													<>
														<Button
															className={'delete-button ml-3 ml-lg-3 mr-0 mr-lg-0 '}
															color="link"
															disabled={track.votings.length > 0}
															onClick={(event) => {
																event.preventDefault();
																event.stopPropagation();
																setDeleteConfirmModal(true);
															}}
														>
															<TrashIcon />
														</Button>
													</>
												) : null}
											</div>
										) : (
											<>
												{props.user.type !== 'artist' ? (
													<TrackCountdown targetDate={new Date(track.expiresAt).getTime()} />
												) : null}
												{track.author ? (
													<RoundedButton
														color="primary"
														className="px-3 ml-3"
														onClick={() => props.history.push('/users/' + authorId)}
													>
														VISUALIZZA Profilo
													</RoundedButton>
												) : null}
											</>
										)}
									</Col>
								</Row>
								<div className="wave-wrapper pt-lg-4 pb-0">
									<TrackWave
										track={track}
										comments={trackComments}
										playing={props.player.playing}
										onLoading={() => {
											setLoadingTrack(true);
											setTime(0);
										}}
										onReady={() => {
											setLoadingTrack(false);
											setTime(0);
										}}
										onFinish={() => (props.pause())}
										percToReach={0.3}
										onPercReached={() => setVotingEnabled(true)}
										onPlaying={(milliseconds) => setTime(milliseconds)}
									/>
								</div>
								<Row>
									{props.user.type === 'artist' && (
										<Col className="d-flex d-lg-none align-items-center">
											{props.user._id === authorId ? (
												<div className="w-100 d-flex align-items-center justify-content-between">
													<p className="track-points">
														{track.average || 0}
														{' punti'}
													</p>
													<p className="track-rewards">
														<RewardsIcon />
														{track.rewards ? track.rewards.length : 0}
														{' Rewards'}
													</p>
													{deletingLoading ? (
														<Loader className="ml-2" size="sm" />
													) : props.user._id === authorId ||
													  (props.user && props.user.type === 'admin') ? (
														<Button
															className="delete-button ml-3 ml-lg-3 mr-0 mr-lg-0"
															color="link"
															disabled={track.votings.length > 0}
															onClick={(event) => {
																event.preventDefault();
																event.stopPropagation();
																setDeleteConfirmModal(true);
															}}
														>
															<TrashIcon />
														</Button>
													) : null}
												</div>
											) : track.author ? (
												<RoundedButton
													color="primary"
													className="px-3"
													onClick={() => props.history.push('/users/' + authorId)}
												>
													VISUALIZZA Profilo
												</RoundedButton>
											) : null}
										</Col>
									)}
								</Row>
								{props.user.type === 'scout' && (
									<Row>
										<Col className="mb-4" xs="12" lg="3">
											<RoundedButton
												onClick={() => setMakingAction('comment')}
												className="px-lg-2 w-100"
											>
												{'COMMENTA A ' + minutes + ':' + (seconds < 10 ? '0' : '') + seconds}
											</RoundedButton>
										</Col>
										<Col className="mb-4" xs="12" lg="3">
											<RoundedButton
												id="vote-btn"
												onClick={alreadyMaxVotings || alreadyVoted || !votingEnabled ? null : () => setMakingAction('vote')}
												className="px-lg-5 w-100"
												style={{ opacity: alreadyMaxVotings || alreadyVoted || !votingEnabled ? 0.5 : 1 }}
											>
												ASSEGNA VOTO
											</RoundedButton>
											{alreadyMaxVotings ? (
												<small className="d-block text-center">
													{'Questo brano ha già raggiunto ' + MAX_VOTINGS + ' voti.'}
												</small>
											) : alreadyVoted ? (
												<small className="d-block text-center">
													{'Hai votato questo brano.'}
												</small>
											) : !votingEnabled ? (
												<small className="d-block text-center">
													{'Ascolta almeno il 30% del brano.'}
												</small>
											) : null}
										</Col>
										<Col className="mb-4" xs="12" lg="3">
											<RoundedButton
												onClick={() => setMakingAction('save')}
												className="px-lg-5 w-100"
											>
												SALVA IN PLAYLIST
											</RoundedButton>
										</Col>
										<Col className="mb-4" xs="12" lg="3">
											<RoundedButton
												onClick={() => setMakingAction('reward')}
												className="px-lg-5 w-100"
											>
												REWARD
											</RoundedButton>
										</Col>
									</Row>
								)}
								<CommentTrackModal
									isOpen={makingAction === 'comment'}
									track={track}
									time={time}
									onClose={() => setMakingAction('')}
									onSuccess={() => loadComments()}
								/>
								<VoteTrackModal
									isOpen={makingAction === 'vote'}
									track={track}
									onClose={() => setMakingAction('')}
									onSuccess={() => load()}
								/>
								<SaveTrackModal
									isOpen={makingAction === 'save'}
									track={track}
									onClose={() => setMakingAction('')}
								/>
								<RewardTrackModal
									isOpen={makingAction === 'reward'}
									track={track}
									onClose={() => setMakingAction('')}
								/>
							</>
						) : null}
					</Col>
				</Row>

				{(loading || !track) ? null : loadingVotings ? <Loader size="sm" /> : (
					<Row className="pt-0 pb-0 pb-lg-3">
						<Col className="h-100">
							<div className='d-flex align-items-center mt-3 mt-lg-0 mb-2'>
								<h3 className="mr-2 d-inline mb-0">{'Feedback complessivi'}</h3>
								<h3 className={'d-inline mb-0 ' + (alreadyMaxVotings? 'complete' : '')}>{trackVotings.length}</h3>
								<p className='mb-0 d-inline'>{'/' + MAX_VOTINGS}</p>
							</div>
							{/* {trackVotings && trackVotings.length > 0 ? <p>{'Il tuo brano è stato valutato da: '}
                        {trackVotings.map((tv, i) => )}</p> : null} */}
							<ul className="h-100 mb-0">
								{trackVotings && trackVotings.length > 0 ? (
										trackVotings.map((tv, i) => (
											<li key={tv._id}>
												<div>
													<h6 className="mr-5 mb-2">
														<b>
															{!tv.voter ? 'Ex Scouter' : ((tv.voter === props.user._id) || props.user.type === 'admin') ?
															tv.voter.name 
																: 'Scouter #' + (i + 1) + ' '}
														</b>
														<span>{' - Voto: '}</span>
														{tv.vote + ' su 5 - Commento: '}
														{tv.comment}
													</h6>
												</div>
											</li>
										))
									) : (
										<p className="mb-0">Nessun feedback ricevuto.</p>
									)}
							</ul>
						</Col>
					</Row>
				)}

				<Row>
					<Col className="h-100">
						{!loading && tracks.length > 0 ? (
							<ul className="tracks-list pt-3 h-50 overflow-visible">
							<h4 className="mt2 mt-lg-0 mb-4">Da valutare</h4>
								{tracks
									.map((t) => (
										<TrackRow
											key={t._id}
											track={t}
											onClick={() => {
												props.pause();
												props.select(t);
											}}
											maxVotings={MAX_VOTINGS}
											active={track ? t._id === track._id : false}
											onRenewComplete={load}
											onDeleteComplete={load}
										/>
									))}
							</ul>
						) : !loading && tracks.length === 0 ? (
							<Row className="pt-3">
								<Col className="h-100">
									{props.user.type === 'scout' ? (
										<p>
											Gli artisti non hanno caricato ancora nessun brano da valutare.
											<br />
											Resta sintonizzato, riceverai una e-mail appena potrai valutare qualcosa.
										</p>
									) : props.user.type === 'artist' ? (
										<div className="text-center">
											<p>
												Non hai caricato ancora nessun brano...
												<br />
												Cosa stai aspettando?
											</p>
											<RoundedButton
												className="px-5"
												color="primary"
												onClick={() => {
													props.history.push('/settings/upload-track');
												}}
											>
												Carica Brano
											</RoundedButton>
										</div>
									) : null}
								</Col>
							</Row>
						) : null}
						{!loading && expiredTracks.length > 0 ? (
							<ul className="tracks-list pt-0 h-50 overflow-visible">
								<h4 className="mt2 mt-lg-0 mb-4">Scaduti</h4>
								{expiredTracks
									.map((et) => (
										<TrackRow
											key={et._id}
											track={et}
											onClick={() => {
												props.pause();
												props.select(et);
											}}
											maxVotings={MAX_VOTINGS}
											active={track ? et._id === track._id : false}
											onRenewComplete={load}
											onDeleteComplete={load}
										/>
									))}
							</ul>
						) : null}
					</Col>
				</Row>
			</Container>
			{deleteConfirmModal ? (
				<SweetAlert
					title={
						'Vuoi davvero eliminare il brano selezionato? Tutti i voti, commenti, reward e ogni cosa collegata al brano verranno eliminati.'
					}
					showCancel
					confirmBtnBsStyle="primary"
					confirmBtnText="Elimina"
					cancelBtnBsStyle="secondary"
					cancelBtnText="Annulla"
					onConfirm={handleOnDeleteTrack}
					onCancel={() => setDeleteConfirmModal(false)}
				/>
			) : null}
		</Page>
	);
};

const mapStatetoProps = (state) => {
	return {
		user: state.Auth.user,
		player: state.Player,
	};
};

const mapDispatchToProps = {
	play,
	pause,
	select,
	setTracksToVote,
};

export default connect(mapStatetoProps, mapDispatchToProps)(Tracks);
