import { useEffect } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { jwtDecode } from "jwt-decode";

import ProtectedLayout from '../Layout/ProtectedLayout/ProtectedLayout';

import { logoutUser } from '../../store/actions';

const ProtectedRoute = ({ component: Component, title, showBack, history, logoutUser }) => {

	useEffect(() => {
		document.title = (title ? (title + ' | ') : '') + 'HitReview';
	}, [title]);

	useEffect(() => {
		let token = localStorage.getItem('token');
		if (token) {
			token = token.replace('Bearer ', '');
			const decodedJwt = jwtDecode(token);
			if (Number(decodedJwt.exp) * 1000 > Date.now()) //se l'expiration è nel futuro continua
				return;
		}
		logoutUser(history); //altrimenti logout
	}, [history]);

	return <Route
		render={(props) => {
			//here you can apply condition
			if (!localStorage.getItem('token')) {
				return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
			}

			return <ProtectedLayout showBack={showBack} >
				<Component {...props} />
			</ProtectedLayout>;
		}}
	/>
};

const mapStatetoProps = (state) => {
	return { user: state.Auth.user };
};

export default withRouter(connect(mapStatetoProps, { logoutUser })(ProtectedRoute));
