import { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Col, Form, Button, FormGroup, Input, Label, Row, Alert, UncontrolledTooltip, Tooltip } from 'reactstrap';
import Compressor from 'compressorjs';
import SweetAlert from 'react-bootstrap-sweetalert';
import Autocomplete from 'react-google-autocomplete';
import Dropzone from 'react-dropzone';
import Select from 'react-select';

import Loader from '../../Common/Loader';
import RoundedButton from '../../Buttons/RoundedButton/RoundedButton';
import SpotifyButton from '../../Buttons/SpotifyButton/SpotifyButton';

import { ReactComponent as PlusIcon } from '../../../assets/images/plus.svg';
import { ReactComponent as RemoveIcon } from '../../../assets/images/remove.svg';
import { ReactComponent as RefreshIcon } from '../../../assets/images/refresh.svg';
import { ReactComponent as TrashIcon } from '../../../assets/images/trash.svg';

import { updateUserData } from '../../../store/actions';
import { loadUser, editUser, editUserImage, editUserSpotify, deleteUserRelease } from '../../../helpers/api/users.api';

import '../Forms.scss';

const COLLECTING_OPTS = [
	'SIAE',
	'LEA - Liberi Editori e Autori',
	'Federintermedia',
	'AFI - Associazione Fonografici Italiani',
	'Artisti 7607 Soc. Coop.',
	'Audiocoop',
	'Evolution srl',
	'Getsound srl',
	'Itsright srl',
	'Nuovo Imaie',
	'Rete Artisti Spettacolo per l’Innovazione',
	'SCF srl',
	'Soundreef Ltd',
	'Videorights srl',
];

function formatBytes(bytes, decimals = 2) {
	if (bytes === 0) return '0 Bytes';
	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

function checkDateValue(str, max) {
	if (str.charAt(0) !== '0' || str == '00') {
		var num = parseInt(str);
		if (isNaN(num) || num <= 0 || num > max) num = 1;
		str = num > parseInt(max.toString().charAt(0)) && num.toString().length == 1 ? '0' + num : num.toString();
	}
	return str;
}

const ArtistsContentsForm = (props) => {
	let imagePicker = useRef(null);
	let picsPicker = useRef(null);

	const [user, setUser] = useState(null);

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);

	const [edited, setEdited] = useState(false);

	const [loadingImage, setLoadingImage] = useState(false);

	const [loadingSpotify, setLoadingSpotify] = useState(false);
	const [spotifySetted, setSpotifySetted] = useState(false);

	const [deletingLoading, setDeletingLoading] = useState(false);

	useEffect(() => {
		const unblock = props.history.block(edited
			? "Sicuro di voler uscire? Tutte le modifiche non salvate andranno perse."
			: true)
		return function cleanup() {
			unblock();
		};
	}, [edited]);

	useEffect(() => {
		if (localStorage.getItem('authUser')) {
			setLoading(true);
			const userId = JSON.parse(localStorage.getItem('authUser'))._id;
			loadUser(userId)
				.then((result) => {
					if (result.success) {
						setUser(result.user);
						if (result.user && result.user.spotifyId) setSpotifySetted(true);
					} else console.log(result.message);
					setLoading(false);
				})
				.catch((error) => {
					setLoading(false);
					console.log(error);
				});
		}
	}, []);

	function handleOnImageClick() {
		if (imagePicker.current && imagePicker.current.click) imagePicker.current.click();
	}

	function handleOnImageSubmit({ target: { files } }) {
		if (files && files.length > 0) {
			let image = files[0];
			if (image.size > 5000000) window.alert('File troppo grande. Dimensione massima: 5MB');
			else if (!image.type.startsWith('image')) window.alert('Devi caricare una immagine.');
			else {
				new Compressor(image, {
					quality: 0.6,

					// The compression process is asynchronous,
					// which means you have to access the `result` in the `success` hook function.
					success(compressed) {
						setLoadingImage(true);
						editUserImage(compressed)
							.then((result) => {
								if (result.success) {
									setUser(result.updated);
									props.updateUserData && props.updateUserData(result.updated);
								} else setError(result.message);
								setLoadingImage(false);
							})
							.catch((error) => {
								setError(error.message);
								setLoadingImage(false);
							});
					},
					error(error) {
						window.alert(error.message);
					},
				});
			}
		}
	}

	function handleOnRemovePicClick(index) {
		let editedUser = { ...user };
		if (editedUser.pics && editedUser.pics.length > 0) {
			editedUser.pics.splice(index, 1);
			setUser(editedUser);
		}
	}

	function handleOnPicsClick() {
		if (picsPicker.current && picsPicker.current.click) picsPicker.current.click();
	}

	function handleOnPicSubmit({ target: { files } }) {
		if (files && files.length > 0) {
			let pic = files[0];
			if (pic.size > 5000000) window.alert('File troppo grande. Dimensione massima: 5MB');
			else if (!pic.type.startsWith('image')) window.alert('Devi caricare una immagine.');
			else {
				new Compressor(pic, {
					quality: 0.6,

					// The compression process is asynchronous,
					// which means you have to access the `result` in the `success` hook function.
					success(compressed) {
						compressed.preview = URL.createObjectURL(compressed);
						let editedUser = { ...user };
						editedUser.pics ? editedUser.pics.push(compressed) : (editedUser.pics = [compressed]);
						setUser(editedUser);
					},
					error(error) {
						window.alert(error.message);
					},
				});
			}
		}
	}

	function handleOnFieldChange({ target: { name, value } }) {
		let editedUser = { ...user };

		if (['facebook', 'twitter', 'website', 'instagram', 'tiktok', 'youtube'].includes(name)) {
			if (!editedUser.socialLinks) editedUser.socialLinks = {};
			editedUser.socialLinks[name] = value;
		} else editedUser[name] = value;
		setUser(editedUser);
		setEdited(true)
	}

	function handleOnPlaceSelected(place) {
		let editedUser = { ...user };
		if (place) {
			editedUser['placeId'] = place.place_id;
			editedUser['city'] = place.formatted_address;
			editedUser['region'] = place.address_components.find((x) =>
				x.types.includes('administrative_area_level_1')
			)?.short_name;
		} else {
			editedUser['placeId'] = null;
			editedUser['city'] = user.city;
			editedUser['region'] = null;
		}
		setUser(editedUser);
	}

	function handleOnAddAward() {
		let editedUser = { ...user };
		if (!editedUser.awards || !Array.isArray(editedUser.awards)) editedUser.awards = [];
		editedUser.awards.push({ name: '', info: '' });
		setUser(editedUser);
	}

	function handleOnAwardFieldChange({ target: { name, value } }, index) {
		let editedUser = { ...user };
		editedUser['awards'][index][name] = value;
		setUser(editedUser);
	}

	function handleOnDeleteAward(index) {
		let editedUser = { ...user };
		editedUser.awards.splice(index, 1);
		setUser(editedUser);
	}

	function handleOnAddConcert() {
		let editedUser = { ...user };
		if (!editedUser.concerts || !Array.isArray(editedUser.concerts)) editedUser.concerts = [];
		editedUser.concerts.push({ name: '', location: '', date: '', info: '' });
		setUser(editedUser);
	}

	function handleOnConcertFieldChange({ target: { name, value } }, index) {
		if (name === 'date') {
			if (/\D\/$/.test(value)) value = value.substr(0, value.length - 3);
			var values = value.split('/').map(function (v) {
				return v.replace(/\D/g, '');
			});
			if (values[0]) values[0] = checkDateValue(values[0], 31);
			if (values[1]) values[1] = checkDateValue(values[1], 12);
			var output = values.map(function (v, i) {
				return v.length == 2 && i < 2 ? v + ' / ' : v;
			});
			if (output)
				value = output.join('').substr(0, 14);
		}

		let editedUser = { ...user };
		editedUser['concerts'][index][name] = value;
		setUser(editedUser);
	}

	function handleOnDeleteConcert(index) {
		let editedUser = { ...user };
		editedUser.concerts.splice(index, 1);
		setUser(editedUser);
	}

	function handleOnAddCoverClick(pickerId) {
		document.getElementById(pickerId).click();
	}

	function handleOnAddRelease() {
		let editedUser = { ...user };
		if (!editedUser.releases || !Array.isArray(editedUser.releases)) editedUser.releases = [];
		editedUser.releases.push({ title: '', type: 'album', year: '', nTracks: '' });
		setUser(editedUser);
	}

	async function handleOnDeleteRelease(index) {
		if (spotifySetted) {
			await handleOnDeleteSpotifyRelease(index)
		}
		let editedUser = { ...user };
		editedUser.releases.splice(index, 1);
		setUser(editedUser);
	}

	async function handleOnDeleteSpotifyRelease(index) {
		if (index) {
			setDeletingLoading(index);
			deleteUserRelease(index)
			.then((result) => {
				if (!result.success) console.log(result.message);
				setDeletingLoading(false);
			})
			.catch((error) => {
				console.log(error.message);
				setDeletingLoading(false);
			});
		}
	}

	function resetReleases() {
		let editedUser = { ...user };
		editedUser.releases = [];
		editedUser.releases.push({ title: '', type: 'album', year: '', nTracks: '' });
		setUser(editedUser);
	}

	function handleOnReleaseFieldChange({ target: { name, value } }, index) {
		let editedUser = { ...user };
		editedUser['releases'][index][name] = value;
		setUser(editedUser);
	}

	function handleOnReleaseCoverChange(files, index) {
		if (files && files.length > 0) {
			if (files[0].size > 1000000) window.alert('File troppo grande. Dimensioni massine: 1MB.');

			let cover = files[0];
			Object.assign(cover, {
				preview: URL.createObjectURL(cover),
				formattedSize: formatBytes(cover.size),
			});

			let editedUser = { ...user };
			editedUser['releases'][index]['cover'] = cover;
			setUser(editedUser);
		}
	}

	function handleOnReleaseCoverRemove(index) {
		let editedUser = { ...user };
		editedUser['releases'][index]['cover'] = undefined;
		setUser(editedUser);
	}

	function handleOnSpotifyUrlChange({ target: { value } }) {
		let editedUser = { ...user };
		let spotifyId = null;
		if (value) spotifyId = /.*\/([^?]+)/.exec(value)[1];
		editedUser['spotifyId'] = spotifyId;
		console.log('spotifyId', spotifyId);
		setUser(editedUser);
	}

	async function handleOnResetSpotify() {
		let editedUser = { ...user };
		editedUser['spotifyId'] = null;
		editedUser.releases = [];
		editedUser.releases.push({ title: '', type: 'album', year: '', nTracks: '' });
		setUser(editedUser);
		setSpotifySetted(false);
	}

	async function handleOnSpotifySubmit() {
		setSpotifySetted(false);
		setLoadingSpotify(true);
		editUserSpotify(user.spotifyId)
			.then((result) => {
				if (result.success) {
					let editedUser = { ...user };
					if (result.updated)
						editedUser['releases'] = result.updated.releases;
					setUser(editedUser);
					setSpotifySetted(true);
				} else window.alert(result.message);
				setLoadingSpotify(false);
			})
			.catch((error) => {
				setLoadingSpotify(false);
				window.alert(error.message);
			});
	}

	function handleOnDataUpdateSubmit(event) {
		event.preventDefault();
		setLoading(true);
		editUser(user)
			.then((result) => {
				if (result.success) {
					setEdited(false);
					props.updateUserData && props.updateUserData(result.updated);
					props.history.push('/users/' + user._id);
				} else setError(result.message);
				setLoading(false);
			})
			.catch((error) => {
				setError(error.message);
				setLoading(false);
			});
	}

	const spotifyInfo = (
		<>
			<RoundedButton
				id="spotify"
				type="button"
				onClick={(event) => event.stopPropagation()}
				className="p-0 text-center ml-1 mb-0"
				style={{ textTransform: 'lowercase', width: '14px', height: '14px', cursor: 'pointer' }}
				color="secondary outline"
			>
				i
			</RoundedButton>
			<UncontrolledTooltip placement="top" className="info-tooltip" target="spotify">
				<div>
					Vai su <a href="https://open.spotify.com/">Spotify</a>, cerca la tua scheda artista e copia l'URL
					presente nella barra degli indirizzi del tuo browser.
				</div>
			</UncontrolledTooltip>
		</>
	);

	return (
		<>
			{error && <Alert color="danger">{'' + error}</Alert>}
			{loading ? (
				<Loader />
			) : user ? (
				<Form onSubmit={handleOnDataUpdateSubmit}>
					<FormGroup row>
						<Col md="4">
							<h6>Foto Profilo</h6>
							{loadingImage ? (
								<Loader />
							) : user.image ? (
								<div
									style={{
										backgroundImage: 'url(' + user.image.url + ')',
									}}
									className="avatar-xs rounded-circle profile-avatar"
								></div>
							) : null}

							<RoundedButton className="px-5 mt-4" color="primary" onClick={handleOnImageClick}>
								Carica
							</RoundedButton>
							<Input
								innerRef={imagePicker}
								type="file"
								accept="image/jpeg"
								style={{ display: 'none' }}
								onChange={handleOnImageSubmit}
							/>

							<h6>Informazioni</h6>

							<Label for="artistType">Tipologia</Label>
							<Input
								type="select"
								name="artistType"
								id="artistType"
								value={user.artistType}
								onChange={handleOnFieldChange}
							>
								<option selected={!user.artistType} value="">
									-
								</option>
								<option selected={user.artistType === 'solo'} value="solo">
									Solista
								</option>
								<option selected={user.artistType === 'band'} value="band">
									Band
								</option>
								<option selected={user.artistType === 'producer'} value="producer">
									Producer
								</option>
							</Input>
							{user.artistType === 'band' ?
							<>
							<Label for="nMembers">N° componenti</Label>
							<Input
								type="number"
								disabled={user.artistType !== 'band'}
								name="nMembers"
								min={1}
								id="nMembers"
								value={user.nMembers}
								onChange={handleOnFieldChange}
							/>
							</> : null}

							<Label for="city">Città</Label>
							<Autocomplete
								apiKey={'AIzaSyALRFTVJXyYyjpf623vo80ZgdX0O2KBeS0'}
								onChange={(event) => {
									handleOnPlaceSelected(null);
									console.log('changed')
								}}
								onPlaceSelected={handleOnPlaceSelected}
								defaultValue={user.city}
								options={{
									types: ['(cities)'],
									componentRestrictions: { country: 'it' },
								}}
								style={{
									width: '100%',
									padding: '.5em 1em',
									fontStyle: 'italic',
									fontFamily: 'Poppins',
									fontSize: '.75em',
									background: 'transparent',
									borderRadius: '20px',
									marginBottom: '0.5em',
									border: '1px solid rgba(35, 35, 35, .45)',
								}}
							/>

							<Label for="nMembers">Biografia</Label>
							<Input
								type="textarea"
								name="bio"
								id="bio"
								rows={6}
								value={user.bio}
								onChange={handleOnFieldChange}
							/>
						</Col>
						<Col
							md={{
								size: 6,
								offset: 2,
							}}
						>
							<Row>
								<Col md="8">
									<h6 className="mt-3">Link Social</h6>
									<Label for="website">Sito Web</Label>
									<Input
										type="text"
										name="website"
										id="website"
										value={user.socialLinks?.website}
										onChange={handleOnFieldChange}
									/>
									<Label for="facebook">Facebook</Label>
									<Input
										type="text"
										name="facebook"
										id="facebook"
										value={user.socialLinks?.facebook}
										onChange={handleOnFieldChange}
									/>
									<Label for="instagram">Instagram</Label>
									<Input
										type="text"
										name="instagram"
										id="instagram"
										value={user.socialLinks?.instagram}
										onChange={handleOnFieldChange}
									/>
									<Label for="tiktok">TikTok</Label>
									<Input
										type="text"
										name="tiktok"
										id="tiktok"
										value={user.socialLinks?.tiktok}
										onChange={handleOnFieldChange}
									/>
									<Label for="youtube">YouTube</Label>
									<Input
										type="text"
										name="youtube"
										id="youtube"
										value={user.socialLinks?.youtube}
										onChange={handleOnFieldChange}
									/>
								</Col>

								<Col md="12">
									<h6>Foto</h6>
									<div className="pics-scroller">
										{user.pics &&
											user.pics.map((p, i) => (
												<div className="artist-pic" key={'pic+' + i}>
													<Button
														color="link"
														className="remove-pic"
														onClick={() => handleOnRemovePicClick(i)}
													>
														<RemoveIcon />
													</Button>
													<img src={p.preview || p.url} alt="" />
												</div>
											))}

										{!user.pics || user.pics.length <= 5 ? (
											<Button className="add-pic" color="link" onClick={handleOnPicsClick}>
												<PlusIcon />
												<Input
													innerRef={picsPicker}
													type="file"
													accept="image/jpeg"
													style={{
														display: 'none',
													}}
													onChange={handleOnPicSubmit}
												/>
											</Button>
										) : null}
									</div>
								</Col>

								<Col md="8">
									<h6>Video</h6>
									<Label for="youtubeUrl">
										Inserisci un link che compare su YouTube al click su "Condividi"
									</Label>
									<Input
										type="text"
										name="youtubeUrl"
										id="youtubeUrl"
										rows="3"
										value={user.youtubeUrl}
										onChange={handleOnFieldChange}
									/>
								</Col>
							</Row>
						</Col>
					</FormGroup>
					<FormGroup row>
						<Col xs="12" lg="10">
							<hr />
							<Row>
								<Col xs="12" className="d-flex align-items-center mb-2">
									<h6 className="d-inline mb-0">Album/EP/Singoli</h6>
									{!spotifySetted && (
										<Button
											className="add-release d-inline"
											color="link"
											onClick={handleOnAddRelease}
											disabled={spotifySetted}
										>
											<PlusIcon />
										</Button>
									)}
								</Col>
								{spotifySetted ? (
									<Col xs="12" className="d-flex align-items-center mb-2">
										<p className="d-block d-lg-inline ml-0 mr-1 mb-0 text-center">
											{'Il tuo ID Artista Spotify è '}
											<b>{user.spotifyId}</b>
										</p>
										<RoundedButton className="ml-0 mb-0 py-0" onClick={handleOnSpotifySubmit}>
											<RefreshIcon
												style={{
													fill: '#ff3883',
													width: '12px',
													marginRight: '4px',
												}}
											/>
											REFRESH
										</RoundedButton>
										<RoundedButton className="ml-0 mb-0 py-0" onClick={handleOnResetSpotify}>
											<PlusIcon
												style={{
													stroke: '#ff3883',
													width: '12px',
													marginRight: '4px',
													transform: 'rotate(-45deg)',
												}}
											/>
											RESET
										</RoundedButton>
									</Col>
								) : (
									<>
										<Col xs="12" className="d-flex align-items-center">
											<p className="d-block d-lg-inline ml-0 mr-1 mb-3 text-center">
												oppure inserisci il link alla tua scheda artista di Spotify{' '}
												{spotifyInfo}
											</p>
										</Col>
										<Col xs="12" className="d-flex align-items-center">
											{loadingSpotify ? (
												<Loader size="sm" />
											) : (
												<>
													<Input
														name="spotifyUrl"
														type="text"
														className="form-control d-inline w-50 mb-0 mr-2"
														style={{ padding: '1.5em 1.5em', flex: 1 }}
														id="spotifyUrl"
														placeholder="es: https://open.spotify.com/artist/abcdefghijkl0123456789"
														value={user.spotifyUrl}
														onChange={handleOnSpotifyUrlChange}
													/>
													<SpotifyButton
														className="mb-0 d-inline w-auto"
														onClick={handleOnSpotifySubmit}
														disabled={!user.spotifyId}
													/>
												</>
											)}
										</Col>
									</>
								)}
							</Row>
							{!loadingSpotify &&
								user.releases?.map((r, i) => (
									<Row key={'release-' + i}>
										<Col xs="10" lg="11">
											<Row className="pt-3">
												<Col xs="10" lg="3">
													<Label for="type">Tipologia</Label>
													<Input
														type="select"
														name="type"
														id="type"
														value={r.type}
														onChange={(event) => handleOnReleaseFieldChange(event, i)}
														disabled={spotifySetted}
													>
														<option selected={r.type === 'album'} value="album">
															Album
														</option>
														<option selected={r.type === 'single'} value="single">
															EP/Singolo
														</option>
													</Input>
												</Col>
												<Col xs="10" lg="3">
													<Label for="title">Titolo</Label>
													<Input
														type="text"
														name="title"
														id="title"
														value={r.title}
														onChange={(event) => handleOnReleaseFieldChange(event, i)}
														disabled={spotifySetted}
													/>
												</Col>
												<Col xs="10" lg="2">
													<Label for="year">Anno</Label>
													<Input
														type="number"
														step={1}
														name="year"
														id="year"
														value={r.year}
														onChange={({ target: { name, value, files } }) =>
															handleOnReleaseFieldChange(
																{ target: { name, value: Number(value), files } },
																i
															)
														}
														disabled={spotifySetted}
													/>
												</Col>
												<Col xs="10" lg="2">
													<Label for="nTracks">N° di Tracce</Label>
													<Input
														type="number"
														step={1}
														name="nTracks"
														id="nTracks"
														value={r.nTracks}
														onChange={({ target: { name, value, files } }) =>
															handleOnReleaseFieldChange(
																{ target: { name, value: Number(value), files } },
																i
															)
														}
														disabled={spotifySetted}
													/>
												</Col>
												<Col xs="10" lg="2">
													{r.cover && r.cover.preview ? (
														<div className="cover-pic">
															{!spotifySetted && (
																<Button
																	color="link"
																	className="remove-pic"
																	onClick={() => handleOnReleaseCoverRemove(i)}
																>
																	<RemoveIcon />
																</Button>
															)}
															<img src={r.cover.preview} alt="Album cover" />
														</div>
													) : r.cover && r.cover.url ? (
														<div className="cover-pic">
															<img src={r.cover.url} alt="Album cover" />
														</div>
													) : !spotifySetted ? (
														<Dropzone
															accept={['image/jpeg']}
															multiple={false}
															maxFiles={1}
															onDrop={(acceptedFiles) =>
																handleOnReleaseCoverChange(acceptedFiles, i)
															}
														>
															{({ getRootProps, getInputProps }) => (
																<div
																	className="dropzone add-cover w-100 h-100 needsclick"
																	{...getRootProps()}
																>
																	<input {...getInputProps()} />
																	<div className="dz-message needsclick">
																		<PlusIcon />
																		<small className="d-block text-center mt-2">
																			cover
																		</small>
																	</div>
																</div>
															)}
														</Dropzone>
													) : null}
												</Col>
											</Row>
										</Col>
										
											<Col
												xs="2"
												lg="1"
												className="d-flex align-items-center justify-content-center"
											>{deletingLoading === i ? (
												<Loader className="ml-2" size="sm" />
											) : 
												<Button
													// disabled={spotifySetted}
													className="delete-button mb-0 px-0 w-100"
													color="link"
													onClick={() => handleOnDeleteRelease(i)}
												>
													<TrashIcon />
												</Button>}
											</Col>
									</Row>
								))}
						</Col>
					</FormGroup>
					<FormGroup row>
						<Col xs="12" lg="10">
							<h6 className="d-inline mb-0">Storico Concerti</h6>
							<Button className="add-concert" color="link" onClick={handleOnAddConcert}>
								<PlusIcon />
							</Button>
							<hr />
							{user.concerts?.map((c, i) => (
								<Row key={'concert-' + i}>
									<Col xs="10" lg="12">
										<Row className="py-3">
											<Col xs="10" lg="3">
												<Label for="name">Evento</Label>
												<Input
													type="text"
													name="name"
													id="name"
													value={c.name}
													onChange={(event) => handleOnConcertFieldChange(event, i)}
												/>
											</Col>
											<Col xs="10" lg="3">
												<Label for="info">Informazioni</Label>
												<Input
													type="text"
													name="info"
													id="info"
													placeholder="Informazioni aggiuntive..."
													value={c.info}
													onChange={(event) => handleOnConcertFieldChange(event, i)}
												/>
											</Col>
											<Col xs="10" lg="3">
												<Label for="location">Luogo</Label>
												<Input
													type="text"
													name="location"
													id="location"
													value={c.location}
													onChange={(event) => handleOnConcertFieldChange(event, i)}
												/>
											</Col>
											<Col xs="10" lg="2">
												<Label for="date">Data</Label>
												<Input
													type="text"
													name="date"
													id="date"
													placeholder="gg/mm/aaaa"
													value={c.date}
													onChange={(event) => handleOnConcertFieldChange(event, i)}
												/>
											</Col>
											<Col
												xs="0"
												lg="1"
												className="d-none d-lg-flex align-items-end justify-content-center"
											>
												<Button
													className="delete-button mb-2 px-0 w-100"
													color="link"
													onClick={() => handleOnDeleteConcert(i)}
												>
													<TrashIcon />
												</Button>
											</Col>
										</Row>
									</Col>
									<Col
										xs="2"
										lg="0"
										className="d-lg-none d-flex align-items-center justify-content-center"
									>
										<Button
											className="delete-button mb-0 px-0 w-100"
											color="link"
											onClick={() => handleOnDeleteConcert(i)}
										>
											<TrashIcon />
										</Button>
									</Col>
								</Row>
							))}
						</Col>
					</FormGroup>
					<FormGroup row>
						<Col xs="12" lg="10">
							<h6 className="d-inline mb-0">Riconoscimenti</h6>
							<Button className="add-award" color="link" onClick={handleOnAddAward}>
								<PlusIcon />
							</Button>
							<hr />
							{user.awards?.map((a, i) => (
								<Row key={'award-' + i}>
									<Col xs="10" lg="12">
										<Row className="py-3">
											<Col xs="10" lg="4">
												<Label for="name">Nome</Label>
												<Input
													type="text"
													name="name"
													id="name"
													value={a.name}
													onChange={(event) => handleOnAwardFieldChange(event, i)}
												/>
											</Col>
											<Col xs="10" lg="4">
												<Label for="info">Informazioni</Label>
												<Input
													type="text"
													name="info"
													id="info"
													placeholder="Informazioni aggiuntive..."
													value={a.info}
													onChange={(event) => handleOnAwardFieldChange(event, i)}
												/>
											</Col>
											<Col
												xs="0"
												lg={{ size: 1, offset: 3 }}
												className="d-none d-lg-flex align-items-end justify-content-center"
											>
												<Button
													className="delete-button mb-2 px-0 w-100"
													color="link"
													onClick={() => handleOnDeleteAward(i)}
												>
													<TrashIcon />
												</Button>
											</Col>
										</Row>
									</Col>
									<Col
										xs="2"
										lg="0"
										className="d-lg-none d-flex align-items-center justify-content-center"
									>
										<Button
											className="delete-button mb-0 px-0 w-100"
											color="link"
											onClick={() => handleOnDeleteAward(i)}
										>
											<TrashIcon />
										</Button>
									</Col>
								</Row>
							))}
						</Col>
					</FormGroup>
					<FormGroup row>
						<Col xs="12" lg="10">
							<h6>Label</h6>
							<hr />
							<Label for="label">Nome</Label>
							<Input
								type="text"
								name="label"
								id="label"
								className="w-50"
								value={user.label}
								onChange={handleOnFieldChange}
							/>
						</Col>
					</FormGroup>
					<FormGroup row>
						<Col xs="12" lg="10">
							<h6>Management</h6>
							<hr />
							<Label for="management">Nome</Label>
							<Input
								type="text"
								name="management"
								id="management"
								className="w-50"
								value={user.management}
								onChange={handleOnFieldChange}
							/>
						</Col>
					</FormGroup>
					<FormGroup row>
						<Col xs="12" lg="10">
							<h6>Collecting</h6>
							<hr />
							<Label for="collecting">
								Seleziona la società di collecting se presente (puoi selezionare più opzioni)
							</Label>

							<Select
								defaultValue={
									user.collecting && Array.isArray(user.collecting)
										? user.collecting.map((c) => ({
											value: c,
											label: c,
										}))
										: []
								}
								placeholder="Seleziona..."
								isMulti
								name="collecting"
								id="collecting"
								classNamePrefix="select"
								className="w-50"
								options={COLLECTING_OPTS.map((c) => ({
									value: c,
									label: c,
								}))}
								onChange={(selected) =>
									handleOnFieldChange({
										target: {
											name: 'collecting',
											value: selected ? selected.map((s) => s.value) : [],
										},
									})
								}
							/>
						</Col>
					</FormGroup>
					<FormGroup row>
						<Col xs="12" lg="10">
							<RoundedButton
								className="d-block px-5 py-2 mt-4 mb-4 mx-auto"
								color="primary"
								type="submit"
							>
								Salva Modifiche
							</RoundedButton>
						</Col>
					</FormGroup>
				</Form>
			) : null}
		</>
	);
};

export default withRouter(connect(null, { updateUserData })(ArtistsContentsForm));
