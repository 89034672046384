import { Row, Col, Container } from 'reactstrap';

import './Policies.scss'

const Terms = () => (
	<Container className="policies my-5">
		<Row className="pb-3">
			<Col
				className="h-100 d-flex flex-column"
				md={{
					size: 11,
					offset: 1,
				}}
			>
				<h2 className="mb-5">Condizioni generali di contratto</h2>

				<p>
					Ai sensi e per gli effetti dell’art. 49 del Codice del Consumo, HitReview srls. La informa di quanto
					segue:
				</p>
				<ul>
					<li className="mb-2">
						<b>1.</b> Hitreview srls con sede in Roma 00177, Via Prenestina 445 (di seguito: “Venditore”).
					</li>
					<li className="mb-2">
						<b>2.</b> Il sito internet www.hitreview.it (di seguito: “Sito”) è un portale on line di vendita
						del Venditore.{' '}
					</li>
					<li className="mb-2">
						<b>3.</b> Le caratteristiche dei servizi (di seguito: “Servizio” o “Servizi”) sono quelle
						presentate sul sito web e descritte nell'ambito di ciascuna scheda, anche se soggette ad
						eventuali miglioramenti ed implementazioni medio tempore effettuati{' '}
					</li>
					<li className="mb-2">
						<b>4.</b> I prezzi, le tariffe e, in generale, ogni costo applicabile sarà specificamente
						indicato durante il procedimento di acquisto, prima di procedere alla conclusione del contratto.{' '}
					</li>
					<li className="mb-2">
						<b>5.</b> In caso di acquisto di più servizi contemporaneamente, il prezzo definitivo deve
						intendersi quello indicato nella Sua conferma di ricezione dell’ordine e nella email di conferma
						di pagamento e autorizzazione.{' '}
					</li>
					<li className="mb-2">
						<b>6.</b> Il pagamento del prezzo per l’acquisto del servizio o dei servizi avviene attraverso i
						metodi di pagamento “Paypal” o “Stripe”.{' '}
					</li>
					<li className="mb-2">
						<b>7.</b> Le varie fasi tecniche per la stipulazione del contratto e per l’acquisto dei
						Prodotti:
					</li>
					<ul className="ml-4">
						<li className="mb-2">
							<b>a)</b> Registrazione gratuita alla piattaforma.
						</li>
						<li className="mb-2">
							<b>b)</b> Compilazione dati profilo utente.{' '}
						</li>
						<li className="mb-2">
							<b>c)</b> Utilizzo del servizio richiesto previo pagamento e ricezione della mail di
							conferma.
						</li>
					</ul>
					<li className="mb-2">
						<b>8.</b> Dal momento di ricezione della mail di conferma di esecuzione del servizio richiesto,
						Lei perde il diritto al recesso, che diversamente, nel caso in cui il servizio non sia stato già
						prestato e non sia arrivata la relativa mail di conferma, può essere esercitato, senza dover
						indicarne le ragioni, entro 14 giorni dalla conclusione del contratto.{' '}
					</li>
					<li className="mb-2">
						<b>9.</b> Per esercitare il diritto di recesso, Lei può informare il Venditore attraverso:{' '}
					</li>
					<ul className="ml-4">
						<li className="mb-2">
							<b>a)</b> una dichiarazione esplicita della sua decisione di recedere dal contratto da
							inviare al seguente indirizzo: hitreview.italia@gmail.com.it
						</li>
						<li className="mb-2">
							<b>b)</b> Per rispettare il termine di recesso, è sufficiente che Lei invii la comunicazione
							relativa all’esercizio del diritto di recesso prima della scadenza del periodo di recesso.
						</li>
						<p>
							Se Lei recede dal contratto, il Venditore rimborserà tutti i pagamenti che ha effettuato al
							momento della conferma dell’ordine. Tale rimborso sarà effettuato nei Suoi confronti senza
							indebito ritardo e, in ogni caso, non oltre 14 giorni dal giorno in cui il Venditore è
							informato della Sua decisione di recedere dal contratto.
						</p>
						<p>
							Detti rimborsi saranno effettuati utilizzando lo stesso mezzo di pagamento da Lei usato per
							la transazione iniziale, salvo che Lei non abbia espressamente convenuto altrimenti; in ogni
							caso, Lei non dovrà sostenere alcun costo quale conseguenza di tale rimborso.
						</p>
					</ul>
					<ul>
						<li className="mb-2">
							<b>10.</b> Il contratto si intende eseguito dal momento in cui il Venditore riceve l’email
							di conferma.
						</li>
						<li className="mb-2">
							<b>11.</b> Il contratto stipulato sarà archiviato a cura di Venditore e Lei potrà
							richiederne copia inviando una mail al “servizio clienti” al seguente indirizzo email:
							hitreview.italia@gmail.com.it
						</li>
						<li className="mb-2">
							<b>12.</b> Il contratto è regolato dalla legge italiana. Ogni controversia relativa
							all’applicazione, esecuzione, interpretazione e violazione del contratto sarà di competenza
							del Foro di Roma, ad eccezione del foro del consumatore, qualora previsto dalla legge.
						</li>
						<li className="mb-2">
							<b>13.</b> Le segnalazioni e/o i reclami possono essere inviati:
							<ul className="ml-4">
								<li className="mb-2">
									a. A mezzo di posta elettronica all’indirizzo: hitreview.italia@gmail.com.it
								</li>
								<li className="mb-2">
									b. A mezzo di lettera raccomanda a/r a: Hitreview srls. Roma 00177, Via Prenestina
									445.
								</li>
							</ul>
						</li>
					</ul>
				</ul>
				<h2 className="mb-5">Modulo di recesso tipo</h2>
				<p>Ai sensi dell’art. 49, comma 1, lett. h). </p>
				<p className="mb-4">
					Compilare e restituire il presente modulo solo se si desidera recedere dal contratto:
				</p>
				<p>
					Con la presente io/noi<sup>(*)</sup> notifichiamo il recesso dal mio/nostro<sup>(*)</sup> contratto
					di vendita dei seguenti beni/servizi<sup>(*)</sup> <br />• Ordinato il<sup>(*)</sup>/ricevuto il
					<sup>(*)</sup> <br />• Nome del/dei consumatore<sup>(i)</sup>
					<br />• Indirizzo del/dei consumatore<sup>(i)</sup>
					<br />• Firma del/dei consumatore<sup>(i)</sup> (solo se il presente modulo è notificato in versione
					cartacea)
					<br />• Data<sup>(*)</sup>
				</p>
				<p>Cancellare la dicitura inutile.</p>
			</Col>
		</Row>
	</Container>
);

export default Terms;
