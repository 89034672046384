import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';

import Page from '../../components/Layout/Page/Page';
import UserDetail from '../../components/Users/UserDetail/UserDetail';

import './Profile.scss';

const Profile = (props) => {
	const [profileId, setProfileId] = useState(null);

	useEffect(() => {
		if (props.location.pathname) {
			const id =
				props.location.pathname.split('/')[props.location.pathname.split('/').length - 1];
			setProfileId(id);
		}
	}, [props.location.pathname]);

	return <Page>
		<UserDetail userId={profileId} />
	</Page>;
};

export default withRouter(Profile);
