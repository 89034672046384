import {
	LOGIN_USER,
	LOGIN_USER_BY_TOKEN,

	LOGIN_USER_SUCCESS,
	LOGIN_USER_FAILED,
	
	LOGOUT_USER,

	REGISTER_USER, REGISTER_USER_SUCCESSFUL, REGISTER_USER_FAILED,

    UPDATE_USER_DATA
} from './actionTypes';

export const loginUser = (credentials, history, errorCallback) => {
    return {
        type: LOGIN_USER,
        payload: { credentials, history, errorCallback }
    }
}

export const loginUserByToken = (id, token, history, errorCallback) => {
    return {
        type: LOGIN_USER_BY_TOKEN,
        payload: { id, token, history, errorCallback }
    }
}

export const loginSuccess = (user) => {
    return {
        type: LOGIN_USER_SUCCESS,
        payload: user
    }
}

export const loginFailed = () => {
    return {
        type: LOGIN_USER_FAILED
    }
}

export const logoutUser = (history) => {
    return {
        type: LOGOUT_USER,
        payload: { history }
    }
}

export const registerUser = (user, errorCallback, successCallback) => {
    return {
        type: REGISTER_USER,
        payload: { user, errorCallback, successCallback }
    }
}

export const registerUserSuccessful = (user) => {
    return {
        type: REGISTER_USER_SUCCESSFUL,
        payload: user
    }
}

export const registerUserFailed = () => {
    return {
        type: REGISTER_USER_FAILED
    }
}

export const updateUserData = (user) => {
    return {
        type: UPDATE_USER_DATA,
        payload: user
    }
}

