import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Container, Input, Label, FormGroup, Alert, Form } from 'reactstrap';
import { isPlatform } from '@ionic/react';

import RoundedButton from '../../../components/Buttons/RoundedButton/RoundedButton';
import Loader from '../../../components/Common/Loader';

import { ReactComponent as HitReviewLogo } from '../../../assets/images/HitReview_beta.svg';

import { requestPwdReset } from '../../../helpers/api/auth.api';

import '../Auth.scss';

const ForgetPwd = (props) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [success, setSuccess] = useState(false);

	function handleOnSubmit(event) {
		event.preventDefault();
		setError(false)
		setSuccess(false)
		setLoading(true)
		let values = {
			email: event.target.email.value
		};
		requestPwdReset(values)
			.then(result => {
				setLoading(false)
				if (result.success) {
					setSuccess(true)
				} else {
					setError(result.message)
				}
			})
			.catch((error) => {
				setLoading(false)
				setError(error.message);
			});
	}

	useEffect(() => {
		document.body.classList.add('auth-body-bg');

		return () => {
			document.body.classList.remove('auth-body-bg');
		};
	}, []);

	const isMobile = isPlatform('mobile');

	return (
		<Container fluid className="auth">
			<Row>
				<Col lg={{ size: 4, offset: 4 }}>
					<div className="authentication-page-content py-4 mt-3 d-flex flex-column align-items-stretch justify-content-between min-vh-100 w-100">
						<h1 className="text-center mt-5 mb-0">RECUPERO</h1>

						<div className="p-2">

							{success ? (
								<div className='text-center'>
									<h5 className="text-center mb-5">
										Riceverai una e-mail con un link al suo interno
										(controlla anche nello spam!).<br />Clicca sul link per ripristinare la tua password!
									</h5>

									<Link to="/login" className="d-block text-center">
										Torna alla login
									</Link>
									{/* <p className='text-center mb-0'>Non hai ricevuto l'e-mail?</p>
					{loadingMail ? <Loader size={'sm'} />
						: (!successMail && !errorMail) ? <Button color="link" onClick={resendMail} className='text-center py-0 mx-auto my-0'>
							<p className='d-inline'>Inviala nuovamente</p>
						</Button>
							: successMail ? <p className='d-inline'>Inviata correttamente.</p>
								: errorMail ?
									<p className='d-inline'>{errorMail}</p>
									: null
					} */}
								</div>
							) : loading ? (
								<Loader />
							) : (
								<>
									<Form className="form-horizontal" onSubmit={handleOnSubmit}>
										<h5 className="text-center mb-5">Ti invieremo un link all'indirizzo con cui ti sei registrato su HitReview</h5>
										<FormGroup className="auth-form-group-custom mb-4">
											{/* <i className="ri-user-2-line auti-custom-input-icon"></i> */}
											<Label className="d-block text-center" htmlFor="email">
												Mail
											</Label>
											<Input
												name="email"
												type="text"
												className="form-control"
												style={{ padding: '1.5em 1.5em' }}
												id="email"
												placeholder="mariorossi@hitreview.it"
											/>
										</FormGroup>

										<div className="mt-4 mb-5 text-center">
											<RoundedButton color="primary" className="w-50" type="submit">
												RECUPERA
											</RoundedButton>
										</div>
									</Form>

									{error ? (
										<Alert className="text-center" color="danger">
											{error}
										</Alert>
									) : null}

									<p className="mt-4 text-center">
										Ricordi la password?{' '}
										<Link to="/login" className="d-inline">
											{/* <i className="mdi mdi-lock mr-1"></i> */}
											Accedi ora
										</Link>
									</p>
								</>
							)}
						</div>

						<Link to={isMobile ? '#' : "/"}>
							<div className="my-5 mx-auto w-25 text-center">
								<HitReviewLogo className="logo" />
							</div>
						</Link>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default ForgetPwd;
