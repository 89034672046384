import { useEffect, useState } from 'react';
import { Col, Form, FormGroup, Input, Label, Row, Alert } from 'reactstrap';

import Loader from '../../Common/Loader';
import RoundedButton from '../../Buttons/RoundedButton/RoundedButton';

import { editUser, loadUser } from '../../../helpers/api/users.api';

const ScoutsPaymentsForm = (props) => {
    const [user, setUser] = useState(null);
    const [iban, setIban] = useState(null);
    const [ibanHolder, setIbanHolder] = useState(null);

    const [loading, setLoading] = useState(false);

    const [error, setError] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('authUser')) {
            setLoading(true);
            const userId = JSON.parse(localStorage.getItem('authUser'))._id;
            loadUser(userId)
                .then((result) => {
                    if (result.success)
                        setUser(result.user);
                    else console.log(result.message);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                });
        }
    }, []);

    useEffect(() => {
        if (user) {
            setIban(user.iban);
            setIbanHolder(user.ibanHolder);
        }
    }, [user]);

    function handleOnSubmit(event) {
        event.preventDefault();
        setLoading(true);
        editUser({iban, ibanHolder})
            .then((result) => {
                if (result.success) setUser(result.updated);
                else console.log(result.message);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    }

    return loading ? (
        <Loader />
    ) : error ? (<Alert color="danger">{error}</Alert>) : user ?
    <Form onSubmit={handleOnSubmit}>
        <FormGroup className="my-5" row>
            <Col md="5">
                <h6>IBAN</h6>
                <Input
                    type="text"
                    name="iban"
                    id="iban"
                    value={iban}
                    onChange={({target: {value}}) => setIban(value)}
                />
            </Col>
        </FormGroup>
        <FormGroup className="my-5" row>
            <Col md="5">
                <h6>
                    Intestato a
                </h6>
                <Input
                    type="text"
                    name="ibanHolder"
                    id="ibanHolder"
                    value={ibanHolder}
                    onChange={({target: {value}}) => setIbanHolder(value)}
                />
            </Col>
        </FormGroup>

        <Row>
            <Col md="5">
                <RoundedButton
                    color="primary"
                    className="px-5 mb-4"
                    type="submit">
                    Salva
                </RoundedButton>
            </Col>
        </Row>
    </Form> : null;
};

export default ScoutsPaymentsForm;
