import React from 'react';
import { ModalBody, Modal } from 'reactstrap';

import RegisterForm from '../../Forms/Auth/RegisterForm';

import '../Modals.scss';
import './AuthModal.scss';

const RegisterModal = (props) => {

	function handleOnClose() {
		if (props.onClose) props.onClose();
	}

	return (
		<Modal
		className="auth-modal"
			isOpen={props.isOpen}
			autoFocus={true}
			centered={true}
			backdrop={true}
			toggle={handleOnClose}>
			<ModalBody>
				<RegisterForm onCancel={handleOnClose} />
			</ModalBody>
		</Modal>
	);
};

export default RegisterModal;
