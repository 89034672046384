import ObjectID from 'bson-objectid';
import moment from 'moment';
import React from 'react'
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import './OrderRow.scss';

const OrderRow = props => {

    console.log(props)

	return <li className="order-row py-3">
		<Row className='w-100'>
			<Col xs="12" sm="10" className="d-flex flex-column align-items-start justify-content-center">
				<h6 className="mb-1">Ordine n. {props.id}</h6>
				<p>
					{'Totale: € '}{Number(props.transaction.total/100).toFixed(2)}
					{props.transaction.discount ? 
					' - Codice Sconto: ' + props.transaction.discount.code + ' (-' + props.transaction.discount.value + '%)'
					: ''}
				</p>
				<p>{'Effettuato da '}
				{
						props.user ?
						<Link to={'/users/' + props.user._id}><strong>{props.user.name}</strong></Link>
						: 'utente eliminato'}
					{' il '}{moment(ObjectID(props._id).getTimestamp()).format('DD/MM/YYYY')}
				</p>
				{props.transaction && <>
					<p>{'Metodo di pagamento: '}
						<strong>{props.transaction.method === 'card' ? 'Carta di credito (Stripe)' : props.transaction.method === 'paypal' ?  'PayPal' : 'Gratis'}</strong>
						{props.transaction.method !== 'free' && <>
						{' - Tx #'}
						{props.transaction.id}
						</>}
					</p>
				</>}
			</Col>
		</Row>
	</li>;
}

export default withRouter(OrderRow);