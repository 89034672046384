import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { LOGIN_USER, LOGIN_USER_BY_TOKEN, LOGOUT_USER } from '../actionTypes';
import { loginSuccess, loginFailed, loadChats, loadUnreadNotifications, loadTracksToVote, setTracksToVote } from '../../actions';

import * as authAPI from '../../../helpers/api/auth.api';
import * as usersAPI from '../../../helpers/api/users.api';

function* loginUser({ payload: { credentials, history, errorCallback } }) {
	const response = yield call(authAPI.loginUser, credentials);

	if (response.success) {
		localStorage.setItem("authUser", JSON.stringify(response.user));

		yield put(loginSuccess(response.user));

		if (response.user.type === 'admin')
			history.push('/admin/')
		else {
			if (response.user.type === 'scout')
				yield put(loadTracksToVote());
			yield put(loadChats());
			yield put(loadUnreadNotifications());
			history.push('/dashboard');
		}
	} else {
		yield put(loginFailed());
		yield call(errorCallback, response.message);
	}
}

function* loginUserByToken({ payload: { id, token, history, errorCallback } }) {
	const response = yield call(usersAPI.loadUser, id);

	if (response.success) {
		localStorage.setItem("authUser", JSON.stringify(response.user));
		localStorage.setItem('token', token);

		yield put(loginSuccess(response.user));

		if (response.user.type === 'admin')
			history.push('/admin/')
		else {
			if (response.user.type === 'scout')
				yield put(loadTracksToVote());
			yield put(loadChats());
			yield put(loadUnreadNotifications());
			history.push('/dashboard');
		}
	} else {
		yield put(loginFailed());
		yield call(errorCallback, response.message);
	}
}

function* logoutUser({ payload: { history } }) {
	yield put(setTracksToVote([]));
	localStorage.removeItem('authUser');
	localStorage.removeItem('token');
	history.replace('/login');
}

export function* watchUserLogin() {
	yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLoginByToken() {
	yield takeEvery(LOGIN_USER_BY_TOKEN, loginUserByToken);
}

export function* watchUserLogout() {
	yield takeEvery(LOGOUT_USER, logoutUser);
}

function* authSaga() {
	yield all([fork(watchUserLogin), fork(watchUserLoginByToken), fork(watchUserLogout)]);
}

export default authSaga;
