const { API_URL } = require('../../app.config');

export async function loginUser({ email, password }) {
	const body = JSON.stringify({
		email,
		password
	});

	let response = await fetch(API_URL + '/auth/login', {
		method: 'POST',
		body,
		headers: {
			'Content-Type': 'application/json'
		}
	}).then((response) => {
		// console.log(response.headers.get('authorization'))
		localStorage.setItem('token', response.headers.get('authorization'));
		return response.json();
	});

	return response;
}

export async function resendConfirmMail(email) {
	const body = JSON.stringify({
		email
	});

	let response = await fetch(API_URL + '/auth/resend-confirm', {
		method: 'POST',
		body,
		headers: {
			'Content-Type': 'application/json'
		}
	}).then((response) => {
		// console.log(response.headers.get('authorization'))
		return response.json();
	});

	return response;
}

export async function registerUser({
	name,
	// age,
	// city,
	
	// genre,
	// albumsPublished,
	// tracksPublished,
	// formation,
	
	email,
	password,
	confirmPassword,

	terms, consent, newsletter
}) {
	const body = JSON.stringify({
		name,
		// age,
		// city,
		
		// genre,
		// albumsPublished,
		// tracksPublished,
		// formation,

		email,
		password,
		confirmPassword, 

		terms, consent, newsletter
	});

	let response = await fetch(API_URL + '/auth/register', {
		method: 'POST',
		body,
		headers: {
			'Content-Type': 'application/json'
		}
	}).then((response) => response.json());

	if (response.success) return response;
	else throw response.message;
}

export async function requestPwdReset({ email }) {
	let response = await fetch(API_URL + '/auth/request-reset', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({email})
	}).then((response) => response.json());

	if (response.success) return response;
	else throw response.message;
}

export async function resetPassword({ userId, password, token }) {
	if (userId && password && token) {
		let body = JSON.stringify({
			password
		});

		let response = await fetch(API_URL + '/auth/reset/' + userId + '?token=' + token, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body
		}).then((response) => response.json());

		if (response.success) return response;
		else throw response.message;
	} else throw 'Parametri mancanti';
}

// module.exports = { loginUser, registerUser, forgetPassword, resetPassword };

export async function authorizeSpotify() {
	let token = localStorage.getItem('token');

	let headers = new Headers();
	headers.append('Authorization', token);
	headers.append('Content-Type', 'application/json');

	let response = await fetch(API_URL + '/users/spotify', {
		method: 'GET',
		headers
	}).then((response) => response.json());

	if (response.success) return response;
	else throw response.message;
}