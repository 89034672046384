import React from 'react';
import { connect } from 'react-redux';

import ArtistsMobileSettingsMenu from './ArtistsMobileSettingsMenu';
import ScoutsMobileSettingsMenu from './ScoutsMobileSettingsMenu';

import './SettingsMenu.scss';

const SettingsMenu = (props) => {
    return <div className='settings-menu'>
        {props.user.type === 'artist' ? <ArtistsMobileSettingsMenu />
        : props.user.type === 'scout' ? <ScoutsMobileSettingsMenu /> : null}
        </div>
};

const mapStatetoProps = (state) => {
    return { user: state.Auth.user };
};

export default connect(mapStatetoProps, null)(SettingsMenu);
