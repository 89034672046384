import { Row, Col, Container } from 'reactstrap';

import './Policies.scss'

const PrivacyPolicy = () => (
	<Container className="policies my-5">
		<Row className="pb-3">
			<Col
				className="h-100 d-flex flex-column"
				md={{
					size: 11,
					offset: 1,
				}}
			>
				<h2 className="mb-5">Informativa Privacy</h2>

				<p>
					Ai sensi dell'articolo 13 del nuovo Regolamento Europeo 2016/679 relativo alla protezione delle
					persone fisiche con riguardo al Trattamento dei Dati Personali, nonchè alla libera circolazione di
					tali dati (GDPR), la Hitreview srls, con sede legale in Roma 00177, alla Via Prenestina 445, in
					qualità di titolare del trattamento dei dati, informa l'utente che i dati personali forniti,
					formeranno oggetto di operazioni di trattamento nel rispetto della normativa sopracitata e degli
					obblighi di riservatezza cui è ispirata l'attività del Titolare. I dati, previo espresso consenso
					dell’utente, potranno essere utilizzati per fini commerciali e o essere ceduti a terzi e o essere
					utilizzati per l’invio di materiale informativo e o pubblicitario. I dati verranno trattati sia con
					strumenti informatici sia su supporti cartacei sia su ogni altro tipo di supporto idoneo, nel
					rispetto di adeguate misure tecniche ed organizzative di sicurezza previste dal GDPR. I dati
					raccolti verranno conservati per un arco di tempo non superiore al conseguimento delle finalità per
					le quali sono trattati ("principio di limitazione della conservazione", art.5, GDPR) o in base alle
					scadenze previste dalle norme di legge. La verifica sulla obsolescenza dei dati conservati in
					relazione alle finalità per cui sono stati raccolti viene effettuata periodicamente. L'interessato
					ha sempre diritto a richiedere al Titolare l'accesso ai Suoi dati, la rettifica o la cancellazione
					degli stessi, la limitazione del trattamento o la possibilità di opporsi al trattamento, di
					richiedere la portabilità dei dati, di revocare il consenso al trattamento facendo valere questi e
					gli altri diritti previsti dal GDPR tramite semplice comunicazione al Titolare. L'interessato può
					proporre reclamo anche a un'autorità di controllo. Resta inteso che la prestazione del consenso al
					trattamento dati tramite libera scelta è facoltativa, ma indispensabile per procedere con
					l’effettuazione di ordini e o in ogni caso con l’instaurazione di rapporti di natura commerciale.
				</p>
			</Col>
		</Row>
	</Container>
);

export default PrivacyPolicy;
