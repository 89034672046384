import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { UncontrolledTooltip, Tooltip, Button } from 'reactstrap';

import Loader from '../../Common/Loader';

import { increaseStream } from '../../../helpers/api/tracks.api';

import { pause } from '../../../store/actions';

import './TrackWave.scss';

const TrackWave = (props) => {
	const [loading, setLoading] = useState(false);

	const [lastPlay, setLastPlay] = useState(0);
	const [lastPause, setLastPause] = useState(0);

	const [playedSections, setPlayedSections] = useState([]);

	const [totPlayedPerc, setTotPlayedPerc] = useState(0);
	const [session, setSession] = useState(0);

	const [tooltips, setTooltips] = useState([]);

	const waveRef = useRef();
	useEffect(() => {
		if (!waveRef.current && window.WaveSurfer) {
			const waveformEl = document.getElementById('waveform');
			const timelineEl = document.getElementById('timeline');
			if (waveformEl && timelineEl) {
				waveRef.current = window.WaveSurfer.create({
					container: waveformEl,
					barWidth: 4,
					barHeight: 1,
					barRadius: 4,
					responsive: true,
					progressColor: '#FFC300',
					waveColor: '#FF3B83',
					plugins: [
						window.WaveSurfer.cursor.create({
							// showTime: true,
							opacity: 0.5,
							color: 'transparent'
							// customShowTimeStyle: {
							// 	'background-color': 'transparent',
							// 	color: '#f8f0fb',
							// 	padding: '2px',
							// 	'font-size': '10px'
							// }
						}),
						window.WaveSurfer.markers.create({
							markers: []
						}),
						window.WaveSurfer.timeline.create({
							container: timelineEl,
							primaryColor: '#000000',
							secondaryColor: '#000000',
							primaryFontColor: '#F0F0F7',
							secondaryFontColor: '#000000',
							primaryLabelInterval: 2,
							secondaryLabelInterval: () => {
								return 0;
							},
							formatTimeCallback: (seconds) => {
								let label = '';
								if (seconds < 60) label += '' + seconds + 's';
								else if (seconds >= 60 && seconds <= 3600) {
									label += Math.floor(seconds / 60) + 'm ';
									if (seconds % 60 > 0) label += (seconds % 60) + 's';
								}
								return label;
							}
						})
					]
				});
				waveRef.current.on('loading', (progress) => {
					setLoading(true);
					setLastPlay(0);
					setLastPause(0);
					setPlayedSections([]);
					setSession(1);
					setTotPlayedPerc(0);
					setTooltips([]);
					props.onLoading && props.onLoading(progress);
				});
				waveRef.current.on('ready', () => {
					setLoading(false);
					props.onReady && props.onReady();
					if (props.playing) {
						waveRef.current.play();
					}
				});
				waveRef.current.on('play', () => {
					setLastPlay(waveRef.current.getCurrentTime());
				});
				waveRef.current.on('pause', () => {
					// console.log(waveRef.current.getCurrentTime())
					setLastPause(waveRef.current.getCurrentTime());
				});
				waveRef.current.on('finish', () => {
					props.onFinish && props.onFinish();
				});
				waveRef.current.on('seek', (percentage) => {
					console.log('seek', percentage);
					const seconds = waveRef.current.getDuration() * percentage;
					const milliseconds = Math.floor(seconds * 1000);
					props.onPlaying && props.onPlaying(milliseconds);
				});
				waveRef.current.on('audioprocess', (seconds) => {
					if (seconds - lastPause > 0 && seconds - lastPause > 3) {
						setLastPause(seconds); // I trigger lastPause so the useEffect is triggered;
					}
					// console.log('audioprocess', seconds);
					props.onPlaying && props.onPlaying(Math.floor(seconds * 1000));
				});
				waveRef.current.on('marker-click', (event) => {
					// const commentId = event.el.children[1].children[0].id.split['marker'][0];
				});
			}
		}
		return () => {
			if (waveRef.current) {
				props.pause && props.pause();
				waveRef.current.stop();
				waveRef.current.destroy();
			}
		};
	}, []);

	useEffect(() => {
		let newSection = {
			start: lastPlay,
			end: lastPause,
			duration: lastPause - lastPlay
		};
		if (newSection.duration === 0) return;

		let overlappingArray = playedSections.filter((ps, i) => {
			let sx = newSection.start, ex = newSection.end, s0 = ps.start, e0 = ps.end;
			return (sx >= s0 && sx <= e0) || (ex >= s0 && ex <= e0) || (s0 <= sx && ex <= e0) || (s0 >= sx && e0 <= ex);
		});

		let res = overlappingArray.reduce((prev, current, index, array) => {
			let temp = {
				start: 0,
				end: 0,
				duration: 0
			}
			let sx = prev.start, ex = prev.end, s0 = current.start, e0 = current.end;
			if (sx >= s0 && sx <= e0) {
				temp = {
					start: s0, end: ex, duration: ex - s0
				}
			} else if (ex >= s0 && ex <= e0) {
				temp = {
					start: sx, end: e0, duration: e0 - sx
				}
			} else if (s0 <= sx && ex <= e0) {
				temp = current;
			} else if (s0 >= sx && e0 <= ex) {
				temp = prev;
			}
			temp.position = current.position;
			return temp;
		}, newSection);

		let newPsArray = [...playedSections];
		if (res.position === undefined)
			newPsArray.push({
				...res,
				position: playedSections.length
			});
		else newPsArray[res.position] = res;

		setPlayedSections(newPsArray);
	}, [lastPause]);

	useEffect(() => {
		if (waveRef.current && waveRef.current.getDuration() > 0) {
			let tot = playedSections.reduce((prev, curr, i, array) => prev + curr.duration, 0);
			setTotPlayedPerc(tot / waveRef.current.getDuration());
		}
	}, [playedSections]);

	useEffect(() => {
		if (totPlayedPerc > 0.3 && session && props.track) {
			increaseStream(props.track._id);
			setSession(0);
		}
		if (props.percToReach && totPlayedPerc > props.percToReach) 
			props.onPercReached && props.onPercReached();
	}, [totPlayedPerc]);

	useEffect(() => {
		if (waveRef.current && props.track && props.track.file) {
			waveRef.current.empty();
			waveRef.current.load(props.track.file.url);
		}
	}, [props.track]);

	useEffect(() => {
		if (waveRef.current) {
			if (props.playing) {
				waveRef.current.play();
			} else {
				waveRef.current.pause();
			}
		}
	}, [props.playing]);

	useEffect(() => {
		renderComments();
	}, [props.comments]);

	function renderComments() {
		if (waveRef.current) {
			waveRef.current.clearMarkers();
			if (props.comments) {
				let tooltips = [];
				(props.comments).forEach((c, i) => {
					if (c.time) {
						let markerImage = new Image(20, 20);
						markerImage.id = 'marker' + c._id;
						markerImage.class = 'wave-marker-icon';
						markerImage.src = require('../../../assets/images/marker.svg').default;

						waveRef.current.addMarker({
							id: c._id,
							time: c.time / 1000,
							// label: c.text,
							color: '#f8f0fb',
							position: 'top',
							draggable: false,
							markerElement: markerImage
						});

						tooltips.push(
							<UncontrolledTooltip
								key={c._id}
								target={'marker' + c._id}
								placement="top"
								offset="10%">
								<p>{'"' + c.text + '"'}</p>
								<p>{c.sender ? (c.sender._id !== props.user._id ?
									'Scouter #' + (i + 1) + ' '
									: props.user.name) : 'Ex Scouter'
								}</p>
							</UncontrolledTooltip>
						);
					}
				});
				setTooltips(tooltips);
			}
		}
	}

	return (
		<div className="wave-wrapper">
			<div className="wave-container">
				<div id="waveform"></div>
			</div>
			<div className="timeline-container">
				<div id="timeline"></div>
			</div>
			{tooltips}
			{loading && <Loader />}
		</div>
	);
};

const mapStatetoProps = (state) => {
	return {
		user: state.Auth.user,
        player: state.Player
	};
};

const mapDispatchToProps = {
	pause
}

export default connect(mapStatetoProps, mapDispatchToProps)(TrackWave);
