import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useMediaQuery } from 'react-responsive';

import NotificationsDropdown from '../../Dropdown/NotificationsDropdown/NotificationsDropdown';
import ProfileMenu from '../../Dropdown/ProfileMenu/ProfileMenu';

import logo from '../../../assets/images/HitReview_beta_ext.svg';
import { ReactComponent as MessageIcon } from '../../../assets/images/message.svg';
import { ReactComponent as NotificationsIcon } from '../../../assets/images/notifications.svg';
import { ReactComponent as SettingsIcon } from '../../../assets/images/settings.svg';
import { ReactComponent as BackIcon } from '../../../assets/images/expand.svg';

import './ProtectedHeader.scss';

const ProtectedHeader = props => {

    const MOBILE_AND_TABLET = useMediaQuery({
        query: "(max-device-width: 768px)",
    });

    const DESKTOP = useMediaQuery({
        query: "(min-device-width: 769px)",
    });

	const nUnreadNoti = props.notifications ? props.notifications.filter((n) => n.read === false).length : 0;

    return <>
        <header id="page-topbar">
            {MOBILE_AND_TABLET && <div className='header-spacer'></div>}
            <div className="navbar-header">
                {MOBILE_AND_TABLET ?
                <>
                    <div>
                        {props.showBack ? 
                        <Button onClick={() => props.history.goBack()} className="back-button ml-0 mr-2">
                            <BackIcon />
                        </Button>
                        : <Button onClick={() => props.history.push('/settings')} className="settings-button ml-0 mr-2">
                            <SettingsIcon />
                        </Button>}
                    </div>
                    <h5>
                        {document.title}
                    </h5>
                    <div>
                    {!props.showBack && <>
                        <Button onClick={() => props.history.push('/chats')} className="settings-button ml-3 mr-0">
                            <MessageIcon />
                            {props.hasUnreadChats && <span className='dot'></span>}
                        </Button>
                        <Button onClick={() => props.history.push('/notifications')} className="settings-button ml-3 mr-0">
                            <NotificationsIcon />
                            {nUnreadNoti ? <span className='dot'></span> : null}
                        </Button>
                        </>}
                    </div>
                    </>
                        :
                    <>
                        <div className="d-flex align-items-center">
                            <div className="navbar-brand-box">
                                <Link to="/dashboard" className="logo logo-dark">
                                    <img src={logo} />
                                </Link>
                            </div>
                        </div>
                        <div className='center-tools d-flex align-items-center justify-content-end'>
                            <Link to="/help">Aiuto</Link>
                        </div>
                        <div className="d-flex align-items-center">
                            <NotificationsDropdown nUnread={nUnreadNoti} />
                            <Button onClick={() => props.history.push('/settings')} className="settings-button ml-3 mr-2">
                                <SettingsIcon />
                            </Button>
                            <ProfileMenu />
                        </div>
                    </>}
            </div>
        </header>
    </>;
}

function mapStateToProps(state) {
	return {
		hasUnreadChats: state.Chat.unreadChats && state.Chat.unreadChats.length > 0,
		notifications: state.Notifications.list,
		loading: state.Notifications.loading
	};
}

export default connect(mapStateToProps, null)(withRouter(ProtectedHeader));