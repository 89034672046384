import RoundedButton from '../RoundedButton/RoundedButton';

import './SpotifyButton.scss';

const SpotifyButton = (props) => {
	return (
		<RoundedButton
			color="primary"
			{...props}
			type="submit"
			style={{ backgroundColor: '#1DB954' }}
			className={'spotify-button ' + props.className}
		>
			COLLEGA SPOTIFY
		</RoundedButton>
	);
};

export default SpotifyButton;
