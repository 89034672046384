import React, { useState, useEffect } from 'react';
import { ModalFooter, ModalBody, Modal, Col, Input, Row, Alert } from 'reactstrap';
import moment from 'moment';

import Loader from '../../Common/Loader';
import RoundedButton from '../../Buttons/RoundedButton/RoundedButton';

import { commentTrack } from '../../../helpers/api/tracks.api';

import '../Modals.scss';

const CommentTrackModal = (props) => {
	const [comment, setComment] = useState('');
	const [time, setTime] = useState(0);

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);

	useEffect(() => {
		if (props.isOpen) setTime(props.time);
	}, [props.isOpen]);

	function handleOnSubmit() {
		setLoading(true);
		commentTrack(props.track._id, comment, time)
			.then((result) => {
				setLoading(false);
				setComment('');
				if (result.success) {
					setSuccess(result.message);
					setTimeout(() => {
						props.onSuccess && props.onSuccess();
						handleOnClose();
					}, 2000);
				} else {
					setError(result.message);
				}
			})
			.catch((error) => {
				setLoading(false);
				setError(error.message);
			});
	}

	function handleOnClose() {
		if (props.onClose) props.onClose();
		setError(null);
		setSuccess(null);
		setComment('');
	}

	return (
		<Modal
			isOpen={props.isOpen}
			autoFocus={true}
			centered={true}
			backdrop={true}
			toggle={handleOnClose}>
			<ModalBody>
				{loading ? (
					<Loader className="p-5" />
				) : success ? (
					<Alert className="mb-4" color="success">
						{success}
					</Alert>
				) : (
					<Row>
						<Col sm="12">
							<h6>Commento a:</h6>
							<p>
								<b>{props.track.title}</b>
								{' di ' +
									(props.track.author ? props.track.author.name : 'Utente eliminato') +
									' - ' +
									moment(time).format("mm'ss''")}
							</p>
							<Input
								id="comment"
								name="comment"
								rows={6}
								type="textarea"
								className="form-control"
								onChange={(event) => setComment(event.target.value)}
							/>
							<p className="error">{error}</p>
						</Col>
					</Row>
				)}
			</ModalBody>
			{!loading && !success && (
				<ModalFooter>
					<RoundedButton
						className="waves-effect px-5 m-0 mb-2"
						type="button"
						color="primary"
						onClick={handleOnSubmit}>
						Invia
					</RoundedButton>
				</ModalFooter>
			)}
		</Modal>
	);
};

export default CommentTrackModal;
